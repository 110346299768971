var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Store } from '@ngrx/store';
export var SummaryActionTypes;
(function (SummaryActionTypes) {
    SummaryActionTypes["LOAD_SUMMARY"] = "[Summaries] Load Summary";
    SummaryActionTypes["LOAD_SUMMARY_SUCCESS"] = "[Summaries] Load Summary Success";
    SummaryActionTypes["LOAD_SUMMARY_ERROR"] = "[Summaries] Load Summary Error";
    SummaryActionTypes["SAVE_SUMMARY"] = "[Summaries] Save Summary";
    SummaryActionTypes["SAVE_SUMMARY_SUCCESS"] = "[Summaries] Save Summary Success";
    SummaryActionTypes["SAVE_SUMMARY_ERROR"] = "[Summaries] Save Summary Error";
    SummaryActionTypes["UPDATE_SUMMARY"] = "[Summaries] Update Summary";
    SummaryActionTypes["UPDATE_SUMMARY_SUCCESS"] = "[Summaries] Update Summary Success";
    SummaryActionTypes["UPDATE_SUMMARY_ERROR"] = "[Summaries] Update Summary Error";
    SummaryActionTypes["SIGN_SUMMARY"] = "[Summaries] Sign Summary";
    SummaryActionTypes["SIGN_SUMMARY_SUCCESS"] = "[Summaries] Sign Summary Success";
    SummaryActionTypes["SIGN_SUMMARY_ERROR"] = "[Summaries] Sign Summary Error";
    SummaryActionTypes["DELETE_SUMMARY"] = "[Summaries] Delete Summary";
    SummaryActionTypes["DELETE_SUMMARY_SUCCESS"] = "[Summaries] Delete Summary Success";
    SummaryActionTypes["DELETE_SUMMARY_ERROR"] = "[Summaries] Delete Summary Error";
    SummaryActionTypes["REDACT_SUMMARY"] = "[Summaries] Redact Summary";
    SummaryActionTypes["REDACT_SUMMARY_SUCCESS"] = "[Summaries] Redact Summary Success";
    SummaryActionTypes["REDACT_SUMMARY_ERROR"] = "[Summaries] Redact Summary Error";
    SummaryActionTypes["LINK_PATIENT_ALLERGIES"] = "[Summaries] Link Patient Allergies";
    SummaryActionTypes["LINK_PATIENT_ALLERGIES_SUCCESS"] = "[Summaries] Link Patient Allergies Success";
    SummaryActionTypes["LINK_PATIENT_ALLERGIES_ERROR"] = "[Summaries] Link Patient Allergies Error";
    SummaryActionTypes["LINK_PROBLEMS"] = "[Summaries] Link Problems";
    SummaryActionTypes["LINK_PROBLEMS_SUCCESS"] = "[Summaries] Link Problems Success";
    SummaryActionTypes["LINK_PROBLEMS_ERROR"] = "[Summaries] Link Problems Error";
    SummaryActionTypes["LINK_HEALTH_GOALS"] = "[Summaries] Link Health Goals";
    SummaryActionTypes["LINK_HEALTH_GOALS_SUCCESS"] = "[Summaries] Link Health Goals Success";
    SummaryActionTypes["LINK_HEALTH_GOALS_ERROR"] = "[Summaries] Link Health Goals Error";
    SummaryActionTypes["LINK_HEALTH_BACKGROUND"] = "[Summaries] Link Health Background";
    SummaryActionTypes["LINK_HEALTH_BACKGROUND_SUCCESS"] = "[Summaries] Link Health Background Success";
    SummaryActionTypes["LINK_HEALTH_BACKGROUND_ERROR"] = "[Summaries] Link Health Background Error";
    SummaryActionTypes["LINK_MEDICATIONS"] = "[Summaries] Link Medications";
    SummaryActionTypes["LINK_MEDICATIONS_SUCCESS"] = "[Summaries] Link Medications Success";
    SummaryActionTypes["LINK_MEDICATIONS_ERROR"] = "[Summaries] Link Medications Error";
    SummaryActionTypes["LINK_VACCINATIONS"] = "[Summaries] Link Vaccinations";
    SummaryActionTypes["LINK_VACCINATIONS_SUCCESS"] = "[Summaries] Link Vaccinations Success";
    SummaryActionTypes["LINK_VACCINATIONS_ERROR"] = "[Summaries] Link Vaccinations Error";
    SummaryActionTypes["LINK_ASSESSED_PROBLEMS"] = "[Summaries] Link Assessed Problems";
    SummaryActionTypes["LINK_ASSESSED_PROBLEMS_SUCCESS"] = "[Summaries] Link Assessed Problems Success";
    SummaryActionTypes["LINK_ASSESSED_PROBLEMS_ERROR"] = "[Summaries] Link Assessed Problems Error";
    SummaryActionTypes["UNLINK"] = "[Summaries] Unlink Section";
    SummaryActionTypes["UNLINK_SUCCESS"] = "[Summaries] Unlink Section Success";
    SummaryActionTypes["UNLINK_ERROR"] = "[Summaries] Unlink Section Error";
    SummaryActionTypes["SET_CURRENT_SUMMARY_ID"] = "[Summaries] Set Current Summary Id";
    SummaryActionTypes["REFRESH_TIMELINE"] = "[Summaries] Refresh Timeline";
    SummaryActionTypes["REFRESH_TIMELINE_DONE"] = "[Summaries] Refresh Timeline Done";
    SummaryActionTypes["CLOSE_TIMELINE_ITEM"] = "[Summaries] Close Opened Timeline Item";
    SummaryActionTypes["CLOSE_TIMELINE_ITEM_DONE"] = "[Summaries] Close Opened Timeline Item Done";
    SummaryActionTypes["CLOSE_WORKSPACE_ITEM"] = "[Summaries] Close workspace item";
    SummaryActionTypes["SAVE_ADDENDUM"] = "[Summaries] Save Addendum";
    SummaryActionTypes["SAVE_ADDENDUM_SUCCESS"] = "[Summaries] Save Addendum Success";
    SummaryActionTypes["SAVE_ADDENDUM_ERROR"] = "[Summaries] Save Addendum Error";
    SummaryActionTypes["UPDATE_TOTAL_COMMENT_COUNT"] = "[Summaries] Update total comment count";
})(SummaryActionTypes || (SummaryActionTypes = {}));
var LoadSummary = /** @class */ (function () {
    function LoadSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LOAD_SUMMARY;
    }
    return LoadSummary;
}());
export { LoadSummary };
var LoadSummarySuccess = /** @class */ (function () {
    function LoadSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LOAD_SUMMARY_SUCCESS;
        this.error = false;
    }
    return LoadSummarySuccess;
}());
export { LoadSummarySuccess };
var LoadSummaryError = /** @class */ (function () {
    function LoadSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LOAD_SUMMARY_ERROR;
        this.error = true;
    }
    return LoadSummaryError;
}());
export { LoadSummaryError };
var SaveSummary = /** @class */ (function () {
    function SaveSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_SUMMARY;
    }
    return SaveSummary;
}());
export { SaveSummary };
var SaveSummarySuccess = /** @class */ (function () {
    function SaveSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_SUMMARY_SUCCESS;
        this.error = false;
    }
    return SaveSummarySuccess;
}());
export { SaveSummarySuccess };
var SaveSummaryError = /** @class */ (function () {
    function SaveSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_SUMMARY_ERROR;
        this.error = true;
    }
    return SaveSummaryError;
}());
export { SaveSummaryError };
var UpdateSummary = /** @class */ (function () {
    function UpdateSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UPDATE_SUMMARY;
    }
    return UpdateSummary;
}());
export { UpdateSummary };
var UpdateSummarySuccess = /** @class */ (function () {
    function UpdateSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UPDATE_SUMMARY_SUCCESS;
        this.error = false;
    }
    return UpdateSummarySuccess;
}());
export { UpdateSummarySuccess };
var UpdateTotalSummaryCommentCount = /** @class */ (function () {
    function UpdateTotalSummaryCommentCount(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UPDATE_TOTAL_COMMENT_COUNT;
        this.error = false;
    }
    return UpdateTotalSummaryCommentCount;
}());
export { UpdateTotalSummaryCommentCount };
var UpdateSummaryError = /** @class */ (function () {
    function UpdateSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UPDATE_SUMMARY_ERROR;
        this.error = true;
    }
    return UpdateSummaryError;
}());
export { UpdateSummaryError };
var SignSummary = /** @class */ (function () {
    function SignSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SIGN_SUMMARY;
    }
    return SignSummary;
}());
export { SignSummary };
var SignSummarySuccess = /** @class */ (function () {
    function SignSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SIGN_SUMMARY_SUCCESS;
        this.error = false;
    }
    return SignSummarySuccess;
}());
export { SignSummarySuccess };
var SignSummaryError = /** @class */ (function () {
    function SignSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SIGN_SUMMARY_ERROR;
        this.error = true;
    }
    return SignSummaryError;
}());
export { SignSummaryError };
var DeleteSummary = /** @class */ (function () {
    function DeleteSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.DELETE_SUMMARY;
    }
    return DeleteSummary;
}());
export { DeleteSummary };
var DeleteSummarySuccess = /** @class */ (function () {
    function DeleteSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.DELETE_SUMMARY_SUCCESS;
        this.error = false;
    }
    return DeleteSummarySuccess;
}());
export { DeleteSummarySuccess };
var DeleteSummaryError = /** @class */ (function () {
    function DeleteSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.DELETE_SUMMARY_ERROR;
        this.error = true;
    }
    return DeleteSummaryError;
}());
export { DeleteSummaryError };
var RedactSummary = /** @class */ (function () {
    function RedactSummary(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.REDACT_SUMMARY;
    }
    return RedactSummary;
}());
export { RedactSummary };
var RedactSummarySuccess = /** @class */ (function () {
    function RedactSummarySuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.REDACT_SUMMARY_SUCCESS;
        this.error = false;
    }
    return RedactSummarySuccess;
}());
export { RedactSummarySuccess };
var RedactSummaryError = /** @class */ (function () {
    function RedactSummaryError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.REDACT_SUMMARY_ERROR;
        this.error = true;
    }
    return RedactSummaryError;
}());
export { RedactSummaryError };
var LinkPatientAllergies = /** @class */ (function () {
    function LinkPatientAllergies(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PATIENT_ALLERGIES;
    }
    return LinkPatientAllergies;
}());
export { LinkPatientAllergies };
var LinkPatientAllergiesSuccess = /** @class */ (function () {
    function LinkPatientAllergiesSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PATIENT_ALLERGIES_SUCCESS;
        this.error = false;
    }
    return LinkPatientAllergiesSuccess;
}());
export { LinkPatientAllergiesSuccess };
var LinkPatientAllergiesError = /** @class */ (function () {
    function LinkPatientAllergiesError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PATIENT_ALLERGIES_ERROR;
        this.error = true;
    }
    return LinkPatientAllergiesError;
}());
export { LinkPatientAllergiesError };
var LinkProblems = /** @class */ (function () {
    function LinkProblems(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PROBLEMS;
    }
    return LinkProblems;
}());
export { LinkProblems };
var LinkProblemsSuccess = /** @class */ (function () {
    function LinkProblemsSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PROBLEMS_SUCCESS;
        this.error = false;
    }
    return LinkProblemsSuccess;
}());
export { LinkProblemsSuccess };
var LinkProblemsError = /** @class */ (function () {
    function LinkProblemsError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_PROBLEMS_ERROR;
        this.error = true;
    }
    return LinkProblemsError;
}());
export { LinkProblemsError };
var LinkAssessedProblems = /** @class */ (function () {
    function LinkAssessedProblems(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS;
    }
    return LinkAssessedProblems;
}());
export { LinkAssessedProblems };
var LinkAssessedProblemsSuccess = /** @class */ (function () {
    function LinkAssessedProblemsSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS_SUCCESS;
    }
    return LinkAssessedProblemsSuccess;
}());
export { LinkAssessedProblemsSuccess };
var LinkAssessedProblemsError = /** @class */ (function () {
    function LinkAssessedProblemsError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS_ERROR;
    }
    return LinkAssessedProblemsError;
}());
export { LinkAssessedProblemsError };
var LinkHealthGoals = /** @class */ (function () {
    function LinkHealthGoals(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_GOALS;
    }
    return LinkHealthGoals;
}());
export { LinkHealthGoals };
var LinkHealthGoalsSuccess = /** @class */ (function () {
    function LinkHealthGoalsSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_GOALS_SUCCESS;
        this.error = false;
    }
    return LinkHealthGoalsSuccess;
}());
export { LinkHealthGoalsSuccess };
var LinkHealthGoalsError = /** @class */ (function () {
    function LinkHealthGoalsError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_GOALS_ERROR;
        this.error = true;
    }
    return LinkHealthGoalsError;
}());
export { LinkHealthGoalsError };
var LinkHealthBackground = /** @class */ (function () {
    function LinkHealthBackground(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_BACKGROUND;
    }
    return LinkHealthBackground;
}());
export { LinkHealthBackground };
var LinkHealthBackgroundSuccess = /** @class */ (function () {
    function LinkHealthBackgroundSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_BACKGROUND_SUCCESS;
        this.error = false;
    }
    return LinkHealthBackgroundSuccess;
}());
export { LinkHealthBackgroundSuccess };
var LinkHealthBackgroundError = /** @class */ (function () {
    function LinkHealthBackgroundError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_HEALTH_BACKGROUND_ERROR;
        this.error = true;
    }
    return LinkHealthBackgroundError;
}());
export { LinkHealthBackgroundError };
var LinkMedications = /** @class */ (function () {
    function LinkMedications(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_MEDICATIONS;
    }
    return LinkMedications;
}());
export { LinkMedications };
var LinkMedicationsSuccess = /** @class */ (function () {
    function LinkMedicationsSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_MEDICATIONS_SUCCESS;
        this.error = false;
    }
    return LinkMedicationsSuccess;
}());
export { LinkMedicationsSuccess };
var LinkMedicationsError = /** @class */ (function () {
    function LinkMedicationsError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_MEDICATIONS_ERROR;
        this.error = true;
    }
    return LinkMedicationsError;
}());
export { LinkMedicationsError };
var LinkVaccinations = /** @class */ (function () {
    function LinkVaccinations(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_VACCINATIONS;
    }
    return LinkVaccinations;
}());
export { LinkVaccinations };
var LinkVaccinationsSuccess = /** @class */ (function () {
    function LinkVaccinationsSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_VACCINATIONS_SUCCESS;
        this.error = false;
    }
    return LinkVaccinationsSuccess;
}());
export { LinkVaccinationsSuccess };
var LinkVaccinationsError = /** @class */ (function () {
    function LinkVaccinationsError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.LINK_VACCINATIONS_ERROR;
        this.error = true;
    }
    return LinkVaccinationsError;
}());
export { LinkVaccinationsError };
var Unlink = /** @class */ (function () {
    function Unlink(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UNLINK;
        switch (payload.type) {
            case 'Problems': {
                payload.extraFields = __assign({}, payload.extraFields);
                break;
            }
            case 'Allergies': {
                payload.extraFields = __assign({}, payload.extraFields, { patient_allergy_history_ids: [], no_known_allergies: false });
                break;
            }
            case 'Medications': {
                payload.extraFields = {
                    no_medications: false,
                    patient_medication_regimen_ids: [],
                };
                break;
            }
            case 'Vaccines': {
                payload.extraFields = __assign({}, payload.extraFields, { vaccine_history_ids: [] });
                break;
            }
            case 'Health-Background': {
                payload.extraFields = __assign({}, payload.extraFields, { health_background_history_id: null });
                break;
            }
            case 'Health-Maintenance': {
                payload.extraFields = __assign({}, payload.extraFields, { health_goal_history_ids: [], health_goal_screening_history_ids: [], health_maintenance_note_history_id: null });
                break;
            }
        }
    }
    return Unlink;
}());
export { Unlink };
var UnlinkSuccess = /** @class */ (function () {
    function UnlinkSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UNLINK_SUCCESS;
    }
    return UnlinkSuccess;
}());
export { UnlinkSuccess };
var UnlinkError = /** @class */ (function () {
    function UnlinkError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.UNLINK_ERROR;
    }
    return UnlinkError;
}());
export { UnlinkError };
var SetCurrentSummaryId = /** @class */ (function () {
    function SetCurrentSummaryId(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SET_CURRENT_SUMMARY_ID;
    }
    return SetCurrentSummaryId;
}());
export { SetCurrentSummaryId };
var RefreshTimeline = /** @class */ (function () {
    function RefreshTimeline() {
        this.type = SummaryActionTypes.REFRESH_TIMELINE;
    }
    return RefreshTimeline;
}());
export { RefreshTimeline };
var RefreshTimelineDone = /** @class */ (function () {
    function RefreshTimelineDone() {
        this.type = SummaryActionTypes.REFRESH_TIMELINE_DONE;
    }
    return RefreshTimelineDone;
}());
export { RefreshTimelineDone };
var CloseTimelineItem = /** @class */ (function () {
    function CloseTimelineItem() {
        this.type = SummaryActionTypes.CLOSE_TIMELINE_ITEM;
    }
    return CloseTimelineItem;
}());
export { CloseTimelineItem };
var CloseTimelineItemDone = /** @class */ (function () {
    function CloseTimelineItemDone() {
        this.type = SummaryActionTypes.CLOSE_TIMELINE_ITEM_DONE;
    }
    return CloseTimelineItemDone;
}());
export { CloseTimelineItemDone };
var CloseWorkspaceItem = /** @class */ (function () {
    function CloseWorkspaceItem() {
        this.type = SummaryActionTypes.CLOSE_WORKSPACE_ITEM;
    }
    return CloseWorkspaceItem;
}());
export { CloseWorkspaceItem };
var SaveAddendum = /** @class */ (function () {
    function SaveAddendum(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_ADDENDUM;
    }
    return SaveAddendum;
}());
export { SaveAddendum };
var SaveAddendumSuccess = /** @class */ (function () {
    function SaveAddendumSuccess(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_ADDENDUM_SUCCESS;
    }
    return SaveAddendumSuccess;
}());
export { SaveAddendumSuccess };
var SaveAddendumError = /** @class */ (function () {
    function SaveAddendumError(payload) {
        this.payload = payload;
        this.type = SummaryActionTypes.SAVE_ADDENDUM_ERROR;
    }
    return SaveAddendumError;
}());
export { SaveAddendumError };
var SummariesActions = /** @class */ (function () {
    function SummariesActions(store) {
        this.store = store;
    }
    SummariesActions.prototype.loadSummary = function (payload) {
        this.store.dispatch(new LoadSummary(payload));
    };
    SummariesActions.prototype.updateSummarySuccess = function (payload) {
        this.store.dispatch(new UpdateSummarySuccess({ id: payload.id, changes: __assign({}, payload) }));
    };
    SummariesActions.prototype.updateTotalCommentCount = function (payload) {
        this.store.dispatch(new UpdateTotalSummaryCommentCount({
            id: payload.id,
            changes: payload,
        }));
    };
    SummariesActions.prototype.saveSummary = function (payload) {
        this.store.dispatch(new SaveSummary(payload));
    };
    SummariesActions.prototype.updateSummary = function (payload) {
        this.store.dispatch(new UpdateSummary(payload));
    };
    SummariesActions.prototype.signSummary = function (payload) {
        this.store.dispatch(new SignSummary(payload));
    };
    SummariesActions.prototype.deleteSummary = function (payload) {
        this.store.dispatch(new DeleteSummary(payload));
    };
    SummariesActions.prototype.redactSummary = function (payload) {
        this.store.dispatch(new RedactSummary(payload));
    };
    SummariesActions.prototype.linkPatientAllergies = function (payload) {
        this.store.dispatch(new LinkPatientAllergies(payload));
    };
    SummariesActions.prototype.linkProblems = function (payload) {
        this.store.dispatch(new LinkProblems(payload));
    };
    SummariesActions.prototype.linkAssessedProblems = function (payload) {
        this.store.dispatch(new LinkAssessedProblems(payload));
    };
    SummariesActions.prototype.linkHealthGoals = function (payload) {
        this.store.dispatch(new LinkHealthGoals(payload));
    };
    SummariesActions.prototype.linkHealthBackground = function (payload) {
        this.store.dispatch(new LinkHealthBackground(payload));
    };
    SummariesActions.prototype.linkMedications = function (payload) {
        this.store.dispatch(new LinkMedications(payload));
    };
    SummariesActions.prototype.linkVaccinations = function (payload) {
        this.store.dispatch(new LinkVaccinations(payload));
    };
    SummariesActions.prototype.unlink = function (payload) {
        this.store.dispatch(new Unlink(payload));
    };
    SummariesActions.prototype.setCurrentSummaryId = function (payload) {
        this.store.dispatch(new SetCurrentSummaryId(payload));
    };
    SummariesActions.prototype.refreshTimeline = function () {
        this.store.dispatch(new RefreshTimeline());
    };
    SummariesActions.prototype.refreshTimelineDone = function () {
        this.store.dispatch(new RefreshTimelineDone());
    };
    SummariesActions.prototype.closeTimelineItem = function () {
        this.store.dispatch(new CloseTimelineItem());
    };
    SummariesActions.prototype.closeTimelineItemDone = function () {
        this.store.dispatch(new CloseTimelineItemDone());
    };
    SummariesActions.prototype.closeWorkspaceItem = function () {
        this.store.dispatch(new CloseWorkspaceItem());
    };
    SummariesActions.prototype.saveAddendum = function (payload) {
        this.store.dispatch(new SaveAddendum(payload));
    };
    return SummariesActions;
}());
export { SummariesActions };
