import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DropdownItem } from '@app/shared/components';
import {
  fuzzyDateValidator,
  setFormErrors,
  wholeNumberValidator,
} from '@app/shared/forms';

import { buildScreeningEventOptions } from '../../shared/health-goal-type-options';
import {
  HealthGoalActionAllowedResults,
  HealthGoalScreeningErrors,
  HealthGoalScreeningForm,
  HealthGoalType,
  newEntityId,
} from '../../shared/health-maintenance.type';

@Component({
  selector: 'omg-screening-form',
  templateUrl: './screening-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningFormComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<HealthGoalScreeningForm>();
  @Output() delete = new EventEmitter<HealthGoalScreeningForm>();
  @Output() cancel = new EventEmitter();

  form: FormGroup;
  eventOptions: DropdownItem[];
  selectedEventName: string;
  normalizeResult: boolean;
  allowedResults: HealthGoalActionAllowedResults;

  private _formValue: HealthGoalScreeningForm;
  private _healthGoalType: HealthGoalType;
  private _unsubscribe = new Subject();

  @Input() get healthGoalType() {
    return this._healthGoalType;
  }

  set healthGoalType(value: HealthGoalType) {
    if (value && this._healthGoalType !== value) {
      this._healthGoalType = value;
      this.eventOptions = buildScreeningEventOptions(value.healthGoalActions);
      this.onEventIdChange();
    }
  }

  @Input()
  get formValue() {
    return this.form.value;
  }

  set formValue(value: HealthGoalScreeningForm) {
    if (this._formValue !== value) {
      this._formValue = value;
      this.setFormValue(value);
    }
  }

  @Input()
  get formErrors() {
    return this.form.errors;
  }

  set formErrors(errors: HealthGoalScreeningErrors) {
    setFormErrors(this.form, errors, 'apiError');
  }

  get isNewScreening() {
    const id = this.form.get('id').value;
    return id === null || id === newEntityId;
  }

  get eventId(): number {
    return this.form.get('eventId').value;
  }

  constructor() {
    this.buildForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  onEventIdChange() {
    this.setSelectedEventName();
    this.setAllowedResults();
    this.setResultValidators();
  }

  onCancel() {
    this.resetForm();
    this.cancel.emit();
  }

  onSave() {
    this.save.emit(this.form.value);
  }

  onDelete() {
    this.delete.emit(this.form.value);
  }

  private buildForm() {
    this.form = new FormGroup({
      id: new FormControl(),
      dateFuzzy: new FormControl(null, {
        validators: [Validators.required, fuzzyDateValidator],
      }),
      eventId: new FormControl(null, Validators.required),
      healthGoalId: new FormControl(),
      normalized: new FormControl(),
      patientId: new FormControl(),
      patientSubmitted: new FormControl(),
      result: new FormControl(null, Validators.required),
    });

    this.resetForm();
  }

  private subscribeToValueChanges() {
    this.form
      .get('eventId')
      .valueChanges.pipe(
        tap(id => {
          this.onEventIdChange();
        }),
        takeUntil(this._unsubscribe),
      )
      .subscribe();
  }

  private unsubscribeToValueChanges() {
    this._unsubscribe.next();
  }

  private setFormValue(value: HealthGoalScreeningForm) {
    this.unsubscribeToValueChanges();

    this.form.setValue(value);
    this.setNormalizeResult();
    this.onEventIdChange();

    this.form.markAsPristine();
    this.subscribeToValueChanges();
  }

  private resetForm() {
    this.unsubscribeToValueChanges();

    this.form.reset(this._formValue);
    this.form.markAsPristine();

    this.subscribeToValueChanges();
  }

  private findAction(eventId: number) {
    const action =
      this._healthGoalType &&
      this._healthGoalType.healthGoalActions.find(a => a.id === eventId);
    return action;
  }

  private setSelectedEventName() {
    const action =
      this._healthGoalType &&
      this._healthGoalType.healthGoalActions.find(a => a.id === this.eventId);
    this.selectedEventName = (action && action.name) || null;
  }

  private setNormalizeResult() {
    const id = this.form.get('id').value as number;
    const normalized = this.form.get('normalized').value as boolean;
    this.normalizeResult = normalized || this.isNewScreening;
  }

  private setAllowedResults() {
    const selectedAction = this.findAction(this.eventId);
    this.allowedResults = selectedAction ? selectedAction.allowedResults : null;
  }

  private setResultValidators() {
    const resultControl = this.form.get('result');
    const type = this.allowedResults && this.allowedResults.type;
    let resultTypeValidators = [];

    if (type === 'integer') {
      resultTypeValidators = [
        Validators.min(this.allowedResults.min),
        Validators.max(this.allowedResults.max),
        wholeNumberValidator(),
      ];
    }

    resultControl.setValidators([Validators.required, ...resultTypeValidators]);
  }
}
