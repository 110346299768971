import { Patient } from '../shared/patient.type';
import * as patientActions from './patient.actions';

export const patientStatePath = 'patient';

export interface PatientState {
  entity: Patient;
  error: any;
  loading: boolean;
}

export const patientInitialState: PatientState = {
  entity: null,
  error: null,
  loading: false,
};

export function patientReducer(
  state = patientInitialState,
  action: patientActions.PatientAction,
): PatientState {
  switch (action.type) {
    case patientActions.GET_PATIENT: {
      return {
        ...state,
        loading: true,
      };
    }

    case patientActions.GET_PATIENT_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        entity: action.payload,
      };
    }

    case patientActions.GET_PATIENT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        entity: null,
      };
    }

    case patientActions.UPDATE_PATIENT: {
      return {
        ...state,
        loading: true,
      };
    }

    case patientActions.UPDATE_PATIENT_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        entity: action.payload,
      };
    }

    case patientActions.UPDATE_PATIENT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
}
