var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createEntityMetadataSelectors, orderBy, pipe } from '@app/utils';
import { groupBy } from 'lodash';
import { vaccinationsStatePath, } from './vaccinations.reducer';
import * as fromVaccineHistories from './vaccine-histories.reducer';
export var selectVaccinationsState = createFeatureSelector(vaccinationsStatePath);
export var selectVaccineHistoriesState = createSelector(selectVaccinationsState, function (state) { return state.vaccineHistories; });
export var selectVaccineHistoriesIds = createSelector(selectVaccineHistoriesState, fromVaccineHistories.selectVaccineHistoriesIds);
export var selectVaccineHistoriesEntities = createSelector(selectVaccineHistoriesState, fromVaccineHistories.selectVaccineHistoriesEntities);
var _selectAllVaccineHistories = createSelector(selectVaccineHistoriesState, fromVaccineHistories.selectAllVaccineHistories);
export var selectAllVaccineHistories = createSelector(_selectAllVaccineHistories, function (vaccineHistories) {
    return pipe(orderBy('id', 'asc'), orderBy('givenOn', 'desc'), orderBy('name', 'asc'))(vaccineHistories);
});
export var selectAllVaccineHistoryIds = createSelector(selectAllVaccineHistories, function (vaccineHistories) {
    return vaccineHistories.map(function (vaccineHistory) { return vaccineHistory.historyVaccineHistoryId; });
});
export var selectVaccinationsSectionLinkPayload = createSelector(selectAllVaccineHistories, selectAllVaccineHistoryIds, function (vaccineHistories, ids) { return ({
    legacyData: vaccineHistories,
    vaccineHistoryIds: ids,
}); });
export var selectVaccineHistoryById = createSelector(selectVaccineHistoriesState, function (state, _a) {
    var id = _a.id;
    return state.entities[id];
});
export var selectHasVaccineHistoryFullyLoaded = createSelector(selectVaccineHistoryById, function (state) { return state && state.hasFullyLoaded; });
export var selectHasVaccineHistories = createSelector(selectAllVaccineHistories, function (state) { return state && state.length >= 1; });
export var selectEntityMetadata = (_a = createEntityMetadataSelectors(selectVaccineHistoriesState, selectVaccineHistoryById), _a.selectEntityMetadata), selectEntityWithMetadata = _a.selectEntityWithMetadata, selectEntityError = _a.selectEntityError;
export var selectAllGroupedVaccineHistories = createSelector(selectAllVaccineHistories, function (allHistories) { return groupBy(allHistories, function (item) { return item.name; }); });
