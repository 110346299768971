import { Injector, NgZone } from '@angular/core';
import { Overlay, OverlayConfig, } from '@angular/cdk/overlay';
import { PortalInjector } from '@angular/cdk/portal';
import { getOriginOverlayPositions } from '../utils/cdk-overlay-utils';
import { applyConfigDefaults } from './popover-config';
import { PopoverPortalComponent } from './popover-portal.component';
import { PopoverRef } from './popover-ref';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
var PopoverService = /** @class */ (function () {
    function PopoverService(overlay, injector, zone) {
        this.overlay = overlay;
        this.injector = injector;
        this.zone = zone;
    }
    PopoverService.prototype.open = function (config) {
        config = applyConfigDefaults(config);
        var overlayRef = this.overlay.create(this.getOverlayConfig(config));
        var popoverRef = new PopoverRef(overlayRef, config, this.zone);
        var injector = this.createInjector(popoverRef, this.injector);
        popoverRef.attach(PopoverPortalComponent, injector);
        return popoverRef;
    };
    PopoverService.prototype.getOverlayConfig = function (config) {
        return new OverlayConfig({
            hasBackdrop: false,
            width: config.width || config.minWidth,
            height: config.height,
            minWidth: config.minWidth,
            minHeight: config.minHeight,
            maxWidth: config.maxWidth,
            maxHeight: config.maxHeight,
            backdropClass: '',
            panelClass: '',
            positionStrategy: this.getOverlayPosition(config),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    };
    PopoverService.prototype.getOverlayPosition = function (config) {
        var positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(config.origin)
            .withPositions(this.getPositions(config))
            .withFlexibleDimensions(false)
            .withPush(false);
        return positionStrategy;
    };
    PopoverService.prototype.createInjector = function (popoverRef, injector) {
        var tokens = new WeakMap([[PopoverRef, popoverRef]]);
        return new PortalInjector(injector, tokens);
    };
    PopoverService.prototype.getPositions = function (config) {
        return getOriginOverlayPositions(config.placement);
    };
    PopoverService.ngInjectableDef = i0.defineInjectable({ factory: function PopoverService_Factory() { return new PopoverService(i0.inject(i1.Overlay), i0.inject(i0.INJECTOR), i0.inject(i0.NgZone)); }, token: PopoverService, providedIn: "root" });
    return PopoverService;
}());
export { PopoverService };
