<omg-card>
  <omg-card-title heading="Health Background">
    <button omgButton
            variant="invisible"
            (click)="linkSectionClick()"
            angulartics2On
            angularticsAction="Health Background Section Added"
            [disabled]="!(isSummaryActive | async)">
      <span tooltipText="Add to Note"
            tooltipPosition="top"
            omgTooltip
            class="om-icon clickable icon-push-to-note">
      </span>
    </button>
  </omg-card-title>
  <div om-card-content
       om-layout="vertical"
       om-layout-gutter-large
       class="padding-normal"
       *ngIf="isLoaded">
    <omg-health-background-form formTitle="Important Events & Surgeries"
                                data-qaid="events-surgeries"
                                noteType="illnessesAndSurgeries"
                                [formModel]="illnessesAndSurgeriesFormModel"
                                [updatedAt]="illnessesAndSurgeries.updatedAt"
                                [updatedBy]="illnessesAndSurgeries.updatedBy"
                                [reviewed]="illnessesAndSurgeries.reviewed"
                                (markAsReviewed)="markAsReviewed('illnessesAndSurgeries', illnessesAndSurgeriesFormModel.value)">
    </omg-health-background-form>
    <omg-health-background-form formTitle="Family Data"
                                data-qaid="family-data"
                                noteType="familyData"
                                [formModel]="familyDataFormModel"
                                [updatedAt]="familyData.updatedAt"
                                [updatedBy]="familyData.updatedBy"
                                [reviewed]="familyData.reviewed"
                                (markAsReviewed)="markAsReviewed('familyData', familyDataFormModel.value)">
    </omg-health-background-form>
    <omg-health-background-form formTitle="Social Data"
                                data-qaid="social-data"
                                noteType="socialData"
                                [formModel]="socialDataFormModel"
                                [updatedAt]="socialData.updatedAt"
                                [updatedBy]="socialData.updatedBy"
                                [reviewed]="socialData.reviewed"
                                (markAsReviewed)="markAsReviewed('socialData', socialDataFormModel.value)">
    </omg-health-background-form>
  </div>
</omg-card>
