import { Component, Input, ViewChild } from '@angular/core';

import { CollapseDirective } from '@app/shared';

import { ProblemHistory } from '../../shared/problems.type';

@Component({
  selector: 'omg-problem-history',
  styleUrls: ['./problem-history.component.scss'],
  templateUrl: './problem-history.component.html',
})
export class ProblemHistoryComponent {
  @ViewChild('historyCollapse')
  historyCollapse: CollapseDirective;
  @Input() history: ProblemHistory;
  @Input() editedBy: string;
  @Input() updatedAt: string;
  @Input() historyStartDate: string;
  @Input() aAndP: string;

  expanded = false;

  setExpanded(expanded: boolean) {
    this.expanded = expanded;
  }
}
