import { NgModule } from '@angular/core';

import { DateAgoPipe } from './date-ago.pipe';
import { DatePipe } from './date.pipe';
import { EmphasizePipe } from './emphasize.pipe';
import { InjectSlashPipe } from './inject-slash.pipe';
import { PharmacyAddressPipe } from './pharmacy-address.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PossessivePipe } from './possessive.pipe';
import { RemainingCharactersPipe } from './remaining-characters.pipe';
import { SafePipe } from './safe.pipe';
import { ScrubTextDirective, ScrubTextPipe } from './scrub-text';
import { WrapTextPipe } from './wrap-text.pipe';

const pipes = [
  DateAgoPipe,
  DatePipe,
  EmphasizePipe,
  InjectSlashPipe,
  PhoneNumberPipe,
  PossessivePipe,
  RemainingCharactersPipe,
  SafePipe,
  ScrubTextPipe,
  WrapTextPipe,
  PharmacyAddressPipe,
];

@NgModule({
  imports: [],
  exports: [...pipes],
  declarations: [...pipes, ScrubTextDirective],
  providers: [...pipes],
})
export class PipesModule {}
