export function RootScopeProviderFactory(injector) {
  return injector.get('$rootScope');
}

export function StateProviderFactory(injector) {
  const rootScope = injector.get('$rootScope');
  return rootScope.$state;
}

export function LocationProviderFactory(injector) {
  const location = injector.get('$location');
  return location;
}

export const upgradedProviders = [
  {
    provide: '$rootScope',
    useFactory: RootScopeProviderFactory,
    deps: ['$injector'],
  },
  {
    provide: '$state',
    useFactory: StateProviderFactory,
    deps: ['$injector'],
  },
  {
    provide: '$location',
    useFactory: LocationProviderFactory,
    deps: ['$injector'],
  },
];
