import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService, PatientSelectors } from '@app/core';

import { VaccinationsApiService } from '../shared/vaccinations-api.service';
import {
  VaccineHistory,
  VaccinesActionTypes,
} from '../shared/vaccinations.type';
import {
  DeleteVaccineHistory,
  DeleteVaccineHistoryError,
  DeleteVaccineHistorySuccess,
  LoadAllVaccineHistories,
  LoadAllVaccineHistoriesError,
  LoadAllVaccineHistoriesSuccess,
  LoadVaccineHistory,
  LoadVaccineHistoryError,
  LoadVaccineHistorySuccess,
  SaveVaccineHistory,
  SaveVaccineHistoryError,
  SaveVaccineHistorySuccess,
} from './vaccinations.actions';

@Injectable()
export class VaccinationsEffects {
  constructor(
    private actions$: Actions,
    private service: VaccinationsApiService,
    private patientSelectors: PatientSelectors,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  loadVaccineHistory$: Observable<Action> = this.actions$.pipe(
    ofType<LoadVaccineHistory>(VaccinesActionTypes.LoadVaccineHistory),
    withLatestFrom(this.patientSelectors.patientId),
    mergeMap(([action, patientId]) =>
      this.service
        .get({
          vaccineHistoryId: action.payload.vaccineHistoryId,
          patientId,
        })
        .pipe(
          map(response => new LoadVaccineHistorySuccess(response)),
          catchError(error =>
            of(
              new LoadVaccineHistoryError(
                this.errorHandler.handleErrorSafe(error),
                {
                  id: action.payload.vaccineHistoryId,
                },
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  loadAllVaccineHistories$: Observable<Action> = this.actions$.pipe(
    ofType<LoadAllVaccineHistories>(
      VaccinesActionTypes.LoadAllVaccineHistories,
    ),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service.getAll(patientId).pipe(
        map(
          (response: VaccineHistory[]) =>
            new LoadAllVaccineHistoriesSuccess(response),
        ),
        catchError(error =>
          of(
            new LoadAllVaccineHistoriesError(
              this.errorHandler.handleErrorSafe(error),
            ),
          ),
        ),
      ),
    ),
  );

  @Effect()
  saveVaccineHistory$: Observable<Action> = this.actions$.pipe(
    ofType<SaveVaccineHistory>(VaccinesActionTypes.SaveVaccineHistory),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service
        .save({ vaccineHistory: action.payload.vaccineHistory, patientId })
        .pipe(
          map(
            (response: VaccineHistory) =>
              new SaveVaccineHistorySuccess(response),
          ),
          catchError(error =>
            of(
              new SaveVaccineHistoryError(
                this.errorHandler.handleErrorSafe(error),
                {
                  id: action.payload.vaccineHistory.id,
                },
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  deleteVaccineHistory$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteVaccineHistory>(VaccinesActionTypes.DeleteVaccineHistory),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service
        .delete({
          vaccineHistoryId: action.payload.vaccineHistoryId,
          patientId,
        })
        .pipe(
          map(
            () =>
              new DeleteVaccineHistorySuccess(action.payload.vaccineHistoryId),
          ),
          catchError(error =>
            of(
              new DeleteVaccineHistoryError(
                this.errorHandler.handleErrorSafe(error),
                {
                  id: action.payload.vaccineHistoryId,
                },
              ),
            ),
          ),
        ),
    ),
  );
}
