var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RouterBridgeService } from '../services/router-bridge.service';
import { useUpgradeRoute } from '../shared/constants';
var appStateName = useUpgradeRoute ? 'upgrade' : 'app';
var rootPath = useUpgradeRoute ? '/upgrade' : '';
export var upgradeRouteResolver = function ($rootScope, $state, $location, $transitions) {
    $rootScope.$on('$stateChangeStart', function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        RouterBridgeService.isAngularJSTransitioning.next(true);
    });
    $rootScope.$on('$stateChangeSuccess', function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        RouterBridgeService.isAngularJSTransitioning.next(false);
    });
    $rootScope.$on('$stateChangeCancel', function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        RouterBridgeService.isAngularJSTransitioning.next(false);
    });
    $rootScope.$on('$stateChangeError', function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        RouterBridgeService.isAngularJSTransitioning.next(false);
    });
    var isOrdersRoute = function (state) { return state.name.startsWith('app.chart.orders'); };
    var migratedWorkspaceRoutes = [
        'app.chart.workspace.newWorkspaceItem',
        'app.chart.workspace.summaries',
        'app.chart.workspace.patientTimelinePosts',
        'app.chart.workspace.notes',
    ];
    var isWorkspaceRoute = function (state) {
        return migratedWorkspaceRoutes.findIndex(function (route) { return route === state.name; }) > -1;
    };
    var isLetterRoute = function (state) {
        return state.name.startsWith('app.chart.workspace.letters');
    };
    var criteria = {
        to: function (state) {
            return isWorkspaceRoute(state) || isOrdersRoute(state) || isLetterRoute(state);
        },
    };
    $transitions.onBefore(criteria, function (transition) {
        var params = Object.assign({}, transition.params());
        if (params.patientId === undefined) {
            // Get patient id from url
            var patientId = $location.path().match(/patients\/([0-9]+?)\//);
            if (useUpgradeRoute && patientId && patientId.length > 0) {
                // abort current transition
                transition.abort();
                var upgradedRoute = transition.to().name.replace('app.', 'upgrade.');
                // return new transition containing correct patientId
                return $state.transitionTo(upgradedRoute, __assign({}, params, { patientId: patientId[1] }), { notify: false });
            }
        }
    });
};
upgradeRouteResolver.$inject = [
    '$rootScope',
    '$state',
    '$location',
    '$transitions',
];
export var upgradeStates = function ($stateProvider) {
    $stateProvider
        .state("" + appStateName, {
        template: '',
        controller: [
            'profile',
            function app(profile) {
                this.profile = profile;
                return undefined;
            },
        ],
        controllerAs: 'app',
        data: {
            authenticate: true,
        },
        resolve: {
            profile: ['ProfileService', function (ProfileService) { return ProfileService.get(); }],
        },
    })
        .state(appStateName + ".chart", {
        url: rootPath + "/patients/:patientId/chart",
        abstract: true,
        resolve: {
            patient: [
                '$stateParams',
                'PatientService',
                function ($stateParams, PatientService) {
                    return PatientService.get($stateParams.patientId);
                },
            ],
            medications: [
                '$stateParams',
                'PatientMedicationsService',
                function ($stateParams, PatientMedications) {
                    return PatientMedications.getAllForPatient($stateParams.patientId);
                },
            ],
            newRxCart: [
                '$stateParams',
                'NewRxCart',
                function ($stateParams, NewRxCart) {
                    return NewRxCart.get({ patientId: $stateParams.patientId }).$promise;
                },
            ],
            patientPharmacies: [
                '$stateParams',
                'PatientPharmacyService',
                function ($stateParams, PatientPharmacyService) {
                    return PatientPharmacyService.query($stateParams.patientId);
                },
            ],
            renewalsCount: [
                '$stateParams',
                'RefillRequest',
                function renewalsCount($stateParams, RefillRequest) {
                    return RefillRequest.count({
                        patientId: $stateParams.patientId,
                    }).$promise;
                },
            ],
            chartAccess: [
                '$stateParams',
                'ChartAccessService',
                function chartAccess($stateParams, ChartAccessService) {
                    if ($stateParams.patientId) {
                        ChartAccessService.create($stateParams.patientId);
                    }
                },
            ],
        },
        controller: 'EncounterController',
        controllerAs: 'encounter',
        template: '',
    })
        .state(appStateName + ".chart.orders", {
        url: '/orders',
        abstract: true,
        sticky: true,
        dsr: {
            default: { state: appStateName + ".chart.orders.list" },
        },
        views: {
            'orders_list@app.chart': {
                template: '<ui-view/>',
            },
            'orders_edit@app.chart': {
                template: '<ui-view/>',
            },
            'consult_order_edit@app.chart': {
                template: '<ui-view/>',
            },
            'follow_up_order_new@app.chart': {
                template: '<ui-view/>',
            },
            'follow_up_order_edit@app.chart': {
                template: '<ui-view/>',
            },
            'vaccine_order_edit@app.chart': {
                template: '<ui-view/>',
            },
            'vaccine_order_new@app.chart': {
                template: '<ui-view/>',
            },
            'survey_order_new@app.chart': {
                template: '<ui-view/>',
            },
            'survey_order_edit@app.chart': {
                template: '<ui-view/>',
            },
            'procedure_order_edit@app.chart': {
                template: '<ui-view/>',
            },
        },
    })
        .state(appStateName + ".chart.orders.list", {
        url: '/list',
        template: '',
    })
        .state(appStateName + ".chart.orders.edit", {
        url: '/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.orders.editConsultOrder", {
        url: '/:id/edit_consult_order',
        template: '',
    })
        .state(appStateName + ".chart.orders.editProcedureOrder", {
        url: '/procedure_orders/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.orders.editVaccineOrder", {
        url: '/vaccine_orders/:orderId/edit',
        template: '',
    })
        .state(appStateName + ".chart.orders.newFollowUpOrder", {
        url: '/follow_up_orders/new',
        template: '',
    })
        .state(appStateName + ".chart.orders.editFollowUpOrder", {
        url: '/follow_up_orders/:orderType/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.orders.editSurveyOrder", {
        url: '/survey_orders/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.orders.newSurveyOrder", {
        url: '/survey_orders/new',
        template: '',
    })
        .state(appStateName + ".chart.renewals", {
        url: '/renewals',
        abstract: true,
        sticky: true,
        dsr: {
            default: { state: appStateName + ".chart.renewals.list" },
        },
        views: {
            'renewals_list@app.chart': {
                template: '<ui-view/>',
            },
        },
    })
        .state(appStateName + ".chart.renewals.list", {
        url: '/list',
        template: '',
        resolve: {
            patient: [
                '$stateParams',
                'PatientService',
                function ($stateParams, PatientService) {
                    return PatientService.get($stateParams.patientId);
                },
            ],
            profile: ['ProfileService', function (ProfileService) { return ProfileService.get(); }],
            cart: [
                '$stateParams',
                'RefillRequest',
                function refillRequest($stateParams, RefillRequest) {
                    return RefillRequest.latestCart({
                        patientId: $stateParams.patientId,
                    });
                },
            ],
        },
    })
        .state(appStateName + ".chart.workspace", {
        abstract: true,
        sticky: true,
    })
        .state(appStateName + ".chart.workspace.newWorkspaceItem", {
        url: '/summaries/new',
        template: '',
    })
        .state(appStateName + ".chart.workspace.patientTimelinePosts", {
        url: '/patient_timeline_posts/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.workspace.notes", {
        url: '/notes/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.workspace.summaries", {
        url: '/summaries/:id/edit',
        template: '',
    })
        .state(appStateName + ".chart.workspace.letters", {
        url: '/letters/:id/edit',
        template: '',
    })
        /**
         * chart.newrx is a dummy route necessary so that angularJS
         * can pick up patientId
         * https://www.pivotaltracker.com/story/show/164610881
         */
        .state(appStateName + ".chart.newrx", {
        url: '/new-rxs',
        template: '',
    });
    if (useUpgradeRoute) {
        $stateProvider
            // NOTE: This makes patientId available to upgraded components
            .state('upgrade-all', {
            url: '/upgrade/patients/:patientId/*path',
            template: '',
        });
    }
    $stateProvider
        // NOTE: This is to allow for sibling routing during angular migration
        .state('sink', {
        url: '/*path',
        dontStorePath: true,
        template: '',
    });
};
upgradeStates.$inject = ['$stateProvider'];
