import { FsaAction } from '@app/core';
import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import { VaccineHistoryErrorData } from '../shared/vaccinations-api.type';
import {
  VaccineHistory,
  VaccinesActionTypes,
} from '../shared/vaccinations.type';
import { VaccinationsState } from './vaccinations.reducer';

interface LoadVaccineHistoryActionData {
  vaccineHistoryId: number;
}
interface SaveVaccineHistoryActionData {
  vaccineHistory: VaccineHistory;
}
interface DeleteVaccineHistoryActionData extends LoadVaccineHistoryActionData {}

export class LoadVaccineHistory
  implements FsaAction<LoadVaccineHistoryActionData> {
  readonly type = VaccinesActionTypes.LoadVaccineHistory;

  constructor(public payload: LoadVaccineHistoryActionData) {}
}

export class LoadVaccineHistorySuccess implements FsaAction<VaccineHistory> {
  readonly type = VaccinesActionTypes.LoadVaccineHistorySuccess;
  error = false;

  constructor(public payload: VaccineHistory) {}
}

export class LoadVaccineHistoryError
  implements FsaAction<VaccineHistoryErrorData> {
  readonly type = VaccinesActionTypes.LoadVaccineHistoryError;
  error = true;

  constructor(
    public payload: VaccineHistoryErrorData,
    public meta: { id: number },
  ) {}
}

export class LoadAllVaccineHistories implements FsaAction<void> {
  readonly type = VaccinesActionTypes.LoadAllVaccineHistories;
}

export class LoadAllVaccineHistoriesSuccess
  implements FsaAction<VaccineHistory[]> {
  readonly type = VaccinesActionTypes.LoadAllVaccineHistoriesSuccess;
  error = false;

  constructor(public payload: VaccineHistory[]) {}
}

export class LoadAllVaccineHistoriesError
  implements FsaAction<VaccineHistoryErrorData> {
  readonly type = VaccinesActionTypes.LoadAllVaccineHistoriesError;
  error = true;

  constructor(public payload: VaccineHistoryErrorData) {}
}

export class SaveVaccineHistory
  implements FsaAction<SaveVaccineHistoryActionData> {
  readonly type = VaccinesActionTypes.SaveVaccineHistory;

  constructor(public payload: SaveVaccineHistoryActionData) {}
}

export class SaveVaccineHistorySuccess implements FsaAction<VaccineHistory> {
  readonly type = VaccinesActionTypes.SaveVaccineHistorySuccess;
  error = false;

  constructor(public payload: VaccineHistory) {}
}

export class SaveVaccineHistoryError
  implements FsaAction<VaccineHistoryErrorData> {
  readonly type = VaccinesActionTypes.SaveVaccineHistoryError;
  error = true;

  constructor(
    public payload: VaccineHistoryErrorData,
    public meta: { id: number },
  ) {}
}

export class DeleteVaccineHistory
  implements FsaAction<DeleteVaccineHistoryActionData> {
  readonly type = VaccinesActionTypes.DeleteVaccineHistory;

  constructor(public payload: DeleteVaccineHistoryActionData) {}
}

export class DeleteVaccineHistorySuccess implements FsaAction<number> {
  readonly type = VaccinesActionTypes.DeleteVaccineHistorySuccess;
  error = false;

  constructor(public payload: number) {}
}

export class DeleteVaccineHistoryError
  implements FsaAction<VaccineHistoryErrorData> {
  readonly type = VaccinesActionTypes.DeleteVaccineHistoryError;
  error = true;

  constructor(
    public payload: VaccineHistoryErrorData,
    public meta: { id: number },
  ) {}
}

export type VaccineHistoryActions =
  | LoadVaccineHistory
  | LoadVaccineHistorySuccess
  | LoadVaccineHistoryError
  | LoadAllVaccineHistories
  | LoadAllVaccineHistoriesSuccess
  | LoadAllVaccineHistoriesError
  | SaveVaccineHistory
  | SaveVaccineHistorySuccess
  | SaveVaccineHistoryError
  | DeleteVaccineHistory
  | DeleteVaccineHistorySuccess
  | DeleteVaccineHistoryError;

@Injectable()
export class VaccinationsActions {
  constructor(private store: Store<VaccinationsState>) {}

  deleteVaccineHistory(vaccineHistoryId: number) {
    this.store.dispatch(
      new DeleteVaccineHistory({ vaccineHistoryId: vaccineHistoryId }),
    );
  }
}
