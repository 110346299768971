import { Injectable } from '@angular/core';
import { ApiService, patientRoute } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  mapInsightResponseToEntity,
  mapInsightTypeResponseToEntity,
} from './insights-api-mapper';
import { InsightResponse, InsightTypeResponse } from './insights.response.type';
import { Insight, InsightType } from './insights.type';

@Injectable()
export class InsightsService {
  constructor(private api: ApiService) {}

  getAll(patientId: number): Observable<Insight[]> {
    return this.api
      .get<InsightResponse>(patientRoute(patientId, `/insights`))
      .pipe(map(mapInsightResponseToEntity));
  }

  getItem(
    patientId: number,
    itemType: string,
    itemId: number,
  ): Observable<InsightType> {
    return this.api
      .get<InsightTypeResponse>(
        patientRoute(patientId, `/insights/${itemType}/${itemId}`),
      )
      .pipe(map(mapInsightTypeResponseToEntity));
  }
}
