var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEntityAdapter } from '@ngrx/entity';
import { flatMap, getProperty } from '@app/utils';
import { getEntityMetadataInitialState, resetEntityMetadata, selectIdsBy, updateEntityMetadata, } from '@app/utils/store';
import { newEntityId, } from '../shared/health-maintenance.type';
import { HealthGoalScreeningActionTypes, } from './health-goal-screening.actions';
import { HealthGoalActionTypes, } from './health-goal.actions';
export var adapter = createEntityAdapter({
    selectId: function (entity) { return entity.id; },
});
export var initialState = adapter.getInitialState(__assign({ loading: false, error: null }, getEntityMetadataInitialState({})));
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HealthGoalScreeningActionTypes.AddHealthGoalScreening: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(newEntityId, { pending: true, error: null }, state));
        }
        case HealthGoalScreeningActionTypes.AddHealthGoalScreeningSuccess: {
            // Re-associate screening with new health goal (if applicable)
            var healthGoalId = action.payload.healthGoalId === null
                ? newEntityId
                : action.payload.healthGoalId;
            return __assign({}, adapter.addOne(__assign({}, action.payload, { healthGoalId: healthGoalId }), state), { loading: false, error: null }, updateEntityMetadata(newEntityId, { pending: false, error: null }, state));
        }
        case HealthGoalScreeningActionTypes.AddHealthGoalScreeningError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(newEntityId, { pending: false, error: action.payload }, state));
        }
        case HealthGoalScreeningActionTypes.UpdateHealthGoalScreening: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.id, { pending: true, error: null }, state));
        }
        case HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningSuccess: {
            return __assign({}, adapter.upsertOne(action.payload, state), { loading: false, error: null }, updateEntityMetadata(action.payload.id, { pending: false, error: null }, state));
        }
        case HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case HealthGoalScreeningActionTypes.DeleteHealthGoalScreening: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload, { pending: true }, state));
        }
        case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningSuccess: {
            return __assign({}, adapter.removeOne(action.payload, state), { loading: false }, resetEntityMetadata(action.payload, state));
        }
        case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case HealthGoalScreeningActionTypes.ClearHealthGoalScreenings: {
            return __assign({}, adapter.removeAll(state), { loading: false, error: null });
        }
        /**
         * Normalization for HealthGoals
         */
        case HealthGoalActionTypes.InitNewHealthGoal: {
            // remove existing screenings mapped to new health goal
            // this means these may be orphaned in the database
            // TODO: Confirm this behaviour is expected
            return __assign({}, adapter.removeMany(selectIdsBy(state, function (i) { return i.healthGoalId === -1; }), state));
        }
        case HealthGoalActionTypes.LoadHealthGoalsSuccess: {
            var entities = flatMap(function (i) { return i.screeningHistory; }, action.payload);
            return __assign({}, adapter.addAll(entities, state));
        }
        case HealthGoalActionTypes.AddHealthGoalSuccess: {
            return __assign({}, adapter.upsertMany(getProperty(action.payload, 'screeningHistory'), state));
        }
        case HealthGoalActionTypes.DeleteHealthGoalSuccess: {
            var ids = selectIdsBy(state, function (i) { return i.healthGoalId === action.payload; });
            return __assign({}, adapter.removeMany(ids, state));
        }
        case HealthGoalActionTypes.ClearHealthGoals: {
            return __assign({}, adapter.removeAll(state), { loading: false, error: null });
        }
        default: {
            return state;
        }
    }
}
// get the selectors
var _a = adapter.getSelectors(), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
// select the array of ids
export var selectHealthGoalScreeningIds = selectIds;
// select the dictionary of entities
export var selectHealthGoalScreeningEntities = selectEntities;
// select the array of items
export var selectAllHealthGoalScreenings = selectAll;
// select the total count
export var selectHealthGoalScreeningTotal = selectTotal;
