import { OnDestroy, OnInit, QueryList, } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsActions, FeatureFlagSelectors, PatientSelectors, } from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { ProblemsApiService } from '../shared/problems-api.service';
import { removeHistoryId, sortProblems } from '../shared/problems-utils';
import { newEntityId } from '../shared/problems.type';
import { ProblemActions } from '../store/problems.actions';
import { ProblemSelectors } from '../store/problems.selectors';
import { ProblemFormComponent } from './problem-form/problem-form.component';
var ProblemsComponent = /** @class */ (function () {
    function ProblemsComponent(problemsApi, patientSelectors, featureFlagSelectors, problemSelectors, problemActions, analyticsActions, summariesSelectors) {
        this.problemsApi = problemsApi;
        this.patientSelectors = patientSelectors;
        this.featureFlagSelectors = featureFlagSelectors;
        this.problemSelectors = problemSelectors;
        this.problemActions = problemActions;
        this.analyticsActions = analyticsActions;
        this.summariesSelectors = summariesSelectors;
        this.reset = false;
        this.disableAddNewProblem = new Subject();
        this.unsubscribe = new Subject();
        this.trackByFn = function (index, problem) { return problem.id || index; };
    }
    ProblemsComponent.prototype.ngOnInit = function () {
        this.loadProblems();
        this.setupSelectors();
    };
    ProblemsComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    ProblemsComponent.prototype.addToNote = function () {
        var _this = this;
        this.problemSelectors.problems.pipe(take(1)).subscribe(function (problems) {
            _this.problemsApi.linkSection(_this.patientId, sortProblems(problems));
        });
    };
    ProblemsComponent.prototype.saveProblem = function (form, collapseRef) {
        var _this = this;
        this.problemActions.saveProblem(this.patientId, form);
        this.problemSelectors
            .problemMetadata(newEntityId)
            .pipe(filter(function (metadata) { return !metadata.pending; }), withLatestFrom(this.problemSelectors.problemHistoryId), take(1))
            .subscribe(function (_a) {
            var state = _a[0], historyId = _a[1];
            if (!state.error) {
                _this.problemsApi.linkAssessed(_this.patientId, null, historyId, 'problemSaved');
                _this.problemForms.first.resetForm();
                collapseRef.collapse();
            }
        });
    };
    ProblemsComponent.prototype.updateProblem = function (form, collapseRef, index) {
        var _this = this;
        this.problemActions.updateProblem(this.patientId, form);
        this.problemSelectors
            .problemMetadata(form.id)
            .pipe(filter(function (metadata) { return !metadata.pending; }), withLatestFrom(this.problemSelectors.problemHistoryId), take(1))
            .subscribe(function (_a) {
            var state = _a[0], historyId = _a[1];
            if (!state.error) {
                if (form.addProblemToNote) {
                    _this.problemsApi.linkAssessed(_this.patientId, form.id, historyId, 'problemUpdated');
                }
                _this.collapseHistory(index);
                collapseRef.collapse();
            }
        });
    };
    ProblemsComponent.prototype.deleteProblem = function (problemId, collapseRef) {
        var _this = this;
        var confirmation = window.confirm('Are you sure you want to delete this problem?');
        if (!confirmation) {
            collapseRef.collapse();
            return;
        }
        this.problemActions.deleteProblem(this.patientId, problemId);
        this.problemSelectors
            .problemMetadata(problemId)
            .pipe(withLatestFrom(this.problemSelectors.state, this.summariesSelectors.assessedProblemHistoryIds), filter(function (_a) {
            var metadata = _a[0], state = _a[1];
            return !!(!metadata.pending && state.lastDeleted);
        }), take(1))
            .subscribe(function (_a) {
            var metadata = _a[0], state = _a[1], assessedProblemHistoryIds = _a[2];
            var historyIds = removeHistoryId(assessedProblemHistoryIds, state.lastDeleted.problemHistoryId);
            if (!metadata.error) {
                /** This will unlink the problem from the unsigned summary A&P
                 * in the old app. This should be removed once section link
                 * compatibility is no longer needed.
                 */
                _this.problemsApi.linkAssessed(_this.patientId, state.lastDeleted.id, state.lastDeleted.problemHistoryId, 'problemDeleted');
                /** This will unlink the problem from Angular 7 unsigned
                 * summary A&P.
                 */
                _this.problemsApi.unlinkAssessed(_this.patientId, historyIds);
                collapseRef.collapse();
            }
        });
    };
    ProblemsComponent.prototype.resolveProblem = function (problemId, collapseRef, index) {
        var _this = this;
        this.problemActions.resolveProblem(this.patientId, problemId);
        this.problemSelectors.problemMetadata(problemId).subscribe(function () {
            _this.collapseHistory(index);
            collapseRef.collapse();
        });
    };
    ProblemsComponent.prototype.reactivateProblem = function (problemId, collapseRef, index) {
        var _this = this;
        this.problemActions.reactivateProblem(this.patientId, problemId);
        this.problemSelectors.problemMetadata(problemId).subscribe(function () {
            _this.collapseHistory(index);
            collapseRef.collapse();
        });
    };
    ProblemsComponent.prototype.onAddProblemClick = function (collapseRef, focus) {
        focus.setFocus('problemFocus-create');
        collapseRef.expand();
        this.resetAddingProblemSessionId();
        this.trackEvent();
    };
    ProblemsComponent.prototype.onExpandedChange = function (isExpanded, id) {
        if (isExpanded) {
            this.problemActions.getProblemHistory(this.patientId, id);
        }
    };
    ProblemsComponent.prototype.collapseHistory = function (index) {
        this.problemForms.toArray()[index].problemHistory.setExpanded(false);
    };
    ProblemsComponent.prototype.trackEvent = function () {
        this.analyticsActions.trackEvent('Add Problem Clicked', {
            workflow: 'Charting',
            component: 'Problems',
            subWorkflowId: this.addProblemSessionId,
            subComponent: 'Add Problem Button',
        });
    };
    ProblemsComponent.prototype.resetAddingProblemSessionId = function () {
        this.addProblemSessionId = +new Date();
    };
    ProblemsComponent.prototype.loadProblems = function () {
        var _this = this;
        this.patientSelectors.patientId
            .pipe(filter(Boolean), tap(function (patientId) { return (_this.patientId = patientId); }), takeUntil(this.unsubscribe))
            .subscribe(function (patientId) {
            _this.problemActions.getProblems(patientId);
        });
    };
    ProblemsComponent.prototype.setupSelectors = function () {
        this.allowAddToNote = this.summariesSelectors.hasActiveSummary;
        this.activeProblems = this.problemSelectors.activeProblems.pipe(takeUntil(this.unsubscribe));
        this.resolvedProblems = this.problemSelectors.resolvedProblems.pipe(takeUntil(this.unsubscribe));
        this.autoCheckInCreationEnabled = this.featureFlagSelectors.featureEnabled('auto_check_in_creation');
    };
    return ProblemsComponent;
}());
export { ProblemsComponent };
