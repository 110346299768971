import { ActionReducerMap } from '@ngrx/store';

import {
  HealthGoalScreeningState as HealthGoalsScreeningState,
  reducer as healthGoalScreeningsReducer,
} from './health-goal-screening.reducer';
import {
  HealthGoalTypeState as HealthGoalTypesState,
  reducer as healthGoalTypesReducer,
} from './health-goal-type.reducer';
import {
  HealthGoalState as HealthGoalsState,
  reducer as healthGoalsReducer,
} from './health-goal.reducer';

export const healthMaintenanceStatePath = 'healthMaintenance';

export interface HealthMainteanceState {
  healthGoalTypes: HealthGoalTypesState;
  healthGoals: HealthGoalsState;
  healthGoalScreenings: HealthGoalsScreeningState;
}

export const reducers: ActionReducerMap<HealthMainteanceState> = {
  healthGoalTypes: healthGoalTypesReducer,
  healthGoals: healthGoalsReducer,
  healthGoalScreenings: healthGoalScreeningsReducer,
};
