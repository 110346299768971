import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { HealthGoalService } from '../shared/health-goal.service';

import { selectHealthGoalScreeningsByHealthGoalId } from './health-goal-screening.selectors';
import {
  AddHealthGoal,
  AddHealthGoalError,
  AddHealthGoalSuccess,
  DeleteHealthGoal,
  DeleteHealthGoalError,
  DeleteHealthGoalSuccess,
  HealthGoalActionTypes,
  LoadHealthGoals,
  LoadHealthGoalsError,
  LoadHealthGoalsSuccess,
  UpdateHealthGoal,
  UpdateHealthGoalError,
  UpdateHealthGoalSuccess,
} from './health-goal.actions';
import { HealthMainteanceState } from './health-maintenance.reducer';

@Injectable()
export class HealthGoalEffects {
  constructor(
    private actions$: Actions,
    private service: HealthGoalService,
    private store: Store<HealthMainteanceState>,
    private patientSelectors: PatientSelectors,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  loadHealthGoals$: Observable<Action> = this.actions$.pipe(
    ofType<LoadHealthGoals>(HealthGoalActionTypes.LoadHealthGoals),
    switchMap(action =>
      this.service.getAll(action.payload).pipe(
        map(healthGoals => new LoadHealthGoalsSuccess(healthGoals)),
        catchError(error =>
          of(
            new LoadHealthGoalsError(this.errorHandler.handleErrorSafe(error)),
          ),
        ),
      ),
    ),
  );

  @Effect()
  addHealthGoal$: Observable<Action> = this.actions$.pipe(
    ofType<AddHealthGoal>(HealthGoalActionTypes.AddHealthGoal),
    mergeMap(action =>
      this.store.pipe(
        select(selectHealthGoalScreeningsByHealthGoalId, {
          id: action.payload.healthGoal.id,
        }),
        map(screeningHistory => ({
          action,
          patientId: action.payload.patientId,
          healthGoal: { ...action.payload.healthGoal, screeningHistory },
        })),
        first(),
      ),
    ),
    switchMap(result =>
      this.service.add(result.patientId, result.healthGoal).pipe(
        map(healthGoal => new AddHealthGoalSuccess(healthGoal)),
        catchError(error =>
          of(
            new AddHealthGoalError(this.errorHandler.handleErrorSafe(error), {
              id: result.healthGoal.id,
            }),
          ),
        ),
      ),
    ),
  );

  @Effect()
  updateHealthGoal$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateHealthGoal>(HealthGoalActionTypes.UpdateHealthGoal),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service.update(patientId, action.payload).pipe(
        map(healthGoal => new UpdateHealthGoalSuccess(healthGoal)),
        catchError(error =>
          of(
            new UpdateHealthGoalError(
              this.errorHandler.handleErrorSafe(error),
              {
                id: action.payload.id,
              },
            ),
          ),
        ),
      ),
    ),
  );

  @Effect()
  deleteHealthGoal$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteHealthGoal>(HealthGoalActionTypes.DeleteHealthGoal),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service.delete(patientId, action.payload).pipe(
        map(healthGoal => new DeleteHealthGoalSuccess(action.payload)),
        catchError(error =>
          of(
            new DeleteHealthGoalError(
              this.errorHandler.handleErrorSafe(error),
              {
                id: action.payload,
              },
            ),
          ),
        ),
      ),
    ),
  );
}
