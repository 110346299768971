import { createFeatureSelector } from '@ngrx/store';

import {
  HealthMainteanceState,
  healthMaintenanceStatePath,
} from './health-maintenance.reducer';

export const selectHealthMaintenanceState = createFeatureSelector<
  HealthMainteanceState
>(healthMaintenanceStatePath);
