/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-title.component";
var styles_CardTitleComponent = [i0.styles];
var RenderType_CardTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardTitleComponent, data: {} });
export { RenderType_CardTitleComponent as RenderType_CardTitleComponent };
export function View_CardTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "om-card-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "om-title"], ["om-layout", ""], ["om-layout-align", "space-between center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "inline om-title-text"], ["om-flex", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["om-layout", ""], ["om-layout-align", "start stretch"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 4, 0, currVal_0); }); }
export function View_CardTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-card-title", [], null, null, null, View_CardTitleComponent_0, RenderType_CardTitleComponent)), i1.ɵdid(1, 49152, null, 0, i2.CardTitleComponent, [], null, null)], null, null); }
var CardTitleComponentNgFactory = i1.ɵccf("omg-card-title", i2.CardTitleComponent, View_CardTitleComponent_Host_0, { heading: "heading" }, {}, ["*"]);
export { CardTitleComponentNgFactory as CardTitleComponentNgFactory };
