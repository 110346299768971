import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DomHandler } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';

import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonComponent } from './button/button.component';
import { CardModule } from './card';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CollapseModule } from './collapse';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DialogModule } from './dialog';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditableLabelComponent } from './editable-label/editable-label.component';
import { FeatureFlagComponentLoader } from './feature-flag-component-loader/feature-flag-component-loader';
import { FeatureFlagComponentLoaderDirective } from './feature-flag-component-loader/feature-flag-component-loader.directive';
import { GoogleMapsModule } from './google-maps';
import { InputNumberDirective } from './input-number/input-number.directive';
import { InputTextDirective } from './input-text/input-text.directive';
import { NoteHistoryComponent } from './note-history/note-history.component';
import { NoteIconModule } from './note-icon';
import { PagerComponent } from './pager/pager.component';
import { PopoverModule } from './popover';
import { ProfileImageBubbleModule } from './profile-image-bubble';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { SplitButtonComponent } from './split-button/split-button.component';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { TextareaAutosizeDirective } from './textarea/textarea-autosize.directive';
import { TextareaDirective } from './textarea/textarea.directive';
import { ToastModule } from './toast';
import { TransmissionDetailsComponent } from './transmission-details/transmission-details.component';

const declarations = [
  AutoCompleteComponent,
  ButtonComponent,
  CheckboxComponent,
  DatePickerComponent,
  DropdownComponent,
  ButtonGroupComponent,
  EditableLabelComponent,
  InputNumberDirective,
  InputTextDirective,
  NoteHistoryComponent,
  RadioButtonComponent,
  RichTextEditorComponent,
  SplitButtonComponent,
  TabComponent,
  TabsComponent,
  TextareaAutosizeDirective,
  TextareaDirective,
  TransmissionDetailsComponent,
  FeatureFlagComponentLoader,
  FeatureFlagComponentLoaderDirective,
  PagerComponent,
];

const componentModules = [
  CardModule,
  CollapseModule,
  DialogModule,
  NoteIconModule,
  PopoverModule,
  ToastModule,
  ProfileImageBubbleModule,
  GoogleMapsModule,
];

const primeNgModules = [
  CalendarModule,
  CheckboxModule,
  DropdownModule,
  EditorModule,
  InputTextareaModule,
  MenuModule,
  RadioButtonModule,
  SplitButtonModule,
  TooltipModule,
];

const vendorModules = [NgSelectModule, ...primeNgModules];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DirectivesModule,
    PipesModule,
    ...vendorModules,
    ...componentModules,
  ],
  providers: [DomHandler],
  exports: [...primeNgModules, ...componentModules, ...declarations],
  declarations: [...declarations],
})
export class ComponentsModule {}
