<div class="om-patient-nav-expand"
     *ngIf="!showMenu"
     om-layout
     om-layout-align="center center"
     (click)="toggleMenu()"
     omgTooltip
     tooltipText="Patient Menu"
     tooltipPosition="right">
  <div class="om-patient-nav-caret"
       om-flex="15">
    <i class="fa fa-angle-right"></i>
  </div>
</div>

<div class="om-patient-nav"
     om-layout="vertical"
     om-layout-align="start"
     *ngIf="showMenu">
  <ng-container *ngIf="patientUrls as urls">
    <div class="om-patient-nav-title"
         om-layout
         om-layout-gutter
         om-layout-align="start center">
      <div class="om-patient-nav-caret"
           om-flex="15"
           (click)="toggleMenu()">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="om-patient-nav-title-text"
           om-flex="60"><span>Patient Menu</span></div>
      <img alt="Patient Menu"
           class="om-patient-nav-photo"
           [src]="patient.photoUrl">
    </div>
    <div class="om-patient-nav-body"
         *ngIf="patient.pastOrCurrentElectronicTosAccepted">
      <div class="om-patient-nav-section"
           *ngIf="hideLegacyChartTabs$ | async">
        <a class="om-patient-nav-header-item"
           (click)="onMenuItemClick('Chart')"
           title="Chart">Chart
        </a>
      </div>
      <div class="om-patient-nav-section"
           *ngIf="!(hideLegacyChartTabs$ | async)">
        <div class="om-patient-nav-header">Chart</div>
        <a class="om-patient-nav-item -current"
           (click)="onMenuItemClick('Chart')"
           title="Chart">Chart
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.chart.legacyChart"
           [useHref]="true"
           (click)="onMenuItemClick('Legacy Chart')"
           title="Legacy Chart">Legacy Chart
        </a>
      </div>
      <div class="om-patient-nav-section">
        <a class="om-patient-nav-header-item"
           [omgLegacyUiHref]="urls.tasks"
           [useHref]="true"
           (click)="onMenuItemClick('Tasks')"
           title="Tasks">Tasks
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Appointments</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.appointments.new"
           [useHref]="true"
           (click)="onMenuItemClick('New')"
           title="New">New
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.appointments.upcoming"
           [useHref]="true"
           (click)="onMenuItemClick('Upcoming & Past')"
           title="Upcoming & Past">Upcoming & Past
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.appointments.walkin"
           [useHref]="true"
           (click)="onMenuItemClick('Walk-in')"
           title="Walk-in">Walk-in
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Account</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.account.info"
           [useHref]="true"
           (click)="onMenuItemClick('Account Info')"
           title="Account Info">Account Info
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.account.membership"
           [useHref]="true"
           (click)="onMenuItemClick('Membership Info')"
           title="Membership Info">Membership Info
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.account.office"
           [useHref]="true"
           (click)="onMenuItemClick('Office/PCP')"
           title="Office/PCP">Office/PCP
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Documents & Records</div>
        <a *ngIf="showCCD$ | async"
           class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.documents.ccd"
           [useHref]="true"
           (click)="onMenuItemClick('CCD Generator')"
           title="CCD Generator">CCD Generator
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.documents.upload"
           [useHref]="true"
           (click)="onMenuItemClick('Document Upload')"
           title="Document Upload">Document Upload
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.documents.fax"
           [useHref]="true"
           (click)="onMenuItemClick('Fax Records')"
           title="Fax Records">Fax Records
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Misc</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.addVitals"
           [useHref]="true"
           (click)="onMenuItemClick('Add Vitals')"
           title="Add Vitals">Add Vitals
        </a>
        <a *ngIf="hideLegacyChartTabs$ | async"
           class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.archiveEmail"
           [useHref]="true"
           (click)="onMenuItemClick('Archive Email')"
           title="Archive Email">Archive Email
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.accessedBy"
           [useHref]="true"
           (click)="onMenuItemClick('Chart Accessed By')"
           title="Chart Accessed By">Chart Accessed By
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.clincalPrograms"
           [useHref]="true"
           (click)="onMenuItemClick('Clinical Programs')"
           title="Clinical Programs">Clinical Programs
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.phs"
           [useHref]="true"
           (click)="onMenuItemClick('PHS')"
           title="PHS">PHS
        </a>
        <a *ngIf="hideLegacyChartTabs$ | async"
           class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.rxHistory"
           [useHref]="true"
           (click)="onMenuItemClick('Rx History')"
           title="Rx History">Rx History
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.renewalRequest"
           [useHref]="true"
           (click)="onMenuItemClick('Rx Renewal Request')"
           title="Rx Renewal Request">Rx Renewal Request
        </a>
      </div>
    </div>
    <div class="om-patient-nav-body"
         *ngIf="showMenu && !patient.pastOrCurrentElectronicTosAccepted">
      <div class="om-patient-nav-section"
           *ngIf="hideLegacyChartTabs$ | async">
        <a class="om-patient-nav-header-item"
           (click)="onMenuItemClick('Chart')"
           title="Chart">Chart
        </a>
      </div>
      <div class="om-patient-nav-section"
           *ngIf="!(hideLegacyChartTabs$ | async)">
        <div class="om-patient-nav-header">Chart</div>
        <a class="om-patient-nav-item -current"
           (click)="onMenuItemClick('Chart')"
           title="Chart">Chart
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.chart.legacyChart"
           [useHref]="true"
           (click)="onMenuItemClick('Legacy Chart')"
           title="Legacy Chart">Legacy Chart
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Appointments</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.appointments.new"
           [useHref]="true"
           (click)="onMenuItemClick('New')"
           title="New">New
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Account</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.account.info"
           [useHref]="true"
           (click)="onMenuItemClick('Account Info')"
           title="Account Info">Account Info
        </a>
      </div>
      <div class="om-patient-nav-section">
        <div class="om-patient-nav-header">Misc</div>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.addVitals"
           [useHref]="true"
           (click)="onMenuItemClick('Add Vitals')"
           title="Add Vitals">Add Vitals
        </a>
        <a *ngIf="hideLegacyChartTabs$ | async"
           class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.archiveEmail"
           [useHref]="true"
           (click)="onMenuItemClick('Archive Email')"
           title="Archive Email">Archive Email
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.phs"
           [useHref]="true"
           (click)="onMenuItemClick('PHS')"
           title="PHS">PHS
        </a>
        <a class="om-patient-nav-item"
           [omgLegacyUiHref]="urls.misc.accessedBy"
           [useHref]="true"
           (click)="onMenuItemClick('Chart Accessed By')"
           title="Chart Accessed By">Chart Accessed By
        </a>
      </div>
    </div>
    <div class="om-patient-nav-no-membership-section"
         *ngIf="showMenu && !patient.pastOrCurrentElectronicTosAccepted">
      Other chart actions are unavailable until the patient completes registration.
    </div>
  </ng-container>
</div>
