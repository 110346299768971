import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, RadioControlValueAccessor } from '@angular/forms';

export interface ButtonGroupOption {
  label: string;
  value: any;
}

@Component({
  selector: 'omg-button-group',
  templateUrl: './button-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ButtonGroupComponent),
    },
  ],
})
export class ButtonGroupComponent extends RadioControlValueAccessor {
  @Input() options: ButtonGroupOption[];
  @Input() label: string;
  @ViewChild('button') button: ElementRef;

  checked(option: ButtonGroupOption) {
    return this.value === option.value;
  }

  /* istanbul ignore next */
  writeValue(value: string) {
    this.value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  focus() {
    this.button.nativeElement.focus();
  }
}
