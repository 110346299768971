import { EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { FeatureFlagSelectors } from '@app/core';
import { map } from 'rxjs/operators';
import { VaccinationsActions } from '../../store/vaccinations.actions';
var VaccineHistoryComponent = /** @class */ (function () {
    function VaccineHistoryComponent(featureFlagSelectors, vaccinationsActions) {
        this.featureFlagSelectors = featureFlagSelectors;
        this.vaccinationsActions = vaccinationsActions;
        this.delete = new EventEmitter();
    }
    VaccineHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.canDelete$ = this.featureFlagSelectors
            .featureEnabled('vaccine_order_delete_and_edit')
            .pipe(map(function (enabled) { return enabled || !_this.vaccineHistory.wasAdministered; }));
        if (this.vaccineHistory.stepInSeriesPhrase) {
            this.displayedStep = ', ' + this.vaccineHistory.stepInSeriesPhrase;
        }
        this.displayedGivenAt =
            this.vaccineHistory.givenAt === 'One Medical Group'
                ? 'One Medical'
                : this.vaccineHistory.givenAt;
    };
    VaccineHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.vaccineHistory) {
            var events = changes.vaccineHistory.currentValue.events;
            if (events) {
                this.vaccineHistoryEvents = events.filter(function (event) {
                    return ['signed', 'completed'].includes(event.statusChange);
                });
            }
        }
    };
    Object.defineProperty(VaccineHistoryComponent.prototype, "noInfoToShow", {
        get: function () {
            return (this.vaccineHistory.vaccineBrandName == null &&
                this.vaccineHistory.vaccineRouteName == null &&
                this.vaccineHistory.vaccineLocationName == null &&
                this.vaccineHistory.lotNumber == null &&
                this.vaccineHistory.comments == null &&
                this.vaccineHistory.fhirSource == null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VaccineHistoryComponent.prototype, "hasNoAdministrationDetails", {
        get: function () {
            return (this.hasFullyLoaded &&
                this.isExplicitlyFalse([
                    this.vaccineHistory.vaccineBrandName,
                    this.vaccineHistory.vaccineRouteName,
                    this.vaccineHistory.vaccineLocationName,
                    this.vaccineHistory.lotNumber,
                ]));
        },
        enumerable: true,
        configurable: true
    });
    VaccineHistoryComponent.prototype.trackByFn = function (index, item) {
        return item.id || index;
    };
    VaccineHistoryComponent.prototype.onDelete = function () {
        var confirmation = window.confirm('Are you sure you want to delete this vaccine?');
        if (confirmation) {
            this.vaccinationsActions.deleteVaccineHistory(this.vaccineHistory.id);
        }
    };
    VaccineHistoryComponent.prototype.isExplicitlyFalse = function (values) {
        return values.reduce(function (result, value) {
            if (value !== null && !value) {
                return true;
            }
            return result;
        }, false);
    };
    return VaccineHistoryComponent;
}());
export { VaccineHistoryComponent };
