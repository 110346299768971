import { Injectable } from '@angular/core';
import { PatientSelectors } from '@app/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ErrorHandlerService } from '@app/core';

import { HealthGoalScreeningService } from '../shared/health-goal-screening.service';
import {
  AddHealthGoalScreening,
  AddHealthGoalScreeningError,
  AddHealthGoalScreeningSuccess,
  DeleteHealthGoalScreening,
  DeleteHealthGoalScreeningError,
  DeleteHealthGoalScreeningSuccess,
  HealthGoalScreeningActionTypes,
  UpdateHealthGoalScreening,
  UpdateHealthGoalScreeningError,
  UpdateHealthGoalScreeningSuccess,
} from './health-goal-screening.actions';

@Injectable()
export class HealthGoalScreeningEffects {
  constructor(
    private actions$: Actions,
    private service: HealthGoalScreeningService,
    private patientSelectors: PatientSelectors,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  addHealthGoalScreening$: Observable<Action> = this.actions$.pipe(
    ofType<AddHealthGoalScreening>(
      HealthGoalScreeningActionTypes.AddHealthGoalScreening,
    ),
    withLatestFrom(this.patientSelectors.patientId),
    switchMap(([action, patientId]) =>
      this.service.add(patientId, action.payload).pipe(
        map(screening => new AddHealthGoalScreeningSuccess(screening)),
        catchError(err =>
          of(
            new AddHealthGoalScreeningError(
              this.errorHandler.handleErrorSafe(err),
              {
                id: action.payload.id,
              },
            ),
          ),
        ),
      ),
    ),
  );

  @Effect()
  updateHealthGoalScreening$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateHealthGoalScreening>(
      HealthGoalScreeningActionTypes.UpdateHealthGoalScreening,
    ),
    switchMap(action =>
      this.service.update(action.payload).pipe(
        map(screening => new UpdateHealthGoalScreeningSuccess(screening)),
        catchError(err =>
          of(
            new UpdateHealthGoalScreeningError(
              this.errorHandler.handleErrorSafe(err),
              {
                id: action.payload.id,
              },
            ),
          ),
        ),
      ),
    ),
  );

  @Effect()
  deleteHealthGoalScreening$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteHealthGoalScreening>(
      HealthGoalScreeningActionTypes.DeleteHealthGoalScreening,
    ),
    switchMap(action =>
      this.service.delete(action.payload).pipe(
        map(
          () =>
            new DeleteHealthGoalScreeningSuccess(action.payload, {
              healthGoalId: action.meta.healthGoalId,
            }),
        ),
        catchError(err =>
          of(
            new DeleteHealthGoalScreeningError(
              this.errorHandler.handleErrorSafe(err),
              { id: action.payload },
            ),
          ),
        ),
      ),
    ),
  );
}
