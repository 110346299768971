import { NgModule } from '@angular/core';

import { AccessTokenResolver } from './shared/access-token-resolver';
import { AuthGuard } from './shared/auth.guard';
import { AuthService } from './shared/auth.service';
import { CodeToAccessTokenResolver } from './shared/code-to-access-token-resolver';
import { CookieService } from './shared/cookie.service';
import { LogoutGuard } from './shared/logout.guard';

@NgModule({
  providers: [
    CookieService,
    AuthService,
    AccessTokenResolver,
    CodeToAccessTokenResolver,
    LogoutGuard,
    AuthGuard,
  ],
})
export class AuthModule {
  constructor() {}
}
