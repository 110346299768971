import { PatientAllergy } from '@app/modules/allergies/shared/allergies.type';
import { mapToHealthBackgroundResponse } from '@app/modules/health-background/shared/health-background-utils';

import { PatientHealthMaintenanceNote } from '@app/core';
import {
  HealthGoalsSectionLinkPayload,
  HealthGoalWithScreenings,
} from '@app/modules/health-maintenance/shared/health-maintenance.type';
import { HealthGoalScreening } from '@app/modules/health-maintenance/shared/health-maintenance.type';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { VaccineHistory } from '@app/modules/vaccinations/shared/vaccinations.type';

const mapPatientAllergiesToSectionLinkedPayload = (
  allergies: PatientAllergy[],
) => {
  const payload = (allergies || []).map(item => ({
    active: item.active,
    allergy: {
      drug_allergy_check_compatible:
        item.allergy && item.allergy.drugAllergyCheckCompatible,
      id: item.allergy && item.allergy.id,
      name: item.allergy && item.allergy.name,
    },
    comment: item.comment,
    custom_allergen: item.customAllergen,
    id: item.id,
    patient_allergy_history_id: item.patientAllergyHistoryId,
    reaction: item.reaction,
  }));

  return [...payload];
};

const mapHealthMaintenanceNoteToSectionLink = (
  healthMaintenanceNote: PatientHealthMaintenanceNote,
) => ({
  id: healthMaintenanceNote.id,
  health_maintenance_note_history_id:
    healthMaintenanceNote.healthMaintenanceNoteHistoryId,
});

const mapScreeningHistoryToSectionLink = (
  screeningHistory: HealthGoalScreening[],
) =>
  screeningHistory.map(value => ({
    id: value.id,
    screening_history_id: value.screeningHistoryId,
  }));

const mapHealthGoalsToSectionLink = (healthGoals: HealthGoalWithScreenings[]) =>
  healthGoals.map(healthGoal => ({
    id: healthGoal.id,
    health_goal_history_id: healthGoal.healthGoalHistoryId,
    screening_history: mapScreeningHistoryToSectionLink(
      healthGoal.screeningHistory,
    ),
  }));

const mapHealthGoalsToSectionLinkPayload = (
  payload: HealthGoalsSectionLinkPayload,
) => {
  const healthGoals = payload.legacyData.healthGoals;
  const healthMaintenanceNote = payload.legacyData.healthMaintenanceNote;

  return [
    mapHealthGoalsToSectionLink(healthGoals),
    mapHealthMaintenanceNoteToSectionLink(healthMaintenanceNote),
  ];
};

const mapProblemsToSectionLinkPayload = (payload: Problem[]) =>
  payload.map(data => {
    return {
      included_in_medical_history: data.includedInMedicalHistory,
      active: data.active,
      problem_history_id: data.problemHistoryId,
    };
  });

const mapAssessedProblemToSectionLinkPayload = (payload: Problem) => {
  return {
    id: payload.id,
    problem_history_id: payload.problemHistoryId,
  };
};

const mapVaccineHistoryToSectionLink = (vaccineHistory: VaccineHistory) => ({
  given_on: vaccineHistory.givenOn,
  given_on_with_precision: vaccineHistory.givenOnWithPrecision,
  history_vaccine_history_id: vaccineHistory.historyVaccineHistoryId,
  id: vaccineHistory.id,
  name: vaccineHistory.name,
  step: vaccineHistory.step,
  was_administered: vaccineHistory.wasAdministered,
});

const mapVaccinationsToSectionLinkData = (vaccineHistory: VaccineHistory[]) =>
  vaccineHistory.map(mapVaccineHistoryToSectionLink);

const identity = x => x;

const sectionLinkedTransforms = {
  HealthBackground: hb => [mapToHealthBackgroundResponse(hb)],
  PatientAllergies: pa => [mapPatientAllergiesToSectionLinkedPayload(pa)],
  HealthGoals: healthGoal => mapHealthGoalsToSectionLinkPayload(healthGoal),
  Problems: problem => [mapProblemsToSectionLinkPayload(problem)],
  AssessedProblems: assessedProblem =>
    mapAssessedProblemToSectionLinkPayload(assessedProblem),
  Vaccinations: vaccination => [mapVaccinationsToSectionLinkData(vaccination)],
};

export const getSectionLinkedTransform = (sectionKey: string) => {
  const transform = sectionLinkedTransforms[sectionKey];
  return transform || identity;
};
