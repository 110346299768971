import { AfterViewInit, ElementRef, EventEmitter, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { HotkeysService } from 'angular2-hotkeys/src/hotkeys.service';
import { parseHotkeyStringToEventProperties } from '@app/shared/directives/shortcut-click/shortcut-click-handlers';
/**
 * Originally based on PrimeNG's p-editor
 * See their sourcecode for reference:
 * https://github.com/primefaces/primeng/blob/master/src/app/components/editor/editor.ts
 */
var RichTextEditorComponent = /** @class */ (function () {
    function RichTextEditorComponent(el, hotkeyService) {
        this.el = el;
        this.hotkeyService = hotkeyService;
        this.bounds = 'self';
        this.useMarkdownStyling = true;
        this.init = new EventEmitter();
        this.textChange = new EventEmitter();
        this.selectionChange = new EventEmitter();
        this._value = '';
        this._readonly = false;
        this.editorSelector = 'div.ui-editor-content';
        this.toolbarSelector = 'div.ui-editor-toolbar';
        this.onModelChange = function () { };
        this.onModelTouched = function () { };
    }
    Object.defineProperty(RichTextEditorComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                this.onModelChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RichTextEditorComponent.prototype, "readonly", {
        get: function () {
            return this._readonly;
        },
        set: function (val) {
            this._readonly = val;
            if (this.quill) {
                if (this._readonly) {
                    this.quill.disable();
                }
                else {
                    this.quill.enable();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    RichTextEditorComponent.prototype.ngAfterViewInit = function () {
        this.editorElement = this.selectElement(this.el, this.editorSelector);
        this.toolbarElement = this.selectElement(this.el, this.toolbarSelector);
        this.setupQuillEditor();
    };
    RichTextEditorComponent.prototype.insertText = function (index, html, source) {
        if (index === void 0) { index = 0; }
        if (source === void 0) { source = 'user'; }
        this.quill.clipboard.dangerouslyPasteHTML(index, html, source);
    };
    RichTextEditorComponent.prototype.onSelectionChange = function (range, oldRange, source) {
        this.selectionChange.emit({
            range: range,
            oldRange: oldRange,
            source: source,
        });
    };
    RichTextEditorComponent.prototype.onTextChange = function (delta, oldContents, source) {
        if (source === 'user') {
            var html = this.editorElement.children[0].innerHTML;
            var text = this.quill.getText().trim();
            if (html === '<p><br></p>') {
                html = null;
            }
            this.textChange.emit({
                htmlValue: html,
                textValue: text,
                delta: delta,
                source: source,
            });
            this.onModelChange(html);
            this.onModelTouched();
        }
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.registerShortcutClick = function () {
        var _this = this;
        if (!this.triggerShortcutClickOn) {
            return;
        }
        if (this.quill) {
            this.quill.keyboard.addBinding(parseHotkeyStringToEventProperties(this.triggerShortcutClickOn), function () {
                var hotkey = (_this.hotkeyService.get(_this.triggerShortcutClickOn));
                hotkey.callback(null, _this.triggerShortcutClickOn);
            });
        }
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.focus = function () {
        if (this.quill) {
            this.quill.focus();
        }
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.writeValue = function (value) {
        this.value = value;
        if (this.quill) {
            if (value) {
                this.quill.pasteHTML(value);
            }
            else {
                this.quill.setText('');
            }
        }
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.registerOnChange = function (fn) {
        this.onModelChange = fn;
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.registerOnTouched = function (fn) {
        this.onModelTouched = fn;
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.selectElement = function (el, selector) {
        return el.nativeElement.querySelector(selector);
    };
    /* istanbul ignore next */
    RichTextEditorComponent.prototype.setupQuillEditor = function () {
        this.quill = new Quill(this.editorElement, {
            modules: { toolbar: this.toolbarElement },
            placeholder: this.placeholderText,
            readOnly: this.readonly,
            theme: 'snow',
            bounds: this.bounds === 'self' ? this.editorElement : this.bounds,
        });
        if (this.value) {
            this.quill.pasteHTML(this.value);
        }
        this.quill.on('text-change', this.onTextChange.bind(this));
        this.quill.on('selection-change', this.onSelectionChange.bind(this));
        this.init.emit({
            editor: this.quill,
        });
        this.registerShortcutClick();
    };
    return RichTextEditorComponent;
}());
export { RichTextEditorComponent };
