import { Todo } from '@app/modules/todo/shared/todo.type';

import { SummaryNoteType, SummaryProblem } from './summaries.type';

/* Convert lbs/inches^2 to kg/m^2. */
const conversionFactor = 703;

export const getBmi = (weight: number, height: number) =>
  (weight / (height * height)) * conversionFactor;

export const hasNoSignificantMedicalHistory = (
  activeProblemsLength: number,
  resolvedProblemsLength: number,
) => activeProblemsLength + resolvedProblemsLength === 0;

export const sortProblemsByClinicalDescription = (
  problems: SummaryProblem[],
) => {
  return problems.sort((a, b) => {
    const itemA = a.problemType.clinicalDescription.toLowerCase();
    const itemB = b.problemType.clinicalDescription.toLowerCase();

    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }
    return 0;
  });
};

export const isFinishedCosignTodo = (todo: Todo, todoId: number) => {
  return (
    todo.name === 'Co-Sign' && todo.state === 'finished' && todo.id === todo.id
  );
};

export const isWalkinVisit = (summaryNoteType: SummaryNoteType) => {
  return summaryNoteType.systemName === 'walkin_visit';
};
