<omg-collapse om-layout-fill
              (expandedChange)="onExpanded($event)"
              #collapseRef="omgCollapse">

  <omg-collapsed om-layout-fill
                 disableAnimation="true">
    <div om-layout
         om-layout-fill
         om-layout-align="start center">
      <span om-flex="20">
        {{ screening?.dateFuzzy }}
      </span>
      <div om-flex="50">
        {{ screening?.eventName }}
        <span *ngIf="screening?.result">
          -
          <button omgButton
                  variant="link"
                  class="original-case"
                  omgTooltip
                  [tooltipText]="screening?.result"
                  tooltipPosition="top">
            {{ screening?.result | slice:0:21 }}
          </button>
        </span>
      </div>
      <span om-flex>
        <span *ngIf="screening?.patientSubmitted">
          Patient submitted
        </span>
        <span *ngIf="!screening?.patientSubmitted">
          <button omgButton
                  variant="link"
                  omgCollapseToggle>
            Edit
          </button>
        </span>
      </span>
    </div>
  </omg-collapsed>

  <omg-expanded om-layout-fill
                disableAnimation="true">
    <ng-container *ngIf="formLoaded; else formLoading">
      <omg-screening-form class="-subform"
                          [healthGoalType]="healthGoalType"
                          [formValue]="screeningFormValue"
                          [formErrors]="screeningErrors$ | async"
                          (cancel)="onCancel()"
                          (save)="onSave($event)"
                          (delete)="onDelete($event)">
      </omg-screening-form>
    </ng-container>
  </omg-expanded>

</omg-collapse>

<ng-template #formLoading>
  <div class="data-table"
       om-layout-fill>
    <div class="row"
         om-layout-align="center">
      <i class="fa fa-lg fa-spinner fa-pulse"></i>
    </div>
  </div>
</ng-template>
