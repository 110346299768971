var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Store } from '@ngrx/store';
export var TodoActionTypes;
(function (TodoActionTypes) {
    TodoActionTypes["LOAD_TODO"] = "[Todos] Load Todo";
    TodoActionTypes["LOAD_TODO_BY_SUMMARY_ID"] = "[Todos] Load Todo By Summary Id";
    TodoActionTypes["LOAD_TODO_SUCCESS"] = "[Todos] Load Todo Success";
    TodoActionTypes["LOAD_TODO_ERROR"] = "[Todos] Load Todo Error";
    TodoActionTypes["UPDATE_TODO"] = "[Todos] Update Todo";
    TodoActionTypes["UPDATE_TODO_SUCCESS"] = "[Todos] Update Todo Success";
    TodoActionTypes["UPDATE_TODO_ERROR"] = "[Todos] Update Todo Error";
    TodoActionTypes["COMPLETE_TODO"] = "[Todos] Complete Todo";
    TodoActionTypes["COMPLETE_TODO_SUCCESS"] = "[Todos] Complete Todo Success";
    TodoActionTypes["COMPLETE_TODO_ERROR"] = "[Todos] Complete Todo Error";
    TodoActionTypes["REOPEN_TODO"] = "[Todos] Reopen Todo";
    TodoActionTypes["REOPEN_TODO_SUCCESS"] = "[Todos] Reopen Todo Success";
    TodoActionTypes["REOPEN_TODO_ERROR"] = "[Todos] Reopen Todo Error";
})(TodoActionTypes || (TodoActionTypes = {}));
var LoadTodo = /** @class */ (function () {
    function LoadTodo(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.LOAD_TODO;
    }
    return LoadTodo;
}());
export { LoadTodo };
var LoadTodoBySummaryId = /** @class */ (function () {
    function LoadTodoBySummaryId(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID;
    }
    return LoadTodoBySummaryId;
}());
export { LoadTodoBySummaryId };
var LoadTodoSuccess = /** @class */ (function () {
    function LoadTodoSuccess(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.LOAD_TODO_SUCCESS;
        this.error = false;
    }
    return LoadTodoSuccess;
}());
export { LoadTodoSuccess };
var LoadTodoError = /** @class */ (function () {
    function LoadTodoError(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.LOAD_TODO_ERROR;
        this.error = true;
    }
    return LoadTodoError;
}());
export { LoadTodoError };
var UpdateTodo = /** @class */ (function () {
    function UpdateTodo(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.UPDATE_TODO;
    }
    return UpdateTodo;
}());
export { UpdateTodo };
var UpdateTodoSuccess = /** @class */ (function () {
    function UpdateTodoSuccess(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.UPDATE_TODO_SUCCESS;
        this.error = false;
    }
    return UpdateTodoSuccess;
}());
export { UpdateTodoSuccess };
var UpdateTodoError = /** @class */ (function () {
    function UpdateTodoError(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.UPDATE_TODO_ERROR;
        this.error = true;
    }
    return UpdateTodoError;
}());
export { UpdateTodoError };
var CompleteTodo = /** @class */ (function () {
    function CompleteTodo(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.COMPLETE_TODO;
    }
    return CompleteTodo;
}());
export { CompleteTodo };
var CompleteTodoSuccess = /** @class */ (function () {
    function CompleteTodoSuccess(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.COMPLETE_TODO_SUCCESS;
        this.error = false;
    }
    return CompleteTodoSuccess;
}());
export { CompleteTodoSuccess };
var CompleteTodoError = /** @class */ (function () {
    function CompleteTodoError(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.COMPLETE_TODO_ERROR;
        this.error = true;
    }
    return CompleteTodoError;
}());
export { CompleteTodoError };
var ReopenTodo = /** @class */ (function () {
    function ReopenTodo(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.REOPEN_TODO;
    }
    return ReopenTodo;
}());
export { ReopenTodo };
var ReopenTodoSuccess = /** @class */ (function () {
    function ReopenTodoSuccess(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.REOPEN_TODO_SUCCESS;
        this.error = false;
    }
    return ReopenTodoSuccess;
}());
export { ReopenTodoSuccess };
var ReopenTodoError = /** @class */ (function () {
    function ReopenTodoError(payload) {
        this.payload = payload;
        this.type = TodoActionTypes.REOPEN_TODO_ERROR;
        this.error = true;
    }
    return ReopenTodoError;
}());
export { ReopenTodoError };
var TodoActions = /** @class */ (function () {
    function TodoActions(store) {
        this.store = store;
    }
    TodoActions.prototype.loadTodo = function (id) {
        this.store.dispatch(new LoadTodo(id));
    };
    TodoActions.prototype.loadTodoBySummaryId = function (summaryId) {
        this.store.dispatch(new LoadTodoBySummaryId(summaryId));
    };
    TodoActions.prototype.loadTodoSuccess = function (todo) {
        this.store.dispatch(new LoadTodoSuccess(todo));
    };
    TodoActions.prototype.updateTodo = function (todo) {
        this.store.dispatch(new UpdateTodo(todo));
    };
    TodoActions.prototype.completeTodo = function (todo) {
        this.store.dispatch(new CompleteTodo(todo));
    };
    TodoActions.prototype.completeWithoutSigningNote = function (todo) {
        this.store.dispatch(new CompleteTodo(__assign({}, todo, { viaNote: false })));
    };
    TodoActions.prototype.completeAndSignNote = function (todo) {
        this.store.dispatch(new CompleteTodo(__assign({}, todo, { viaNote: true })));
    };
    TodoActions.prototype.reopenTodo = function (todo) {
        this.store.dispatch(new ReopenTodo(todo));
    };
    return TodoActions;
}());
export { TodoActions };
