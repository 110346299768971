import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessageService as PrimeNgMessageService } from 'primeng/api';
import { ToastModule as PrimeNgToastModule } from 'primeng/toast';

import { ToastMessageService } from './toast-message.service';
import { ToastComponent } from './toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, RouterModule, PrimeNgToastModule],
  providers: [PrimeNgMessageService],
  exports: [ToastComponent, PrimeNgToastModule],
})
export class ToastModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ToastModule,
      providers: [ToastMessageService],
    };
  }
}
