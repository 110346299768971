// Elasticsearch V6-ready options can go below this line:
export var buildQueryStringWithFieldsV6Strategy = function (query, options) {
    if (options === void 0) { options = {}; }
    return {
        index: options.index,
        size: options.size,
        scroll: options.scroll,
        body: {
            sort: options.sort,
            query: {
                bool: {
                    must: {
                        query_string: {
                            query: query,
                            fields: options.fields,
                            default_operator: options.operator || 'or',
                        },
                    },
                    filter: options.filter,
                },
            },
        },
    };
};
export var buildMultiMatchWithFieldsV6Strategy = function (query, options) {
    if (options === void 0) { options = {}; }
    return {
        index: options.index,
        size: options.size,
        scroll: options.scroll,
        body: {
            sort: options.sort,
            query: {
                bool: {
                    must: {
                        multi_match: {
                            query: query,
                            fields: options.fields,
                            operator: options.operator || 'or',
                        },
                    },
                    filter: options.filter,
                },
            },
        },
    };
};
export var buildFunctionScoreV6Strategy = function (query, options) {
    if (options === void 0) { options = {}; }
    return {
        index: options.index,
        size: options.size,
        scroll: options.scroll,
        body: {
            sort: options.sort,
            query: {
                function_score: {
                    query: {
                        constant_score: {
                            filter: {
                                multi_match: {
                                    query: query,
                                    fields: options.fields,
                                    operator: options.operator || 'or',
                                },
                            },
                        },
                    },
                    functions: options.fields &&
                        options.fields.map(function (field) {
                            var _a;
                            var _b = Array.from(field.split('^')), name = _b[0], boost = _b[1];
                            return {
                                filter: {
                                    match: (_a = {},
                                        _a[name] = query,
                                        _a),
                                },
                                weight: boost || '1',
                            };
                        }),
                    score_mode: 'first',
                },
            },
        },
    };
};
