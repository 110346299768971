import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Angulartics2Module } from 'angulartics2';

import { AnalyticsActions } from '@app/core/analytics/store/analytics.actions';
import { AnalyticsService } from './shared/analytics.service';
import { AnalyticsEffects } from './store/analytics.effects';

@NgModule({
  imports: [
    Angulartics2Module.forRoot({
      pageTracking: {
        autoTrackVirtualPages: false,
      },
    }),
    EffectsModule.forFeature([AnalyticsEffects]),
  ],
  exports: [Angulartics2Module],
  providers: [AnalyticsService, AnalyticsActions],
})
export class AnalyticsModule {
  constructor(service: AnalyticsService) {}
}
