/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/directives/legacy-ui-href.directive";
import * as i3 from "../../../../shared/window/window.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/primeng/components/menu/menu.ngfactory";
import * as i6 from "primeng/components/dom/domhandler";
import * as i7 from "primeng/components/menu/menu";
import * as i8 from "./app-navbar.component";
import * as i9 from "../../../../core/profile/store/profile.selectors";
import * as i10 from "../../../../core/feature-flag/store/feature-flag.selectors";
import * as i11 from "@angular/router";
var styles_AppNavbarComponent = [i0.styles];
var RenderType_AppNavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppNavbarComponent, data: {} });
export { RenderType_AppNavbarComponent as RenderType_AppNavbarComponent };
function View_AppNavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tasks"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.adminAppURL; _ck(_v, 0, 0, currVal_0); }); }
function View_AppNavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["omgLegacyUiHref", "/admin/task?current_user"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i2.LegacyUiHrefDirective, [i3.windowToken], { omgLegacyUiHref: [0, "omgLegacyUiHref"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tasks"]))], function (_ck, _v) { var currVal_1 = "/admin/task?current_user"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hrefAttr; _ck(_v, 0, 0, currVal_0); }); }
function View_AppNavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "om-nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["href", "/storybook/index.html"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Storybook"]))], null, null); }
export function View_AppNavbarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { profileMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 26, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 25, "ul", [["class", "om-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "li", [["class", "om-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "One Medical"], ["src", "img/om-atom.ad772541.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "li", [["class", "om-nav-item"], ["omgLegacyUiHref", "/admin"]], [[1, "href", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 540672, null, 0, i2.LegacyUiHrefDirective, [i3.windowToken], { omgLegacyUiHref: [0, "omgLegacyUiHref"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "li", [["class", "om-nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppNavbarComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppNavbarComponent_2)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppNavbarComponent_3)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 3, "p-menu", [["styleClass", "signout-button"]], null, null, null, i5.View_Menu_0, i5.RenderType_Menu)), i1.ɵprd(512, null, i6.DomHandler, i6.DomHandler, []), i1.ɵdid(21, 180224, [[1, 4], ["profileMenu", 4]], 0, i7.Menu, [i1.ElementRef, i6.DomHandler, i1.Renderer2], { model: [0, "model"], popup: [1, "popup"], styleClass: [2, "styleClass"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, null, 4, "li", [["class", "om-nav-item pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleProfileMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(25, null, ["", ""])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "fa fa-caret-down"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "/admin"; _ck(_v, 6, 0, currVal_1); var currVal_2 = !!i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.adminUiTasksEnabled$)); _ck(_v, 11, 0, currVal_2); var currVal_3 = !i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.adminUiTasksEnabled$)); _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.isEngineer$)); _ck(_v, 17, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform(_co.profileOptions)); var currVal_6 = true; var currVal_7 = "signout-button"; _ck(_v, 21, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).hrefAttr; _ck(_v, 5, 0, currVal_0); var currVal_8 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform(_co.primaryIdentityName)); _ck(_v, 25, 0, currVal_8); }); }
export function View_AppNavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-app-navbar", [], null, null, null, View_AppNavbarComponent_0, RenderType_AppNavbarComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppNavbarComponent, [i9.ProfileSelectors, i10.FeatureFlagSelectors, i11.Router, i1.ChangeDetectorRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppNavbarComponentNgFactory = i1.ɵccf("omg-app-navbar", i8.AppNavbarComponent, View_AppNavbarComponent_Host_0, {}, {}, []);
export { AppNavbarComponentNgFactory as AppNavbarComponentNgFactory };
