import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';

// import { CollapseModule } from './collapse/collapse.module';
import { ComponentsModule } from './components';
import { SearchableAutoCompleteComponent } from './components/searchable-auto-complete/searchable-auto-complete.component';
import { DirectivesModule } from './directives/directives.module';
import { FormsModule as OmgFormsModule } from './forms/forms.module';
import { PipesModule } from './pipes';
import { windowProviders } from './window/window.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // Custom Modules go below here
    // CollapseModule,
    ComponentsModule,
    DirectivesModule,
    OmgFormsModule,
    PipesModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    Angulartics2Module,
    // Custom Modules go below here
    // CollapseModule,
    SearchableAutoCompleteComponent,
    DirectivesModule,
    ComponentsModule,
    OmgFormsModule,
    PipesModule,
  ],
  declarations: [SearchableAutoCompleteComponent],
  providers: [...windowProviders],
})
export class SharedModule {}
