import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'omg-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutoCompleteComponent),
    },
  ],
})
export class AutoCompleteComponent
  implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() items: any[];
  @Input() placeholder = 'Select items';
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() customItem = false;
  @Input() clearable = true;
  @Input() resetItemsOnClear = true;
  @Input() addItemText = 'Add custom item';
  @Input() optionHighlight: string;
  @Input() trackByKey: string;
  @Input() hideInput: boolean;
  @Input() autoWidth: boolean;
  @Input() dropdownPosition = 'auto';
  @Input() fillLayout = true;
  @Input() highlightMatch = true;
  @Input() hideDropdownArrow = false;
  @Input() hideClearAll = false;
  @Input() searchFn: any;
  @Input() addTag: any;
  @Input() searchable = true;
  @Input() openOnChange = true;
  @Input() @ContentChild(TemplateRef) customDropdownTemplate: TemplateRef<any>;
  @Input() @ContentChild('labelTemplate') labelTemplate: TemplateRef<any>;
  @Input() @ContentChild('noResultsTemplate') noResultsTemplate: TemplateRef<
    any
  >;

  // tslint:disable-next-line:no-input-rename
  @Input('om-flex') flexValue: number = null;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgSelectComponent) private ref: NgSelectComponent;

  initialItems: any[];
  loading = false;

  // This is a workaround to use the ng-select in a flexbox
  get inlineStyle() {
    const inlineStyle = {} as CSSStyleDeclaration;

    if (this.flexValue) {
      inlineStyle.width = `${this.flexValue}%`;
    }

    return inlineStyle;
  }

  ngOnInit() {
    this.checkAutoWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.openOnChange) {
      return;
    }
    if (
      changes.items.previousValue === null &&
      changes.items.currentValue !== null
    ) {
      this.open();
    }
    if (
      changes.items.previousValue &&
      changes.items.currentValue &&
      changes.items.previousValue.length !==
        changes.items.currentValue.length &&
      changes.items.currentValue.length > 1
    ) {
      this.open();
    }
  }

  toggle() {
    this.ref.toggle();
  }

  open() {
    this.ref.open();
  }

  close() {
    this.ref.close();
  }

  trackByFn = (index, item: any) => item[this.trackByKey] || index;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.initialItems = this.items;

    const defaultOpen = this.ref.open;
    this.ref.open = () => {
      if (!this.items) {
        return false;
      }

      return defaultOpen.bind(this.ref)();
    };
  }

  /* istanbul ignore next */
  onOpen(event) {
    this.opened.emit();

    this.ngZone.runOutsideAngular(() =>
      window.addEventListener('scroll', this.closeDropdown.bind(this), true),
    );
  }

  /* istanbul ignore next */
  onClose(event) {
    this.closed.emit();

    this.ngZone.runOutsideAngular(() =>
      window.removeEventListener('scroll', this.closeDropdown.bind(this), true),
    );
  }

  focus() {
    this.ref.focus();
  }

  registerOnChange(fn: any) {
    this.ref.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.ref.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.ref.setDisabledState(isDisabled);
  }

  writeValue(obj: any) {
    this.ref.writeValue(obj);
  }

  onSearch(text: string) {
    if (!!text) {
      this.search.emit(text);
    } else {
      if (this.resetItemsOnClear) {
        this.resetItems(this.initialItems);
      }
      this.close();
    }
  }

  onBlur(text: string) {
    this.blur.emit(text);
  }

  resetItems(items?: any[]) {
    this.items = items ? items : null;
  }

  private checkAutoWidth() {
    const autoWidthClassName = 'auto-width';
    if (this.autoWidth) {
      if (this.ref.classes.indexOf(autoWidthClassName) === -1) {
        this.ref.classes = `${this.ref.classes} ${autoWidthClassName}`;
      } else {
        this.ref.classes = this.ref.classes;
      }
    }
  }

  /* istanbul ignore next */
  private closeDropdown(event) {
    if (this.ref && this.ref.isOpen) {
      this.ref.updateDropdownPosition();
    }
  }
}
