/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./problem-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angulartics2";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/pipes/date.pipe";
import * as i5 from "../../../../shared/pipes/date-ago.pipe";
import * as i6 from "../../../../shared/components/collapse/collapse.directive";
import * as i7 from "../../../../shared/components/collapse/collapse-provider.directive";
import * as i8 from "./problem-history.component";
var styles_ProblemHistoryComponent = [i0.styles];
var RenderType_ProblemHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProblemHistoryComponent, data: {} });
export { RenderType_ProblemHistoryComponent as RenderType_ProblemHistoryComponent };
function View_ProblemHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h4", [["class", "heading"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setExpanded(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["angulartics2On", "click"], ["angularticsAction", "Problem History Expanded"]], null, null, null, null, null)), i1.ɵdid(2, 1064960, null, 0, i2.Angulartics2On, [i1.ElementRef, i2.Angulartics2, i1.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-chevron-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Expand problem history "]))], function (_ck, _v) { var currVal_0 = "click"; var currVal_1 = "Problem History Expanded"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProblemHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h4", [["class", "heading"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setExpanded(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["angulartics2On", "click"], ["angularticsAction", "Problem History Collapsed"]], null, null, null, null, null)), i1.ɵdid(2, 1064960, null, 0, i2.Angulartics2On, [i1.ElementRef, i2.Angulartics2, i1.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-chevron-up"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Collapse problem history "]))], function (_ck, _v) { var currVal_0 = "click"; var currVal_1 = "Problem History Collapsed"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProblemHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Last modified by: ", " at ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editedBy; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.updatedAt, "withTime2y")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProblemHistoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " at ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.aAndP; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.updatedAt, "withTime2y")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProblemHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editedBy !== "Patient"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.editedBy === "Patient"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProblemHistoryComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.problemCodeLocation.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ProblemHistoryComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"], ["om-layout-gutter", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " by ", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_8)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.problemCodeLocation; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.createdAt)); var currVal_1 = (_v.context.$implicit.editedBy.name || "an unknown user"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.problemType.clinicalDescription; _ck(_v, 6, 0, currVal_2); var currVal_4 = _v.context.$implicit.briefComment; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.aAndP; _ck(_v, 12, 0, currVal_5); }); }
function View_ProblemHistoryComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " older edits not shown"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.history.total - 50); _ck(_v, 2, 0, currVal_0); }); }
function View_ProblemHistoryComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Edits to this problem made before ", " are not available"])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.historyStateDate, "4y")); _ck(_v, 2, 0, currVal_0); }); }
function View_ProblemHistoryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_9)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_10)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.history.items; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.history.total > 50); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.historyStartDate; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ProblemHistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i5.DateAgoPipe, []), i1.ɵqud(402653184, 1, { historyCollapse: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "data-table"], ["omgCollapse", ""]], [[2, "om-collapse", null], [1, "om-collapse", 0], [2, "om-collapse-expanded", null], [2, "-disabled", null]], null, null, null, null)), i1.ɵdid(4, 81920, [[1, 4], ["historyCollapse", 4]], 0, i6.CollapseDirective, [], null, null), i1.ɵdid(5, 540672, null, 0, i7.CollapseProviderDirective, [[2, i6.CollapseDirective]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemHistoryComponent_6)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_4 = !_co.expanded; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.expanded; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.editedBy; _ck(_v, 11, 0, currVal_6); var currVal_7 = (_co.history && _co.expanded); _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).collapseClass; var currVal_1 = i1.ɵnov(_v, 4).collapseAttribute; var currVal_2 = i1.ɵnov(_v, 4).expandedClass; var currVal_3 = i1.ɵnov(_v, 4).disabledClass; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ProblemHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-problem-history", [], null, null, null, View_ProblemHistoryComponent_0, RenderType_ProblemHistoryComponent)), i1.ɵdid(1, 49152, null, 0, i8.ProblemHistoryComponent, [], null, null)], null, null); }
var ProblemHistoryComponentNgFactory = i1.ɵccf("omg-problem-history", i8.ProblemHistoryComponent, View_ProblemHistoryComponent_Host_0, { history: "history", editedBy: "editedBy", updatedAt: "updatedAt", historyStartDate: "historyStartDate", aAndP: "aAndP" }, {}, []);
export { ProblemHistoryComponentNgFactory as ProblemHistoryComponentNgFactory };
