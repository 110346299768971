var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSelector } from '@ngrx/store';
import { orderBy } from '@app/utils';
import { createEntityMetadataSelectors, pluckEntitiesByIds, } from '@app/utils/store';
import { selectHealthGoalActionEntities } from './health-goal-type.selectors';
import { selectHealthGoalById } from './health-goal.selectors';
import { selectHealthMaintenanceState } from './health-maintenance-shared';
import * as fromHealthGoalScreening from './health-goal-screening.reducer';
// selects the state slice
export var selectHealthGoalScreeningState = createSelector(selectHealthMaintenanceState, function (state) { return state && state.healthGoalScreenings; });
// selects array of ids
export var selectHealthGoalScreeningIds = createSelector(selectHealthGoalScreeningState, fromHealthGoalScreening.selectHealthGoalScreeningIds);
// selects the dictionary
export var selectHealthGoalScreeningEntities = createSelector(selectHealthGoalScreeningState, fromHealthGoalScreening.selectHealthGoalScreeningEntities);
// selects the array
export var selectAllHealthGoalScreenings = createSelector(selectHealthGoalScreeningState, fromHealthGoalScreening.selectAllHealthGoalScreenings);
// selects the total number count
export var selectTotalHealthGoalScreenings = createSelector(selectHealthGoalScreeningState, fromHealthGoalScreening.selectHealthGoalScreeningTotal);
// selects by item id
export var selectHealthGoalScreeningById = createSelector(selectHealthGoalScreeningState, function (state, _a) {
    var id = _a.id;
    return state && state.entities && state.entities[id];
});
// selects loading
export var selectLoadingHealthGoalScreenings = createSelector(selectHealthGoalScreeningState, function (state) { return state && state.loading; });
// selects the general state error
export var selectHealthGoalScreeningsError = createSelector(selectHealthGoalScreeningState, function (state) { return state && state.error; });
/**
 * Projected Views
 */
var mapToSummary = function (actions, screening) {
    var action = actions[screening.eventId];
    return __assign({}, screening, { eventName: action && action.name });
};
var ɵ0 = mapToSummary;
// selects items by health goal id
export var selectHealthGoalScreeningsByHealthGoalId = createSelector(selectHealthGoalById, selectHealthGoalScreeningEntities, selectHealthGoalActionEntities, function (healthGoal, allScreenings, healthGoalActions) {
    if (!healthGoal) {
        return null;
    }
    var screenings = pluckEntitiesByIds(allScreenings, healthGoal.screeningHistoryIds);
    var result = screenings.map(function (i) { return mapToSummary(healthGoalActions, i); });
    return orderBy('date', 'desc', result);
});
/**
 * Entity Metadata
 */
export var selectHealthGoalScreeningWithMetadata = (_a = createEntityMetadataSelectors(selectHealthGoalScreeningState, selectHealthGoalScreeningById), _a.selectEntityWithMetadata), selectHealthGoalScreeningError = _a.selectEntityError;
export var selectHealthGoalScreeningSummaryWithMetadata = createSelector(selectHealthGoalScreeningWithMetadata, selectHealthGoalActionEntities, function (data, actions) { return (__assign({}, data, { entity: mapToSummary(actions, data.entity) })); });
export { ɵ0 };
