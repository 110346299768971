<div class="om-info-bar"
     om-layout
     om-layout-align="start center"
     om-layout-gutter>
  <ng-container *ngIf="patientInfo | async as info">
    <omg-patient-nav [patient]="info"></omg-patient-nav>
    <div class="avatar -with-nav">
      <img alt="Patient Photo"
           class="photo"
           [src]="info.photoUrl">
    </div>
    <div class="profile">
      <div om-layout
           om-layout-align="start center">
        <h2 class="human">{{info.name}}</h2>
        <p *ngIf="info.legalName"
           class="legal-name">
          Legal Name: {{info.legalName}}
        </p>
        <span class="om-label padding-half -inactive"
              *ngIf="info.isMinor">
          {{info.unborn ? "Unborn" : "Minor"}}
        </span>
      </div>
      <hr>
      <div class="demographics">
        <ul class="-data">
          <li>
            <span *ngIf="info.unborn; else born">
              <strong>Due Date </strong>{{info.dueDate | omgDate: '4y'}}
            </span>
            <ng-template #born>
              <strong>Birthday</strong> {{info.dateOfBirth | omgDate: '4y'}} ({{ info.age }})
            </ng-template>
          </li>
          <li [ngClass]="{
            'om-label': !!genderDescription
          }">
            <div om-layout
                 omgTooltip
                 [tooltipText]="info.genderDescription"
                 tooltipPosition="right"
                 om-layout-align="space-between center"
                 om-layout-gutter-mini>
              <span>
                <strong>Sex</strong> {{info.gender}},
                <span>
                  <button omgButton
                          variant="link"
                          class="-inline"
                          (click)="togglePronoun()">{{info.pronouns}}</button>
                  <omg-auto-complete #pronounDropdown
                                     [items]="pronounDropdownOptions | async"
                                     hideInput="true"
                                     autoWidth="true"
                                     trackByKey="id"
                                     bindLabel="name"
                                     resetItemsOnClear="false"
                                     bindValue="id"
                                     (change)="updatePronouns($event?.id)"
                                     om-layout-fill>
                  </omg-auto-complete>
                </span>
              </span>
              <span *ngIf="!!info.genderDescription"
                    class="om-icon icon-alert-info"></span>
            </div>
          </li>
        </ul>
        <ul class="-data">
          <li>
            <strong>Phone</strong> {{info.phone}}
          </li>
          <li>
            <strong>Email</strong> {{info.email}}
          </li>
        </ul>
        <ul class="-data">
          <li>
            <strong>PCP</strong> {{info.pcp}}
          </li>
          <li>
            <strong>Office</strong> {{info.officeName}}
          </li>
        </ul>
        <ul class="-data">
          <li>
            <strong>Joined</strong> {{info.memberSince | omgDate: 'withoutDay4y'}}
            <span class="capitalize padding-half warning"
                  *ngIf="info.membershipStatus">{{ info.membershipStatus }}</span>
          </li>
          <li>
            <strong>Insurance</strong> {{info.primaryInsurance?.name}} - {{info.primaryInsurance?.type}},
            {{info.primaryInsurance?.network}}
          </li>
        </ul>
        <ul class="-data"
            *ngIf="info.isB2b">
          <li>
            <strong>Enterprise</strong> {{info.enterprise?.companyPlanInfo}}
          </li>
          <li *ngIf="info.enterprise?.benefitsFaqUrl">
            <a target="_blank"
               [href]="info.enterprise?.benefitsFaqUrl">View Enterprise Benefits</a>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
