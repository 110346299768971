<omg-card>
  <omg-card-title omgCollapse
                  #vaccinesFormCollapse="omgCollapse"
                  heading="Vaccinations"
                  name="vaccineEditor">
    <a [omgLegacyUiHref]="vaccinePrintHref">
      <i class="fa fa-print vaccine-history-print-icon"
         title="Print Vaccine History">
      </i>
    </a>
    <button omgFocusProvider
            #focus="omgFocusProvider"
            omgShortcutClick="v"
            omgButton
            variant="invisible"
            omgCollapseToggle="expand"
            (click)="focus.setFocus('vaccinationFocus-create')">
      <span class="om-icon clickable icon-add-new"
            omgTooltip
            tooltipText="Add to Vaccine History [ V ]"
            tooltipPosition="right"></span>
    </button>
    <button omgButton
            variant="invisible"
            (click)="onLinkSection()"
            [disabled]="!(isSummaryActive | async)"
            angulartics2On
            angularticsAction="Vaccinations Section Added">
      <span omgTooltip
            tooltipText="Add to Note"
            tooltipPosition="right"
            class="om-icon clickable icon-push-to-note"></span>
    </button>

  </omg-card-title>
  <omg-expanded [collapseProvider]="vaccinesFormCollapse">
    <omg-vaccine-form (cancel)="onCancel()"
                      (save)="onSave($event)"></omg-vaccine-form>
  </omg-expanded>
  <div class="om-list"
       om-card-content
       *ngIf="hasVaccineHistories$ | async">
    <div *ngIf="groupingEnabled$ | async; else displayAll">
      <omg-vaccine-history-item class="om-item clickable -selectable -bordered"
                                om-collapse
                                *ngFor="let groupedHistory of (groupedVaccines$ | async | keyvalue); trackBy: trackByFn"
                                [groupedVaccineHistory]="groupedHistory.value"
                                [groupingEnabled]="true"
                                [patientId]="patientId$ | async">
      </omg-vaccine-history-item>
    </div>
    <ng-template #displayAll>
      <omg-vaccine-history-item class="om-item clickable"
                                om-collapse
                                *ngFor="let vaccineHistory of (vaccineHistories$ | async); trackBy: trackByFn"
                                [groupingEnabled]="false"
                                [vaccineHistory]="vaccineHistory"
                                [ngClass]="{ '-inset': vaccineHistory.fhirSource }"
                                [patientId]="patientId$ | async">
      </omg-vaccine-history-item>
    </ng-template>
  </div>
</omg-card>
