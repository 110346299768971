import { HttpClient } from '@angular/common/http';
import { NgZone, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from '@app/core/config';
import { ToastMessageService, } from '@app/shared/components/toast';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../shared/components/toast/toast-message.service";
var VersionCheckService = /** @class */ (function () {
    function VersionCheckService(config, http, zone, toastService) {
        this.config = config;
        this.http = http;
        this.zone = zone;
        this.toastService = toastService;
        this.parser = new DOMParser();
        this.unsubscribe = new Subject();
        this.targetUrl = this.config.environment.versionChecker
            ? this.config.environment.versionChecker.targetUrl
            : '';
    }
    VersionCheckService.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    VersionCheckService.prototype.versionDiffers = function (version) {
        if (!this.config.environment.codeVersion) {
            return false;
        }
        var strippedVersion = version.replace(/@@/, '');
        return strippedVersion !== this.config.environment.codeVersion;
    };
    VersionCheckService.prototype.parseHtml = function (data) {
        var html = this.parser.parseFromString(data, 'text/html');
        var head = html.head;
        var versionMetaTag = head.querySelector('meta[name="version"]');
        return versionMetaTag ? versionMetaTag.content : '';
    };
    VersionCheckService.prototype.notifyIfVersionDiffers = function (data) {
        var _this = this;
        var version = this.parseHtml(data);
        if (version) {
            var versionDiffers = this.versionDiffers(version);
            if (versionDiffers) {
                var notificationPayload_1 = {
                    severity: 'info',
                    summary: '1Life Updates',
                    detail: 'We made some updates, please refresh the page.',
                    sticky: true,
                };
                clearInterval(this.versionCheckIntervalId);
                this.zone.run(function () {
                    _this.toastService.add(notificationPayload_1);
                });
            }
        }
    };
    VersionCheckService.prototype.checkRemoteTarget = function () {
        var _this = this;
        return this.http
            .get(this.targetUrl, { responseType: 'text' })
            .pipe(tap(function (data) { return _this.notifyIfVersionDiffers(data); }));
    };
    VersionCheckService.prototype.startInterval = function () {
        var _this = this;
        return this.zone.runOutsideAngular(function () {
            return (_this.versionCheckIntervalId = setInterval(function () { return _this.checkRemoteTarget().subscribe(); }, _this.config.environment.versionChecker.pollingFrequency));
        });
    };
    VersionCheckService.ngInjectableDef = i0.defineInjectable({ factory: function VersionCheckService_Factory() { return new VersionCheckService(i0.inject(i1.ConfigService), i0.inject(i2.HttpClient), i0.inject(i0.NgZone), i0.inject(i3.ToastMessageService)); }, token: VersionCheckService, providedIn: "root" });
    return VersionCheckService;
}());
export { VersionCheckService };
