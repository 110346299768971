import { Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export var EXPAND_TIMELINE = '[PatientChart] Toggle Expanded Timeline';
export var EXPAND_TIMELINE_SUCCESS = '[PatientChart] Toggle Expanded Timeline Success';
export var EXPAND_TIMELINE_ERROR = '[PatientChart] Toggle Expanded Timeline Error';
var ExpandTimeline = /** @class */ (function () {
    function ExpandTimeline(payload) {
        this.payload = payload;
        this.type = EXPAND_TIMELINE;
    }
    return ExpandTimeline;
}());
export { ExpandTimeline };
var ExpandTimelineSuccess = /** @class */ (function () {
    function ExpandTimelineSuccess(payload) {
        this.payload = payload;
        this.type = EXPAND_TIMELINE_SUCCESS;
        this.error = false;
    }
    return ExpandTimelineSuccess;
}());
export { ExpandTimelineSuccess };
var ExpandTimelineError = /** @class */ (function () {
    function ExpandTimelineError(payload) {
        this.payload = payload;
        this.type = EXPAND_TIMELINE_ERROR;
        this.error = true;
    }
    return ExpandTimelineError;
}());
export { ExpandTimelineError };
var PatientChartActions = /** @class */ (function () {
    function PatientChartActions(store) {
        this.store = store;
    }
    PatientChartActions.prototype.expandTimeline = function (expanded) {
        this.store.dispatch(new ExpandTimeline({ expanded: expanded }));
    };
    PatientChartActions.ngInjectableDef = i0.defineInjectable({ factory: function PatientChartActions_Factory() { return new PatientChartActions(i0.inject(i1.Store)); }, token: PatientChartActions, providedIn: "root" });
    return PatientChartActions;
}());
export { PatientChartActions };
