import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fuzzyDateValidator, setFormErrors, wholeNumberValidator, } from '@app/shared/forms';
import { buildScreeningEventOptions } from '../../shared/health-goal-type-options';
import { newEntityId, } from '../../shared/health-maintenance.type';
var ScreeningFormComponent = /** @class */ (function () {
    function ScreeningFormComponent() {
        this.save = new EventEmitter();
        this.delete = new EventEmitter();
        this.cancel = new EventEmitter();
        this._unsubscribe = new Subject();
        this.buildForm();
    }
    Object.defineProperty(ScreeningFormComponent.prototype, "healthGoalType", {
        get: function () {
            return this._healthGoalType;
        },
        set: function (value) {
            if (value && this._healthGoalType !== value) {
                this._healthGoalType = value;
                this.eventOptions = buildScreeningEventOptions(value.healthGoalActions);
                this.onEventIdChange();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScreeningFormComponent.prototype, "formValue", {
        get: function () {
            return this.form.value;
        },
        set: function (value) {
            if (this._formValue !== value) {
                this._formValue = value;
                this.setFormValue(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScreeningFormComponent.prototype, "formErrors", {
        get: function () {
            return this.form.errors;
        },
        set: function (errors) {
            setFormErrors(this.form, errors, 'apiError');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScreeningFormComponent.prototype, "isNewScreening", {
        get: function () {
            var id = this.form.get('id').value;
            return id === null || id === newEntityId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScreeningFormComponent.prototype, "eventId", {
        get: function () {
            return this.form.get('eventId').value;
        },
        enumerable: true,
        configurable: true
    });
    ScreeningFormComponent.prototype.ngOnInit = function () { };
    ScreeningFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    };
    ScreeningFormComponent.prototype.onEventIdChange = function () {
        this.setSelectedEventName();
        this.setAllowedResults();
        this.setResultValidators();
    };
    ScreeningFormComponent.prototype.onCancel = function () {
        this.resetForm();
        this.cancel.emit();
    };
    ScreeningFormComponent.prototype.onSave = function () {
        this.save.emit(this.form.value);
    };
    ScreeningFormComponent.prototype.onDelete = function () {
        this.delete.emit(this.form.value);
    };
    ScreeningFormComponent.prototype.buildForm = function () {
        this.form = new FormGroup({
            id: new FormControl(),
            dateFuzzy: new FormControl(null, {
                validators: [Validators.required, fuzzyDateValidator],
            }),
            eventId: new FormControl(null, Validators.required),
            healthGoalId: new FormControl(),
            normalized: new FormControl(),
            patientId: new FormControl(),
            patientSubmitted: new FormControl(),
            result: new FormControl(null, Validators.required),
        });
        this.resetForm();
    };
    ScreeningFormComponent.prototype.subscribeToValueChanges = function () {
        var _this = this;
        this.form
            .get('eventId')
            .valueChanges.pipe(tap(function (id) {
            _this.onEventIdChange();
        }), takeUntil(this._unsubscribe))
            .subscribe();
    };
    ScreeningFormComponent.prototype.unsubscribeToValueChanges = function () {
        this._unsubscribe.next();
    };
    ScreeningFormComponent.prototype.setFormValue = function (value) {
        this.unsubscribeToValueChanges();
        this.form.setValue(value);
        this.setNormalizeResult();
        this.onEventIdChange();
        this.form.markAsPristine();
        this.subscribeToValueChanges();
    };
    ScreeningFormComponent.prototype.resetForm = function () {
        this.unsubscribeToValueChanges();
        this.form.reset(this._formValue);
        this.form.markAsPristine();
        this.subscribeToValueChanges();
    };
    ScreeningFormComponent.prototype.findAction = function (eventId) {
        var action = this._healthGoalType &&
            this._healthGoalType.healthGoalActions.find(function (a) { return a.id === eventId; });
        return action;
    };
    ScreeningFormComponent.prototype.setSelectedEventName = function () {
        var _this = this;
        var action = this._healthGoalType &&
            this._healthGoalType.healthGoalActions.find(function (a) { return a.id === _this.eventId; });
        this.selectedEventName = (action && action.name) || null;
    };
    ScreeningFormComponent.prototype.setNormalizeResult = function () {
        var id = this.form.get('id').value;
        var normalized = this.form.get('normalized').value;
        this.normalizeResult = normalized || this.isNewScreening;
    };
    ScreeningFormComponent.prototype.setAllowedResults = function () {
        var selectedAction = this.findAction(this.eventId);
        this.allowedResults = selectedAction ? selectedAction.allowedResults : null;
    };
    ScreeningFormComponent.prototype.setResultValidators = function () {
        var resultControl = this.form.get('result');
        var type = this.allowedResults && this.allowedResults.type;
        var resultTypeValidators = [];
        if (type === 'integer') {
            resultTypeValidators = [
                Validators.min(this.allowedResults.min),
                Validators.max(this.allowedResults.max),
                wholeNumberValidator(),
            ];
        }
        resultControl.setValidators([Validators.required].concat(resultTypeValidators));
    };
    return ScreeningFormComponent;
}());
export { ScreeningFormComponent };
