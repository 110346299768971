var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { VaccinationsApiService } from '../shared/vaccinations-api.service';
import { VaccinesActionTypes, } from '../shared/vaccinations.type';
import { DeleteVaccineHistoryError, DeleteVaccineHistorySuccess, LoadAllVaccineHistoriesError, LoadAllVaccineHistoriesSuccess, LoadVaccineHistoryError, LoadVaccineHistorySuccess, SaveVaccineHistoryError, SaveVaccineHistorySuccess, } from './vaccinations.actions';
var VaccinationsEffects = /** @class */ (function () {
    function VaccinationsEffects(actions$, service, patientSelectors, errorHandler) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.patientSelectors = patientSelectors;
        this.errorHandler = errorHandler;
        this.loadVaccineHistory$ = this.actions$.pipe(ofType(VaccinesActionTypes.LoadVaccineHistory), withLatestFrom(this.patientSelectors.patientId), mergeMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service
                .get({
                vaccineHistoryId: action.payload.vaccineHistoryId,
                patientId: patientId,
            })
                .pipe(map(function (response) { return new LoadVaccineHistorySuccess(response); }), catchError(function (error) {
                return of(new LoadVaccineHistoryError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.vaccineHistoryId,
                }));
            }));
        }));
        this.loadAllVaccineHistories$ = this.actions$.pipe(ofType(VaccinesActionTypes.LoadAllVaccineHistories), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service.getAll(patientId).pipe(map(function (response) {
                return new LoadAllVaccineHistoriesSuccess(response);
            }), catchError(function (error) {
                return of(new LoadAllVaccineHistoriesError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.saveVaccineHistory$ = this.actions$.pipe(ofType(VaccinesActionTypes.SaveVaccineHistory), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service
                .save({ vaccineHistory: action.payload.vaccineHistory, patientId: patientId })
                .pipe(map(function (response) {
                return new SaveVaccineHistorySuccess(response);
            }), catchError(function (error) {
                return of(new SaveVaccineHistoryError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.vaccineHistory.id,
                }));
            }));
        }));
        this.deleteVaccineHistory$ = this.actions$.pipe(ofType(VaccinesActionTypes.DeleteVaccineHistory), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service
                .delete({
                vaccineHistoryId: action.payload.vaccineHistoryId,
                patientId: patientId,
            })
                .pipe(map(function () {
                return new DeleteVaccineHistorySuccess(action.payload.vaccineHistoryId);
            }), catchError(function (error) {
                return of(new DeleteVaccineHistoryError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.vaccineHistoryId,
                }));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VaccinationsEffects.prototype, "loadVaccineHistory$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VaccinationsEffects.prototype, "loadAllVaccineHistories$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VaccinationsEffects.prototype, "saveVaccineHistory$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VaccinationsEffects.prototype, "deleteVaccineHistory$", void 0);
    return VaccinationsEffects;
}());
export { VaccinationsEffects };
