<div *ngIf="inputType === 'radio'">
  <label *ngFor="let value of allowedResults.values"
         [for]="value">
    <input omgRadioButton
           type="radio"
           name="result"
           [id]="value"
           [value]="value"
           [checked]="value === result"
           (change)="onResultChange(value)" />
    {{ value }}
  </label>
</div>

<div *ngIf="inputType === 'integer'">
  <input omgInputNumber
         class="om-input"
         type="number"
         name="result"
         [ngModel]="result"
         (ngModelChange)="onResultChange($event)"
         placeholder="Enter {{ allowedResults?.min }} - {{ allowedResults?.max }}"
         [min]="allowedResults?.min"
         [max]="allowedResults?.max" />
</div>

<div *ngIf="inputType === 'text'">
  <input omgInputText
         type="text"
         name="result"
         [ngModel]="result"
         (ngModelChange)="onResultChange($event)" />
</div>
