/**
 * Re-export functions from lodash/fp
 * - https://github.com/lodash/lodash/wiki/FP-Guide
 */

export {
  cloneDeep,
  curry,
  defaultTo,
  escapeRegExp,
  difference,
  flow,
  filter,
  first,
  flatMap,
  flow as pipe,
  get,
  getOr,
  head,
  invoke,
  isArray,
  isDate,
  isEqual,
  isError,
  isNumber,
  isObject,
  isPlainObject,
  isRegExp,
  isString,
  join,
  map,
  omit,
  orderBy,
  pickBy,
  range,
  reduce,
  remove,
  sortBy,
  without,
} from 'lodash/fp';

// uncapped versions
export { pad } from 'lodash';
