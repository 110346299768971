export const angularJsModuleName = 'onelifeUi';
export const isHybridMode = true;

// NOTE: This is required only for toggling the patient { chart } and can be
// removed once the toggle is no longer required.
declare global {
  // Global constants injected at build time
  const USE_UPGRADED_CHART: boolean;
}

// Environment variable provided by Webpack DefinePlugin
export const useUpgradedChart = USE_UPGRADED_CHART || false;
export const useUpgradeRoute = !USE_UPGRADED_CHART || false;
