/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/directives/legacy-ui-href.directive";
import * as i2 from "../../../../shared/window/window.service";
import * as i3 from "./schedule.component";
var styles_ScheduleComponent = [];
var RenderType_ScheduleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScheduleComponent, data: {} });
export { RenderType_ScheduleComponent as RenderType_ScheduleComponent };
export function View_ScheduleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["om-flex", ""], ["om-layout", ""], ["om-layout-align", "center center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Clinical UX notes can be started from your "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["omgLegacyUiHref", "/admin"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i1.LegacyUiHrefDirective, [i2.windowToken], { omgLegacyUiHref: [0, "omgLegacyUiHref"] }, null), (_l()(), i0.ɵted(-1, null, ["schedule"])), (_l()(), i0.ɵted(-1, null, [" by clicking the clock icon for an appointment "]))], function (_ck, _v) { var currVal_1 = "/admin"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).hrefAttr; _ck(_v, 3, 0, currVal_0); }); }
export function View_ScheduleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-schedule", [], null, null, null, View_ScheduleComponent_0, RenderType_ScheduleComponent)), i0.ɵdid(1, 49152, null, 0, i3.ScheduleComponent, [], null, null)], null, null); }
var ScheduleComponentNgFactory = i0.ɵccf("omg-schedule", i3.ScheduleComponent, View_ScheduleComponent_Host_0, {}, {}, []);
export { ScheduleComponentNgFactory as ScheduleComponentNgFactory };
