import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { DomHandler } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[omgTooltip]',
  providers: [DomHandler],
})
export class TooltipDirective extends Tooltip implements OnInit, OnChanges {
  @Input()
  public tooltipText: string;

  @Input()
  public showTooltip = true;

  constructor(el: ElementRef, domHandler: DomHandler, zone: NgZone) {
    super(el, domHandler, zone);

    this.showDelay = 250;
  }

  public ngOnInit() {
    this._text = this.tooltipText;
    this.disabled = !this.showTooltip;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.showTooltip) {
      this.disabled = !changes.showTooltip.currentValue;
    }
  }
}
