import { OrderTypes } from '../shared/order-utils';
import {
  ConsultOrder,
  Order,
  OrderContact,
  ProcedureOrder,
} from '../shared/order.type';

export const mapOrderToEntity = (fullOrder: Order): Order => {
  const { indication, indications: fullOrderIndications, ...order } = fullOrder;
  let indications = fullOrderIndications || [];
  if (indication) {
    indications = [...indications, ...[indication]];
  }

  switch (fullOrder.type) {
    case OrderTypes.ProcedureOrder:
      const procedureOrder = <ProcedureOrder>order;
      return <ProcedureOrder>{
        ...order,
        indications,
        contact: procedureOrder.contact || new OrderContact(),
      };
    case OrderTypes.ConsultOrder:
      const consultOrder = order as ConsultOrder;
      return <ConsultOrder>{
        ...order,
        indications,
        contact: consultOrder.contact || new OrderContact(),
      };
    default:
      return fullOrder;
  }
};

export const mapOrdersToEntities = (orders: Order[]): Order[] => {
  return orders.map(mapOrderToEntity);
};
