import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { SearchService } from '@app/core/search/search.service';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { PatientAllergy } from '@app/modules/allergies/shared/allergies.type';

import {
  allergiesRoute,
  mapAllergyResponseToEntity,
  mapNoKnownAllergyResponseToEntity,
} from './allergies-utils';
import {
  PatientAllergies,
  PatientAllergiesResponse,
  PatientAllergyResponse,
} from './allergies.type';

@Injectable()
export class AllergiesService {
  private patientBaseRoute = 'patients';
  private allergiesBaseRoute = 'patient_allergies';
  private allergiesSubRoute = '/patient_allergies';
  private noKnownAllergiesSubRoute =
    '/patient_allergies/patient_no_known_allergy';

  constructor(
    private api: ApiService,
    private searchService: SearchService,
    private config: ConfigService,
    private summariesActions: SummariesActions,
  ) {}

  getAll(patientId: number): Observable<PatientAllergies> {
    return this.api
      .get<PatientAllergiesResponse>(
        allergiesRoute(
          patientId,
          this.patientBaseRoute,
          this.allergiesSubRoute,
        ),
      )
      .pipe(map(mapAllergyResponseToEntity));
  }

  save(
    patientId: number,
    allergy: PatientAllergyResponse,
  ): Observable<PatientAllergies> {
    return this.api
      .save<PatientAllergiesResponse>(
        allergiesRoute(
          patientId,
          this.patientBaseRoute,
          this.allergiesSubRoute,
        ),
        allergy,
      )
      .pipe(map(mapAllergyResponseToEntity));
  }

  update(
    patientId: number,
    allergy: Partial<PatientAllergyResponse>,
  ): Observable<PatientAllergies> {
    return this.api
      .update<PatientAllergiesResponse>(
        allergiesRoute(patientId, this.allergiesBaseRoute, '', allergy.id),
        allergy,
      )
      .pipe(map(mapAllergyResponseToEntity));
  }

  delete(patientId: number, allergyId: number) {
    return this.api.delete(
      allergiesRoute(patientId, this.allergiesBaseRoute, '', allergyId),
    );
  }

  noKnownAllergy(patientId: number): Observable<any> {
    return this.api
      .save(
        allergiesRoute(
          patientId,
          this.patientBaseRoute,
          this.noKnownAllergiesSubRoute,
        ),
        null,
      )
      .pipe(map(mapNoKnownAllergyResponseToEntity));
  }

  linkSection(patientId: number, data: PatientAllergy[]) {
    this.summariesActions.linkPatientAllergies({
      patientId,
      patientAllergies: data,
    });
  }

  getSearchResults(text: string): Observable<any> {
    const query = new QueryBuilder('function_score_v6').build(text, {
      size: '8',
      fields: ['clinical_abbreviation^4', 'name^2', 'name.words'],
      sort: ['_score', 'name.keyword'],
      index: [this.config.searchIndex('allergies')],
    });

    return this.searchService.search(query).pipe(
      map(response => {
        const hits = response.hits || {};
        const items = hits.hits || [];

        return items.map(hit => hit._source);
      }),
    );
  }
}
