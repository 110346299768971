var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createFeatureSelector, createSelector, select, Store, } from '@ngrx/store';
import * as utils from '../shared/patient-utils';
import { patientStatePath } from './patient.reducer';
export var selectPatientState = createFeatureSelector(patientStatePath);
export var selectPatient = createSelector(selectPatientState, function (state) { return state && state.entity; });
export var selectPatientId = createSelector(selectPatient, function (p) { return p && p.id; });
export var selectPcpComments = createSelector(selectPatient, function (p) { return p && p.pcpComments; });
export var selectHealthMaintenanceNote = createSelector(selectPatient, function (p) { return p && p.healthMaintenanceNote; });
export var selectFullName = createSelector(selectPatient, function (p) { return p && p.firstName + " " + p.lastName; });
export var selectDateOfBirth = createSelector(selectPatient, function (p) { return p && p.dateOfBirth; });
export var selectGender = createSelector(selectPatient, function (p) { return p && p.gender; });
export var selectPastOrCurrentElectronicTosAccepted = createSelector(selectPatient, function (p) { return p && p.pastOrCurrentElectronicTosAccepted; });
export var selectPatientStateName = createSelector(selectPatient, function (p) {
    return p &&
        p.office &&
        p.office.address &&
        p.office.address.state &&
        p.office.address.state.name;
});
export var selectFormattedAge = createSelector(selectPatient, function (p) { return utils.formattedAge(p); });
export var selectageToNearestMonth = createSelector(selectPatient, function (p) { return p && p.ageToNearestMonth; });
export var selectIsMinor = createSelector(selectPatient, function (p) { return utils.isMinor(p); });
export var selectIsTeen = createSelector(selectPatient, function (p) { return utils.isTeen(p); });
export var selectIsPreteen = createSelector(selectPatient, function (p) { return utils.isPreteen(p); });
export var selectHasIncompleteDemographics = createSelector(selectPatient, function (p) { return utils.hasIncompleteDemographics(p); });
export var selectAcceptsDigitalCommunications = createSelector(selectPatient, function (p) { return utils.acceptsDigitalCommunications(p); });
export var selectLimitedAccessMembership = createSelector(selectPatient, function (p) { return utils.limitedAccessMembership(p); });
export var selectExpiredMembership = createSelector(selectPatient, function (p) { return utils.expiredMembership(p); });
export var selectPatientWarnings = createSelector(selectPatient, function (p) { return utils.buildPatientWarnings(p); });
export var selectPatientInfo = createSelector(selectPatient, selectFormattedAge, selectIsTeen, selectIsPreteen, selectAcceptsDigitalCommunications, selectHasIncompleteDemographics, selectExpiredMembership, selectLimitedAccessMembership, function (patient, formattedAge, isTeen, isPreteen, acceptsDigitalCommunications, hasIncompleteDemographics, expiredMembership, limitedAccessMembership) { return (__assign({}, patient, { formattedAge: formattedAge,
    isTeen: isTeen,
    isPreteen: isPreteen,
    acceptsDigitalCommunications: acceptsDigitalCommunications,
    hasIncompleteDemographics: hasIncompleteDemographics,
    expiredMembership: expiredMembership,
    limitedAccessMembership: limitedAccessMembership })); });
/* istanbul ignore next */
var PatientSelectors = /** @class */ (function () {
    function PatientSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(PatientSelectors.prototype, "state", {
        get: function () {
            return this.store.pipe(select(selectPatientState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "patientId", {
        get: function () {
            return this.store.pipe(select(selectPatientId));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "patient", {
        get: function () {
            return this.store.pipe(select(selectPatient));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "pcpComments", {
        get: function () {
            return this.store.pipe(select(selectPcpComments));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "healthMaintenanceNote", {
        get: function () {
            return this.store.pipe(select(selectHealthMaintenanceNote));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "fullName", {
        get: function () {
            return this.store.pipe(select(selectFullName));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "dateOfBirth", {
        get: function () {
            return this.store.pipe(select(selectDateOfBirth));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "gender", {
        get: function () {
            return this.store.pipe(select(selectGender));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "formattedAge", {
        get: function () {
            return this.store.pipe(select(selectFormattedAge));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "ageToNearestMonth", {
        get: function () {
            return this.store.pipe(select(selectageToNearestMonth));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "isMinor", {
        get: function () {
            return this.store.pipe(select(selectIsMinor));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "isTeen", {
        get: function () {
            return this.store.pipe(select(selectIsTeen));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "isPreteen", {
        get: function () {
            return this.store.pipe(select(selectIsPreteen));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "hasIncompleteDemographics", {
        get: function () {
            return this.store.pipe(select(selectHasIncompleteDemographics));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "acceptsDigitalCommunications", {
        get: function () {
            return this.store.pipe(select(selectAcceptsDigitalCommunications));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "limitedAccessMembership", {
        get: function () {
            return this.store.pipe(select(selectLimitedAccessMembership));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "expiredMembership", {
        get: function () {
            return this.store.pipe(select(selectExpiredMembership));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "patientInfo", {
        get: function () {
            return this.store.pipe(select(selectPatientInfo));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "patientWarnings", {
        get: function () {
            return this.store.pipe(select(selectPatientWarnings));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "pastOrCurrentElectronicTosAccepted", {
        get: function () {
            return this.store.pipe(select(selectPastOrCurrentElectronicTosAccepted));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientSelectors.prototype, "patientStateName", {
        get: function () {
            return this.store.pipe(select(selectPatientStateName));
        },
        enumerable: true,
        configurable: true
    });
    return PatientSelectors;
}());
export { PatientSelectors };
