/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./upgrade-route-link.component";
import * as i2 from "../../core/patient/store/patient.selectors";
import * as i3 from "@angular/router";
var styles_UpgradeRouteLinkComponent = [];
var RenderType_UpgradeRouteLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UpgradeRouteLinkComponent, data: {} });
export { RenderType_UpgradeRouteLinkComponent as RenderType_UpgradeRouteLinkComponent };
export function View_UpgradeRouteLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["title", "WORK IN PROGRESS"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Angular 7 Chart"]))], null, null); }
export function View_UpgradeRouteLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-upgrade-route-link", [], null, null, null, View_UpgradeRouteLinkComponent_0, RenderType_UpgradeRouteLinkComponent)), i0.ɵdid(1, 245760, null, 0, i1.UpgradeRouteLinkComponent, [i2.PatientSelectors, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpgradeRouteLinkComponentNgFactory = i0.ɵccf("omg-upgrade-route-link", i1.UpgradeRouteLinkComponent, View_UpgradeRouteLinkComponent_Host_0, {}, {}, []);
export { UpgradeRouteLinkComponentNgFactory as UpgradeRouteLinkComponentNgFactory };
