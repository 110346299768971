import { DropdownItem } from '@app/shared/components';

import { HealthGoalAction, HealthGoalType } from './health-maintenance.type';

const mapHealthGoalTypeOption = (
  healthGoalType: HealthGoalType,
): DropdownItem => ({
  label: healthGoalType.name,
  value: healthGoalType.id,
});

const mapHealthGoalActionOption = (
  healthGoalAction: HealthGoalAction,
): DropdownItem => ({
  label: healthGoalAction.name,
  value: healthGoalAction.id,
});

const findHealthGoalType = (
  healthGoalTypes: HealthGoalType[],
  healthGoalTypeId: number,
) => healthGoalTypes.find(i => i.id === healthGoalTypeId);

const getFirstHealthGoalActionId = (healthGoalType: HealthGoalType): number =>
  healthGoalType && healthGoalType.healthGoalActions.length > 0
    ? healthGoalType.healthGoalActions[0].id
    : null;

export const buildGoalTypeOptions = (healthGoalTypes: HealthGoalType[]) => [
  ...(healthGoalTypes || []).map(mapHealthGoalTypeOption),
];

export const buildGoalActionOptions = (actions: HealthGoalAction[]) => [
  { label: 'Undetermined', value: null },
  ...actions.map(mapHealthGoalActionOption),
];

export const buildScreeningEventOptions = (actions: HealthGoalAction[]) => [
  { label: 'Select an Event', value: null, disabled: true },
  ...actions.map(mapHealthGoalActionOption),
];

export const getDefaultHealthGoalActionId = (
  healthGoalTypes: HealthGoalType[],
  healthGoalTypeId: number,
) => {
  // Return the first action id as the default or null
  const healthGoalType = findHealthGoalType(healthGoalTypes, healthGoalTypeId);
  return getFirstHealthGoalActionId(healthGoalType);
};

export const getDefaultScreeningEventId = (
  healthGoalType: HealthGoalType,
  healthGoalActionId: number = null,
) => {
  if (!healthGoalType) {
    return null;
  }

  // Return the parent health goals action id or if null the first action
  if (healthGoalActionId) {
    return healthGoalActionId;
  } else {
    return getFirstHealthGoalActionId(healthGoalType);
  }
};

interface HealthGoalTypeOptionsState {
  healthGoalTypes: HealthGoalType[];
  healthGoalTypeOptions: DropdownItem[];
  healthGoalActionOptions: DropdownItem[];
  selectedHealthGoalType: HealthGoalType;
}

const buildInitialState = (): HealthGoalTypeOptionsState => ({
  healthGoalTypes: [],
  healthGoalTypeOptions: [],
  healthGoalActionOptions: [],
  selectedHealthGoalType: null,
});

export class HealthGoalTypeOptions {
  private _state: HealthGoalTypeOptionsState = buildInitialState();

  get options() {
    return this._state.healthGoalTypeOptions;
  }

  get actionOptions() {
    return this._state.healthGoalActionOptions;
  }

  get healthGoalTypes() {
    return this._state.healthGoalTypes;
  }

  set healthGoalTypes(value: HealthGoalType[]) {
    if (this._state.healthGoalTypes !== value) {
      this._state.healthGoalTypes = value;
      this._state.healthGoalTypeOptions = buildGoalTypeOptions(value);
    }
  }

  get selectedGoalType() {
    return this._state.selectedHealthGoalType;
  }

  get selectedTypeId() {
    return (
      this._state.selectedHealthGoalType &&
      this._state.selectedHealthGoalType.id
    );
  }

  set selectedTypeId(value: number) {
    if (this.selectedTypeId !== value) {
      const healthGoalType = findHealthGoalType(this.healthGoalTypes, value);
      this._state.selectedHealthGoalType = healthGoalType;
      const healthGoalActions =
        (healthGoalType && healthGoalType.healthGoalActions) || [];
      this._state.healthGoalActionOptions = buildGoalActionOptions(
        healthGoalActions,
      );
    }
  }
}
