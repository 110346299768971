<form [formGroup]="formModel.form"
      [omgForm]="formModel"
      class="padding-normal"
      *ngIf="formModel.form.value"
      om-layout="vertical"
      om-layout-gutter>
  <textarea omgTextarea
            autosize
            rows="5"
            formControlName="content"
            placeholder="Enter other health maintenance info here"
            om-layout-fill></textarea>
  <div>
    <omg-form-state when="saving"></omg-form-state>&nbsp;
  </div>
</form>
