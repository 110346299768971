import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  updateEntityMetadata,
} from '@app/utils';

import {
  newEntityId,
  VaccineHistory,
  VaccinesActionTypes,
} from '../shared/vaccinations.type';
import { VaccineHistoryActions } from './vaccinations.actions';

export interface VaccineHistoriesState extends EntityState<VaccineHistory> {
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<VaccineHistory>;
}

const sortComparer = (a: VaccineHistory, b: VaccineHistory) =>
  a.name && b.name && a.name.localeCompare(b.name);

export const adapter: EntityAdapter<VaccineHistory> = createEntityAdapter<
  VaccineHistory
>({
  selectId: (entity: VaccineHistory) => entity.id,
  sortComparer,
});

export const initialState: VaccineHistoriesState = adapter.getInitialState({
  loading: false,
  error: null,
  ...getEntityMetadataInitialState({}),
});

export function vaccineHistoriesReducer(
  state = initialState,
  action: VaccineHistoryActions,
): VaccineHistoriesState {
  switch (action.type) {
    case VaccinesActionTypes.LoadVaccineHistory: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.vaccineHistoryId,
          { pending: true },
          state,
        ),
      };
    }

    case VaccinesActionTypes.LoadVaccineHistorySuccess: {
      return {
        ...adapter.upsertOne(
          { ...action.payload, ...{ hasFullyLoaded: true } },
          state,
        ),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          action.payload.id,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case VaccinesActionTypes.LoadVaccineHistoryError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case VaccinesActionTypes.LoadAllVaccineHistories: {
      return {
        ...state,
        loading: true,
      };
    }

    case VaccinesActionTypes.LoadAllVaccineHistoriesSuccess: {
      return {
        ...adapter.addAll(action.payload, state),
        loading: false,
        error: null,
      };
    }

    case VaccinesActionTypes.LoadAllVaccineHistoriesError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case VaccinesActionTypes.SaveVaccineHistory: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case VaccinesActionTypes.SaveVaccineHistorySuccess: {
      return {
        ...adapter.addOne(action.payload, state),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case VaccinesActionTypes.SaveVaccineHistoryError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case VaccinesActionTypes.DeleteVaccineHistory: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.vaccineHistoryId,
          { pending: true },
          state,
        ),
      };
    }

    case VaccinesActionTypes.DeleteVaccineHistorySuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload, state),
      };
    }

    case VaccinesActionTypes.DeleteVaccineHistoryError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    default: {
      return state;
    }
  }
}

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectVaccineHistoriesIds = selectIds;
export const selectVaccineHistoriesEntities = selectEntities;
export const selectAllVaccineHistories = selectAll;
export const selectVaccineHistoriesTotal = selectTotal;
