/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "./dialog-container";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/common";
import * as i5 from "./dialog-config";
var styles_DialogContainer = [];
var RenderType_DialogContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogContainer, data: {} });
export { RenderType_DialogContainer as RenderType_DialogContainer };
function View_DialogContainer_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_DialogContainer_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { portalOutlet: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogContainer_1)), i0.ɵdid(2, 212992, [[1, 4]], 0, i1.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DialogContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-dialog-container", [["aria-modal", "true"], ["class", "om-dialog-container"], ["tabindex", "-1"]], [[1, "id", 0], [1, "role", 0], [1, "aria-labelledby", 0], [1, "aria-label", 0], [1, "aria-describedby", 0]], null, null, View_DialogContainer_0, RenderType_DialogContainer)), i0.ɵdid(1, 49152, null, 0, i2.DialogContainer, [i0.ElementRef, i3.FocusTrapFactory, [2, i4.DOCUMENT], i5.DialogConfig], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._id; var currVal_1 = i0.ɵnov(_v, 1)._config.role; var currVal_2 = (i0.ɵnov(_v, 1)._config.ariaLabel ? null : i0.ɵnov(_v, 1)._ariaLabelledBy); var currVal_3 = i0.ɵnov(_v, 1)._config.ariaLabel; var currVal_4 = (i0.ɵnov(_v, 1)._config.ariaDescribedBy || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var DialogContainerNgFactory = i0.ɵccf("omg-dialog-container", i2.DialogContainer, View_DialogContainer_Host_0, {}, {}, []);
export { DialogContainerNgFactory as DialogContainerNgFactory };
