import { createSelector } from '@ngrx/store';

import { flatMap, sortBy } from '@app/utils';

import { HealthGoalAction } from '../shared/health-maintenance.type';
import * as fromHealthGoalType from './health-goal-type.reducer';
import { selectHealthMaintenanceState } from './health-maintenance-shared';

// selects the state slice
export const selectHealthGoalTypeState = createSelector(
  selectHealthMaintenanceState,
  state => state.healthGoalTypes,
);

// selects array of ids
export const selectHealthGoalTypeIds = createSelector(
  selectHealthGoalTypeState,
  fromHealthGoalType.selectHealthGoalTypeIds,
);

// selects the dictionary
export const selectHealthGoalTypeEntities = createSelector(
  selectHealthGoalTypeState,
  fromHealthGoalType.selectHealthGoalTypeEntities,
);

// selects the array
export const selectAllHealthGoalTypes = createSelector(
  selectHealthGoalTypeState,
  fromHealthGoalType.selectAllHealthGoalTypes,
);

// selected by item id
export const selectHealthGoalTypeById = createSelector(
  selectHealthGoalTypeState,
  (state, { id }) => state.entities[id],
);

// selects the total number count
export const selectTotalHealthGoalTypes = createSelector(
  selectHealthGoalTypeState,
  fromHealthGoalType.selectHealthGoalTypeTotal,
);

// selects loading
export const selectLoadingHealthGoalTypes = createSelector(
  selectHealthGoalTypeState,
  state => state && state.loading,
);

// selects any error
export const selectHealthGoalTypesError = createSelector(
  selectHealthGoalTypeState,
  state => state && state.error,
);

/**
 * Projected Views
 * NOTE: at some point it may make sense to normalize (un nest) the health goal
 * actions from their type parents. This may improve performance and make lookups
 * by id easier
 */

export const selectAllHealthGoalTypesSorted = createSelector(
  selectAllHealthGoalTypes,
  healthGoalTypes =>
    healthGoalTypes.map(healthGoalType => ({
      ...healthGoalType,
      healthGoalActions: sortBy(
        (a: HealthGoalAction) => a.name,
        healthGoalType.healthGoalActions,
      ),
    })),
);

// selects array of all actions
export const selectAllHealthGoalActions = createSelector(
  selectAllHealthGoalTypes,
  healthGoalTypes => flatMap(i => i.healthGoalActions, healthGoalTypes),
);

// selects the dictionary of all actions
export const selectHealthGoalActionEntities = createSelector(
  selectAllHealthGoalActions,
  actions => actions.reduce((accum, i) => ({ ...accum, [i.id]: i }), {}),
);
