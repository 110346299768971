import { createSelector, select, Store } from '@ngrx/store';
var getState = function (state) { return state.activeRoutes; };
var ɵ0 = getState;
export var selectActiveOrders = createSelector(getState, function (state) { return state.orders; });
export var selectActiveWorkspace = createSelector(getState, function (state) { return state.workspace; });
/* istanbul ignore next */
var ActiveRoutesSelectors = /** @class */ (function () {
    function ActiveRoutesSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(ActiveRoutesSelectors.prototype, "activeOrders", {
        get: function () {
            return this.store.pipe(select(selectActiveOrders));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActiveRoutesSelectors.prototype, "activeWorkspace", {
        get: function () {
            return this.store.pipe(select(selectActiveWorkspace));
        },
        enumerable: true,
        configurable: true
    });
    return ActiveRoutesSelectors;
}());
export { ActiveRoutesSelectors };
export { ɵ0 };
