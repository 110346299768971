import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent {
  constructor() {}
  @Input() heading: string;
}
