var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { filter, map } from 'rxjs/operators';
import { PatientSelectors, ProfileSelectors } from '@app/core';
import { snakeCase } from '@app/utils';
var AppBridgeSelectors = /** @class */ (function () {
    function AppBridgeSelectors(profileSelectors, patientSelectors) {
        this.profileSelectors = profileSelectors;
        this.patientSelectors = patientSelectors;
    }
    Object.defineProperty(AppBridgeSelectors.prototype, "profile", {
        get: function () {
            return this.profileSelectors.profileInfo.pipe(filter(function (profile) { return !!profile; }), map(function (profile) { return (__assign({}, snakeCase(profile), { hasRole: profile.hasRole })); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppBridgeSelectors.prototype, "patient", {
        get: function () {
            return this.patientSelectors.patientInfo.pipe(filter(function (patient) { return !!patient; }), map(function (patient) { return (__assign({}, patient, { primaryAddress: patient.primaryAddress && __assign({}, patient.primaryAddress, { state: patient.primaryAddress.state &&
                        patient.primaryAddress.state.name &&
                        (patient.primaryAddress.state.shortName ||
                            patient.primaryAddress.state.name) }) })); }), map(function (patient) {
                var snakeCased = snakeCase(patient);
                return __assign({}, snakeCased, { primary_address: __assign({}, snakeCased.primary_address, { address1: patient.primaryAddress && patient.primaryAddress.street }), incompleteDemographics: function () { return patient.hasIncompleteDemographics; }, formattedAge: function () { return patient.formattedAge; }, isTeen: function () { return patient.isTeen; }, isPreteen: function () { return patient.isPreteen; }, acceptsDigitalCommunications: function () {
                        return patient.acceptsDigitalCommunications;
                    } });
            }));
        },
        enumerable: true,
        configurable: true
    });
    return AppBridgeSelectors;
}());
export { AppBridgeSelectors };
