import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { PatientNavModule } from '@app/modules/patient-nav/patient-nav.module';
import { PatientInfoComponent } from './components/patient-info/patient-info.component';

@NgModule({
  imports: [SharedModule, PatientNavModule],
  declarations: [PatientInfoComponent],
  exports: [PatientInfoComponent],
  providers: [],
})
export class PatientInfoModule {}
