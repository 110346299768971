import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { PopoverOptions, PopoverPlacement } from './popover-config';
import { PopoverRef } from './popover-ref';

@Component({
  selector: 'omg-popover',
  template: `
    <ng-template> <ng-content></ng-content> </ng-template>
  `,
})
export class PopoverComponent implements PopoverOptions {
  @Input() title?: string = null;
  @Input() placement: PopoverPlacement;
  @Input() width: number | string;
  @Input() height: number | string;
  @Input() enabled = true;

  @ViewChild(TemplateRef) content: TemplateRef<any>;

  popoverRef: PopoverRef;

  close() {
    if (this.popoverRef) {
      this.popoverRef.close();
    }
  }
}
