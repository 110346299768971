import { getOr } from '@app/utils';

import {
  Patient,
  PatientEnterprise,
  PatientPrimaryInsurance,
  patientPronouns,
} from '@app/core';
import { PatientInfo } from './patient-info.type';

export const formatPatientPreferredName = (patient: Patient) => {
  const preferredName = patient.nickname ? patient.nickname : patient.firstName;
  return `${preferredName} ${patient.lastName}`;
};

export const formatPatientLegalName = (patient: Patient) => {
  const legalFirstName =
    patient.nickname && patient.nickname !== patient.firstName
      ? patient.firstName
      : null;
  return legalFirstName ? `${legalFirstName} ${patient.lastName}` : null;
};

export const formatGenderAcronym = (patient: Patient) =>
  patient.gender ? patient.gender.substring(0, 1) : '';

export const formatPatientPronouns = (patient: Patient) => {
  const pronouns = patientPronouns[patient.pronouns];
  return pronouns ? pronouns.label : 'Unknown Pronouns';
};

export const formatPatientPhone = (patient: Patient) => {
  const flavor = getOr('', 'contactPhone.flavor', patient).toLowerCase();
  const phone = getOr('Number not found', 'contactPhone.number', patient);

  if (!patient.contactPhone || (!flavor && !phone)) {
    return 'No phone on file.';
  }

  return flavor ? `${phone} (${flavor})` : phone;
};

export const formatPatientEmail = (patient: Patient) => {
  const flavor = getOr('', 'contactEmail.flavor', patient);
  const address = getOr('Address not found', 'contactEmail.address', patient);

  if (!patient.contactEmail || (!flavor && !address)) {
    return 'No email on file.';
  }

  return flavor ? `${address} (${flavor})` : address;
};

export const formatPatientPcp = (patient: Patient) =>
  patient.pcp ? patient.pcp : 'No PCP selected';

export const formatPatientOffice = (patient: Patient) => {
  const street = getOr('Street not found', 'office.address.street', patient);
  const city = getOr('City not found', 'office.address.city', patient);

  if (!patient.office || !patient.office.address || (!street && !city)) {
    return 'No office selected';
  }

  return `${street}, ${city}`;
};

export const formatPatientOfficeName = (patient: Patient) => {
  const name = getOr('Office Name not found', 'office.name', patient);

  if (!patient.office || !patient.office.name) {
    return 'No office selected';
  }

  return name;
};

export const formatPatientMembershipStatus = (patient: Patient) => {
  const membership = patient.membership;
  if (!membership) {
    return null;
  }

  if (!membership.active) {
    if (membership.status === 'pending') {
      return `Incomplete Membership`;
    }
    return `${membership.status} Membership`;
  } else if (membership.limitedAccess) {
    return 'Limited Access';
  }
  return null;
};

export const formatEnterpriseCompanyPlanInfo = (patient: Patient) => {
  if (patient.isB2b) {
    const enterprise = patient.enterprise || ({} as PatientEnterprise);
    let companyPlanInfo = enterprise.b2bCompanyName;

    if (enterprise.dependentPlan) {
      companyPlanInfo += ' (Dependent)';
    } else if (enterprise.visitorPlan) {
      companyPlanInfo += ' (Visitor)';
    }

    return companyPlanInfo;
  }
  return null;
};

export const mapToPatientInfo = (
  patient: Patient = null,
  formattedAge: string = null,
  isMinor: boolean = false,
): PatientInfo => {
  patient = patient || ({} as Patient);
  const insurance = patient.primaryInsurance || ({} as PatientPrimaryInsurance);
  const enterprise = patient.enterprise || ({} as PatientEnterprise);

  return {
    id: patient.id || -1,
    age: formattedAge || '',
    dateOfBirth: patient.dateOfBirth || '',
    unborn: patient.unborn || false,
    dueDate: patient.dueDate || '',
    email: formatPatientEmail(patient),
    enterprise: {
      companyPlanInfo: formatEnterpriseCompanyPlanInfo(patient),
      benefitsFaqUrl: enterprise.benefitsFaqUrl,
    },
    gender: formatGenderAcronym(patient) || '',
    genderDescription:
      patient.genderDetails && patient.genderDetails.description,
    isB2b: patient.isB2b || false,
    isMinor,
    legalName: formatPatientLegalName(patient),
    legacyUiRef: `/${patient.id}/chart`,
    memberSince: patient.memberSince || '',
    membershipStatus: formatPatientMembershipStatus(patient),
    name: formatPatientPreferredName(patient),
    office: formatPatientOffice(patient),
    officeName: formatPatientOfficeName(patient),
    pastOrCurrentElectronicTosAccepted:
      patient.pastOrCurrentElectronicTosAccepted,
    pcp: formatPatientPcp(patient),
    phone: formatPatientPhone(patient),
    photoUrl: patient.photoUrl || '/img/photo_placeholder.c908064d.jpg',
    primaryInsurance: {
      name: insurance.name || '',
      type: insurance.type || '',
      network: insurance.networkShort || '',
    },
    pronouns: formatPatientPronouns(patient),
  };
};
