// NOTE: These are module and components that need to be downgraded
// into AngularJS. They are commented out now to reduce bundle size
// until they are actually needed.

import { ToastComponent, ToastModule } from '@app/shared/components/toast';
import { downgradeComponent } from '../shared/utils';

import { AllergiesComponent, AllergiesModule } from '../../modules/allergies';
import {
  HealthBackgroundComponent,
  HealthBackgroundModule,
} from '../../modules/health-background';
import {
  HealthMaintenanceComponent,
  HealthMaintenanceModule,
} from '../../modules/health-maintenance';
import {
  InsightsModule,
  ProblemInsightsComponent,
} from '../../modules/insights';
import {
  PatientInfoComponent,
  PatientInfoModule,
} from '../../modules/patient-info';
import {
  PcpCommentsComponent,
  PcpCommentsModule,
} from '../../modules/pcp-comments';
import { ProblemsComponent, ProblemsModule } from '../../modules/problems';
import {
  VaccinationsComponent,
  VaccinationsModule,
} from '../../modules/vaccinations';
import {
  VitalsDataComponent,
  VitalsDataModule,
} from '../../modules/vitals-data';

export const downgradedComponentModules = [
  AllergiesModule,
  HealthBackgroundModule,
  HealthMaintenanceModule,
  PatientInfoModule,
  PcpCommentsModule,
  ProblemsModule,
  ToastModule,
  InsightsModule,
  VitalsDataModule,
  VaccinationsModule,
];

export const downgradedComponents = [
  AllergiesComponent,
  HealthBackgroundComponent,
  HealthMaintenanceComponent,
  PatientInfoComponent,
  PcpCommentsComponent,
  ProblemsComponent,
  ToastComponent,
  ProblemInsightsComponent,
  VitalsDataComponent,
  VaccinationsComponent,
];

export const downgradeComponents = () => {
  downgradeComponent(AllergiesComponent, 'omgAllergies');
  downgradeComponent(HealthBackgroundComponent, 'omgHealthBackground');
  downgradeComponent(HealthMaintenanceComponent, 'omgHealthMaintenance');
  downgradeComponent(PatientInfoComponent, 'omgPatientInfo');
  downgradeComponent(PcpCommentsComponent, 'omgPcpComments');
  // downgradeComponent(ProblemsComponent, 'omgProblems');
  downgradeComponent(ToastComponent, 'omToast');
  downgradeComponent(ProblemInsightsComponent, 'omgProblemInsights');
  downgradeComponent(VitalsDataComponent, 'omgVitalsData');
  downgradeComponent(VaccinationsComponent, 'omgVaccinations');
};
