import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class CodeToAccessTokenResolver implements Resolve<Observable<string>> {
  constructor(private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { code } = route.queryParams;
    return code && !route.fragment ? this.auth.loginWithCode(code) : null;
  }
}
