import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { ProfileActions, ProfileSelectors } from '@app/core';

@Injectable()
export class ProfileGuard implements CanActivate, Resolve<void> {
  constructor(
    private profileActions: ProfileActions,
    private profileSelectors: ProfileSelectors,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.profileActions.getProfile();

    return this.profileSelectors.profile.pipe(
      filter(Boolean),
      catchError(() => of(false)),
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.profileActions.getProfile();
  }
}
