import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PatientActions, ProfileActions } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';

interface Loc {
  pathname: string;
  search: string;
  hash: string;
}

/**
 * Allows the AngularJS ui-router to trigger the Angular router and vice versa
 */
@Injectable()
export class RouterBridgeService implements OnDestroy {
  public static isAngularJSTransitioning = new BehaviorSubject(false);
  private subscription: Subscription;

  constructor(
    @Inject('$rootScope') private rootScope,
    @Inject('$state') private state,
    @Inject('$location') private location,
    private router: Router,
    private profileActions: ProfileActions,
    private patientActions: PatientActions,
    private ngZone: NgZone,
    private summariesActions: SummariesActions,
  ) {
    // navigate to the intial route
    this.navigateByUrl(window.location);
    // Listen to route changes
    this.listenToLocationChanges();
    this.listenToStateChanges();
    this.listenToRouterEvents();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private listenToLocationChanges() {
    // when a location change is started in AngularJS start one in Angular
    const unregisterLocationListener = this.rootScope.$on(
      '$locationChangeStart',
      (event: any, next: string, current: string) => {
        const nextUrlIncludesCurrent: boolean = next.includes(this.router.url);
        if (!nextUrlIncludesCurrent) {
          const url = document.createElement('a');
          url.href = next;
          this.navigateByUrl(url);
        }
      },
    );
  }

  private listenToStateChanges() {
    // https://ui-router.github.io/guide/ng1/migrate-to-1_0#state-change-events
    this.rootScope.$on('$stateChangeSuccess', (event, state, params) => {
      // This checks if the profile is already fetched
      this.profileActions.getProfile();

      // Make sure we load the patient
      if (params.patientId) {
        this.patientActions.getPatient(params.patientId);
        if (params.id && state.name && state.name.includes('summaries')) {
          this.summariesActions.setCurrentSummaryId(params.id);
        }
      }
    });
  }

  private listenToRouterEvents() {
    // when a navigation starts in Angular, reload the AngularJS state. This
    // causes AngularJS to pickup the change.
    this.subscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
      }
    });
  }

  private updateAngularRouterOnly(url: string) {
    window.location.href = `/#${url}`;
  }

  private updateAngularAndAngularJSRouter(url: string) {
    this.ngZone.run(() => this.router.navigateByUrl(url));
  }

  private navigateByUrl(loc: Loc) {
    const url = `${loc.pathname}${loc.search}${loc.hash}`.replace('/#/', '/');
    const initialAuthCodeUrl = loc.search.includes('code=');
    if (initialAuthCodeUrl) {
      // if we update the angularjs router, the angularjs router is going to re-update the
      // angular router with the ?code=xxx.  So, we update only the angular router in the case
      // the user is authenticating
      this.updateAngularRouterOnly(url);
    } else {
      this.updateAngularAndAngularJSRouter(url);
    }
  }
}
