import { UrlHandlingStrategy } from '@angular/router';

import { loginPath, logoutPath } from '@app/core';

import { useUpgradedChart } from '../shared/constants';

export const upgradeTestRoutePath = 'upgrade';
export const oauthResponsePath = 'access_token';
export const schedulePath = 'schedule';
export const unauthorizedPath = 'unauthorized';

// Angular router only handles specific routes
export class CustomUrlHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url) {
    return (
      url.toString().startsWith(`/${upgradeTestRoutePath}`) ||
      url.toString().startsWith(`/${oauthResponsePath}`) ||
      url.toString().startsWith(`/${loginPath}`) ||
      url.toString().startsWith(`/${logoutPath}`) ||
      url.toString().startsWith(`/${schedulePath}`) ||
      url.toString().startsWith(`/${unauthorizedPath}`) ||
      url.toString() === '/'
    );
  }

  extract(url) {
    return url;
  }

  merge(url, whole) {
    return url;
  }
}

export const onUpgrade = () =>
  useUpgradedChart || window.location.href.indexOf(upgradeTestRoutePath) > -1;
