import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { fuzzyDateValidator } from '@app/shared';

import { VaccinationsApiService } from '../../shared';
import { VaccineForm } from '../../shared/vaccinations.type';

@Component({
  selector: 'omg-vaccine-form',
  templateUrl: './vaccine-form.component.html',
  styleUrls: ['./vaccine-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaccineFormComponent implements OnInit {
  @Output() save = new EventEmitter<VaccineForm>();
  @Output() cancel = new EventEmitter();

  form: FormGroup;
  autocompleteItems: Observable<any>;

  get showGivenOnErrors() {
    const fieldControl = this.form.get('givenOn');
    return !!fieldControl.touched && fieldControl.status !== 'VALID';
  }

  get showGivenOnRequiredError() {
    return this.form.get('givenOn').hasError('required');
  }

  get showGivenOnFuzzyDateError() {
    return (
      !this.showGivenOnRequiredError &&
      this.form.get('givenOn').hasError('dateFuzzy')
    );
  }

  constructor(private vaccinationsApi: VaccinationsApiService) {}

  ngOnInit() {
    this.buildForm();
  }

  onSave() {
    this.setHiddenFormValues();
    this.save.emit(this.form.value);
    this.resetForm();
  }

  onCancel() {
    this.cancel.emit();
    this.resetForm();
  }

  getSearchResults(text: string) {
    this.autocompleteItems =
      text && text.length > 1
        ? this.vaccinationsApi.getSearchResults(text)
        : of([]);
  }

  setVaccine(vaccineData: string) {
    this.form.get('vaccine').patchValue(vaccineData);
  }

  private buildForm() {
    this.form = new FormGroup({
      vaccineId: new FormControl(null, Validators.required),
      step: new FormControl(),
      givenOn: new FormControl(null, {
        validators: [Validators.required, fuzzyDateValidator],
      }),
      comments: new FormControl(),
      // hidden fields used for api request
      vaccine: new FormControl(),
      givenAt: new FormControl(),
    });
    this.resetForm();
  }

  private setHiddenFormValues() {
    this.form.get('givenAt').patchValue('Other Facility');
  }

  private resetForm() {
    this.form.reset();
    this.form.markAsPristine();
  }
}
