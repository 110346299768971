var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorHandlerService, PatientSelectors, SectionLinkedQueueService, } from '@app/core';
import { summariesPath } from '@app/features/workspace/shared/workspace-utils';
import { onUpgrade } from '@app/upgrade/routing/custom-url-handling-strategy';
import { mapToSummaryHealthBackgroundUpdateRequest, mapToSummaryHealthGoalsUpdateRequest, mapToSummaryMedicationsUpdateRequest, mapToSummaryPatientAllergiesRequest, mapToSummaryVaccinationsUpdateRequest, } from '../shared/summaries-api-mappers';
import { SummariesApiService } from '../shared/summaries-api.service';
import { DeleteSummaryError, DeleteSummarySuccess, LinkAssessedProblemsError, LinkAssessedProblemsSuccess, LinkHealthBackgroundError, LinkHealthBackgroundSuccess, LinkHealthGoalsError, LinkHealthGoalsSuccess, LinkMedicationsError, LinkMedicationsSuccess, LinkPatientAllergiesError, LinkPatientAllergiesSuccess, LinkProblemsError, LinkProblemsSuccess, LinkVaccinationsError, LinkVaccinationsSuccess, LoadSummaryError, LoadSummarySuccess, RedactSummaryError, RedactSummarySuccess, SaveAddendumError, SaveAddendumSuccess, SaveSummaryError, SaveSummarySuccess, SignSummaryError, SignSummarySuccess, SummariesActions, SummaryActionTypes, UnlinkError, UnlinkSuccess, UpdateSummaryError, UpdateSummarySuccess, } from './summaries.actions';
import { SummariesSelectors } from './summaries.selectors';
/* The filter using onUpgrade should be removed once the upgrade path is removed. */
var SummariesEffects = /** @class */ (function () {
    function SummariesEffects(action$, summariesApi, summariesSelectors, sectionLinkedQueue, patientSelectors, router, summariesActions, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.summariesApi = summariesApi;
        this.summariesSelectors = summariesSelectors;
        this.sectionLinkedQueue = sectionLinkedQueue;
        this.patientSelectors = patientSelectors;
        this.router = router;
        this.summariesActions = summariesActions;
        this.errorHandler = errorHandler;
        this.loadSummary$ = this.action$.pipe(ofType(SummaryActionTypes.LOAD_SUMMARY), filter(function () { return onUpgrade(); }), switchMap(function (action) {
            return _this.summariesApi
                .get(action.payload.patientId, action.payload.summaryId)
                .pipe(map(function (summary) { return new LoadSummarySuccess(summary); }), catchError(function (error) {
                return of(new LoadSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.saveSummary$ = this.action$.pipe(ofType(SummaryActionTypes.SAVE_SUMMARY), filter(function () { return onUpgrade(); }), switchMap(function (action) {
            return _this.summariesApi
                .save(action.payload.patientId, action.payload.noteTypeId)
                .pipe(map(function (summary) { return new SaveSummarySuccess(summary); }), catchError(function (error) {
                return of(new SaveSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.updateSummary$ = this.action$.pipe(ofType(SummaryActionTypes.UPDATE_SUMMARY), filter(function () { return onUpgrade(); }), switchMap(function (action) {
            return _this.summariesApi
                .update(action.payload.changes.patientId, action.payload.changes.summaryId, action.payload.changes.data)
                .pipe(map(function (summary) {
                return new UpdateSummarySuccess({
                    id: summary.id,
                    changes: __assign({}, summary),
                });
            }), catchError(function (error) {
                return of(new UpdateSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.signSummary$ = this.action$.pipe(ofType(SummaryActionTypes.SIGN_SUMMARY), filter(function () { return onUpgrade(); }), switchMap(function (action) {
            return _this.summariesApi
                .sign(action.payload.changes.patientId, action.payload.changes.summaryId, action.payload.changes.summary)
                .pipe(map(function (summary) {
                return new SignSummarySuccess({
                    id: summary.id,
                    changes: __assign({}, summary),
                });
            }), catchError(function (error) {
                return of(new SignSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.signSummarySuccess$ = this.action$.pipe(ofType(SummaryActionTypes.SIGN_SUMMARY_SUCCESS), filter(function () { return onUpgrade(); }), tap(function () {
            _this.summariesActions.refreshTimeline();
            _this.summariesActions.closeWorkspaceItem();
        }));
        this.deleteSummary$ = this.action$.pipe(ofType(SummaryActionTypes.DELETE_SUMMARY), filter(function () { return onUpgrade(); }), switchMap(function (action) {
            return _this.summariesApi
                .delete(action.payload.patientId, action.payload.summaryId)
                .pipe(map(function (summary) { return new DeleteSummarySuccess(summary); }), catchError(function (error) {
                return of(new DeleteSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.deleteSummarySuccess$ = this.action$.pipe(ofType(SummaryActionTypes.DELETE_SUMMARY_SUCCESS), filter(function () { return onUpgrade(); }), tap(function () {
            _this.summariesActions.refreshTimeline();
            _this.summariesActions.closeWorkspaceItem();
        }));
        this.redactSummary$ = this.action$.pipe(ofType(SummaryActionTypes.REDACT_SUMMARY), switchMap(function (action) {
            return _this.summariesApi
                .redact(action.payload.changes.patientId, action.payload.changes.summaryId)
                .pipe(map(function (summary) {
                return new RedactSummarySuccess({
                    id: summary.id,
                    changes: __assign({}, summary),
                });
            }), catchError(function (error) {
                return of(new RedactSummaryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.redactSummarySuccess$ = this.action$.pipe(ofType(SummaryActionTypes.REDACT_SUMMARY_SUCCESS), tap(function () {
            _this.summariesActions.closeTimelineItem();
            _this.summariesActions.refreshTimeline();
            _this.summariesActions.closeWorkspaceItem();
        }));
        this.linkPatientAllergies$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_PATIENT_ALLERGIES), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('PatientAllergies', action.payload.patientAllergies);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkPatientAllergies(action.payload.patientId, currentSummaryId, mapToSummaryPatientAllergiesRequest(action.payload.patientAllergies))
                .pipe(map(function (summary) { return new LinkPatientAllergiesSuccess(summary); }), catchError(function (error) {
                return of(new LinkPatientAllergiesError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkProblem$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_PROBLEMS), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('Problems', action.payload.problems);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkProblems(action.payload.patientId, currentSummaryId, action.payload.problems)
                .pipe(map(function (summary) { return new LinkProblemsSuccess(summary); }), catchError(function (error) {
                return of(new LinkProblemsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkAssessedProblem$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_ASSESSED_PROBLEMS), withLatestFrom(this.summariesSelectors.currentSummaryId, this.summariesSelectors.assessedProblems), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1], assessedProblems = _a[2];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('AssessedProblems', {
                id: action.payload.problemId,
                problemHistoryId: action.payload.historyId,
            }, action.payload.eventKey);
        }), filter(function (_a) {
            var action = _a[0];
            return onUpgrade() && action.payload.eventKey !== 'problemDeleted';
        }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1], assessedProblems = _a[2];
            return _this.summariesApi
                .linkAssessedProblems(action.payload.patientId, currentSummaryId, assessedProblems, action.payload.historyId, action.payload.problemId, action.payload.hasAssessedProblem)
                .pipe(map(function (summary) { return new LinkAssessedProblemsSuccess(summary); }), catchError(function (error) {
                return of(new LinkAssessedProblemsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkHealthGoals$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_HEALTH_GOALS), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('HealthGoals', action.payload.healthGoals);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkHealthGoals(action.payload.patientId, currentSummaryId, 
            /* Might be able to move the request mapping function call here instead of
             * during action creation. This will separate Summaries from HealthMaintenance more
             * cleanly. */
            mapToSummaryHealthGoalsUpdateRequest(action.payload.healthGoals))
                .pipe(map(function (summary) { return new LinkHealthGoalsSuccess(summary); }), catchError(function (error) {
                return of(new LinkHealthGoalsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkHealthBackground$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_HEALTH_BACKGROUND), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('HealthBackground', action.payload.healthBackground);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkHealthBackground(action.payload.patientId, currentSummaryId, mapToSummaryHealthBackgroundUpdateRequest(action.payload.healthBackground))
                .pipe(map(function (summary) { return new LinkHealthBackgroundSuccess(summary); }), catchError(function (error) {
                return of(new LinkHealthBackgroundError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkMedications$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_MEDICATIONS), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            // NOTE: This is for backwards compatibility for the AngularJS chart
            // It can be removed once the upgrade or AngularJS chart is removed.
            return _this.sectionLinkedQueue.addSectionLink('Medications', action.payload.medications.legacyData);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkMedications(action.payload.patientId, currentSummaryId, mapToSummaryMedicationsUpdateRequest(action.payload.medications.summaryData.noMedications, action.payload.medications.summaryData.patientMedicationRegimenIds))
                .pipe(map(function (summary) { return new LinkMedicationsSuccess(summary); }), catchError(function (error) {
                return of(new LinkMedicationsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.linkVaccinations$ = this.action$.pipe(ofType(SummaryActionTypes.LINK_VACCINATIONS), withLatestFrom(this.summariesSelectors.currentSummaryId), tap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.sectionLinkedQueue.addSectionLink('Vaccinations', 
            /* Since Vaccinations has not been migrated yet, the payload shape
             * is not known - will have to be revised once Vaccinations work has started. */
            action.payload.vaccinations.legacyData);
        }), filter(function () { return onUpgrade(); }), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .linkVaccinations(action.payload.patientId, currentSummaryId, 
            /* Might be able to move the request mapping function call here instead of
             * during action creation. This will separate Summaries from Vaccinations more
             * cleanly. */
            mapToSummaryVaccinationsUpdateRequest(action.payload.vaccinations.vaccineHistoryIds))
                .pipe(map(function (summary) { return new LinkVaccinationsSuccess(summary); }), catchError(function (error) {
                return of(new LinkVaccinationsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.unlink$ = this.action$.pipe(ofType(SummaryActionTypes.UNLINK), withLatestFrom(this.summariesSelectors.currentSummaryId), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi
                .update(action.payload.patientId, currentSummaryId, __assign({}, action.payload.extraFields))
                .pipe(map(function (summary) { return new UnlinkSuccess(summary); }), catchError(function (error) {
                return of(new UnlinkError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.closeWorkspaceItem$ = this.action$.pipe(ofType(SummaryActionTypes.CLOSE_WORKSPACE_ITEM), withLatestFrom(this.patientSelectors.patientId), tap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.router.navigateByUrl(summariesPath(patientId, null, 'new'));
        }));
        this.saveAddendum = this.action$.pipe(ofType(SummaryActionTypes.SAVE_ADDENDUM), withLatestFrom(this.summariesSelectors.currentSummaryId), switchMap(function (_a) {
            var action = _a[0], currentSummaryId = _a[1];
            return _this.summariesApi.saveAddendum(currentSummaryId, action.payload).pipe(map(function (addendum) { return new SaveAddendumSuccess(addendum); }), catchError(function (error) {
                return of(new SaveAddendumError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "loadSummary$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "saveSummary$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "updateSummary$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "signSummary$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "signSummarySuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "deleteSummary$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "deleteSummarySuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "redactSummary$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "redactSummarySuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkPatientAllergies$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkAssessedProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkHealthGoals$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkHealthBackground$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkMedications$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "linkVaccinations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "unlink$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], SummariesEffects.prototype, "closeWorkspaceItem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SummariesEffects.prototype, "saveAddendum", void 0);
    return SummariesEffects;
}());
export { SummariesEffects };
