var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { escapeRegExp } from 'lodash/fp';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, patientRoute, SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { mapMultipleVaccineHistoriesResponseToVaccineHistories, mapVaccineHistoryResponseToVaccineHistory, mapVaccineHistoryToSaveRequest, } from './vaccinations-api-mappers';
var createVaccineHistoryPath = function (patientId) {
    return patientRoute(patientId, '/history_vaccines');
};
var ɵ0 = createVaccineHistoryPath;
var VaccinationsApiService = /** @class */ (function () {
    function VaccinationsApiService(api, config, searchService) {
        this.api = api;
        this.config = config;
        this.searchService = searchService;
    }
    VaccinationsApiService.prototype.get = function (payload) {
        /*
          Why do we use 'get' when 'getAll' gets all of the VaccineHistory's?
    
          The response of 'getAll' does not return the full data of each
          respective VaccineHistory (e.g. comments, givenAt... required for UI),
          but 'get' on the same VaccineHistory id will.
        */
        return this.api
            .get(createVaccineHistoryPath(payload.patientId) + "/" + payload.vaccineHistoryId)
            .pipe(map(function (response) { return mapVaccineHistoryResponseToVaccineHistory(response); }), catchError(function (error) { return throwError(error); }));
    };
    VaccinationsApiService.prototype.getAll = function (patientId) {
        return this.api
            .get(createVaccineHistoryPath(patientId))
            .pipe(map(function (response) {
            return mapMultipleVaccineHistoriesResponseToVaccineHistories(response);
        }), catchError(function (error) { return throwError(error); }));
    };
    VaccinationsApiService.prototype.getSearchResults = function (text, options) {
        var _this = this;
        if (options === void 0) { options = { isOrderable: false }; }
        return this.searchService
            .search(this.buildQuery(text, { isOrderable: options.isOrderable }), {
            camelize: true,
        })
            .pipe(map(function (response) {
            var hits = response.hits || {};
            var items = hits.hits || [];
            return items.map(function (hit) {
                var searchResult = __assign({}, hit.source, {
                    longName: _this.addTagToResult(hit.source, text),
                });
                return searchResult;
            });
        }));
    };
    VaccinationsApiService.prototype.save = function (payload) {
        return this.api
            .save("" + createVaccineHistoryPath(payload.patientId), mapVaccineHistoryToSaveRequest(payload.vaccineHistory))
            .pipe(map(function (response) { return mapVaccineHistoryResponseToVaccineHistory(response); }), catchError(function (error) { return throwError(error); }));
    };
    VaccinationsApiService.prototype.delete = function (payload) {
        return this.api
            .delete(createVaccineHistoryPath(payload.patientId) + "/" + payload.vaccineHistoryId)
            .pipe(catchError(function (error) { return throwError(error); }));
    };
    VaccinationsApiService.prototype.buildQuery = function (text, _a) {
        var _b = _a.isOrderable, isOrderable = _b === void 0 ? false : _b;
        var options = {
            size: '8',
            fields: ['long_name', 'pref_name^2', 'tags^3', 'abbreviation^4'],
            sort: ['_score', 'pref_name.keyword'],
            index: [this.config.searchIndex('vaccines')],
            operator: 'and',
            filter: null,
        };
        if (isOrderable) {
            options.filter = {
                bool: {
                    should: [{ term: { is_orderable: true } }],
                },
            };
        }
        return new QueryBuilder('query_string_with_fields_v6').build(text, options);
    };
    VaccinationsApiService.prototype.addTagToResult = function (vaccine, text) {
        var escapedQuery = escapeRegExp(text);
        var re = new RegExp("\\b(" + escapedQuery + ")", 'i');
        if (re.test(vaccine.prefName)) {
            return vaccine.prefName;
        }
        if (re.test(vaccine.abbreviation)) {
            return this.displayWithTag(vaccine.prefName, vaccine.abbreviation);
        }
        return this.displayWithTag(vaccine.prefName, vaccine.tags.find(function (tag) { return re.test(tag); }));
    };
    VaccinationsApiService.prototype.displayWithTag = function (baseName, tag) {
        return tag ? baseName + " (" + tag + ")" : baseName;
    };
    return VaccinationsApiService;
}());
export { VaccinationsApiService };
export { ɵ0 };
