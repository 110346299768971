var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var formattedAge = function (p) {
    return p && (p.age >= 3 ? p.age + " yo" : p.infantAge);
};
export var isMinor = function (p) { return p && p.age < 18; };
export var isTeen = function (p) { return p && (p.age > 16 && p.age < 18); };
export var isPreteen = function (p) { return p && p.age < 16; };
export var hasIncompleteDemographics = function (p) {
    return p &&
        !(p.dateOfBirth &&
            p.firstName &&
            p.lastName &&
            p.gender &&
            (p.primaryAddress || p.addresses.length > 0));
};
export var acceptsDigitalCommunications = function (p) {
    return p && !!p.patientPreferences && p.patientPreferences.acceptsClinicalEmails;
};
export var limitedAccessMembership = function (p) {
    return p && p.membership && p.membership.limitedAccess;
};
export var expiredMembership = function (p) {
    return p && p.membership && !p.membership.active;
};
var messages = {
    noDigitalCommunications: 'Patient opted out of digital communications.',
    limitedAccessMember: 'Patient has limited access membership and cannot reply.',
    expiredMember: 'Patient cannot reply until their membership is renewed.',
    teenMinor: 'Patient is a teenage minor. Legally protected info might be seen by a guardian.',
    preTeenMinor: 'Patient is a pre-teen minor. Messages might be seen by a guardian.',
};
export var buildPatientWarnings = function (p) { return (__assign({}, (isTeen(p) ? { teenMinor: messages.teenMinor } : {}), (isPreteen(p) ? { preTeenMinor: messages.preTeenMinor } : {}), (expiredMembership(p) ? { expiredMember: messages.expiredMember } : {}), (limitedAccessMembership(p)
    ? { limitedAccessMember: messages.limitedAccessMember }
    : {}), (!acceptsDigitalCommunications(p)
    ? { noDigitalCommunications: messages.noDigitalCommunications }
    : {}))); };
