import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '@app/core';

import { ProblemsApiService } from '../shared/problems-api.service';
import {
  DeleteProblem,
  DeleteProblemError,
  DeleteProblemSuccess,
  GetProblemHistory,
  GetProblemHistoryError,
  GetProblemHistorySuccess,
  GetProblems,
  GetProblemsError,
  GetProblemsSuccess,
  ProblemActionTypes,
  ReactivateProblem,
  ReactivateProblemError,
  ReactivateProblemSuccess,
  ResolveProblem,
  ResolveProblemError,
  ResolveProblemSuccess,
  SaveProblem,
  SaveProblemError,
  SaveProblemSuccess,
  UpdateProblem,
  UpdateProblemError,
  UpdateProblemSuccess,
} from './problems.actions';

@Injectable()
export class ProblemsEffects {
  constructor(
    private action$: Actions,
    private problemsApi: ProblemsApiService,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  getProblems$ = this.action$.pipe(
    ofType<GetProblems>(ProblemActionTypes.GET_PROBLEMS),
    switchMap(action =>
      this.problemsApi
        .query(action.payload.patientId, action.payload.params)
        .pipe(
          map(problems => new GetProblemsSuccess(problems)),
          catchError(error =>
            of(new GetProblemsError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect()
  saveProblem$ = this.action$.pipe(
    ofType<SaveProblem>(ProblemActionTypes.SAVE_PROBLEM),
    switchMap(action =>
      this.problemsApi
        .save(action.payload.patientId, action.payload.problem)
        .pipe(
          map(problem => new SaveProblemSuccess(problem)),
          catchError(error =>
            of(new SaveProblemError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect()
  updateProblem$ = this.action$.pipe(
    ofType<UpdateProblem>(ProblemActionTypes.UPDATE_PROBLEM),
    switchMap(action =>
      this.problemsApi
        .update(action.payload.patientId, action.payload.problem)
        .pipe(
          map(problem => new UpdateProblemSuccess(problem)),
          catchError(error =>
            of(
              new UpdateProblemError(this.errorHandler.handleErrorSafe(error), {
                id: action.payload.problem.id,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect()
  resolveProblem$ = this.action$.pipe(
    ofType<ResolveProblem>(ProblemActionTypes.RESOLVE_PROBLEM),
    switchMap(action =>
      this.problemsApi
        .resolve(action.payload.patientId, action.payload.problemId)
        .pipe(
          map(problem => new ResolveProblemSuccess(problem)),
          catchError(error =>
            of(
              new ResolveProblemError(
                this.errorHandler.handleErrorSafe(error),
                { id: action.payload.problemId },
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  reactivateProblem$ = this.action$.pipe(
    ofType<ReactivateProblem>(ProblemActionTypes.REACTIVATE_PROBLEM),
    switchMap(action =>
      this.problemsApi
        .reactivate(action.payload.patientId, action.payload.problemId)
        .pipe(
          map(problem => new ReactivateProblemSuccess(problem)),
          catchError(error =>
            of(
              new ReactivateProblemError(
                this.errorHandler.handleErrorSafe(error),
                {
                  id: action.payload.problemId,
                },
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  deleteProblem$ = this.action$.pipe(
    ofType<DeleteProblem>(ProblemActionTypes.DELETE_PROBLEM),
    switchMap(action =>
      this.problemsApi
        .delete(action.payload.patientId, action.payload.problemId)
        .pipe(
          map(problem => new DeleteProblemSuccess(problem)),
          catchError(error =>
            of(
              new DeleteProblemError(this.errorHandler.handleErrorSafe(error), {
                id: action.payload.problemId,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect()
  getProblemHistory$ = this.action$.pipe(
    ofType<GetProblemHistory>(ProblemActionTypes.GET_PROBLEM_HISTORY),
    mergeMap(action =>
      this.problemsApi
        .queryProblemHistories(
          action.payload.patientId,
          action.payload.problemId,
        )
        .pipe(
          map(history => new GetProblemHistorySuccess(history)),
          catchError(error =>
            of(
              new GetProblemHistoryError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );
}
