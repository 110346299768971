import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { VaccinationsState } from '@app/modules/vaccinations/store/vaccinations.reducer';

import { selectVaccinationsSectionLinkPayload } from '@app/modules/vaccinations/store/vaccinations.selectors';

@Injectable()
export class VaccinationsService {
  constructor(
    private store: Store<VaccinationsState>,
    private patientSelectors: PatientSelectors,
    private summaryActions: SummariesActions,
  ) {}

  addToNote() {
    this.store
      .pipe(
        select(selectVaccinationsSectionLinkPayload),
        withLatestFrom(this.patientSelectors.patientId),
        take(1),
      )
      .subscribe(([vaccinations, patientId]) => {
        this.summaryActions.linkVaccinations({
          patientId,
          vaccinations,
        });
      });
  }
}
