import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlagSelectors } from '@app/core';
import { FeatureFlag } from '@app/core/feature-flag/shared/feature-flag.type';
import { filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { FeatureFlagComponentLoaderDirective } from './feature-flag-component-loader.directive';
import { ComponentLoaderOptions } from './feature-flag-component-loader.type';

// tslint:disable component-class-suffix
@Component({
  template: `
    <div omgLoadedComponentHost></div>
  `,
})
export class FeatureFlagComponentLoader implements OnInit {
  @ViewChild(FeatureFlagComponentLoaderDirective)
  loadedComponentHost: FeatureFlagComponentLoaderDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  private featureFlagName: FeatureFlag;
  private newComponent: any;
  private legacyComponent: any;

  ngOnInit() {
    this.setProperties();
    this.featureFlagSelectors.featureFlags
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.featureFlagSelectors.featureEnabled(this.featureFlagName),
        ),
      )
      .subscribe(showNewComponent => this.loadComponent(showNewComponent));
  }

  private loadComponent(showNewComponent) {
    const component = showNewComponent
      ? this.newComponent
      : this.legacyComponent;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component,
    );
    const viewContainerRef = this.loadedComponentHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }

  private setProperties() {
    const { featureFlagName, newComponent, legacyComponent } = <
      ComponentLoaderOptions
    >(<any>this.route.data).value;
    this.featureFlagName = featureFlagName;
    this.newComponent = newComponent;
    this.legacyComponent = legacyComponent;
  }
}
