import { Subject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
var PopoverRef = /** @class */ (function () {
    function PopoverRef(overlay, config, zone) {
        this.overlay = overlay;
        this.config = config;
        this.zone = zone;
        this._isOpen = false;
        this.afterClosedSubject = new Subject();
        this.unsubscribe = new Subject();
        this.afterClosed = this.afterClosedSubject.asObservable();
    }
    Object.defineProperty(PopoverRef.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    PopoverRef.prototype.dispose = function () {
        this._isOpen = false;
        window.removeEventListener('scroll', this.scrollHandler, true);
        document.removeEventListener('click', this.clickAwayHandler, true);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.overlay.dispose();
    };
    PopoverRef.prototype.close = function (data) {
        this.closeOverlay('close', data);
    };
    PopoverRef.prototype.updatePosition = function () {
        this.overlay.updatePosition();
    };
    PopoverRef.prototype.attach = function (component, injector) {
        this.overlay.attach(new ComponentPortal(component, null, injector));
        this.onAttached();
    };
    PopoverRef.prototype.onAttached = function () {
        this._isOpen = true;
        this.setupListeners();
    };
    PopoverRef.prototype.setupListeners = function () {
        var _this = this;
        // Close the overlay when backdrop is clicked or handle when no backdrop
        if (this.overlay.getConfig().hasBackdrop) {
            this.overlay.backdropClick().subscribe(function () {
                _this.closeOverlay('backdropClick', null);
            });
        }
        else {
            this.clickAwayHandler = this.handleClickAway.bind(this);
            this.zone.runOutsideAngular(function () {
                return document.addEventListener('click', _this.clickAwayHandler, true);
            });
        }
        // Listen for all clicks on the document
        this.scrollHandler = this.handleScroll.bind(this);
        this.zone.runOutsideAngular(function () {
            return window.addEventListener('scroll', _this.scrollHandler, true);
        });
    };
    PopoverRef.prototype.handleScroll = function () {
        this.updatePosition();
    };
    PopoverRef.prototype.handleClickAway = function (event) {
        var element = event.target;
        if (element && !element.closest("#" + this.overlay.overlayElement.id)) {
            this.closeOverlay('backdropClick', null);
        }
    };
    PopoverRef.prototype.closeOverlay = function (type, data) {
        this.afterClosedSubject.next({ type: type, data: data });
        this.afterClosedSubject.complete();
        this.dispose();
    };
    return PopoverRef;
}());
export { PopoverRef };
