import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '../http/http-status';

export const catchHttpError = <T, R>(
  status: HttpStatusCode | number | null,
  selector: (err: HttpErrorResponse) => Observable<R>,
): OperatorFunction<T, T | R> =>
  catchError<T, R>(err => {
    if (
      err instanceof HttpErrorResponse &&
      (!status || err.status === status)
    ) {
      return selector(err);
    }

    return throwError(err);
  });

export const catchHttpError422 = <T, R>(
  selector: (err: HttpErrorResponse) => Observable<R>,
) => catchHttpError<T, R>(422, err => selector(err));
