import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MarkdownModule } from 'ngx-markdown';

import { SharedModule } from '@app/shared';

import { components, entryComponents, exportComponents } from './components';
import { services } from './shared';
import { effects, reducers, statePath } from './store';

@NgModule({
  imports: [
    SharedModule,
    MarkdownModule.forChild(),
    StoreModule.forFeature(statePath, reducers),
    EffectsModule.forFeature([...effects]),
  ],
  declarations: [...components],
  exports: [...exportComponents],
  providers: [...services],
  entryComponents,
})
export class HealthMaintenanceModule {}
