import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AppBridgeSelectors } from '../services/app-bridge.selectors';

type LegacyProfile = any;

@Injectable()
export class LegacyProfileResolver
  implements Resolve<Observable<LegacyProfile>> {
  
  constructor(private selectors: AppBridgeSelectors) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.selectors.profile.pipe(
      filter(profile => !!(profile && profile.id)),
      map(profile => of(profile)),
      take(1),
    );
  }
}
