<form class="om-form padding-normal"
      [formGroup]="form"
      om-layout="vertical"
      om-layout-fill>

  <div om-layout
       om-layout-fill
       om-layout-gutter>
    <div om-flex="50">
      <label om-layout="vertical">Event
        <omg-dropdown formControlName="eventId"
                      om-flex="95"
                      *ngIf="isNewScreening"
                      [options]="eventOptions">
        </omg-dropdown>
        <div *ngIf="!isNewScreening">
          {{ selectedEventName }}
        </div>
      </label>
    </div>
    <div class="alert"
         *ngIf="form?.get('eventId').hasError('apiError')">
      <div ng-message="required">Cannot be blank</div>
    </div>
  </div>

  <div om-layout
       om-layout-fill
       om-layout-gutter>
    <div om-flex="50">
      <label om-layout="vertical">Date
        <input omgInputText
               type="text"
               placeholder="ex: 05/2012 or 2012"
               formControlName="dateFuzzy" />
      </label>
      <div class="alert"
           *ngIf="form?.get('dateFuzzy').hasError('apiError')">
        Date error: {{ form?.get('dateFuzzy').getError('apiError') }}
      </div>
      <div class="alert"
           *ngIf="form?.get('dateFuzzy').hasError('dateFuzzy')">
        {{ form?.get('dateFuzzy').getError('dateFuzzy') }}
      </div>
    </div>
    <div om-flex>
      <label om-layout="vertical">Result</label>
      <omg-screening-result *ngIf="allowedResults"
                            formControlName="result"
                            [allowedResults]="allowedResults"
                            [normalize]="normalizeResult">
      </omg-screening-result>
    </div>
  </div>

  <div class="alert"
       *ngIf="form?.hasError('healthGoalType')">
    {{ form?.getError('healthGoalType') }}
  </div>

  <div om-layout
       om-layout-gutter
       om-layout-fill>
    <button omgButton
            variant="primary"
            [disabled]="form?.invalid"
            (click)="onSave()">
      {{ isNewScreening ? 'Add' : 'Update' }} Screening
    </button>
    <button omgButton
            variant="flat"
            (click)="onCancel()">
      Cancel
    </button>
    <button omgButton
            variant="critical-link"
            (click)="onDelete()"
            *ngIf="!isNewScreening">
      Delete Screening
    </button>
  </div>

</form>
