import { NgModule } from '@angular/core';

import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SharedModule } from '@app/shared';

import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import {
  AllergiesComponent,
  AllergyFormComponent,
  AllergyItemComponent,
} from './components';
import { AllergiesService } from './shared';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AllergiesComponent,
    AllergyFormComponent,
    AllergyItemComponent,
  ],
  exports: [AllergiesComponent, AllergyFormComponent, AllergyItemComponent],
  providers: [AllergiesService, SummariesActions, SummariesSelectors],
  entryComponents: [AllergiesComponent],
})
export class AllergiesModule {}
