import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllergiesService } from '../../shared/allergies.service';
var AllergyFormComponent = /** @class */ (function () {
    function AllergyFormComponent(allergiesService, formBuilder) {
        this.allergiesService = allergiesService;
        this.formBuilder = formBuilder;
        this.isAddingAllergy = false;
        this.post = new EventEmitter();
        this.update = new EventEmitter();
        this.delete = new EventEmitter();
        this.close = new EventEmitter();
        this.reactionModelChange = new EventEmitter();
        this.autocompleteItems = [];
        this.attemptedSubmit = false;
        this.unsubscribe = new Subject();
    }
    Object.defineProperty(AllergyFormComponent.prototype, "focusKey", {
        get: function () {
            var id = this.allergyItem && this.allergyItem.id;
            return "patientAllergiesFocus-" + (id || 'create');
        },
        enumerable: true,
        configurable: true
    });
    AllergyFormComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.setFormValues(this.allergyItem);
    };
    AllergyFormComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    AllergyFormComponent.prototype.getSearchResults = function (text) {
        var _this = this;
        if (text.length > 1) {
            this.optionHighlight = text;
            this.allergiesService
                .getSearchResults(text)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(function (autocompleteItems) {
                _this.autocompleteItems = autocompleteItems.map(function (item) {
                    return _this.addAbbreviationToName(item);
                });
            });
        }
    };
    AllergyFormComponent.prototype.closeForm = function () {
        this.markSubmitted(false);
        this.close.emit();
    };
    AllergyFormComponent.prototype.closeAndResetForm = function () {
        this.closeForm();
        this.form.reset();
        this.setFormValues(this.allergyItem);
        this.onReactionChange();
    };
    AllergyFormComponent.prototype.onPost = function () {
        if (this.form.valid) {
            this.post.emit(this.form.value);
            this.markSubmitted(false);
            this.closeAndResetForm();
        }
    };
    AllergyFormComponent.prototype.onUpdate = function () {
        if (this.form.valid) {
            this.update.emit(this.form.value);
            this.markSubmitted(false);
        }
    };
    AllergyFormComponent.prototype.onDelete = function () {
        this.delete.emit(this.form.value.id);
        this.markSubmitted(false);
    };
    AllergyFormComponent.prototype.onReactionChange = function () {
        this.reactionModelChange.emit(this.form.get('reaction').value);
    };
    AllergyFormComponent.prototype.markSubmitted = function (attemptedSubmit) {
        this.attemptedSubmit = attemptedSubmit;
    };
    AllergyFormComponent.prototype.buildForm = function () {
        this.form = this.formBuilder.group({
            allergyId: new FormControl('', Validators.required),
            reaction: new FormControl(''),
            comment: new FormControl(''),
            id: new FormControl(-1),
        });
    };
    AllergyFormComponent.prototype.setFormValues = function (allergyItem) {
        var defaults = allergyItem || {};
        var allergy = defaults.allergy || {};
        var allergyName = allergy.name || '';
        var allergyId = allergy.id || null;
        var reaction = defaults.reaction || '';
        var comment = defaults.comment || '';
        var id = defaults.id || -1;
        this.form.get('allergyId').setValue(allergyId);
        this.form.get('reaction').setValue(reaction);
        this.form.get('comment').setValue(comment);
        this.form.get('id').setValue(id);
        this.updateSelectItems(allergyName, allergyId);
    };
    AllergyFormComponent.prototype.updateSelectItems = function (name, id) {
        this.autocompleteItems = [{ name: name, id: id }];
    };
    AllergyFormComponent.prototype.addAbbreviationToName = function (item) {
        if (item.clinical_abbreviation) {
            item.name = item.name + (" (" + item.clinical_abbreviation + ")");
        }
        return item;
    };
    return AllergyFormComponent;
}());
export { AllergyFormComponent };
