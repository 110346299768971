import { HttpErrorResponse } from '@angular/common/http';

import { MeasurementTypeResponseItem } from './measurement-types-response.type';
import { MeasurementType } from './measurement-types.type';

/* istanbul ignore next */
export const mapMeasurementTypeError = (response: HttpErrorResponse) =>
  response;

/* istanbul ignore next */
export const mapMeasurementTypeResponseItemToEntity = (
  response: MeasurementTypeResponseItem,
): MeasurementType => ({
  abbreviation: response.abbreviation || undefined,
  id: response.id || undefined,
  name: response.name || undefined,
});

/* istanbul ignore next */
export const mapMeasurementTypeResponseToEntity = (
  response: MeasurementTypeResponseItem[],
) => response.map(mapMeasurementTypeResponseItemToEntity);
