var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createFeatureSelector, createSelector, select, Store, } from '@ngrx/store';
import { profileStatePath } from './profile.reducer';
export var selectProfileState = createFeatureSelector(profileStatePath);
export var selectProfile = createSelector(selectProfileState, function (state) { return state.entity; });
export var selectProfileId = createSelector(selectProfile, function (profile) { return profile && profile.id; });
var profileHasRole = function (profile) { return function (role) {
    return profile && profile.roles && profile.roles.includes(role);
}; };
var ɵ0 = profileHasRole;
export var selectHasRole = createSelector(selectProfile, function (profile, role) { return profileHasRole(profile)(role); });
export var selectPrimaryIdentity = createSelector(selectProfile, function (profile) {
    return profile &&
        profile.identities &&
        (profile.identities.find(function (identity) { return identity.primary; }) ||
            profile.identities[0]);
});
export var selectPrimaryIdentityName = createSelector(selectPrimaryIdentity, function (identity) { return (identity && identity.name) || ''; });
export var selectProfileInfo = createSelector(selectProfile, selectPrimaryIdentity, function (profile, primaryIdentity) { return (__assign({}, profile, { primaryIdentity: primaryIdentity, hasRole: profileHasRole(profile) })); });
/* istanbul ignore next */
var ProfileSelectors = /** @class */ (function () {
    function ProfileSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(ProfileSelectors.prototype, "state", {
        get: function () {
            return this.store.pipe(select(selectProfileState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileSelectors.prototype, "profile", {
        get: function () {
            return this.store.pipe(select(selectProfile));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileSelectors.prototype, "profileId", {
        get: function () {
            return this.store.pipe(select(selectProfileId));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileSelectors.prototype, "primaryIdentity", {
        get: function () {
            return this.store.pipe(select(selectPrimaryIdentity));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileSelectors.prototype, "primaryIdentityName", {
        get: function () {
            return this.store.pipe(select(selectPrimaryIdentityName));
        },
        enumerable: true,
        configurable: true
    });
    ProfileSelectors.prototype.hasRole = function (role) {
        return this.store.pipe(select(selectHasRole, role));
    };
    Object.defineProperty(ProfileSelectors.prototype, "profileInfo", {
        get: function () {
            return this.store.pipe(select(selectProfileInfo));
        },
        enumerable: true,
        configurable: true
    });
    return ProfileSelectors;
}());
export { ProfileSelectors };
export { ɵ0 };
