import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Patient } from '@app/core';
import { FormModel } from '@app/shared/forms';
import { proxyWith, ProxyWithFunction } from '@app/utils';

import { HealthMaintenanceNoteForm } from '../../shared/health-maintenance.type';

@Component({
  selector: 'omg-health-maintenance-note',
  templateUrl: './health-maintenance-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthMaintenanceNoteComponent implements OnInit {
  @Output() save = new EventEmitter<{
    value: HealthMaintenanceNoteForm;
    complete: ProxyWithFunction<Patient, any>;
  }>();

  formModel: FormModel;

  @Input() get note(): HealthMaintenanceNoteForm {
    return this.formModel.value;
  }

  set note(value: HealthMaintenanceNoteForm) {
    if (value) {
      this.formModel.setValue(value);
    }
  }

  constructor() {
    this.buildForm();
  }

  ngOnInit() {}

  private buildForm() {
    this.formModel = new FormModel(
      new FormGroup({
        content: new FormControl(),
      }),
      { saveFunction: value => this.saveNote(value) },
    );
  }

  private saveNote(value: HealthMaintenanceNoteForm) {
    const { observer, complete } = proxyWith();
    this.save.emit({ value, complete });
    return observer;
  }
}
