import { stateReducerFactory } from '@app/core/store/shared/state';
import { OrderApiService } from '../shared/order-api.service';
import { OrderActions } from './order.actions';
import { orderEntityAdapter, ordersActionNameTypeMap, ordersStateConfig, } from './order.reducer';
import { OrdersSelector } from './order.selectors';
var ɵ0 = function () {
    return stateReducerFactory(ordersActionNameTypeMap, orderEntityAdapter);
}, ɵ1 = function (store) { return new OrdersSelector(store, ordersStateConfig); }, ɵ2 = function (store) { return new OrderActions(store, ordersActionNameTypeMap); }, ɵ3 = function (apiService, patientSelectors) {
    return new OrderApiService(apiService, patientSelectors);
};
var OrdersStoreModule = /** @class */ (function () {
    function OrdersStoreModule() {
    }
    return OrdersStoreModule;
}());
export { OrdersStoreModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
