/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/button/button.component.ngfactory";
import * as i3 from "../../../../shared/components/button/button.component";
import * as i4 from "./login.component";
import * as i5 from "../../../../core/auth/shared/auth.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "om-page"], ["om-layout", ""], ["om-layout-align", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["omgButton", ""], ["variant", "link"]], [[8, "disabled", 0], [2, "om-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(3, 114688, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { variant: [0, "variant"] }, null), (_l()(), i1.ɵted(-1, 0, ["Login with 1Life"]))], function (_ck, _v) { var currVal_2 = "link"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).disabled; var currVal_1 = i1.ɵnov(_v, 3).buttonClass; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 49152, null, 0, i4.LoginComponent, [i5.AuthService], null, null)], null, null); }
var LoginComponentNgFactory = i1.ɵccf("omg-login", i4.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
