var _a;
import { InjectionToken } from '@angular/core';
import { createEntityAdapter } from '@ngrx/entity';
import { buildTokenName, } from '@app/core/store/shared/state';
import { ActionNameTypeMapFactory } from './../../../core/store/shared/state';
export var ordersStateConfig = {
    statePath: 'orders',
    entityName: 'Order',
    pluralName: 'Orders',
};
export var ordersActionNameTypeMap = ActionNameTypeMapFactory(ordersStateConfig);
export var orderEntityAdapter = createEntityAdapter();
export var initialState = orderEntityAdapter.getInitialState({
    loading: false,
    error: null,
});
export var OrdersReducerToken = new InjectionToken(buildTokenName(ordersStateConfig));
export var selectIds = (_a = orderEntityAdapter.getSelectors(), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
