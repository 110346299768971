import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { FeatureFlagSelectors } from '@app/core';
import { CollapseDirective } from '@app/shared/components/collapse';

import { filter, take } from 'rxjs/operators';
import { getAllergyNotCheckedWarning } from '../../shared/allergies-utils';
import { PatientAllergy } from '../../shared/allergies.type';

@Component({
  selector: 'omg-allergy-item',
  templateUrl: './allergy-item.component.html',
  styleUrls: ['./allergy-item.component.scss'],
})
export class AllergyItemComponent implements OnInit, OnChanges {
  @ViewChild('collapseRef') collapseRef: CollapseDirective;

  @Input() allergyItem: PatientAllergy;

  @Output() delete = new EventEmitter<{ id: number; onSuccess: () => void }>();
  @Output() update = new EventEmitter<{ value: any; onSuccess: () => void }>();
  @Output() activate = new EventEmitter<PatientAllergy>();

  reactionText: string;
  showAllergyAlerts: boolean;
  notCheckedWarning: string;

  constructor(private featureFlagSelectors: FeatureFlagSelectors) {}

  ngOnInit() {
    this.loadFeatureFlag();
    if (this.allergyItem) {
      this.updateReaction(this.allergyItem.reaction);
      this.loadAlerts();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allergyItem) {
      this.loadAlerts();
    }
  }

  activateAllergy(isActive: boolean) {
    this.activate.emit({ ...this.allergyItem, active: isActive });
  }

  onDelete(allergyId: number) {
    const message = 'Are you sure you want to delete this allergy?';
    const isConfirmed = this.getConfirmation(message);

    if (!isConfirmed) {
      return;
    }

    this.delete.emit({ id: allergyId, onSuccess: () => this.collapse() });
  }

  onUpdate(formValue: any) {
    this.update.emit({ value: formValue, onSuccess: () => this.collapse() });
  }

  onCancel() {
    this.collapse();
  }

  updateReaction(reaction: string) {
    this.reactionText = reaction;
  }

  loadAlerts() {
    if (this.showAllergyAlerts) {
      this.notCheckedWarning = getAllergyNotCheckedWarning(this.allergyItem);
    }
  }

  private collapse() {
    this.collapseRef.collapse();
  }

  private getConfirmation(message: string): boolean {
    // Native window confirm box will be replaced in future
    if (typeof confirm === 'function') {
      return !!confirm(message);
    }

    return false;
  }

  private loadFeatureFlag() {
    this.featureFlagSelectors
      .featureEnabled('drug_allergy_checking')
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe(value => (this.showAllergyAlerts = value));
  }
}
