var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { HealthGoalService } from '../shared/health-goal.service';
import { selectHealthGoalScreeningsByHealthGoalId } from './health-goal-screening.selectors';
import { AddHealthGoalError, AddHealthGoalSuccess, DeleteHealthGoalError, DeleteHealthGoalSuccess, HealthGoalActionTypes, LoadHealthGoalsError, LoadHealthGoalsSuccess, UpdateHealthGoalError, UpdateHealthGoalSuccess, } from './health-goal.actions';
var HealthGoalEffects = /** @class */ (function () {
    function HealthGoalEffects(actions$, service, store, patientSelectors, errorHandler) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.patientSelectors = patientSelectors;
        this.errorHandler = errorHandler;
        this.loadHealthGoals$ = this.actions$.pipe(ofType(HealthGoalActionTypes.LoadHealthGoals), switchMap(function (action) {
            return _this.service.getAll(action.payload).pipe(map(function (healthGoals) { return new LoadHealthGoalsSuccess(healthGoals); }), catchError(function (error) {
                return of(new LoadHealthGoalsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.addHealthGoal$ = this.actions$.pipe(ofType(HealthGoalActionTypes.AddHealthGoal), mergeMap(function (action) {
            return _this.store.pipe(select(selectHealthGoalScreeningsByHealthGoalId, {
                id: action.payload.healthGoal.id,
            }), map(function (screeningHistory) { return ({
                action: action,
                patientId: action.payload.patientId,
                healthGoal: __assign({}, action.payload.healthGoal, { screeningHistory: screeningHistory }),
            }); }), first());
        }), switchMap(function (result) {
            return _this.service.add(result.patientId, result.healthGoal).pipe(map(function (healthGoal) { return new AddHealthGoalSuccess(healthGoal); }), catchError(function (error) {
                return of(new AddHealthGoalError(_this.errorHandler.handleErrorSafe(error), {
                    id: result.healthGoal.id,
                }));
            }));
        }));
        this.updateHealthGoal$ = this.actions$.pipe(ofType(HealthGoalActionTypes.UpdateHealthGoal), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service.update(patientId, action.payload).pipe(map(function (healthGoal) { return new UpdateHealthGoalSuccess(healthGoal); }), catchError(function (error) {
                return of(new UpdateHealthGoalError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.id,
                }));
            }));
        }));
        this.deleteHealthGoal$ = this.actions$.pipe(ofType(HealthGoalActionTypes.DeleteHealthGoal), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service.delete(patientId, action.payload).pipe(map(function (healthGoal) { return new DeleteHealthGoalSuccess(action.payload); }), catchError(function (error) {
                return of(new DeleteHealthGoalError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload,
                }));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalEffects.prototype, "loadHealthGoals$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalEffects.prototype, "addHealthGoal$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalEffects.prototype, "updateHealthGoal$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalEffects.prototype, "deleteHealthGoal$", void 0);
    return HealthGoalEffects;
}());
export { HealthGoalEffects };
