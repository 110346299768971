import { of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { AppBridgeSelectors } from '../services/app-bridge.selectors';
var LegacyDataResolver = /** @class */ (function () {
    function LegacyDataResolver(selectors) {
        this.selectors = selectors;
    }
    LegacyDataResolver.prototype.resolve = function (route, state) {
        return this.selectors.profile.pipe(withLatestFrom(this.selectors.patient), map(function (_a) {
            var profile = _a[0], patient = _a[1];
            return of({ profile: profile, patient: patient });
        }), take(1));
    };
    return LegacyDataResolver;
}());
export { LegacyDataResolver };
