import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { VitalsDataComponent } from './components/vitals-data.component';
import { MeasurementTypesService } from './shared/measurement-types.service';
import { VitalsDataService } from './shared/vitals-data.service';

@NgModule({
  imports: [SharedModule],
  declarations: [VitalsDataComponent],
  exports: [VitalsDataComponent],
  providers: [MeasurementTypesService, VitalsDataService],
  entryComponents: [VitalsDataComponent],
})
export class VitalsDataModule {}
