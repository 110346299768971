import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PatientApiService, PatientSelectors } from '@app/core';
import { FormModel } from '@app/shared';

@Component({
  selector: 'omg-pcp-comments',
  templateUrl: './pcp-comments.component.html',
})
export class PcpCommentsComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private patientId: number;

  formModel: FormModel;

  constructor(
    private patientApi: PatientApiService,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.formModel = new FormModel(
      new FormGroup({
        pcpComments: new FormControl(''),
      }),
      { saveFunction: value => this.updatePcpComments(value) },
    );

    this.patientSelectors.patientId
      .pipe(
        tap(patientId => {
          this.patientId = patientId;
        }),
        switchMap(() => this.patientSelectors.pcpComments),
        filter(Boolean),
        takeUntil(this.unsubscribe),
      )
      .subscribe((pcpComments: string) => {
        this.formModel.setValue({ pcpComments });
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private updatePcpComments(value: { pcpComments: string }) {
    return this.patientApi.update(this.patientId, value);
  }
}
