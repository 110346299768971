<div class="om-item"
     om-layout="vertical"
     om-layout-gutter>

  <omg-collapse omgCollapse
                #vaccineHistoryItemCollapseRef="omgCollapse">
    <div *ngIf="groupingEnabled; else displayAll">

      <ng-container *ngIf="groupedVaccineHistory[0]">
        <div omgCollapseToggle
             om-layout
             om-layout-gutter>
          <div om-flex="85"
               om-layout-gutter>
            <strong>{{ groupedVaccineHistory[0].name }}</strong>
            <span>{{ groupedVaccineHistory[0].stepInSeriesPhrase }}</span>
          </div>
          <div om-flex="15">
            <span>{{ groupedVaccineHistory[0].givenOnWithPrecision }}</span>
          </div>
        </div>
        <omg-expanded class="om-item"
                      om-layout="vertical"
                      om-layout-gutter>
          <div class="data-table"
               om-layout-fill
               omgStopEvent="click">
            <h4 class="heading">
              <span om-flex="20">Date</span>
              <span om-flex="50">Description</span>
              <span om-flex="20">Given At</span>
              <span om-flex="10"></span>
            </h4>
            <omg-vaccine-history class="row"
                                 om-collapse
                                 *ngFor="let singleVaccine of groupedVaccineHistory; trackBy: trackByFn"
                                 (delete)="onDelete($event)"
                                 [ngClass]="{ '-inset': singleVaccine.fhirSource }"
                                 [groupingEnabled]="true"
                                 [vaccineHistory]="singleVaccine">
            </omg-vaccine-history>
          </div>
        </omg-expanded>
      </ng-container>
    </div>

    <ng-template #displayAll>
      <ng-container *ngIf="vaccineHistory$ | async as vaccineHistory">
        <div omgCollapseToggle
             om-layout
             om-layout-gutter>
          <div om-flex
               om-layout-gutter>
            <strong>{{ vaccineHistory.name }}</strong>
            <span>{{ vaccineHistory.step }}</span>
          </div>
          <div>{{ vaccineHistory.givenOnWithPrecision }}</div>
        </div>

        <omg-expanded class="om-item"
                      om-layout="vertical"
                      om-layout-gutter>
          <omg-vaccine-history [vaccineHistory]="vaccineHistory"
                               [hasFullyLoaded]="vaccineHistoryHasFullyLoaded$ | async"
                               (delete)="onDelete()"></omg-vaccine-history>
        </omg-expanded>
      </ng-container>
    </ng-template>
  </omg-collapse>
</div>
