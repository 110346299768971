import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core';

@Component({
  selector: 'omg-app-shell',
  templateUrl: 'app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit {
  fontFeatureFlags$: Observable<any>;

  constructor(private featureFlagSelectors: FeatureFlagSelectors) {}

  ngOnInit() {
    this.setupSelectors();
  }

  setupSelectors() {
    const optimal = this.featureFlagSelectors
      .featureEnabled('font_inter_optimal')
      .pipe(take(1));

    const inter = this.featureFlagSelectors
      .featureEnabled('font_inter')
      .pipe(take(1));

    this.fontFeatureFlags$ = forkJoin(inter, optimal).pipe(
      map(([interEnabled, interOptimalEnabled]) => {
        let fontClass = '';
        if (interEnabled) {
          fontClass = 'inter';
        }
        if (interOptimalEnabled) {
          fontClass = 'inter optimal';
        }

        return `om-page ${fontClass}`.trim();
      }),
    );
  }
}
