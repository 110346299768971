var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { PatientSelectors } from '../../patient';
import { ProfileSelectors } from '../../profile';
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(config, angulartics2MixPanel, profileSelectors, patientSelectors) {
        this.angulartics2MixPanel = angulartics2MixPanel;
        this.profileSelectors = profileSelectors;
        this.patientSelectors = patientSelectors;
        this.instanceProperties = {
            sessionFingerprint: +new Date(),
        };
        this.unsubscribe = new Subject();
        this._initalized = false;
        this.initialize(config.environment.mixPanel);
    }
    Object.defineProperty(AnalyticsService.prototype, "initialized", {
        get: function () {
            return this._initalized;
        },
        enumerable: true,
        configurable: true
    });
    /* istanbul ignore next */
    AnalyticsService.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    AnalyticsService.prototype.setUsername = function (username) {
        mixpanel.identify(username);
    };
    AnalyticsService.prototype.setSuperProperties = function (properties) {
        mixpanel.register(__assign({}, properties));
    };
    AnalyticsService.prototype.initialize = function (config) {
        if (!config.token || !mixpanel || this.initialized) {
            return;
        }
        mixpanel.init(config.token, { track_pageview: false });
        this.angulartics2MixPanel.startTracking();
        this.instanceProperties = __assign({}, this.instanceProperties, { application: config.application });
        this.setSuperProperties(this.instanceProperties);
        this.subscribeToPatientChanges();
        this.subscriberToProfileChanges();
        this._initalized = true;
    };
    AnalyticsService.prototype.subscribeToPatientChanges = function () {
        var _this = this;
        this.patientSelectors.patientId
            .pipe(filter(Boolean), takeUntil(this.unsubscribe))
            .subscribe(function (patientId) {
            return _this.setSuperProperties(__assign({}, _this.instanceProperties, { patientId: patientId }));
        });
    };
    AnalyticsService.prototype.subscriberToProfileChanges = function () {
        var _this = this;
        this.profileSelectors.profileId
            .pipe(filter(Boolean), takeUntil(this.unsubscribe))
            .subscribe(function (profileId) {
            return _this.setUsername(profileId ? profileId.toString() : '');
        });
    };
    return AnalyticsService;
}());
export { AnalyticsService };
