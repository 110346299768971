import {
  downgradeComponent as ngDowngradeComponent,
  downgradeInjectable as ngDowngradeInjectable,
} from '@angular/upgrade/static';
import {} from '@angular/upgrade/static';

import { angularJsModuleName } from './constants';

export const downgradeComponent = (
  component: any,
  selectorName: string = null,
  moduleName = angularJsModuleName,
) => {
  const module = angular.module(moduleName);
  return module.directive(selectorName, ngDowngradeComponent({ component }));
};

export const downgradeInjectable = (
  name: string = null,
  token: any,
  moduleName = angularJsModuleName,
) => {
  const module = angular.module(moduleName);
  return module.factory(name, ngDowngradeInjectable(token));
};
