import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { setFormErrors } from '@app/shared';

import {
  getDefaultHealthGoalActionId,
  HealthGoalTypeOptions,
} from '../../shared/health-goal-type-options';
import {
  HealthGoalErrors,
  HealthGoalForm,
  HealthGoalScreening,
  HealthGoalType,
  newEntityId,
} from '../../shared/health-maintenance.type';

@Component({
  selector: 'omg-health-goal-form',
  templateUrl: './health-goal-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthGoalFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() editedBy: string;
  @Input() updatedAt: string;
  @Input() screeningHistory: HealthGoalScreening[];

  @Output() save = new EventEmitter<HealthGoalForm>();
  @Output() delete = new EventEmitter<HealthGoalForm>();
  @Output() cancel = new EventEmitter();

  form: FormGroup;
  goalTypeOptions = new HealthGoalTypeOptions();

  private _formValue: HealthGoalForm;
  private _unsubscribe = new Subject();

  @Input()
  get healthGoalTypes() {
    return this.goalTypeOptions.healthGoalTypes;
  }

  set healthGoalTypes(value: HealthGoalType[]) {
    if (value && value.length > 0) {
      this.goalTypeOptions.healthGoalTypes = value;
    }
  }

  @Input()
  get formValue() {
    return this.form.value;
  }

  set formValue(value: HealthGoalForm) {
    if (this._formValue !== value) {
      this._formValue = value;
      this.setFormValue(value);
    }
  }

  @Input()
  get formErrors() {
    return this.form.errors;
  }

  set formErrors(errors: HealthGoalErrors) {
    setFormErrors(this.form, errors, 'apiError');
  }

  get isNewHealthGoal() {
    const id = this.form.get('id').value;
    return id === null || id === newEntityId;
  }

  get focusOnKey() {
    const id = this.form.get('id').value;
    return `healthGoalFocus-${id || 'create'}`;
  }

  constructor() {
    this.buildForm();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const { formValue } = changes;

    /**
     * If form value is being set to default values
     * we should set the healthGoalType to untouched
     * so we don't get validation error
     */
    if (
      formValue &&
      formValue.currentValue &&
      formValue.currentValue.healthGoalTypeId === null
    ) {
      if (this.form && this.form.get('healthGoalTypeId')) {
        this.form.get('healthGoalTypeId').markAsUntouched();
      }
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  onCancel() {
    this.resetForm();
    this.cancel.emit();
  }

  onSave() {
    this.save.emit(this.form.value);
  }

  onDelete() {
    this.delete.emit(this.form.value);
  }

  private buildForm() {
    this.form = new FormGroup({
      id: new FormControl(),
      comment: new FormControl(),
      declined: new FormControl(),
      dueAt: new FormControl(),
      healthGoalActionId: new FormControl(),
      healthGoalTypeId: new FormControl(null, Validators.required),
      indicated: new FormControl(),
      screeningHistory: new FormArray([]),
    });

    this.resetForm();
  }

  private subscribeToValueChanges() {
    this.form
      .get('healthGoalTypeId')
      .valueChanges.pipe(
        tap(id => this.updateHealthGoalType(id)),
        takeUntil(this._unsubscribe),
      )
      .subscribe();
  }

  private unsubscribeToValueChanges() {
    this._unsubscribe.next();
  }

  private setFormValue(value: HealthGoalForm) {
    this.unsubscribeToValueChanges();
    this.form.setValue(value);
    this.goalTypeOptions.selectedTypeId = value.healthGoalTypeId;
    this.form.markAsPristine();
    this.subscribeToValueChanges();
  }

  private resetForm() {
    this.unsubscribeToValueChanges();
    this.form.reset(this._formValue);
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.subscribeToValueChanges();
  }

  private updateHealthGoalType(goalTypeId: number) {
    if (this.goalTypeOptions.selectedTypeId !== goalTypeId) {
      // Set the selected health goal type in order to refresh options
      this.goalTypeOptions.selectedTypeId = goalTypeId;

      // Get and set the new default action type id
      const defaultActionTypeId = getDefaultHealthGoalActionId(
        this.goalTypeOptions.healthGoalTypes,
        goalTypeId,
      );

      this.form.get('healthGoalActionId').setValue(defaultActionTypeId);
    }
  }
}
