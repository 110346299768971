import { EventEmitter, OnInit, } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormModel } from '@app/shared/forms';
import { proxyWith } from '@app/utils';
var HealthMaintenanceNoteComponent = /** @class */ (function () {
    function HealthMaintenanceNoteComponent() {
        this.save = new EventEmitter();
        this.buildForm();
    }
    Object.defineProperty(HealthMaintenanceNoteComponent.prototype, "note", {
        get: function () {
            return this.formModel.value;
        },
        set: function (value) {
            if (value) {
                this.formModel.setValue(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    HealthMaintenanceNoteComponent.prototype.ngOnInit = function () { };
    HealthMaintenanceNoteComponent.prototype.buildForm = function () {
        var _this = this;
        this.formModel = new FormModel(new FormGroup({
            content: new FormControl(),
        }), { saveFunction: function (value) { return _this.saveNote(value); } });
    };
    HealthMaintenanceNoteComponent.prototype.saveNote = function (value) {
        var _a = proxyWith(), observer = _a.observer, complete = _a.complete;
        this.save.emit({ value: value, complete: complete });
        return observer;
    };
    return HealthMaintenanceNoteComponent;
}());
export { HealthMaintenanceNoteComponent };
