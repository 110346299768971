import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { escapeRegExp } from 'lodash/fp';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService, patientRoute, SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';

import {
  mapMultipleVaccineHistoriesResponseToVaccineHistories,
  mapVaccineHistoryResponseToVaccineHistory,
  mapVaccineHistoryToSaveRequest,
} from './vaccinations-api-mappers';
import {
  DeleteVaccineHistoryData,
  LoadVaccineHistoryData,
  SaveVaccineHistoryData,
  VaccineHistoryResponse,
} from './vaccinations-api.type';
import { VaccineSearchOptions } from './vaccinations.type';

const createVaccineHistoryPath = (patientId: number) =>
  patientRoute(patientId, '/history_vaccines');

@Injectable()
export class VaccinationsApiService {
  constructor(
    private api: ApiService,
    private config: ConfigService,
    private searchService: SearchService,
  ) {}

  get(payload: LoadVaccineHistoryData) {
    /*
      Why do we use 'get' when 'getAll' gets all of the VaccineHistory's?

      The response of 'getAll' does not return the full data of each
      respective VaccineHistory (e.g. comments, givenAt... required for UI),
      but 'get' on the same VaccineHistory id will.
    */
    return this.api
      .get<VaccineHistoryResponse>(
        `${createVaccineHistoryPath(payload.patientId)}/${
          payload.vaccineHistoryId
        }`,
      )
      .pipe(
        map(response => mapVaccineHistoryResponseToVaccineHistory(response)),
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
  }

  getAll(patientId: number) {
    return this.api
      .get<VaccineHistoryResponse[]>(createVaccineHistoryPath(patientId))
      .pipe(
        map(response =>
          mapMultipleVaccineHistoriesResponseToVaccineHistories(response),
        ),
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
  }

  getSearchResults(
    text: string,
    options: VaccineSearchOptions = { isOrderable: false },
  ): Observable<any> {
    return this.searchService
      .search(this.buildQuery(text, { isOrderable: options.isOrderable }), {
        camelize: true,
      })
      .pipe(
        map(response => {
          const hits = response.hits || {};
          const items = hits.hits || [];

          return items.map(hit => {
            const searchResult = {
              ...hit.source,
              ...{
                longName: this.addTagToResult(hit.source, text),
              },
            };
            return searchResult;
          });
        }),
      );
  }

  save(payload: SaveVaccineHistoryData) {
    return this.api
      .save<VaccineHistoryResponse>(
        `${createVaccineHistoryPath(payload.patientId)}`,
        mapVaccineHistoryToSaveRequest(payload.vaccineHistory),
      )
      .pipe(
        map(response => mapVaccineHistoryResponseToVaccineHistory(response)),
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
  }

  delete(payload: DeleteVaccineHistoryData) {
    return this.api
      .delete(
        `${createVaccineHistoryPath(payload.patientId)}/${
          payload.vaccineHistoryId
        }`,
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  private buildQuery(text: string, { isOrderable = false }) {
    const options = {
      size: '8',
      fields: ['long_name', 'pref_name^2', 'tags^3', 'abbreviation^4'],
      sort: ['_score', 'pref_name.keyword'],
      index: [this.config.searchIndex('vaccines')],
      operator: 'and',
      filter: null,
    };
    if (isOrderable) {
      options.filter = {
        bool: {
          should: [{ term: { is_orderable: true } }],
        },
      };
    }
    return new QueryBuilder('query_string_with_fields_v6').build(text, options);
  }

  private addTagToResult(vaccine: any, text: string) {
    const escapedQuery = escapeRegExp(text);
    const re = new RegExp(`\\b(${escapedQuery})`, 'i');

    if (re.test(vaccine.prefName)) {
      return vaccine.prefName;
    }

    if (re.test(vaccine.abbreviation)) {
      return this.displayWithTag(vaccine.prefName, vaccine.abbreviation);
    }

    return this.displayWithTag(
      vaccine.prefName,
      vaccine.tags.find(tag => re.test(tag)),
    );
  }

  private displayWithTag(baseName: string, tag: string) {
    return tag ? `${baseName} (${tag})` : baseName;
  }
}
