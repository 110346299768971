import { Injectable, OnDestroy } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ConfigService } from '../../config/config.service';
import { PatientSelectors } from '../../patient';
import { ProfileSelectors } from '../../profile';
import { TrackEventProperties } from './analytics.type';

declare let mixpanel;

interface MixPanelConfig {
  token: string;
  application: string;
}

@Injectable()
export class AnalyticsService implements OnDestroy {
  instanceProperties: Partial<TrackEventProperties> = {
    sessionFingerprint: +new Date(),
  };

  private unsubscribe = new Subject();
  private _initalized = false;

  constructor(
    config: ConfigService,
    private angulartics2MixPanel: Angulartics2Mixpanel,
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
  ) {
    this.initialize(config.environment.mixPanel);
  }

  get initialized() {
    return this._initalized;
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setUsername(username: string) {
    mixpanel.identify(username);
  }

  private setSuperProperties(properties: Partial<TrackEventProperties>) {
    mixpanel.register({ ...properties });
  }

  private initialize(config: MixPanelConfig) {
    if (!config.token || !mixpanel || this.initialized) {
      return;
    }

    mixpanel.init(config.token, { track_pageview: false });
    this.angulartics2MixPanel.startTracking();

    this.instanceProperties = {
      ...this.instanceProperties,
      application: config.application,
    };

    this.setSuperProperties(this.instanceProperties);
    this.subscribeToPatientChanges();
    this.subscriberToProfileChanges();

    this._initalized = true;
  }

  private subscribeToPatientChanges() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        takeUntil(this.unsubscribe),
      )
      .subscribe(patientId =>
        this.setSuperProperties({
          ...this.instanceProperties,
          patientId,
        }),
      );
  }

  private subscriberToProfileChanges() {
    this.profileSelectors.profileId
      .pipe(
        filter(Boolean),
        takeUntil(this.unsubscribe),
      )
      .subscribe(profileId =>
        this.setUsername(profileId ? profileId.toString() : ''),
      );
  }
}
