import { formatDate, orderBy } from '@app/utils';

import {
  HealthGoal,
  HealthGoalForm,
  HealthGoalScreening,
  HealthGoalScreeningForm,
  HealthGoalSummary,
  HealthGoalType,
  HealthGoalWithScreenings,
  newEntityId,
} from './health-maintenance.type';

export const sortHealthGoals = (
  healthGoals: HealthGoal[] | HealthGoalSummary[],
) => {
  const compareFn = (healthGoal: HealthGoalSummary) => {
    const dueAt = healthGoal.dueAt || -1;
    const healthGoalType = healthGoal.healthGoalTypeName
      ? healthGoal.healthGoalTypeName.toLowerCase()
      : -1;
    return [dueAt, healthGoalType];
  };

  return orderBy(['declined', compareFn], ['asc', 'asc', 'asc'])(healthGoals);
};

export const getGoalTypeAndActionNames = (
  healthGoalTypes: HealthGoalType[],
  healthGoalTypeId: number,
  healthGoalActionId: number,
) => {
  const healthGoalType = healthGoalTypes.find(t => t.id === healthGoalTypeId);

  const healthActionType =
    healthGoalType &&
    healthGoalType.healthGoalActions.find(a => a.id === healthGoalActionId);

  const healthGoalTypeName = healthGoalType && healthGoalType.name;
  const healthGoalActionName = healthActionType && healthActionType.name;

  return { healthGoalTypeName, healthGoalActionName };
};

export const buildNewHealthGoalForm = (
  partialHealthGoal: Partial<HealthGoalForm> = {},
): HealthGoalForm => ({
  id: newEntityId, // hack to track unusaved entity status
  comment: null,
  dueAt: null,
  declined: false,
  healthGoalActionId: null,
  healthGoalTypeId: null,
  indicated: true,
  screeningHistory: [],
  ...partialHealthGoal,
});

export const buildNewHealthGoal = (
  partialHealthGoal: Partial<HealthGoalForm> = {},
): HealthGoal => ({
  editedBy: null,
  editedById: null,
  patientId: null,
  healthGoalHistoryId: null,
  screeningHistoryIds: [],
  updatedAt: null,
  url: null,
  ...buildNewHealthGoalForm(partialHealthGoal),
});

export const buildNewHealthGoalScreeningForm = (
  partialScreening: Partial<HealthGoalScreeningForm> = {},
): HealthGoalScreeningForm => ({
  id: newEntityId, // hack to track unusaved entity status
  dateFuzzy: formatDate(new Date(), 'MM/dd/yyyy'),
  eventId: null,
  healthGoalId: null,
  normalized: false,
  patientId: null,
  patientSubmitted: false,
  result: null,
  ...partialScreening,
});

export const mapHealthGoalToFormValue = (
  healthGoal: HealthGoal | HealthGoalSummary,
): HealthGoalForm => ({
  id: healthGoal.id,
  comment: healthGoal.comment,
  dueAt: healthGoal.dueAt,
  declined: healthGoal.declined,
  healthGoalActionId: healthGoal.healthGoalActionId,
  healthGoalTypeId: healthGoal.healthGoalTypeId,
  indicated: healthGoal.indicated,
  screeningHistory: [],
});

export const mapHealthGoalScreeningToFormValue = (
  screening: HealthGoalScreening,
): HealthGoalScreeningForm => ({
  id: screening.id,
  dateFuzzy: screening.dateFuzzy,
  eventId: screening.eventId,
  healthGoalId: screening.healthGoalId,
  normalized: screening.normalized,
  patientId: screening.patientId,
  patientSubmitted: screening.patientSubmitted,
  result: screening.result,
});

export const normalizeHealthGoal = (
  data: HealthGoalWithScreenings,
): HealthGoal => {
  const { screeningHistory, ...healthGoal } = data;
  return {
    ...healthGoal,
    screeningHistoryIds: data.screeningHistory.map(s => s.id),
  };
};
