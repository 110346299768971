var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
var EntityApiService = /** @class */ (function () {
    function EntityApiService(api, config) {
        this.api = api;
        this.basePath = config.basePath;
        this.params = config.params;
        this.apiConfig = config.apiConfig || {};
    }
    EntityApiService.prototype.getAll = function () {
        return this.api
            .get(this.basePath, this.params, true)
            .pipe(catchError(function (error) { return throwError(error); }));
    };
    EntityApiService.prototype.getById = function (id) {
        return this.api
            .get(this.basePath + "/" + id, this.params, true)
            .pipe(catchError(function (error) { return throwError(error); }));
    };
    EntityApiService.prototype.save = function (data) {
        return this.api
            .save(this.basePath, data, {}, true)
            .pipe(catchError(function (error) { return throwError(error); }));
    };
    EntityApiService.prototype.update = function (_a) {
        var id = _a.id, changes = _a.changes;
        return this.api
            .update(this.basePath + "/" + id, changes, this.apiConfig, true)
            .pipe(catchError(function (error) { return throwError(error); }));
    };
    EntityApiService.prototype.delete = function (_a) {
        var id = _a.id;
        return this.api.delete(this.basePath + "/" + id).pipe(map(function () { return id; }), catchError(function (error) { return throwError(error); }));
    };
    return EntityApiService;
}());
export { EntityApiService };
var PatientEntityApiService = /** @class */ (function (_super) {
    __extends(PatientEntityApiService, _super);
    function PatientEntityApiService(api, config, patientSelectors) {
        var _this = _super.call(this, api, config) || this;
        _this.patientSelectors = patientSelectors;
        patientSelectors.patientId.pipe(filter(Boolean)).subscribe(function (patientId) {
            _this.basePath = config.basePath.replace(':patientId', String(patientId));
        });
        return _this;
    }
    return PatientEntityApiService;
}(EntityApiService));
export { PatientEntityApiService };
