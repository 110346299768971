import { createSelector, select, Store } from '@ngrx/store';
import { selectTodoState } from './todo.reducer';
import { SummaryAssociationType } from '@app/features/summaries/shared/summaries-api.type';
import { selectAll, selectEntities } from './todo.reducer';
export var selectTodoEntities = createSelector(selectTodoState, selectEntities);
export var selectAllTodoEntities = createSelector(selectTodoState, selectAll);
export var selectTodoById = createSelector(selectTodoState, function (state, _a) {
    var id = _a.id;
    return state.entities[id];
});
export var selectTodoBySummaryId = createSelector(selectAllTodoEntities, function (todos, _a) {
    var summaryId = _a.summaryId;
    return todos.find(function (e) {
        return e.associatedWithId === summaryId &&
            e.associatedWithType === SummaryAssociationType;
    });
});
export var selectIsComplete = createSelector(selectTodoById, function (todo) { return todo && todo.state === 'finished'; });
export var selectIsIncomplete = createSelector(selectTodoById, selectIsComplete, function (todo, isComplete) { return !!todo && !!todo.id && !isComplete; });
export var selectIsIncompleteAndCosign = createSelector(selectTodoById, selectIsIncomplete, function (todo, isIncomplete) { return todo && todo.name === 'Co-Sign' && isIncomplete; });
export var selectTodoError = createSelector(selectTodoState, function (state) { return state.error; });
export var selectHasTodoError = createSelector(selectTodoError, function (error) { return !!error; });
var TodoSelectors = /** @class */ (function () {
    function TodoSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(TodoSelectors.prototype, "state", {
        get: function () {
            return this.store.pipe(select(selectTodoState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TodoSelectors.prototype, "entities", {
        get: function () {
            return this.store.pipe(select(selectTodoEntities));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TodoSelectors.prototype, "error", {
        get: function () {
            return this.store.pipe(select(selectTodoError));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TodoSelectors.prototype, "hasError", {
        get: function () {
            return this.store.pipe(select(selectHasTodoError));
        },
        enumerable: true,
        configurable: true
    });
    TodoSelectors.prototype.todoById = function (id) {
        return this.store.pipe(select(selectTodoById, { id: id }));
    };
    TodoSelectors.prototype.todoBySummaryId = function (summaryId) {
        return this.store.pipe(select(selectTodoBySummaryId, { summaryId: summaryId }));
    };
    TodoSelectors.prototype.isComplete = function (id) {
        return this.store.pipe(select(selectIsComplete, { id: id }));
    };
    TodoSelectors.prototype.isIncomplete = function (id) {
        return this.store.pipe(select(selectIsIncomplete, { id: id }));
    };
    TodoSelectors.prototype.isIncompleteAndCosign = function (id) {
        return this.store.pipe(select(selectIsIncompleteAndCosign, { id: id }));
    };
    return TodoSelectors;
}());
export { TodoSelectors };
