var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { StateEffects } from '@app/core/store/shared/state';
import { OrderApiService } from '../shared/order-api.service';
import { getOrderEditPath } from '../shared/order-utils';
import { ActionNames } from './../../../core/store/shared/state';
import { ordersActionNameTypeMap } from './order.reducer';
var OrderEffects = /** @class */ (function (_super) {
    __extends(OrderEffects, _super);
    function OrderEffects(actions$, apiService, router, patientSelectors, errorHandler) {
        var _this = _super.call(this, actions$, apiService, ordersActionNameTypeMap, errorHandler) || this;
        _this.router = router;
        _this.patientSelectors = patientSelectors;
        _this.createOrder$ = _this.createEntity$;
        _this.loadOrders$ = _this.loadEntities$;
        _this.updateOrder$ = _this.updateEntity$;
        _this.deleteOrder$ = _this.deleteEntity$;
        _this.createOrderSuccess$ = _this.actions$.pipe(ofType(_this.actionNameTypeMap[ActionNames.CreateSuccess]), withLatestFrom(_this.patientSelectors.patientId), tap(function (_a) {
            var action = _a[0], patientId = _a[1];
            var order = action.payload;
            _this.router.navigateByUrl(getOrderEditPath(patientId, order));
        }));
        return _this;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "createOrder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "loadOrders$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "updateOrder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "deleteOrder$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "createOrderSuccess$", void 0);
    return OrderEffects;
}(StateEffects));
export { OrderEffects };
