import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ErrorHandlerService } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { ToastMessageService } from '@app/shared/components/toast';

import { TodoApiService } from '../shared/todo-api.service';
import {
  CompleteTodo,
  CompleteTodoError,
  CompleteTodoSuccess,
  LoadTodoBySummaryId,
  LoadTodoError,
  LoadTodoSuccess,
  ReopenTodo,
  ReopenTodoError,
  ReopenTodoSuccess,
  TodoActionTypes,
  UpdateTodo,
  UpdateTodoError,
  UpdateTodoSuccess,
} from './todo.actions';

@Injectable()
export class TodoEffects {
  constructor(
    private action$: Actions,
    private todoApi: TodoApiService,
    private toastMessageService: ToastMessageService,
    private summariesActions: SummariesActions,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  loadTodoBySummaryId$: Observable<Action> = this.action$.pipe(
    ofType<LoadTodoBySummaryId>(TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID),
    switchMap(action =>
      this.todoApi.getSummaryTodo(action.payload).pipe(
        map(todo => new LoadTodoSuccess(todo)),
        catchError(error =>
          of(new LoadTodoError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );

  @Effect()
  updateTodo$: Observable<Action> = this.action$.pipe(
    ofType<UpdateTodo>(TodoActionTypes.UPDATE_TODO),
    switchMap(action =>
      this.todoApi.update(action.payload).pipe(
        map(todo => new UpdateTodoSuccess(todo)),
        catchError(error =>
          of(new UpdateTodoError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );

  @Effect()
  completeTodo$: Observable<Action> = this.action$.pipe(
    ofType<CompleteTodo>(TodoActionTypes.COMPLETE_TODO),
    switchMap(action =>
      this.todoApi.complete(action.payload).pipe(
        map(todo => new CompleteTodoSuccess(todo)),
        catchError(error =>
          of(new CompleteTodoError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  completeTodoSuccess$: Observable<Action> = this.action$.pipe(
    ofType<CompleteTodoSuccess>(TodoActionTypes.COMPLETE_TODO_SUCCESS),
    tap(action => {
      this.toastMessageService.add({
        severity: 'success',
        detail: 'Your task has been completed',
      });
      this.summariesActions.refreshTimeline();
      this.summariesActions.closeWorkspaceItem();
    }),
  );

  @Effect()
  reopenTodo$: Observable<Action> = this.action$.pipe(
    ofType<ReopenTodo>(TodoActionTypes.REOPEN_TODO),
    switchMap(action =>
      this.todoApi.reopenTodo(action.payload).pipe(
        map(todo => new ReopenTodoSuccess(todo)),
        catchError(error =>
          of(new ReopenTodoError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );
}
