var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { AnalyticsActions, FeatureFlagSelectors, PatientSelectors, } from '@app/core';
import { OrderTypes } from '@app/features/orders-list/shared/order-utils';
import { OrderActions } from '@app/features/orders-list/store/order.actions';
import { AutoCompleteComponent, CollapseDirective } from '@app/shared';
import { dateStringNumericValidator } from '@app/shared/forms/validators/date-string-validator';
import { merge, of, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProblemsApiService } from '../../shared/problems-api.service';
import { mapProblemToForm, setIncludedInMedicalHistory, } from '../../shared/problems-utils';
import { ProblemSelectors } from '../../store/problems.selectors';
import { ProblemHistoryComponent } from '../problem-history/problem-history.component';
var ProblemFormComponent = /** @class */ (function () {
    function ProblemFormComponent(formBuilder, problemsApi, analyticsActions, problemSelectors, featureFlagSelectors, orderActions, patientSelectors) {
        this.formBuilder = formBuilder;
        this.problemsApi = problemsApi;
        this.analyticsActions = analyticsActions;
        this.problemSelectors = problemSelectors;
        this.featureFlagSelectors = featureFlagSelectors;
        this.orderActions = orderActions;
        this.patientSelectors = patientSelectors;
        this.showHistory = false;
        this.isCustomProblem = false;
        this.save = new EventEmitter();
        this.close = new EventEmitter();
        this.delete = new EventEmitter();
        this.attemptedSubmit = false;
        this.isHistoryExpanded = new Subject();
        this.addProblemToNote = false;
        this.unsubscribeFormListener = new Subject();
        this.searchFn = function (term, item) {
            return !!(item.clinicalDescription || item.clinicalAbbreviation || item.tags);
        };
        this.problemCodeSearchFn = function (term, item) {
            return !!(item.clinicalDescription || item.code);
        };
        this.buildForm();
        this.resetForm();
        this.resetAddProblemToNote();
    }
    ProblemFormComponent.prototype.ngOnInit = function () {
        this.setupSelectors();
    };
    ProblemFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.problem) {
            this.resetForm();
            this.selectProblem(changes.problem.currentValue.problemType);
            this.resetAddProblemToNote();
        }
    };
    ProblemFormComponent.prototype.unsubscribeFromFormListener = function () {
        // unsubscribe from any current listener
        this.unsubscribeFormListener.next();
        this.unsubscribeFormListener.complete();
        // create a new subject to be able to unsubscribe from a new listener
        this.unsubscribeFormListener = new Subject();
    };
    ProblemFormComponent.prototype.resetAddProblemToNote = function () {
        var _this = this;
        this.addProblemToNote = false;
        this.unsubscribeFromFormListener();
        var _a = this.form.controls, includedInMedicalHistory = _a.includedInMedicalHistory, allOtherControls = __rest(_a, ["includedInMedicalHistory"]);
        var formControlValueChanges = Object.values(allOtherControls).map(function (formControl) { return formControl.valueChanges; });
        merge.apply(void 0, formControlValueChanges).pipe(takeUntil(this.unsubscribeFormListener))
            .subscribe(function (change) {
            if (change) {
                _this.addProblemToNote = true;
            }
        });
    };
    ProblemFormComponent.prototype.searchProblems = function (term) {
        this.items$ = this.problemsApi.queryProblemSearch(term).pipe(take(1));
    };
    ProblemFormComponent.prototype.searchProblemCodes = function (term) {
        this.problemCodes = this.problemsApi
            .queryProblemCodeSearch(term)
            .pipe(take(1));
    };
    ProblemFormComponent.prototype.selectProblem = function (problemType) {
        var includedInMedicalHistory = this.problem
            ? this.problem.includedInMedicalHistory
            : setIncludedInMedicalHistory(problemType);
        this.setLocationItems(problemType && problemType.locations);
        this.clinicalDescription = problemType && problemType.clinicalDescription;
        this.clinicalAbbreviation = problemType && problemType.clinicalAbbreviation;
        this.decisionSupport = problemType && problemType.decisionSupport;
        this.setNotificationText(problemType);
        this.form.patchValue({
            includedInMedicalHistory: includedInMedicalHistory,
        });
        if (!problemType ||
            !problemType.locations ||
            !problemType.locations.length) {
            this.form.get('locationId').disable();
        }
        else {
            this.form.get('locationId').enable();
        }
    };
    ProblemFormComponent.prototype.onProblemChange = function (selectedProblem) {
        if (!selectedProblem) {
            this.setProblemItems(null);
        }
        else {
            this.setProblemItems({
                id: selectedProblem.id,
                clinicalDescription: selectedProblem.clinicalDescription,
            });
        }
        this.selectProblem(selectedProblem);
        this.trackProblemFieldEvent('Problem Field');
    };
    ProblemFormComponent.prototype.onProblemCodeChange = function (result) {
        if (!result) {
            this.setProblemCodeItems(null);
        }
        /* Workaround - when choosing an item from chrome's autocomplete dropdown. */
        if (result && result.id) {
            this.form.patchValue({
                problemId: result.id,
                id: this.problem ? this.problem.id : null,
            });
        }
        this.trackProblemFieldEvent('Problem Code Field');
    };
    ProblemFormComponent.prototype.onCustomDescriptionChange = function () {
        this.form.patchValue({ includedInMedicalHistory: true });
        this.trackProblemFieldEvent('Custom Problem Field');
    };
    ProblemFormComponent.prototype.trackProblemFieldEvent = function (subComponent) {
        this.analyticsActions.trackEvent('Problems Field Changed', {
            workflow: 'Charting',
            component: 'Problems',
            subWorkflowId: this.addProblemSessionId,
            subComponent: subComponent,
        });
    };
    ProblemFormComponent.prototype.saveProblem = function () {
        if (this.form.invalid) {
            this.attemptedSubmit = true;
            return;
        }
        this.save.emit(__assign({}, this.form.value, { addProblemToNote: this.addProblemToNote }));
    };
    ProblemFormComponent.prototype.closeForm = function () {
        if (this.problemHistory) {
            this.problemHistory.setExpanded(false);
        }
        this.resetForm();
        this.close.emit();
    };
    ProblemFormComponent.prototype.toggleCustomProblem = function () {
        this.form.enable();
        this.form.patchValue({ isCustom: !this.form.value.isCustom });
        if (!this.form.value.isCustom) {
            this.form.get('customProblemDescription').disable();
            this.form.get('problemCode').disable();
        }
        else {
            this.form.get('locationId').disable();
        }
        this.selectProblem(null);
        this.setProblemItems(null);
        this.setProblemCodeItems(null);
        this.form.get('problemId').reset();
        this.form.get('includedInMedicalHistory').reset();
        this.form.get('basicFollowUpOrder').reset();
        this.form.get('customProblemDescription').reset();
        this.form.get('problemCode').reset();
        this.form.get('locationId').reset();
        this.trackProblemClickEvent();
    };
    ProblemFormComponent.prototype.onDelete = function () {
        this.delete.emit(this.problem.id);
    };
    ProblemFormComponent.prototype.setNotificationText = function (problemType) {
        var _this = this;
        this.notificationText = null;
        if (!problemType) {
            return;
        }
        var autoCreations = problemType.autoCreations;
        var configs = autoCreations.find(function (autoCreation) { return autoCreation.basicFollowUpOrder !== null; });
        if (configs && (!this.problem || !this.problem.id)) {
            var intervalValue = parseInt(configs.basicFollowUpOrder.intervalValue.toString(), 10);
            var intervalType = "" + (configs.basicFollowUpOrder.intervalType +
                (intervalValue > 1 ? 's' : ''));
            this.patientSelectors.isMinor.pipe(take(1)).subscribe(function (isMinor) {
                _this.form.patchValue({ basicFollowUpOrder: !isMinor });
            });
            if (intervalType === 'day' && intervalValue === 0) {
                this.notificationText =
                    'Patient will be sent a notification to check in today.';
            }
            else {
                this.notificationText = "Patient will be sent a notification to check in " + intervalValue + " " + intervalType + " from now.";
            }
        }
        else {
            this.form.patchValue({ basicFollowUpOrder: false });
        }
        return problemType;
    };
    ProblemFormComponent.prototype.resetForm = function () {
        if (this.decisionSupportCollapse) {
            this.decisionSupportCollapse.collapse();
        }
        var formValue = mapProblemToForm(this.problem);
        var locations = this.problem ? this.problem.problemType.locations : null;
        var clinicalDescription = this.problem
            ? this.problem.problemType.clinicalDescription
            : null;
        var decisionSupport = this.problem
            ? this.problem.problemType.decisionSupport
            : null;
        var customDescription = this.problem
            ? this.problem.customProblemTypeCodeDescription
            : null;
        var problem = clinicalDescription && formValue.id
            ? { clinicalDescription: clinicalDescription, id: formValue.problemId }
            : null;
        var problemCodeItems = customDescription && formValue.problemCode
            ? [
                {
                    label: customDescription + " " + formValue.problemCode,
                    code: formValue.problemCode,
                },
            ]
            : null;
        this.setProblemItems(problem);
        this.setProblemCodeItems(problemCodeItems);
        this.setLocationItems(locations);
        this.clinicalDescription = clinicalDescription;
        this.decisionSupport = decisionSupport;
        this.notificationText = null;
        this.form.reset(formValue);
        this.form.enable();
        if (this.form.get('isCustom').value) {
            this.form.get('locationId').disable();
        }
        else {
            this.form.get('customProblemDescription').disable();
            this.form.get('problemCode').disable();
            if (!locations) {
                this.form.get('locationId').disable();
            }
        }
        this.attemptedSubmit = false;
    };
    ProblemFormComponent.prototype.clearProblemId = function () {
        if (this.form.get('problemId').value !== null) {
            this.setProblemItems(null);
            this.form.get('problemId').setValue(null);
            this.problemFieldRef.close();
        }
    };
    ProblemFormComponent.prototype.restoreProblemId = function () {
        if (this.form.get('problemId').value === null) {
            this.setProblemItems(this.lastProblemItem);
            this.form.get('problemId').reset(this.lastProblemItem.id);
            this.form.get('problemId').enable();
        }
    };
    ProblemFormComponent.prototype.createNewProcedureOrder = function () {
        var problemTypeId;
        if (this.lastProblemItem && this.lastProblemItem.id) {
            // Non Persisted / Changed Problem Type
            problemTypeId = this.lastProblemItem.id;
        }
        else if (this.problem && this.problem.problemType) {
            // Persisted Problem Type
            problemTypeId = this.problem.problemType.id;
        }
        this.orderActions.create({
            type: OrderTypes.ProcedureOrder,
            indication: {
                problem_type_id: problemTypeId,
            },
        });
    };
    ProblemFormComponent.prototype.trackProblemClickEvent = function () {
        this.analyticsActions.trackEvent('Standard Problem Clicked', {
            workflow: 'Charting',
            component: 'Problems',
            subComponent: 'Standard Problem Button',
            subWorkflowId: this.addProblemSessionId,
        });
    };
    ProblemFormComponent.prototype.buildForm = function () {
        this.form = this.formBuilder.group({
            onset: new FormControl('', [
                Validators.maxLength(10),
                dateStringNumericValidator,
            ]),
            problemId: new FormControl(null, Validators.required),
            resolution: new FormControl('', [
                Validators.maxLength(10),
                dateStringNumericValidator,
            ]),
            briefComment: new FormControl(''),
            summary: new FormControl(),
            assessmentAndPlan: new FormControl(),
            includedInMedicalHistory: new FormControl(null),
            basicFollowUpOrder: new FormControl(),
            customProblemDescription: new FormControl(null, Validators.required),
            problemCode: new FormControl(null, Validators.required),
            locationId: new FormControl(null, Validators.required),
            isCustom: new FormControl(false),
            id: new FormControl(null),
        });
    };
    ProblemFormComponent.prototype.setProblemItems = function (item) {
        if (item) {
            this.items$ = of([item]);
            this.lastProblemItem = item;
        }
        else {
            this.items$ = of(null);
        }
    };
    ProblemFormComponent.prototype.setProblemCodeItems = function (items) {
        if (items) {
            this.problemCodes = of(items);
        }
        else {
            this.problemCodes = of(null);
        }
    };
    ProblemFormComponent.prototype.setLocationItems = function (locations) {
        if (locations) {
            this.locations = of(locations);
        }
        else {
            this.locations = of(null);
        }
    };
    ProblemFormComponent.prototype.setupSelectors = function () {
        if (this.problem) {
            this.history = this.problemSelectors.problemHistory(this.problem.id);
        }
        this.hasInternalOrders$ = this.featureFlagSelectors
            .featureEnabled('new_1life_internal_procedure_orders')
            .pipe(filter(Boolean), take(1));
    };
    return ProblemFormComponent;
}());
export { ProblemFormComponent };
