import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';

import { ErrorHandlerService, PatientSelectors } from '@app/core';
import { CreateSuccess, StateEffects } from '@app/core/store/shared/state';

import { OrderApiService } from '../shared/order-api.service';
import { getOrderEditPath } from '../shared/order-utils';
import { Order } from '../shared/order.type';
import { ActionNames } from './../../../core/store/shared/state';
import { ordersActionNameTypeMap } from './order.reducer';

@Injectable()
export class OrderEffects extends StateEffects<Order> {
  constructor(
    actions$: Actions,
    apiService: OrderApiService,
    private router: Router,
    private patientSelectors: PatientSelectors,
    errorHandler: ErrorHandlerService,
  ) {
    super(actions$, apiService, ordersActionNameTypeMap, errorHandler);
  }

  @Effect()
  createOrder$ = this.createEntity$;

  @Effect()
  loadOrders$ = this.loadEntities$;

  @Effect()
  updateOrder$ = this.updateEntity$;

  @Effect()
  deleteOrder$ = this.deleteEntity$;

  @Effect({ dispatch: false })
  createOrderSuccess$ = this.actions$.pipe(
    ofType<CreateSuccess<Order>>(
      this.actionNameTypeMap[ActionNames.CreateSuccess],
    ),
    withLatestFrom(this.patientSelectors.patientId),
    tap(([action, patientId]) => {
      const order = (<CreateSuccess<Order>>action).payload;
      this.router.navigateByUrl(getOrderEditPath(patientId, order));
    }),
  );
}
