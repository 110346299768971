var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map, switchMap, take } from 'rxjs/operators';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';
import { mapOrdersToEntities, mapOrderToEntity } from '../store/order.mappers';
import { getCreateOrderApiPath, getOrderApiPath } from './order-utils';
var OrderApiService = /** @class */ (function (_super) {
    __extends(OrderApiService, _super);
    function OrderApiService(api, patientSelectors) {
        var _this = _super.call(this, api, { basePath: "/v2/admin/patients/:patientId/patient_orders" }, patientSelectors) || this;
        _this.withPatientId = function (switchMapCallback) {
            return _this.patientSelectors.patientId.pipe(take(1), switchMap(switchMapCallback));
        };
        return _this;
    }
    OrderApiService.prototype.getAll = function () {
        return _super.prototype.getAll.call(this).pipe(map(function (response) { return mapOrdersToEntities(response); }));
    };
    OrderApiService.prototype.delete = function (_a) {
        var _this = this;
        var id = _a.id, options = _a.options;
        return this.withPatientId(function (patientId) {
            var orderPath = getOrderApiPath(options.type, patientId);
            return _this.api.delete(orderPath + "/" + id).pipe(map(function () { return id; }));
        });
    };
    OrderApiService.prototype.update = function (_a) {
        var _this = this;
        var id = _a.id, changes = _a.changes, options = _a.options;
        return this.withPatientId(function (patientId) {
            var orderPath = getOrderApiPath(options.type, patientId);
            return _this.api.update(orderPath + "/" + id, changes, {}, true);
        });
    };
    OrderApiService.prototype.save = function (order) {
        var _this = this;
        return this.withPatientId(function (patientId) {
            var orderPath = getCreateOrderApiPath(order.type, patientId);
            return _this.api.save(orderPath, order, {}, true).pipe(map(function (response) {
                return mapOrderToEntity(__assign({}, order, response));
            }));
        });
    };
    return OrderApiService;
}(PatientEntityApiService));
export { OrderApiService };
