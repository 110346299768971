import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { ApiService, PatientSelectors } from '@app/core';
import { stateReducerFactory } from '@app/core/store/shared/state';

import { OrderApiService } from '../shared/order-api.service';
import { Order } from '../shared/order.type';
import { OrderActions } from './order.actions';
import { OrderEffects } from './order.effects';
import {
  orderEntityAdapter,
  ordersActionNameTypeMap,
  OrdersReducerToken,
  ordersStateConfig,
} from './order.reducer';
import { OrdersSelector } from './order.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(ordersStateConfig.statePath, OrdersReducerToken),
    EffectsModule.forFeature([OrderEffects]),
  ],
  providers: [
    {
      provide: OrdersReducerToken,
      useFactory: () =>
        stateReducerFactory<Order>(ordersActionNameTypeMap, orderEntityAdapter),
    },
    {
      provide: OrdersSelector,
      useFactory: store => new OrdersSelector(store, ordersStateConfig),
      deps: [Store],
    },
    {
      provide: OrderActions,
      useFactory: store => new OrderActions(store, ordersActionNameTypeMap),
      deps: [Store],
    },
    {
      provide: OrderApiService,
      useFactory: (apiService, patientSelectors) =>
        new OrderApiService(apiService, patientSelectors),
      deps: [ApiService, PatientSelectors],
    },
  ],
})
export class OrdersStoreModule {}
