import { AbstractControl, ValidationErrors } from '@angular/forms';

export const dateStringNumericValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const dateString = control.value;
  const isError = /[^0-9\/]+/.test(dateString); // eslint-disable-line no-useless-escape

  if (!control.pristine && isError) {
    return { isNumeric: dateString };
  }

  return null;
};
