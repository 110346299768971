import { OnChanges, SimpleChanges, } from '@angular/core';
import { CollapseDirective } from './collapse.directive';
var CollapseProviderDirective = /** @class */ (function () {
    function CollapseProviderDirective(collapseDirective) {
        this.collapseDirective = collapseDirective;
        this.collapse = this.collapseDirective;
    }
    Object.defineProperty(CollapseProviderDirective.prototype, "expanded", {
        get: function () {
            return this.collapse.expanded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollapseProviderDirective.prototype, "expandedChange", {
        get: function () {
            return this.collapse.expandedChange;
        },
        enumerable: true,
        configurable: true
    });
    CollapseProviderDirective.prototype.ngOnChanges = function (changes) {
        var collapseProvider = changes.collapseProvider;
        if (collapseProvider) {
            this.collapse = this.collapseProvider || this.collapseDirective;
        }
    };
    return CollapseProviderDirective;
}());
export { CollapseProviderDirective };
