/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i5 from "primeng/components/dom/domhandler";
import * as i6 from "primeng/components/toast/toast";
import * as i7 from "primeng/components/common/messageservice";
import * as i8 from "primeng/components/common/shared";
import * as i9 from "./toast.component";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.data == null) ? null : _v.parent.context.$implicit.data.routerLink); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.data.linkText; _ck(_v, 2, 0, currVal_3); }); }
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["om-layout", ""], ["om-layout-align", "start center"], ["om-layout-gutter", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "ui-toast-icon pi"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pi-info-circle": 0, "pi-exclamation-triangle": 1, "pi-check": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "ui-toast-message-text-content"], ["om-layout", "vertical"], ["om-layout-gutter", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "ui-toast-summary"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "ui-toast-detail"], ["om-layout", "vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "ui-toast-icon pi"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.severity == "info"), ((_v.context.$implicit.severity == "warn") || (_v.context.$implicit.severity == "error")), (_v.context.$implicit.severity == "success")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = (((_v.context.$implicit.data == null) ? null : _v.context.$implicit.data.routerLink) && ((_v.context.$implicit.data == null) ? null : _v.context.$implicit.data.linkText)); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.summary; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.detail; _ck(_v, 7, 0, currVal_3); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-toast", [], null, null, null, i4.View_Toast_0, i4.RenderType_Toast)), i1.ɵprd(512, null, i5.DomHandler, i5.DomHandler, []), i1.ɵdid(2, 1294336, null, 1, i6.Toast, [i7.MessageService, i5.DomHandler], { position: [0, "position"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(5, 16384, [[1, 4]], 0, i8.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.position; _ck(_v, 2, 0, currVal_0); var currVal_1 = "message"; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i9.ToastComponent, [], null, null)], null, null); }
var ToastComponentNgFactory = i1.ɵccf("omg-toast", i9.ToastComponent, View_ToastComponent_Host_0, { position: "position" }, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
