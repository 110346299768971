import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SectionLinkedQueueService } from '@app/core/section-linked-queue/section-linked-queue.service';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { getSectionLinkedTransform } from './root-scope-transforms';
var StoreToRootScopeBridgeService = /** @class */ (function () {
    function StoreToRootScopeBridgeService(rootScope, sectionLinkedQueue, summariesSelectors, summariesActions) {
        this.rootScope = rootScope;
        this.sectionLinkedQueue = sectionLinkedQueue;
        this.summariesSelectors = summariesSelectors;
        this.summariesActions = summariesActions;
        this.unsubscribe = new Subject();
        this.setupStoreListeners();
    }
    StoreToRootScopeBridgeService.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    StoreToRootScopeBridgeService.prototype.emit = function (key) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        var _a;
        return (_a = this.rootScope).$emit.apply(_a, [key].concat(params));
    };
    StoreToRootScopeBridgeService.prototype.setupStoreListeners = function () {
        // Add subscriptions to the store that need to be emitted to rootScope here
        this.subscribeToSectionLinked();
        this.subscribeToTimeline();
    };
    StoreToRootScopeBridgeService.prototype.subscribeToTimeline = function () {
        var _this = this;
        this.summariesSelectors.refreshTimeline
            .pipe(filter(function (refresh) { return refresh; }), takeUntil(this.unsubscribe))
            .subscribe(function (refresh) {
            _this.emit('refreshTimeline');
            _this.summariesActions.refreshTimelineDone();
        });
        this.summariesSelectors.closeTimelineItem
            .pipe(filter(function (close) { return close; }), takeUntil(this.unsubscribe))
            .subscribe(function (close) {
            _this.emit('unset-timeline-note');
            _this.summariesActions.closeTimelineItemDone();
        });
    };
    StoreToRootScopeBridgeService.prototype.subscribeToSectionLinked = function () {
        var _this = this;
        this.sectionLinkedQueue.sectionLinkedItem$
            .pipe(filter(Boolean), takeUntil(this.unsubscribe))
            .subscribe(function (item) {
            var transform = getSectionLinkedTransform(item.sectionKey);
            if (item.eventKey === 'sectionLinked') {
                _this.emit(item.eventKey, item.sectionKey, transform(item.payload));
            }
            else {
                _this.emit(item.eventKey, transform(item.payload));
            }
            _this.sectionLinkedQueue.removeSectionLink();
        });
    };
    return StoreToRootScopeBridgeService;
}());
export { StoreToRootScopeBridgeService };
