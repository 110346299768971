import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AccessTokenResolver implements Resolve<string> {
  constructor(
    private location: Location,
    private auth: AuthService,
    private router: Router,
  ) {}

  resolve() {
    this.auth.setAuthTokenFromUrl(this.location.path());
    const redirectPath = this.auth.getRedirectPath();
    this.router.navigateByUrl(redirectPath);
    return redirectPath;
  }
}
