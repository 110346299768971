<omg-card>
  <omg-card-title heading="PCP Comments">
  </omg-card-title>
  <div class="om-item"
       om-card-content>
    <form [formGroup]="formModel.form"
          [omgForm]="formModel"
          data-qaid="pcp-comments">
      <div om-layout="vertical">
        <textarea omgTextarea
                  autosize
                  rows="4"
                  om-layout="vertical"
                  formControlName="pcpComments"></textarea>
      </div>
      <div>
        <omg-form-state when="saving"></omg-form-state>&nbsp;
      </div>
    </form>
  </div>
</omg-card>
