var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { escapeRegExp, remove, sortBy, union } from 'lodash/fp';
export var setCustomProblemCode = function (problem) {
    var updatedProblem = __assign({}, problem);
    updatedProblem.customProblemCodeSelection = {
        code: updatedProblem.customProblemTypeCode,
        detailDescription: updatedProblem.customProblemTypeCodeDescription,
        id: updatedProblem.id.toString(),
    };
    return updatedProblem;
};
export var sortProblems = function (problems) {
    return sortBy(function (problem) { return [
        !problem.includedInMedicalHistory,
        problem.problemType.clinicalDescription.toLowerCase(),
    ]; }, problems);
};
export var problemCodeHighlight = function (problemCode, query) {
    var escapedQuery = escapeRegExp(query);
    var keywords = escapedQuery.split(/\s/);
    var pattern = new RegExp("(" + keywords.join('|') + ")", 'ig');
    var code = problemCode.code || '';
    var detailDescription = problemCode.detail_description || '';
    var formatProblemCode = function (description, extra) { return description + " " + extra; };
    if (pattern.test(code)) {
        return formatProblemCode(detailDescription, code);
    }
    return formatProblemCode(detailDescription, code);
};
export var setIncludedInMedicalHistory = function (problemType) {
    return !(problemType && problemType.importance === 3);
};
export var problemTypeHighlight = function (problemType, query) {
    var escapedQuery = escapeRegExp(query);
    var re = new RegExp("\\b(" + escapedQuery + ")", 'i');
    var keywords = escapedQuery.split(/\s/);
    var pattern = new RegExp("(" + keywords.join('|') + ")", 'ig');
    var exactTagMatch = problemType.tags.find(function (tag) { return re.test(tag); });
    var clinicalDescription = problemType.clinical_description || '';
    var clinicalAbbreviation = problemType.clinical_abbreviation || '';
    var layDescription = problemType.lay_description || '';
    var tags = problemType.tags || [];
    var displayWith = function (extra) { return clinicalDescription + " (" + extra + ")"; };
    var returnString = clinicalDescription;
    if (exactTagMatch) {
        returnString = displayWith(tags);
    }
    if (pattern.test(clinicalDescription)) {
        return clinicalDescription;
    }
    else if (pattern.test(clinicalAbbreviation)) {
        returnString = displayWith(clinicalAbbreviation);
    }
    else if (pattern.test(layDescription)) {
        returnString = displayWith(layDescription);
    }
    return returnString;
};
export var mapProblemToForm = function (problem) {
    return {
        problemId: problem ? problem.problemType.id : null,
        locationId: problem ? problem.problemCodeLocation.id : null,
        includedInMedicalHistory: problem ? problem.includedInMedicalHistory : null,
        customProblemDescription: problem
            ? problem.customProblemTypeDescription
            : null,
        problemCode: problem ? problem.customProblemTypeCode : null,
        basicFollowUpOrder: problem && Object.keys(problem.autoCreationsFlags).length ? true : false,
        id: problem ? problem.id : null,
        isCustom: problem ? problem.useCustomProblemType : false,
        onset: problem ? problem.onset : null,
        resolution: problem ? problem.resolution : null,
        briefComment: problem ? problem.briefComment : null,
        summary: problem ? problem.summary : null,
        assessmentAndPlan: problem ? problem.aAndPDisplayText : null,
        addProblemToNote: false,
    };
};
export var getHistoryIds = function (assessedProblems, historyId, problemId, hasAssessedProblem) {
    if (!assessedProblems) {
        return [];
    }
    var assessedProblemHistoryIds = assessedProblems.map(function (assessedProblem) { return assessedProblem.problemHistoryId; });
    if (hasAssessedProblem) {
        return assessedProblems.map(function (assessedProblem) {
            if (assessedProblem.id === problemId) {
                return historyId;
            }
            return assessedProblem.problemHistoryId;
        });
    }
    return union(assessedProblemHistoryIds, [historyId]);
};
export var removeHistoryId = function (historyIds, historyId) {
    return remove(function (id) { return id === historyId; }, historyIds);
};
