import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  removeKeyFromList,
  resetEntityMetadata,
  selectEntityById,
  updateEntityMetadata,
} from '@app/utils/store';

import { buildNewHealthGoal } from '../shared/health-maintenance-utils';
import {
  HealthGoal,
  HealthGoalWithScreenings,
  newEntityId,
} from '../shared/health-maintenance.type';

import {
  HealthGoalScreeningActions,
  HealthGoalScreeningActionTypes,
} from './health-goal-screening.actions';
import {
  HealthGoalActions,
  HealthGoalActionTypes,
} from './health-goal.actions';

export interface HealthGoalState extends EntityState<HealthGoal> {
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<HealthGoal>;
}

export const adapter: EntityAdapter<HealthGoal> = createEntityAdapter<
  HealthGoal
>();

export const initialState: HealthGoalState = adapter.getInitialState({
  loading: false,
  error: null,
  ...getEntityMetadataInitialState({}),
});

const pluckHealthGoal = (data: HealthGoalWithScreenings) => {
  const { screeningHistory, ...healthGoal } = data;
  return healthGoal;
};

const selectScreeningHistoryIds = (
  state: HealthGoalState,
  entityId: string | number,
) => {
  const entity = selectEntityById(state, entityId);
  return (entity && entity.screeningHistoryIds) || [];
};

export function reducer(
  state = initialState,
  action: HealthGoalActions | HealthGoalScreeningActions,
): HealthGoalState {
  switch (action.type) {
    case HealthGoalActionTypes.InitNewHealthGoal: {
      return {
        ...adapter.upsertOne(buildNewHealthGoal(), state),
      };
    }

    case HealthGoalActionTypes.LoadHealthGoals: {
      return {
        ...state,
        loading: true,
      };
    }

    case HealthGoalActionTypes.LoadHealthGoalsSuccess: {
      return {
        ...adapter.addAll(action.payload.map(pluckHealthGoal), state),
        loading: false,
        error: null,
      };
    }

    case HealthGoalActionTypes.LoadHealthGoalsError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case HealthGoalActionTypes.LoadHealthGoalsError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case HealthGoalActionTypes.AddHealthGoal: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case HealthGoalActionTypes.AddHealthGoalSuccess: {
      return {
        ...adapter.addOne(pluckHealthGoal(action.payload), state),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case HealthGoalActionTypes.AddHealthGoalError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalActionTypes.UpdateHealthGoal: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload.id, { pending: true }, state),
      };
    }

    case HealthGoalActionTypes.UpdateHealthGoalSuccess: {
      return {
        ...adapter.upsertOne(pluckHealthGoal(action.payload), state),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case HealthGoalActionTypes.UpdateHealthGoalError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalActionTypes.DeleteHealthGoal: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case HealthGoalActionTypes.DeleteHealthGoalSuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload, state),
      };
    }

    case HealthGoalActionTypes.DeleteHealthGoalError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalActionTypes.ClearHealthGoals: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    /**
     * Normalize screenings
     */

    case HealthGoalScreeningActionTypes.AddHealthGoalScreeningSuccess: {
      // Add health goal screening history id
      const healthGoalId = action.payload.healthGoalId || newEntityId;
      const screeningHistory = selectScreeningHistoryIds(state, healthGoalId);

      return {
        ...adapter.updateOne(
          {
            id: healthGoalId,
            changes: {
              screeningHistoryIds: [...screeningHistory, action.payload.id],
            },
          },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningSuccess: {
      // Remove health goal screening history id
      const healthGoalId = action.meta.healthGoalId;
      const ids = selectScreeningHistoryIds(state, healthGoalId);

      return {
        ...adapter.updateOne(
          {
            id: healthGoalId,
            changes: {
              screeningHistoryIds: removeKeyFromList(action.payload, ids),
            },
          },
          state,
        ),
      };
    }

    default: {
      return state;
    }
  }
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of ids
export const selectHealthGoalIds = selectIds;

// select the dictionary of entities
export const selectHealthGoalEntities = selectEntities;

// select the array of items
export const selectAllHealthGoals = selectAll;

// select the total count
export const selectHealthGoalTotal = selectTotal;
