import { OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CollapseDirective } from '@app/shared/components/collapse';
import { waitFor } from '@app/utils';
import { mapHealthGoalScreeningToFormValue } from '../../shared/health-maintenance-utils';
import { DeleteHealthGoalScreening, UpdateHealthGoalScreening, } from '../../store/health-goal-screening.actions';
import { selectHealthGoalScreeningError, selectHealthGoalScreeningSummaryWithMetadata, } from '../../store/health-goal-screening.selectors';
var ScreeningItemComponent = /** @class */ (function () {
    function ScreeningItemComponent(store) {
        this.store = store;
        this.formLoaded = false;
    }
    Object.defineProperty(ScreeningItemComponent.prototype, "screening", {
        get: function () {
            return this._screening;
        },
        set: function (value) {
            if (this._screening !== value) {
                this._screening = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ScreeningItemComponent.prototype.ngOnInit = function () { };
    ScreeningItemComponent.prototype.onExpanded = function (expanded) {
        if (expanded) {
            this.loadForm();
        }
    };
    ScreeningItemComponent.prototype.onCancel = function () {
        this.collapseRef.collapse();
    };
    ScreeningItemComponent.prototype.onSave = function (value) {
        this.saveScreening(value);
    };
    ScreeningItemComponent.prototype.onDelete = function (value) {
        this.deleteScreening(value);
    };
    ScreeningItemComponent.prototype.loadForm = function () {
        if (!this.formLoaded) {
            this.setFormValue(this.screening);
            this.setupSelectors();
            this.formLoaded = true;
        }
    };
    ScreeningItemComponent.prototype.setFormValue = function (value) {
        this.screeningFormValue = mapHealthGoalScreeningToFormValue(value);
    };
    ScreeningItemComponent.prototype.setupSelectors = function () {
        this.screeningErrors$ = this.store.pipe(select(selectHealthGoalScreeningError, { id: this.screening.id }));
    };
    ScreeningItemComponent.prototype.saveScreening = function (value) {
        var _this = this;
        this.store.dispatch(new UpdateHealthGoalScreening(value));
        waitFor(this.store.pipe(select(selectHealthGoalScreeningSummaryWithMetadata, {
            id: value.id,
        })), function (data) { return !data.pending && !data.error; }).subscribe(function (data) {
            _this.screening = data.entity;
            _this.setFormValue(_this.screening);
            _this.collapseRef.collapse();
        });
    };
    ScreeningItemComponent.prototype.deleteScreening = function (value) {
        var confirmation = window.confirm('Are you sure you want to delete this health goal screening?');
        if (confirmation) {
            this.store.dispatch(new DeleteHealthGoalScreening(value.id, {
                healthGoalId: value.healthGoalId,
            }));
        }
    };
    return ScreeningItemComponent;
}());
export { ScreeningItemComponent };
