import { AfterViewInit, EventEmitter, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FocusService } from '@app/shared/directives/focus/focus.service';
var EditableLabelComponent = /** @class */ (function () {
    function EditableLabelComponent(focusService) {
        this.focusService = focusService;
        this._value = '';
        this.required = true;
        this.placeholder = '';
        this.name = 'inPlaceTitle';
        this.disabled = false;
        this.textUpdated = new EventEmitter();
        this.focusOnKey = 'editableLabel-input';
        /* istanbul ignore next */
        this.onChange = function (value) { };
        /* istanbul ignore next */
        this.onTouched = function () { };
    }
    Object.defineProperty(EditableLabelComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                this.onChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    EditableLabelComponent.prototype.ngAfterViewInit = function () {
        this.setupLabel();
    };
    EditableLabelComponent.prototype.setupLabel = function () {
        this.editingSubject = !this.value;
        this.focusOnInput();
    };
    EditableLabelComponent.prototype.refreshLabel = function () {
        this.toggleEditingSubject();
        this.focusOnInput();
    };
    EditableLabelComponent.prototype.toggleEditingSubject = function () {
        this.editingSubject = !this.value || !this.editingSubject;
    };
    EditableLabelComponent.prototype.focusOnInput = function () {
        var _this = this;
        setTimeout(function () { return _this.focusService.setFocus(_this.focusOnKey); });
    };
    EditableLabelComponent.prototype.update = function () {
        this.toggleEditingSubject();
        this.textUpdated.emit(this._value);
    };
    /* istanbul ignore next */
    EditableLabelComponent.prototype.writeValue = function (value) {
        this._value = value;
    };
    /* istanbul ignore next */
    EditableLabelComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /* istanbul ignore next */
    EditableLabelComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /* istanbul ignore next */
    EditableLabelComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    return EditableLabelComponent;
}());
export { EditableLabelComponent };
