import { LabOrder } from '@app/modules/orders/shared/lab-order.type';
import { OrderIndication } from '@app/modules/orders/shared/order-indication/order-indication.type';
import { ProblemType } from '@app/modules/problems/shared/problems.type';

import { InternalUserForOrder } from './order-api.type';
import { OrderTypes } from './order-utils';

export interface BaseOrder {
  createdAt: string;
  id: number;
  status:
    | 'signed'
    | 'unsigned'
    | 'completed'
    | 'redacted'
    | 'cancelled'
    | 'collected'
    | 'declined'
    | 'sent'
    | 'reviewed'
    | 'patient_completed';
  type: OrderTypes;
  indication?: OrderIndication;
  indications?: OrderIndication[];
  supportsMultipleIndications?: boolean;
  reviewable: boolean;
  isExpired?: boolean;
}

export interface VaccineOrderListItem extends BaseOrder {
  dateDue?: string;
  internalUser?: InternalUserForOrder;
  isActive?: boolean;
  name?: string;
  stepInSeriesPhrase?: string;
  type: OrderTypes.VaccineOrder;
}

export interface ProcedureOrder extends BaseOrder {
  dueDate: string;
  contact: Contact;
  orderedBy: string;
  procedureTypeShortDescription: string;
  type: OrderTypes.ProcedureOrder;
  reviewable: boolean;
}

export interface ConsultOrder extends BaseOrder {
  contact: Contact;
  orderedBy: string;
  type: OrderTypes.ConsultOrder;
}

export interface FollowUpOrder extends BaseOrder {
  dueOn: string;
  followUpOrderEvents: OrderEvent[];
  orderedBy: string;
  reminderGranularity: string;
  updatedAt: string;
}

export interface VisitFollowUpOrder extends FollowUpOrder {
  appointmentTypeId: number;
  problems: ProblemType[];
  reasonDetails: string;
  type: OrderTypes.VisitFollowUpOrder;
  visitWithProviderId: number;
}

export interface BasicFollowUpOrder extends FollowUpOrder {
  problem: ProblemType;
  type: OrderTypes.BasicFollowUpOrder;
}

export interface GenericFollowUpOrder extends FollowUpOrder {
  question: string;
  type: OrderTypes.GenericFollowUpOrder;
}

export interface SurveyOrder extends BaseOrder {
  clinicalName: string;
  dueOn: string;
  expiresOn: string;
  orderedBy: string;
  surveyOrderEvents: OrderEvent[];
  type: OrderTypes.SurveyOrder;
  uniqueName: string;
}

export interface Contact {
  name: string;
  specialties: string[];
}

export class OrderContact implements Contact {
  name = '';
  specialties: string[] = [];
}

export interface OrderEvent {
  statusChange: string;
  updatedBy: string;
  createdAt: string;
}

export type Order =
  | VaccineOrderListItem
  | ProcedureOrder
  | ConsultOrder
  | LabOrder
  | VisitFollowUpOrder
  | BasicFollowUpOrder
  | GenericFollowUpOrder
  | SurveyOrder
  | BaseOrder;
