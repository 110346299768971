import { ControlValueAccessor } from '@angular/forms';
import { Checkbox } from 'primeng/checkbox';
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent() {
        this.plainLabel = false;
        this.disabled = false;
        this.required = false;
        this._value = false;
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(CheckboxComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                this.onChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    /* istanbul ignore next */
    CheckboxComponent.prototype.writeValue = function (value) {
        this._value = value;
    };
    /* istanbul ignore next */
    CheckboxComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /* istanbul ignore next */
    CheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /* istanbul ignore next */
    CheckboxComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        this.checkbox.disabled = isDisabled;
    };
    return CheckboxComponent;
}());
export { CheckboxComponent };
