import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AllergiesService } from '../../shared/allergies.service';
import { PatientAllergy } from '../../shared/allergies.type';

@Component({
  selector: 'omg-allergy-form',
  templateUrl: './allergy-form.component.html',
})
export class AllergyFormComponent implements OnInit, OnDestroy {
  @Input() allergyItem: PatientAllergy;
  @Input() isAddingAllergy = false;

  @Output() post = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() delete = new EventEmitter<number>();
  @Output() close = new EventEmitter<void>();
  @Output() reactionModelChange = new EventEmitter<string>();

  form: FormGroup;
  optionHighlight: string;
  autocompleteItems: Array<any> = [];
  attemptedSubmit = false;

  private unsubscribe = new Subject();

  constructor(
    private allergiesService: AllergiesService,
    private formBuilder: FormBuilder,
  ) {}

  get focusKey() {
    const id = this.allergyItem && this.allergyItem.id;
    return `patientAllergiesFocus-${id || 'create'}`;
  }

  ngOnInit() {
    this.buildForm();
    this.setFormValues(this.allergyItem);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getSearchResults(text: string) {
    if (text.length > 1) {
      this.optionHighlight = text;

      this.allergiesService
        .getSearchResults(text)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(autocompleteItems => {
          this.autocompleteItems = autocompleteItems.map(item =>
            this.addAbbreviationToName(item),
          );
        });
    }
  }

  closeForm() {
    this.markSubmitted(false);
    this.close.emit();
  }

  closeAndResetForm() {
    this.closeForm();
    this.form.reset();
    this.setFormValues(this.allergyItem);
    this.onReactionChange();
  }

  onPost() {
    if (this.form.valid) {
      this.post.emit(this.form.value);
      this.markSubmitted(false);
      this.closeAndResetForm();
    }
  }

  onUpdate() {
    if (this.form.valid) {
      this.update.emit(this.form.value);
      this.markSubmitted(false);
    }
  }

  onDelete() {
    this.delete.emit(this.form.value.id);
    this.markSubmitted(false);
  }

  onReactionChange() {
    this.reactionModelChange.emit(this.form.get('reaction').value);
  }

  markSubmitted(attemptedSubmit: boolean) {
    this.attemptedSubmit = attemptedSubmit;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      allergyId: new FormControl('', Validators.required),
      reaction: new FormControl(''),
      comment: new FormControl(''),
      id: new FormControl(-1),
    });
  }

  private setFormValues(allergyItem: PatientAllergy) {
    const defaults = allergyItem || ({} as any);
    const allergy = defaults.allergy || {};
    const allergyName = allergy.name || '';
    const allergyId = allergy.id || null;
    const reaction = defaults.reaction || '';
    const comment = defaults.comment || '';
    const id = defaults.id || -1;

    this.form.get('allergyId').setValue(allergyId);
    this.form.get('reaction').setValue(reaction);
    this.form.get('comment').setValue(comment);
    this.form.get('id').setValue(id);

    this.updateSelectItems(allergyName, allergyId);
  }

  private updateSelectItems(name: string, id: number) {
    this.autocompleteItems = [{ name, id }];
  }

  private addAbbreviationToName(item) {
    if (item.clinical_abbreviation) {
      item.name = item.name + ` (${item.clinical_abbreviation})`;
    }
    return item;
  }
}
