import { Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export var RenewalActionTypes;
(function (RenewalActionTypes) {
    RenewalActionTypes["LoadRenewalCart"] = "[Renewal] Load Renewal Cart";
    RenewalActionTypes["LoadRenewalCartSuccess"] = "[Renewal] Load Renewal Cart Success";
    RenewalActionTypes["LoadRenewalCartError"] = "[Renewal] Load Renewal Cart Error";
    RenewalActionTypes["RefreshRenewalCount"] = "[Renewal] Refresh Renewal Count";
    RenewalActionTypes["RefreshRenewalCountSuccess"] = "[Renewal] Refresh Renewal Count Success";
    RenewalActionTypes["RefreshRenewalCountError"] = "[Renewal] Refresh Renewal Count Error";
})(RenewalActionTypes || (RenewalActionTypes = {}));
var LoadRenewalCart = /** @class */ (function () {
    function LoadRenewalCart() {
        this.type = RenewalActionTypes.LoadRenewalCart;
    }
    return LoadRenewalCart;
}());
export { LoadRenewalCart };
var LoadRenewalCartSuccess = /** @class */ (function () {
    function LoadRenewalCartSuccess(payload) {
        this.payload = payload;
        this.type = RenewalActionTypes.LoadRenewalCartSuccess;
        this.error = false;
    }
    return LoadRenewalCartSuccess;
}());
export { LoadRenewalCartSuccess };
var LoadRenewalCartError = /** @class */ (function () {
    function LoadRenewalCartError(payload) {
        this.payload = payload;
        this.type = RenewalActionTypes.LoadRenewalCartError;
        this.error = true;
    }
    return LoadRenewalCartError;
}());
export { LoadRenewalCartError };
var RefreshRenewalCount = /** @class */ (function () {
    function RefreshRenewalCount() {
        this.type = RenewalActionTypes.RefreshRenewalCount;
    }
    return RefreshRenewalCount;
}());
export { RefreshRenewalCount };
var RefreshRenewalCountSuccess = /** @class */ (function () {
    function RefreshRenewalCountSuccess(payload) {
        this.payload = payload;
        this.type = RenewalActionTypes.RefreshRenewalCountSuccess;
        this.error = false;
    }
    return RefreshRenewalCountSuccess;
}());
export { RefreshRenewalCountSuccess };
var RefreshRenewalCountError = /** @class */ (function () {
    function RefreshRenewalCountError(payload) {
        this.payload = payload;
        this.type = RenewalActionTypes.RefreshRenewalCountError;
        this.error = true;
    }
    return RefreshRenewalCountError;
}());
export { RefreshRenewalCountError };
/**
 * Service
 */
var RenewalActions = /** @class */ (function () {
    function RenewalActions(store) {
        this.store = store;
    }
    RenewalActions.prototype.loadRenewalCart = function () {
        this.store.dispatch(new LoadRenewalCart());
    };
    RenewalActions.prototype.refreshRenewalCount = function () {
        this.store.dispatch(new RefreshRenewalCount());
    };
    RenewalActions.ngInjectableDef = i0.defineInjectable({ factory: function RenewalActions_Factory() { return new RenewalActions(i0.inject(i1.Store)); }, token: RenewalActions, providedIn: "root" });
    return RenewalActions;
}());
export { RenewalActions };
