import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';
import {
  EntityChangesPayload,
  EntityIdentifierPayload,
} from '@app/core/store/shared/entity-api-type';

import { Order } from '../shared/order.type';
import { mapOrdersToEntities, mapOrderToEntity } from '../store/order.mappers';
import { getCreateOrderApiPath, getOrderApiPath } from './order-utils';

export class OrderApiService extends PatientEntityApiService<Order> {
  constructor(api: ApiService, patientSelectors: PatientSelectors) {
    super(
      api,
      { basePath: `/v2/admin/patients/:patientId/patient_orders` },
      patientSelectors,
    );
  }

  withPatientId = <A>(
    switchMapCallback: (patientId: number) => Observable<A>,
  ) => {
    return this.patientSelectors.patientId.pipe(
      take(1),
      switchMap(switchMapCallback),
    );
  };

  getAll() {
    return super.getAll().pipe(map(response => mapOrdersToEntities(response)));
  }

  delete({ id, options }: EntityIdentifierPayload<Order>) {
    return this.withPatientId(patientId => {
      const orderPath = getOrderApiPath(options.type, patientId);
      return this.api.delete<Order>(`${orderPath}/${id}`).pipe(map(() => id));
    });
  }

  update({ id, changes, options }: EntityChangesPayload<Order>) {
    return this.withPatientId(patientId => {
      const orderPath = getOrderApiPath(options.type, patientId);
      return this.api.update<Order>(`${orderPath}/${id}`, changes, {}, true);
    });
  }

  save(order: Order) {
    return this.withPatientId(patientId => {
      const orderPath = getCreateOrderApiPath(order.type, patientId);
      return this.api.save<Order>(orderPath, order, {}, true).pipe(
        map(response =>
          mapOrderToEntity(
            // spread this since the backend doesn't keep type on this response
            { ...order, ...response },
          ),
        ),
      );
    });
  }
}
