import { formatFuzzyDate, FuzzyDatePrecision, toDate } from '@app/utils/dates';

import { newEntityId } from './health-maintenance.type';

import {
  HealthGoalActionAllowedResultsResponse,
  HealthGoalActionResponse,
  HealthGoalResponse,
  HealthGoalSaveErrorResponse,
  HealthGoalSaveRequest,
  HealthGoalScreeningResponse,
  HealthGoalScreeningSaveErrorResponse,
  HealthGoalScreeningSaveRequest,
  HealthGoalTypeResponse,
} from './health-maintenance-api.type';

import {
  HealthGoalAction,
  HealthGoalActionAllowedResults,
  HealthGoalErrors,
  HealthGoalForm,
  HealthGoalScreening,
  HealthGoalScreeningErrors,
  HealthGoalScreeningForm,
  HealthGoalType,
  HealthGoalWithScreenings,
} from './health-maintenance.type';

/* istanbul ignore next */
export const mapHealthGoalActionAllowesResultsResponse = (
  response: HealthGoalActionAllowedResultsResponse,
): HealthGoalActionAllowedResults => ({
  type: response.type,
  min: response.min,
  max: response.max,
  values: response.values,
});

/* istanbul ignore next */
export const mapHealthGoalActionResponse = (
  response: HealthGoalActionResponse,
): HealthGoalAction => ({
  id: response.id,
  name: response.name,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  uniqueName: response.unique_name,
  allowedResults: mapHealthGoalActionAllowesResultsResponse(
    response.allowed_results,
  ),
});

/* istanbul ignore next */
export const mapHealthGoalTypeResponse = (
  data: HealthGoalTypeResponse,
): HealthGoalType => ({
  id: data.id,
  name: data.name,
  healthGoalActions: data.health_goal_actions.map(mapHealthGoalActionResponse),
  decisionSupport: data.decision_support,
});

/* istanbul ignore next */
export const mapHealthGoalScreeningResponse = (
  response: HealthGoalScreeningResponse,
): HealthGoalScreening => ({
  date: new Date(response.date),
  dateFuzzy: response.date_fuzzy,
  datePrecision: response.date_precision as FuzzyDatePrecision,
  eventId: response.event && response.event.id,
  healthGoalId: response.health_goal_id,
  id: response.id,
  normalized: response.normalized,
  patientId: response.patient_id,
  patientSubmitted: response.patient_submitted,
  result: response.result,
  screeningHistoryId: response.screening_history_id,
  url: response.url,
});

/* istanbul ignore next */
export const mapHealthGoalResponse = (
  response: HealthGoalResponse,
): HealthGoalWithScreenings => ({
  id: response.id,
  comment: response.comment,
  declined: response.declined,
  dueAt: response.due_at && toDate(response.due_at),
  editedBy: response.edited_by,
  editedById: response.edited_by_id,
  healthGoalActionId:
    response.health_goal_action && response.health_goal_action.id,
  healthGoalTypeId: response.health_goal_type_id,
  indicated: response.indicated,
  patientId: response.patient_id,
  healthGoalHistoryId: response.health_goal_history_id,
  screeningHistory:
    response.screening_history &&
    response.screening_history.map(mapHealthGoalScreeningResponse),
  screeningHistoryIds:
    response.screening_history && response.screening_history.map(i => i.id),
  updatedAt: response.updated_at,
  url: response.url,
});

/* istanbul ignore next */
export const mapHealthGoalScreeningToSaveRequest = (
  form: HealthGoalScreeningForm,
): HealthGoalScreeningSaveRequest => {
  const fuzzyDate = formatFuzzyDate(form.dateFuzzy);
  return {
    date: fuzzyDate && fuzzyDate.date,
    date_fuzzy: fuzzyDate && fuzzyDate.dateFuzzy,
    date_precision: fuzzyDate ? fuzzyDate.precision : null,
    event: { id: form.eventId },
    health_goal_id:
      form.healthGoalId === newEntityId ? null : form.healthGoalId,
    id: form.id === newEntityId ? null : form.id,
    normalized: form.normalized,
    patient_id: form.patientId,
    patient_submitted: form.patientSubmitted,
    result: form.result,
  };
};

/* istanbul ignore next */
export const mapHealthGoalFormToSaveRequest = (
  form: HealthGoalForm,
): HealthGoalSaveRequest => ({
  id: form.id === newEntityId ? null : form.id,
  comment: form.comment,
  due_at: form.dueAt,
  declined: form.declined,
  health_goal_action: { id: form.healthGoalActionId },
  health_goal_type: { id: form.healthGoalTypeId },
  indicated: form.indicated,
  screening_history: (form.screeningHistory || []).map(
    mapHealthGoalScreeningToSaveRequest,
  ),
});

/* istanbul ignore next */
export const mapHealthGoalSaveErrorResponse = (
  error: HealthGoalSaveErrorResponse,
): HealthGoalErrors => ({
  comment: error.comment,
  declined: error.declined,
  dueAt: error.due_at,
  healthGoalActionId: error.health_goal_action,
  healthGoalTypeId: error.health_goal_type,
});

/* istanbul ignore next */
export const mapHealthGoalScreeningSaveErrorResponse = (
  error: HealthGoalScreeningSaveErrorResponse,
): HealthGoalScreeningErrors => ({
  id: error.id,
  dateFuzzy: error.date_fuzzy,
  reason: error.reason,
});
