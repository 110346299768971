import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '../../errors/error-handler.service';
import { PatientApiService } from '../shared/patient-api.service';
import {
  GET_PATIENT,
  GetPatient,
  GetPatientError,
  GetPatientSuccess,
  UPDATE_PATIENT,
  UpdatePatient,
  UpdatePatientError,
  UpdatePatientSuccess,
} from './patient.actions';
import { PatientSelectors } from './patient.selectors';

@Injectable()
export class PatientEffects {
  constructor(
    private action$: Actions,
    private patientApi: PatientApiService,
    private patientSelectors: PatientSelectors,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  getPatient$: Observable<Action> = this.action$.pipe(
    ofType<GetPatient>(GET_PATIENT),
    withLatestFrom(this.patientSelectors.patientId),
    filter(Boolean),
    filter(
      ([action, currentPatientId]: [GetPatient, number]) =>
        action.payload.id !== currentPatientId || action.payload.forceGet,
    ),
    switchMap(([action, currentPatientId]: [GetPatient, number]) =>
      this.patientApi.get(action.payload.id).pipe(
        map(patient => new GetPatientSuccess(patient)),
        catchError(error =>
          of(new GetPatientError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );

  @Effect()
  updatePatient$: Observable<Action> = this.action$.pipe(
    ofType<UpdatePatient>(UPDATE_PATIENT),
    map(action => action.payload),
    switchMap(payload =>
      this.patientApi.update(payload.id, payload.data).pipe(
        map(patient => new UpdatePatientSuccess(patient)),
        catchError(error =>
          of(new UpdatePatientError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );
}
