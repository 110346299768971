<div class="omg-popover"
     [ngClass]="placement"
     [ngStyle]="{ width: width + 'px' }">
  <div class="arrow"></div>

  <h3 class="popover-title"
      *ngIf="title">{{ title }}</h3>
  <div class="popover-content">
    <ng-container [ngSwitch]="renderMethod">
      <div *ngSwitchCase="'text'"
           [innerHTML]="content"></div>
      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
