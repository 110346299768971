import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
  FeatureFlagSelectors,
  Patient,
  PatientActions,
  PatientSelectors,
} from '@app/core';
import { patientPronouns } from '@app/core/patient/shared/patient-pronouns';
import { AutoCompleteComponent } from '@app/shared/components';

import { PatientInfo } from '../shared/patient-info.type';
import { mapToPatientInfo } from './../shared/patient-info-utils';

@Component({
  selector: 'omg-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInfoComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  patientInfo: Observable<PatientInfo>;
  pronounDropdownOptions: Observable<Array<any>>;

  @ViewChild('pronounDropdown') private ref: AutoCompleteComponent;

  constructor(
    private patientSelectors: PatientSelectors,
    private patientActions: PatientActions,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    // This will emit an empty patient until the actual patient is loaded
    this.patientInfo = merge(of(mapToPatientInfo()), this.selectPatientInfo());
    this.pronounDropdownOptions = this.selectPronounOptions();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  togglePronoun() {
    this.ref.toggle();
  }

  updatePronouns(pronoun: string) {
    this.patientSelectors.patientId
      .pipe(
        takeUntil(this.unsubscribe),
        map(patientId =>
          this.patientActions.updatePatient(patientId, { pronouns: pronoun }),
        ),
      )
      .subscribe();
  }

  private selectPatientInfo() {
    return this.patientSelectors.patient.pipe(
      filter(Boolean),
      withLatestFrom(
        this.patientSelectors.formattedAge,
        this.patientSelectors.isMinor,
      ),
      map(([patient, formattedAge, isMinor]: [Patient, string, boolean]) =>
        mapToPatientInfo(patient, formattedAge, isMinor),
      ),
      takeUntil(this.unsubscribe),
    );
  }

  private selectPronounOptions(): Observable<Array<any>> {
    return this.patientSelectors.patient.pipe(
      filter(Boolean),
      map((patient: Patient) =>
        Object.keys(patientPronouns)
          .filter(pronoun => pronoun !== patient.pronouns)
          .map(pronoun => ({
            name: patientPronouns[pronoun].label,
            id: pronoun,
          })),
      ),
      takeUntil(this.unsubscribe),
    );
  }
}
