import {
  InsightItemResponse,
  InsightMeasurementResponse,
  InsightMeasurementUnitResponse,
  InsightResponse,
  InsightTypeResponse,
} from './insights.response.type';
import {
  Insight,
  InsightMeasurement,
  InsightMeasurementUnit,
  InsightType,
} from './insights.type';

/* istanbul ignore next */
export const mapInsightItemResponseToEntity = (
  data: InsightItemResponse,
): Insight => ({
  itemId: data.item_id,
  itemName: data.item_name,
  itemType: data.item_type,
});

/* istanbul ignore next */
export const mapInsightResponseToEntity = (
  data: InsightResponse,
): Insight[] => [...data.insight_items.map(mapInsightItemResponseToEntity)];

/* istanbul ignore next */
export const mapInsightMeasurementUnitResponseToEntity = (
  data: InsightMeasurementUnitResponse,
): InsightMeasurementUnit => ({
  conversionFactor: data.conversion_factor,
  conversionOffset: data.conversion_offset,
  imperial: data.imperial,
  metric: data.metric,
});

/* istanbul ignore next */
export const mapInsightMeasurementToEntity = (
  data: InsightMeasurementResponse,
): InsightMeasurement => ({
  collectedAt: data.collected_at,
  isMetric: data.is_metric,
  measurementUnit: mapInsightMeasurementUnitResponseToEntity(
    data.measurement_unit,
  ),
  referenceRange: data.reference_range,
  value: data.value,
});

/* istanbul ignore next */
export const mapInsightTypeResponseToEntity = (
  data: InsightTypeResponse,
): InsightType => ({
  measurementTypeName: data.measurement_type_name,
  measurements: data.measurements.map(mapInsightMeasurementToEntity),
  measurementGroups: data.measurement_groups
    ? data.measurement_groups.map(mapInsightTypeResponseToEntity)
    : null,
});
