import { AnalyticsApiRequestData } from '@app/core/analytics/shared/analytics.type';

export const mapTrackEventPropertiesToAnalyticsData = (
  properties,
): AnalyticsApiRequestData => ({
  patient_id: properties.patientId || undefined,
  application: properties.application || undefined,
  type: properties.type || undefined,
  quantity: properties.quantity || undefined,
  unit: properties.unit || undefined,
  session_fingerprint: properties.sessionFingerprint || undefined,
  user_role: properties.userRole || undefined,
  workflow: properties.workflow || undefined,
  component: properties.component || undefined,
  subcomponent: properties.subComponent || undefined,
  subworkflow_id: properties.subWorkflowId || undefined,
  problem_type: properties.problemType || undefined,
  summary_id: properties.summaryId || undefined,
  note_id: properties.noteId || undefined,
  patient_timeline_post_id: properties.patientTimelinePostId || undefined,
  letter_id: properties.letterId || undefined,
  template_id: properties.templateId || undefined,
  template_name: properties.templateName || undefined,
  template_type: properties.templateType || undefined,
  medication_route_id: properties.medicationRouteId || undefined,
  is_overridden: properties.isOverridden || undefined,
  interacting_object_id: properties.interactingObjectId || undefined,
  interacting_object_type: properties.interactingObjectType || undefined,
  severity_level: properties.severityLevel || undefined,
  is_new: properties.isNew || undefined,
});
