var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEntityAdapter } from '@ngrx/entity';
import { getEntityMetadataInitialState, resetEntityMetadata, updateEntityMetadata, } from '@app/utils';
import { newEntityId } from '../shared/problems.type';
import { ProblemActionTypes } from './problems.actions';
export var problemsStatePath = 'problems';
export function selectProblemId(problem) {
    return problem.id;
}
export var adapter = createEntityAdapter({
    selectId: selectProblemId,
});
export var initialProblemsState = adapter.getInitialState(__assign({ loading: false, error: null, lastDeleted: null }, getEntityMetadataInitialState({})));
export function problemsReducer(state, action) {
    if (state === void 0) { state = initialProblemsState; }
    switch (action.type) {
        case ProblemActionTypes.GET_PROBLEMS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case ProblemActionTypes.GET_PROBLEMS_SUCCESS: {
            return adapter.addAll(action.payload, __assign({}, state, { loading: false, error: null }));
        }
        case ProblemActionTypes.GET_PROBLEMS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case ProblemActionTypes.SAVE_PROBLEM: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(newEntityId, { pending: true }, state));
        }
        case ProblemActionTypes.SAVE_PROBLEM_SUCCESS: {
            return adapter.addOne(action.payload, __assign({}, state, { loading: false, error: null }, updateEntityMetadata(newEntityId, { pending: false, error: null }, state)));
        }
        case ProblemActionTypes.SAVE_PROBLEM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(newEntityId, { pending: false, error: action.payload }, state));
        }
        case ProblemActionTypes.UPDATE_PROBLEM: {
            return __assign({}, state, { loading: true, error: null }, updateEntityMetadata(action.payload.problem.id, { pending: true }, state));
        }
        case ProblemActionTypes.UPDATE_PROBLEM_SUCCESS: {
            return __assign({}, adapter.updateOne({
                id: action.payload.id,
                changes: __assign({}, action.payload),
            }, state), { loading: false, error: null }, resetEntityMetadata(action.payload.id, state));
        }
        case ProblemActionTypes.UPDATE_PROBLEM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case ProblemActionTypes.RESOLVE_PROBLEM: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.problemId, { pending: true }, state));
        }
        case ProblemActionTypes.RESOLVE_PROBLEM_SUCCESS: {
            return __assign({}, adapter.updateOne({ id: action.payload.id, changes: __assign({}, action.payload) }, state), { loading: false, error: null }, resetEntityMetadata(action.payload.id, state));
        }
        case ProblemActionTypes.RESOLVE_PROBLEM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case ProblemActionTypes.REACTIVATE_PROBLEM: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.problemId, { pending: true }, state));
        }
        case ProblemActionTypes.REACTIVATE_PROBLEM_SUCCESS: {
            return __assign({}, adapter.updateOne({ id: action.payload.id, changes: __assign({}, action.payload) }, state), { loading: false, error: null }, resetEntityMetadata(action.payload.id, state));
        }
        case ProblemActionTypes.REACTIVATE_PROBLEM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case ProblemActionTypes.DELETE_PROBLEM: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.problemId, { pending: true }, state));
        }
        case ProblemActionTypes.DELETE_PROBLEM_SUCCESS: {
            return __assign({}, adapter.removeOne(action.payload.id, state), { loading: false, lastDeleted: action.payload }, resetEntityMetadata(action.payload.id, state));
        }
        case ProblemActionTypes.DELETE_PROBLEM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, {
                pending: false,
                error: action.payload,
            }, state));
        }
        case ProblemActionTypes.GET_PROBLEM_HISTORY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case ProblemActionTypes.GET_PROBLEM_HISTORY_SUCCESS: {
            return adapter.updateOne({
                id: action.payload.problemId,
                changes: {
                    history: action.payload,
                },
            }, __assign({}, state, { loading: false, error: null }));
        }
        case ProblemActionTypes.GET_PROBLEM_HISTORY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        default: {
            return __assign({}, state);
        }
    }
}
