import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AnalyticsActions,
  FeatureFlagSelectors,
  PatientSelectors,
} from '@app/core';
import { OrderTypes } from '@app/features/orders-list/shared/order-utils';
import { Order } from '@app/features/orders-list/shared/order.type';
import { OrderActions } from '@app/features/orders-list/store/order.actions';
import { AutoCompleteComponent, CollapseDirective } from '@app/shared';
import { dateStringNumericValidator } from '@app/shared/forms/validators/date-string-validator';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { ProblemsApiService } from '../../shared/problems-api.service';
import {
  mapProblemToForm,
  setIncludedInMedicalHistory,
} from '../../shared/problems-utils';
import {
  Problem,
  ProblemCodeLocation,
  ProblemHistory,
  ProblemType,
} from '../../shared/problems.type';
import { ProblemSelectors } from '../../store/problems.selectors';
import { ProblemHistoryComponent } from '../problem-history/problem-history.component';

interface ProblemItem {
  clinicalDescription: string;
  id: number;
}

interface ProblemCodeItem {
  code: string;
  label: string;
}

@Component({
  selector: 'omg-problem-form',
  templateUrl: './problem-form.component.html',
  styleUrls: ['./problem-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProblemFormComponent implements OnInit, OnChanges {
  @ViewChild('decisionSupportCollapse')
  decisionSupportCollapse: CollapseDirective;
  @ViewChild('problemField') private problemFieldRef: AutoCompleteComponent;
  @ViewChild(ProblemHistoryComponent) problemHistory: ProblemHistoryComponent;

  @Input() addProblemSessionId;
  @Input() showHistory = false;
  @Input() autoCheckInCreationEnabled: boolean;
  @Input() problem: Problem;
  @Input() isCustomProblem = false;
  @Output() save = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() delete = new EventEmitter();

  form: FormGroup;
  items$: Observable<ProblemItem[]>;
  problemCodes: Observable<ProblemCodeItem[]>;
  locations: Observable<any[]>;
  notificationText: string;
  clinicalDescription: string;
  clinicalAbbreviation: string;
  decisionSupport: string;
  attemptedSubmit = false;
  problemLabel: string;
  history: Observable<ProblemHistory>;
  isHistoryExpanded = new Subject();
  addProblemToNote = false;
  lastProblemItem: ProblemItem;
  hasInternalOrders$: Observable<boolean>;
  isMinor: boolean;

  private unsubscribeFormListener = new Subject();

  searchFn = (term: string, item: any) =>
    !!(item.clinicalDescription || item.clinicalAbbreviation || item.tags);

  problemCodeSearchFn = (term: string, item: any) =>
    !!(item.clinicalDescription || item.code);

  constructor(
    private formBuilder: FormBuilder,
    private problemsApi: ProblemsApiService,
    private analyticsActions: AnalyticsActions,
    private problemSelectors: ProblemSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
    private orderActions: OrderActions,
    private patientSelectors: PatientSelectors,
  ) {
    this.buildForm();
    this.resetForm();
    this.resetAddProblemToNote();
  }

  ngOnInit() {
    this.setupSelectors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.problem) {
      this.resetForm();
      this.selectProblem(changes.problem.currentValue.problemType);
      this.resetAddProblemToNote();
    }
  }

  unsubscribeFromFormListener() {
    // unsubscribe from any current listener
    this.unsubscribeFormListener.next();
    this.unsubscribeFormListener.complete();
    // create a new subject to be able to unsubscribe from a new listener
    this.unsubscribeFormListener = new Subject();
  }

  resetAddProblemToNote() {
    this.addProblemToNote = false;
    this.unsubscribeFromFormListener();

    const {
      includedInMedicalHistory,
      ...allOtherControls
    } = this.form.controls;
    const formControlValueChanges = Object.values(allOtherControls).map(
      formControl => formControl.valueChanges,
    );

    merge(...formControlValueChanges)
      .pipe(takeUntil(this.unsubscribeFormListener))
      .subscribe(change => {
        if (change) {
          this.addProblemToNote = true;
        }
      });
  }

  searchProblems(term: string) {
    this.items$ = this.problemsApi.queryProblemSearch(term).pipe(take(1));
  }

  searchProblemCodes(term: string) {
    this.problemCodes = this.problemsApi
      .queryProblemCodeSearch(term)
      .pipe(take(1));
  }

  selectProblem(problemType: ProblemType) {
    const includedInMedicalHistory = this.problem
      ? this.problem.includedInMedicalHistory
      : setIncludedInMedicalHistory(problemType);
    this.setLocationItems(problemType && problemType.locations);
    this.clinicalDescription = problemType && problemType.clinicalDescription;
    this.clinicalAbbreviation = problemType && problemType.clinicalAbbreviation;
    this.decisionSupport = problemType && problemType.decisionSupport;
    this.setNotificationText(problemType);
    this.form.patchValue({
      includedInMedicalHistory,
    });

    if (
      !problemType ||
      !problemType.locations ||
      !problemType.locations.length
    ) {
      this.form.get('locationId').disable();
    } else {
      this.form.get('locationId').enable();
    }
  }

  onProblemChange(selectedProblem: ProblemType) {
    if (!selectedProblem) {
      this.setProblemItems(null);
    } else {
      this.setProblemItems(<ProblemItem>{
        id: selectedProblem.id,
        clinicalDescription: selectedProblem.clinicalDescription,
      });
    }

    this.selectProblem(selectedProblem);
    this.trackProblemFieldEvent('Problem Field');
  }

  onProblemCodeChange(result: any) {
    if (!result) {
      this.setProblemCodeItems(null);
    }
    /* Workaround - when choosing an item from chrome's autocomplete dropdown. */
    if (result && result.id) {
      this.form.patchValue({
        problemId: result.id,
        id: this.problem ? this.problem.id : null,
      });
    }
    this.trackProblemFieldEvent('Problem Code Field');
  }

  onCustomDescriptionChange() {
    this.form.patchValue({ includedInMedicalHistory: true });
    this.trackProblemFieldEvent('Custom Problem Field');
  }

  trackProblemFieldEvent(subComponent: string) {
    this.analyticsActions.trackEvent('Problems Field Changed', {
      workflow: 'Charting',
      component: 'Problems',
      subWorkflowId: this.addProblemSessionId,
      subComponent,
    });
  }

  saveProblem() {
    if (this.form.invalid) {
      this.attemptedSubmit = true;
      return;
    }

    this.save.emit({
      ...this.form.value,
      addProblemToNote: this.addProblemToNote,
    });
  }

  closeForm() {
    if (this.problemHistory) {
      this.problemHistory.setExpanded(false);
    }
    this.resetForm();
    this.close.emit();
  }

  toggleCustomProblem() {
    this.form.enable();
    this.form.patchValue({ isCustom: !this.form.value.isCustom });
    if (!this.form.value.isCustom) {
      this.form.get('customProblemDescription').disable();
      this.form.get('problemCode').disable();
    } else {
      this.form.get('locationId').disable();
    }
    this.selectProblem(null);
    this.setProblemItems(null);
    this.setProblemCodeItems(null);
    this.form.get('problemId').reset();
    this.form.get('includedInMedicalHistory').reset();
    this.form.get('basicFollowUpOrder').reset();
    this.form.get('customProblemDescription').reset();
    this.form.get('problemCode').reset();
    this.form.get('locationId').reset();
    this.trackProblemClickEvent();
  }

  onDelete() {
    this.delete.emit(this.problem.id);
  }

  setNotificationText(problemType: ProblemType) {
    this.notificationText = null;
    if (!problemType) {
      return;
    }
    const autoCreations = problemType.autoCreations;
    const configs = autoCreations.find(
      autoCreation => autoCreation.basicFollowUpOrder !== null,
    );

    if (configs && (!this.problem || !this.problem.id)) {
      const intervalValue = parseInt(
        configs.basicFollowUpOrder.intervalValue.toString(),
        10,
      );
      const intervalType = `${configs.basicFollowUpOrder.intervalType +
        (intervalValue > 1 ? 's' : '')}`;

      this.patientSelectors.isMinor.pipe(take(1)).subscribe(isMinor => {
        this.form.patchValue({ basicFollowUpOrder: !isMinor });
      });

      if (intervalType === 'day' && intervalValue === 0) {
        this.notificationText =
          'Patient will be sent a notification to check in today.';
      } else {
        this.notificationText = `Patient will be sent a notification to check in ${intervalValue} ${intervalType} from now.`;
      }
    } else {
      this.form.patchValue({ basicFollowUpOrder: false });
    }
    return problemType;
  }

  resetForm() {
    if (this.decisionSupportCollapse) {
      this.decisionSupportCollapse.collapse();
    }

    const formValue = mapProblemToForm(this.problem);
    const locations = this.problem ? this.problem.problemType.locations : null;
    const clinicalDescription = this.problem
      ? this.problem.problemType.clinicalDescription
      : null;
    const decisionSupport = this.problem
      ? this.problem.problemType.decisionSupport
      : null;
    const customDescription = this.problem
      ? this.problem.customProblemTypeCodeDescription
      : null;
    const problem =
      clinicalDescription && formValue.id
        ? <ProblemItem>{ clinicalDescription, id: formValue.problemId }
        : null;
    const problemCodeItems =
      customDescription && formValue.problemCode
        ? [
            {
              label: `${customDescription} ${formValue.problemCode}`,
              code: formValue.problemCode,
            },
          ]
        : null;

    this.setProblemItems(problem);
    this.setProblemCodeItems(problemCodeItems);
    this.setLocationItems(locations);
    this.clinicalDescription = clinicalDescription;
    this.decisionSupport = decisionSupport;
    this.notificationText = null;
    this.form.reset(formValue);
    this.form.enable();

    if (this.form.get('isCustom').value) {
      this.form.get('locationId').disable();
    } else {
      this.form.get('customProblemDescription').disable();
      this.form.get('problemCode').disable();
      if (!locations) {
        this.form.get('locationId').disable();
      }
    }
    this.attemptedSubmit = false;
  }

  clearProblemId() {
    if (this.form.get('problemId').value !== null) {
      this.setProblemItems(null);
      this.form.get('problemId').setValue(null);
      this.problemFieldRef.close();
    }
  }

  restoreProblemId() {
    if (this.form.get('problemId').value === null) {
      this.setProblemItems(this.lastProblemItem);
      this.form.get('problemId').reset(this.lastProblemItem.id);
      this.form.get('problemId').enable();
    }
  }

  createNewProcedureOrder() {
    let problemTypeId;

    if (this.lastProblemItem && this.lastProblemItem.id) {
      // Non Persisted / Changed Problem Type
      problemTypeId = this.lastProblemItem.id;
    } else if (this.problem && this.problem.problemType) {
      // Persisted Problem Type
      problemTypeId = this.problem.problemType.id;
    }

    this.orderActions.create(<Order>(<any>{
      type: OrderTypes.ProcedureOrder,
      indication: {
        problem_type_id: problemTypeId,
      },
    }));
  }

  private trackProblemClickEvent() {
    this.analyticsActions.trackEvent('Standard Problem Clicked', {
      workflow: 'Charting',
      component: 'Problems',
      subComponent: 'Standard Problem Button',
      subWorkflowId: this.addProblemSessionId,
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      onset: new FormControl('', [
        Validators.maxLength(10),
        dateStringNumericValidator,
      ]),
      problemId: new FormControl(null, Validators.required),
      resolution: new FormControl('', [
        Validators.maxLength(10),
        dateStringNumericValidator,
      ]),
      briefComment: new FormControl(''),
      summary: new FormControl(),
      assessmentAndPlan: new FormControl(),
      includedInMedicalHistory: new FormControl(null),
      basicFollowUpOrder: new FormControl(),
      customProblemDescription: new FormControl(null, Validators.required),
      problemCode: new FormControl(null, Validators.required),
      locationId: new FormControl(null, Validators.required),
      isCustom: new FormControl(false),
      id: new FormControl(null),
    });
  }

  private setProblemItems(item: ProblemItem) {
    if (item) {
      this.items$ = of([item]);
      this.lastProblemItem = item;
    } else {
      this.items$ = of(null);
    }
  }

  private setProblemCodeItems(items: ProblemCodeItem[]) {
    if (items) {
      this.problemCodes = of(items);
    } else {
      this.problemCodes = of(null);
    }
  }

  private setLocationItems(locations: ProblemCodeLocation[]) {
    if (locations) {
      this.locations = of(locations);
    } else {
      this.locations = of(null);
    }
  }

  private setupSelectors() {
    if (this.problem) {
      this.history = this.problemSelectors.problemHistory(this.problem.id);
    }

    this.hasInternalOrders$ = this.featureFlagSelectors
      .featureEnabled('new_1life_internal_procedure_orders')
      .pipe(
        filter(Boolean),
        take(1),
      );
  }
}
