var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEntityAdapter } from '@ngrx/entity';
import { sortBy } from 'lodash';
import { SummaryActionTypes } from './summaries.actions';
export var summariesStatePath = 'summaries';
export function selectSummaryId(summary) {
    return summary.id;
}
export var adapter = createEntityAdapter({
    selectId: selectSummaryId,
});
export var initialSummariesState = adapter.getInitialState({
    loading: false,
    error: null,
    currentSummaryId: null,
    refreshTimeline: false,
    closeTimelineItem: false,
});
export var selectEntities = adapter.getSelectors().selectEntities;
export var getCurrentSummaryId = function (state) {
    if (state === void 0) { state = initialSummariesState; }
    return state.currentSummaryId;
};
export function summariesReducer(state, action) {
    if (state === void 0) { state = initialSummariesState; }
    switch (action.type) {
        case SummaryActionTypes.LOAD_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LOAD_SUMMARY_SUCCESS: {
            return adapter.upsertOne(action.payload, __assign({}, state, { loading: false, error: null, currentSummaryId: action.payload.id }));
        }
        case SummaryActionTypes.LOAD_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.SAVE_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.SAVE_SUMMARY_SUCCESS: {
            return adapter.addOne(action.payload, __assign({}, state, { loading: false, error: null, currentSummaryId: action.payload.id }));
        }
        case SummaryActionTypes.SAVE_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.UPDATE_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.UPDATE_TOTAL_COMMENT_COUNT: {
            return adapter.updateOne(action.payload, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.UPDATE_SUMMARY_SUCCESS: {
            /* Note: updateOne may cause bugs with autosave since
             * we replace the whole summary entity and not individual
             * properties.
             */
            return adapter.updateOne(action.payload, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.UPDATE_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.SIGN_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.SIGN_SUMMARY_SUCCESS: {
            return adapter.updateOne(action.payload, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.SIGN_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.DELETE_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.DELETE_SUMMARY_SUCCESS: {
            return adapter.removeOne(action.payload.id, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.DELETE_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.REDACT_SUMMARY: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.REDACT_SUMMARY_SUCCESS: {
            return adapter.updateOne(action.payload, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.REDACT_SUMMARY_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_PATIENT_ALLERGIES: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_PATIENT_ALLERGIES_SUCCESS: {
            var changes = __assign({}, action.payload, { patientAllergies: sortBy(action.payload.patientAllergies, ['name']) });
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: changes,
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_PATIENT_ALLERGIES_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_PROBLEMS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_PROBLEMS_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_PROBLEMS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_ASSESSED_PROBLEMS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_ASSESSED_PROBLEMS_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_ASSESSED_PROBLEMS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_HEALTH_GOALS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_HEALTH_GOALS_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_HEALTH_GOALS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_HEALTH_BACKGROUND: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_HEALTH_BACKGROUND_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_HEALTH_BACKGROUND_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_MEDICATIONS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_MEDICATIONS_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_MEDICATIONS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.LINK_VACCINATIONS: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.LINK_VACCINATIONS_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.LINK_VACCINATIONS_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.UNLINK: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.UNLINK_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: __assign({}, action.payload),
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.UNLINK_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case SummaryActionTypes.SET_CURRENT_SUMMARY_ID: {
            return __assign({}, state, { currentSummaryId: action.payload });
        }
        case SummaryActionTypes.REFRESH_TIMELINE: {
            return __assign({}, state, { refreshTimeline: true });
        }
        case SummaryActionTypes.REFRESH_TIMELINE_DONE: {
            return __assign({}, state, { refreshTimeline: false });
        }
        case SummaryActionTypes.CLOSE_TIMELINE_ITEM: {
            return __assign({}, state, { closeTimelineItem: true });
        }
        case SummaryActionTypes.CLOSE_TIMELINE_ITEM_DONE: {
            return __assign({}, state, { closeTimelineItem: false });
        }
        case SummaryActionTypes.CLOSE_WORKSPACE_ITEM: {
            return __assign({}, state, { currentSummaryId: null });
        }
        case SummaryActionTypes.SAVE_ADDENDUM: {
            return __assign({}, state, { loading: true, error: null });
        }
        case SummaryActionTypes.SAVE_ADDENDUM_SUCCESS: {
            return adapter.updateOne({
                id: state.currentSummaryId,
                changes: {
                    summaryAddendums: state.entities[state.currentSummaryId].summaryAddendums.concat([
                        __assign({}, action.payload),
                    ]),
                },
            }, __assign({}, state, { loading: false, error: null }));
        }
        case SummaryActionTypes.SAVE_ADDENDUM_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        default: {
            return __assign({}, state);
        }
    }
}
