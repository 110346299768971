var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DatePipe as NgDatePipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { DateFormats } from '@app/utils/dates/date-formats';
var DatePipe = /** @class */ (function (_super) {
    __extends(DatePipe, _super);
    function DatePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatePipe.prototype.transform = function (value, format, timezone, local) {
        return _super.prototype.transform.call(this, value, this.mapFormat(format), timezone, local);
    };
    DatePipe.prototype.mapFormat = function (format) {
        switch (format) {
            case '2y':
                return DateFormats['2y'];
            case '4y':
                return DateFormats['4y'];
            case 'withoutDay4y':
                return DateFormats['withoutDay4y'];
            case 'withTime2y':
                return DateFormats['withTime2y'];
            case 'withTime4y':
                return DateFormats['withTime4y'];
            case '12Time':
                return DateFormats['12Time'];
            case '24Time':
                return DateFormats['24Time'];
            default:
                return format;
        }
    };
    return DatePipe;
}(NgDatePipe));
export { DatePipe };
