import { EventEmitter, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { fuzzyDateValidator } from '@app/shared';
import { VaccinationsApiService } from '../../shared';
var VaccineFormComponent = /** @class */ (function () {
    function VaccineFormComponent(vaccinationsApi) {
        this.vaccinationsApi = vaccinationsApi;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
    }
    Object.defineProperty(VaccineFormComponent.prototype, "showGivenOnErrors", {
        get: function () {
            var fieldControl = this.form.get('givenOn');
            return !!fieldControl.touched && fieldControl.status !== 'VALID';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VaccineFormComponent.prototype, "showGivenOnRequiredError", {
        get: function () {
            return this.form.get('givenOn').hasError('required');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VaccineFormComponent.prototype, "showGivenOnFuzzyDateError", {
        get: function () {
            return (!this.showGivenOnRequiredError &&
                this.form.get('givenOn').hasError('dateFuzzy'));
        },
        enumerable: true,
        configurable: true
    });
    VaccineFormComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    VaccineFormComponent.prototype.onSave = function () {
        this.setHiddenFormValues();
        this.save.emit(this.form.value);
        this.resetForm();
    };
    VaccineFormComponent.prototype.onCancel = function () {
        this.cancel.emit();
        this.resetForm();
    };
    VaccineFormComponent.prototype.getSearchResults = function (text) {
        this.autocompleteItems =
            text && text.length > 1
                ? this.vaccinationsApi.getSearchResults(text)
                : of([]);
    };
    VaccineFormComponent.prototype.setVaccine = function (vaccineData) {
        this.form.get('vaccine').patchValue(vaccineData);
    };
    VaccineFormComponent.prototype.buildForm = function () {
        this.form = new FormGroup({
            vaccineId: new FormControl(null, Validators.required),
            step: new FormControl(),
            givenOn: new FormControl(null, {
                validators: [Validators.required, fuzzyDateValidator],
            }),
            comments: new FormControl(),
            // hidden fields used for api request
            vaccine: new FormControl(),
            givenAt: new FormControl(),
        });
        this.resetForm();
    };
    VaccineFormComponent.prototype.setHiddenFormValues = function () {
        this.form.get('givenAt').patchValue('Other Facility');
    };
    VaccineFormComponent.prototype.resetForm = function () {
        this.form.reset();
        this.form.markAsPristine();
    };
    return VaccineFormComponent;
}());
export { VaccineFormComponent };
