import { OnChanges, SimpleChanges, } from '@angular/core';
import { CollapseProviderDirective } from './collapse-provider.directive';
var CollapseToggleDirective = /** @class */ (function () {
    function CollapseToggleDirective(collapseProviderDirective) {
        this.collapseProviderDirective = collapseProviderDirective;
        // tslint:disable-next-line no-input-rename
        this.toggleAction = null;
        this.setProvider();
    }
    CollapseToggleDirective.prototype.ngOnChanges = function (changes) {
        var collapseProvider = changes.collapseProvider;
        if (collapseProvider) {
            this.setProvider();
        }
    };
    CollapseToggleDirective.prototype.setProvider = function () {
        this.collapseProvider =
            this.collapseProvider || this.collapseProviderDirective;
    };
    Object.defineProperty(CollapseToggleDirective.prototype, "toggleClass", {
        get: function () {
            return !this.toggleAction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollapseToggleDirective.prototype, "toggleCollapseClass", {
        get: function () {
            return this.toggleAction === 'collapse';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollapseToggleDirective.prototype, "toggleExpandClass", {
        get: function () {
            return this.toggleAction === 'expand';
        },
        enumerable: true,
        configurable: true
    });
    CollapseToggleDirective.prototype.onClick = function (event) {
        if (this.ignoreToggleAction()) {
            return;
        }
        this.collapseProvider.collapse.toggle();
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
    };
    CollapseToggleDirective.prototype.ignoreToggleAction = function () {
        var expanded = this.collapseProvider.expanded;
        if ((this.toggleAction === 'expand' && expanded) ||
            (this.toggleAction === 'collapse' && !expanded)) {
            return true;
        }
        return false;
    };
    return CollapseToggleDirective;
}());
export { CollapseToggleDirective };
