import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { AppNavbarComponent } from './components/app-navbar/app-navbar.component';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import { LoginComponent } from './components/login/login.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ProfileGuard } from './shared/profile.guard';

const components = [
  AppShellComponent,
  AppNavbarComponent,
  LoginComponent,
  ScheduleComponent,
  UnauthorizedComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [...components],
  declarations: [...components],
  providers: [ProfileGuard],
})
export class AppShellModule {}
