var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// NOTE: These are global config objects that get injected
// - During build time via Webpack's DefinePlugin
// - During test via Jest global test setup
var envConfig = ENV_CONFIG;
var codeVersion = CODE_VERSION;
// Extract settings from global env config
var appSettings = envConfig.app;
var defaultPollingFrequency = 10000;
var versionChecker = appSettings.versionChecker || {};
var versionCheckerEnabled = !!versionChecker.targetUrl;
var rollbarConfig = appSettings.rollbar || {};
// Strongly typed application enivronment settings
export var environmentConfig = {
    codeVersion: codeVersion,
    versionChecker: {
        enabled: versionCheckerEnabled,
        targetUrl: versionChecker.targetUrl,
        pollingFrequency: ((versionChecker.pollingFequency || defaultPollingFrequency)),
    },
    awsBucket: appSettings.awsBucket,
    mixPanel: {
        token: appSettings.mixpanelToken,
        application: 'onelife-ui',
    },
    api: { baseUrl: appSettings.api.baseUrl },
    search: {
        host: appSettings.search.host,
        indexes: {
            allergies: appSettings.search.indexes.allergies,
            filing_templates: appSettings.search.indexes.filing_templates,
            lab_order_types: appSettings.search.indexes.lab_order_types,
            health_goal_types: appSettings.search.indexes.health_goal_types,
            medication_routes: appSettings.search.indexes.medication_routes,
            message_templates: appSettings.search.indexes.message_templates,
            note_templates: appSettings.search.indexes.note_templates,
            problem_codes: appSettings.search.indexes.problem_codes,
            problem_types: appSettings.search.indexes.problem_types,
            task_assignees: appSettings.search.indexes.task_assignees,
            vaccines: appSettings.search.indexes.vaccines,
        },
    },
    adminApp: { host: appSettings.adminApp.host },
    legacyUi: {
        host: appSettings.legacyUi.host,
    },
    measurementsUi: {
        host: appSettings.measurementsUi.host || '',
        version: appSettings.measurementsUi.version || '',
    },
    oauth2: {
        providerUrl: appSettings.oauth2.providerUrl,
        clientId: appSettings.oauth2.clientId,
    },
    googleMaps: appSettings.googleMaps,
    pusher: {
        pusher_key: appSettings.pusher.pusher_key,
        pusher_encrypted: appSettings.pusher.pusher_encrypted,
    },
    rollbar: {
        enabled: rollbarConfig.enabled || false,
        accessToken: rollbarConfig.accessToken,
        serverAccessToken: rollbarConfig.serverAccessToken,
        captureUncaught: rollbarConfig.captureUncaught || true,
        autoInstrument: rollbarConfig.autoInstrument || true,
        captureUnhandledRejections: rollbarConfig.captureUnhandledRejections || true,
        publicPath: rollbarConfig.publicPath,
        payload: {
            environment: rollbarConfig.payload && rollbarConfig.payload.environment,
            client: {
                javascript: __assign({ source_map_enabled: true, guess_uncaught_frames: true }, (CODE_VERSION ? { code_version: CODE_VERSION } : {})),
            },
        },
    },
};
