import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { PopoverPortalComponent } from './popover-portal.component';
import { PopoverTriggerDirective } from './popover-trigger.directive';
import { PopoverComponent } from './popover.component';
import { PopoverService } from './popover.service';

const cdkModules = [OverlayModule, PortalModule];
const components = [PopoverPortalComponent, PopoverComponent];
const entryComponents = [PopoverPortalComponent];
const directives = [PopoverTriggerDirective];
const providers = [PopoverService];

@NgModule({
  imports: [CommonModule, ...cdkModules],
  declarations: [...components, ...directives],
  exports: [...cdkModules, ...components, ...directives],
  providers: [...providers],
  entryComponents: [...entryComponents],
})
export class PopoverModule {}
