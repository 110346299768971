import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HealthGoalType } from '../../shared/health-maintenance.type';

@Component({
  selector: 'omg-health-goal-tips',
  templateUrl: './health-goal-tips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthGoalTipsComponent {
  @Input() healthGoalType: HealthGoalType;
}
