var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Directionality } from '@angular/cdk/bidi';
import { InjectionToken, Injector, OnDestroy, TemplateRef, } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { Overlay, OverlayConfig, } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal, } from '@angular/cdk/portal';
import { DialogConfig } from './dialog-config';
import { DialogContainer } from './dialog-container';
import { DialogRef } from './dialog-ref';
export var OMG_DIALOG_DATA = new InjectionToken('OMG_DIALOG_DATA');
var buildDefaultConfig = function () { return (__assign({}, new DialogConfig())); };
var ɵ0 = buildDefaultConfig;
var applyConfigDefaults = function (config, defaultOptions) { return (__assign({}, defaultOptions, config)); };
var ɵ1 = applyConfigDefaults;
/**
 * Service to open modal dialogs.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://material.angular.io/components/dialog/overview
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog.ts
 */
var DialogService = /** @class */ (function () {
    function DialogService(injector, overlay) {
        this.injector = injector;
        this.overlay = overlay;
    }
    DialogService.prototype.ngOnDestroy = function () { };
    DialogService.prototype.open = function (componentOrTemplateRef, config) {
        config = applyConfigDefaults(config, buildDefaultConfig());
        var overlayRef = this.createOverlay(config);
        var dialogContainer = this.attachDialogContainer(overlayRef, config);
        var dialogRef = this.attachDialogContent(componentOrTemplateRef, dialogContainer, overlayRef, config);
        return dialogRef;
    };
    /**
     * Creates the overlay into which the dialog will be loaded.
     * @param config The dialog configuration.
     * @returns A promise resolving to the OverlayRef for the created overlay.
     */
    DialogService.prototype.createOverlay = function (config) {
        var overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    };
    /**
     * Creates an overlay config from a dialog config.
     * @param dialogConfig The dialog configuration.
     * @returns The overlay configuration.
     */
    DialogService.prototype.getOverlayConfig = function (dialogConfig) {
        var state = new OverlayConfig({
            positionStrategy: this.overlay.position().global(),
            scrollStrategy: dialogConfig.scrollStrategy,
            panelClass: dialogConfig.panelClass,
            hasBackdrop: dialogConfig.hasBackdrop,
            direction: dialogConfig.direction,
            minWidth: dialogConfig.minWidth,
            minHeight: dialogConfig.minHeight,
            maxWidth: dialogConfig.maxWidth,
            maxHeight: dialogConfig.maxHeight,
            disposeOnNavigation: dialogConfig.closeOnNavigation,
        });
        if (dialogConfig.backdropClass) {
            state.backdropClass = dialogConfig.backdropClass;
        }
        return state;
    };
    /**
     * Attaches an DialogContainer to a dialog's already-created overlay.
     * @param overlay Reference to the dialog's underlying overlay.
     * @param config The dialog configuration.
     * @returns A promise resolving to a ComponentRef for the attached container.
     */
    DialogService.prototype.attachDialogContainer = function (overlayRef, config) {
        var userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
        var injector = new PortalInjector(userInjector || this.injector, new WeakMap([[DialogConfig, config]]));
        var containerPortal = new ComponentPortal(DialogContainer, config.viewContainerRef, injector);
        var containerRef = overlayRef.attach(containerPortal);
        return containerRef.instance;
    };
    /**
     * Attaches the user-provided component to the already-created DialogContainer.
     * @param componentOrTemplateRef The type of component being loaded into the dialog,
     *     or a TemplateRef to instantiate as the content.
     * @param dialogContainer Reference to the wrapping DialogContainer.
     * @param overlayRef Reference to the overlay in which the dialog resides.
     * @param config The dialog configuration.
     * @returns A promise resolving to the DialogRef that should be returned to the user.
     */
    DialogService.prototype.attachDialogContent = function (componentOrTemplateRef, dialogContainer, overlayRef, config) {
        // Create a reference to the dialog we're creating in order to give the user a handle
        // to modify and close it.
        var dialogRef = new DialogRef(overlayRef, dialogContainer, config.id);
        // When the dialog backdrop is clicked, we want to close it.
        if (config.hasBackdrop) {
            overlayRef.backdropClick().subscribe(function () {
                if (!dialogRef.disableClose) {
                    dialogRef.close();
                }
            });
        }
        if (componentOrTemplateRef instanceof TemplateRef) {
            dialogContainer.attachTemplatePortal(new TemplatePortal(componentOrTemplateRef, null, {
                $implicit: config.data,
                dialogRef: dialogRef,
            }));
        }
        else {
            var injector = this.createInjector(config, dialogRef, dialogContainer);
            var contentRef = dialogContainer.attachComponentPortal(new ComponentPortal(componentOrTemplateRef, undefined, injector));
            dialogRef.componentInstance = contentRef.instance;
        }
        dialogRef
            .updateSize(config.width, config.height)
            .updatePosition(config.position);
        return dialogRef;
    };
    /**
     * Creates a custom injector to be used inside the dialog. This allows a component loaded inside
     * of a dialog to close itself and, optionally, to return a value.
     * @param config Config object that is used to construct the dialog.
     * @param dialogRef Reference to the dialog.
     * @param container Dialog container element that wraps all of the contents.
     * @returns The custom injector that can be used inside the dialog.
     */
    DialogService.prototype.createInjector = function (config, dialogRef, dialogContainer) {
        var userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
        // Instantiate new WeakMap for our custom injection tokens
        var injectionTokens = new WeakMap([
            [DialogContainer, dialogContainer],
            [OMG_DIALOG_DATA, config.data],
            [DialogRef, dialogRef],
        ]);
        if (config.direction &&
            (!userInjector ||
                !userInjector.get(Directionality, null))) {
            injectionTokens.set(Directionality, {
                value: config.direction,
                change: observableOf(),
            });
        }
        // Instantiate new PortalInjector
        return new PortalInjector(userInjector || this.injector, injectionTokens);
    };
    return DialogService;
}());
export { DialogService };
export { ɵ0, ɵ1 };
