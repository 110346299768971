/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../screening-item/screening-item.component.ngfactory";
import * as i2 from "../screening-item/screening-item.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/collapse/collapse.directive";
import * as i6 from "../../../../shared/components/collapse/collapse-provider.directive";
import * as i7 from "../../../../shared/components/collapse/collapsed.component.ngfactory";
import * as i8 from "../../../../shared/components/collapse/collapsed.component";
import * as i9 from "../../../../shared/components/button/button.component.ngfactory";
import * as i10 from "../../../../shared/components/collapse/collapse-toggle.directive";
import * as i11 from "../../../../shared/components/button/button.component";
import * as i12 from "../../../../shared/components/collapse/expanded.component.ngfactory";
import * as i13 from "../../../../shared/components/collapse/expanded.component";
import * as i14 from "../screening-form/screening-form.component.ngfactory";
import * as i15 from "../screening-form/screening-form.component";
import * as i16 from "./screening-history.component";
import * as i17 from "../../../../core/patient/store/patient.selectors";
var styles_ScreeningHistoryComponent = [];
var RenderType_ScreeningHistoryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScreeningHistoryComponent, data: {} });
export { RenderType_ScreeningHistoryComponent as RenderType_ScreeningHistoryComponent };
function View_ScreeningHistoryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No screening history for this health goal. "]))], null, null); }
function View_ScreeningHistoryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-screening-item", [["class", "row"], ["om-layout-gutter", ""]], null, null, null, i1.View_ScreeningItemComponent_0, i1.RenderType_ScreeningItemComponent)), i0.ɵdid(1, 114688, null, 0, i2.ScreeningItemComponent, [i3.Store], { healthGoalType: [0, "healthGoalType"], screening: [1, "screening"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.healthGoalType; var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ScreeningHistoryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningHistoryComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningHistoryComponent_3)), i0.ɵdid(4, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_v.context.ngIf == null) ? null : _v.context.ngIf.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf; var currVal_2 = _co.trackByFn; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_ScreeningHistoryComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { newFormCollapse: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "data-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Screening History"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ScreeningHistoryComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 14, "omg-collapse", [], [[2, "om-collapse", null], [1, "om-collapse", 0], [2, "om-collapse-expanded", null], [2, "-disabled", null]], [[null, "expandedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandedChange" === en)) {
        var pd_0 = (_co.onNewScreeningFormExpanded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 81920, [[1, 4], ["newScreeningCollapse", 4]], 0, i5.CollapseDirective, [], null, { expandedChange: "expandedChange" }), i0.ɵdid(9, 540672, null, 0, i6.CollapseProviderDirective, [[2, i5.CollapseDirective]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 6, "omg-collapsed", [["disableAnimation", "true"], ["om-layout", "vertical"], ["om-layout-fill", ""]], [[2, "om-collapsed", null]], null, null, i7.View_CollapsedComponent_0, i7.RenderType_CollapsedComponent)), i0.ɵdid(11, 573440, null, 0, i8.CollapsedComponent, [[2, i6.CollapseProviderDirective]], { disableAnimation: [0, "disableAnimation"] }, null), (_l()(), i0.ɵeld(12, 0, null, 0, 4, "div", [["om-flex", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["omgButton", ""], ["omgCollapseToggle", ""], ["variant", "link"]], [[2, "om-collapse-toggle", null], [2, "om-collapse-collapse", null], [2, "om-collapse-expand", null], [8, "disabled", 0], [2, "om-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i0.ɵdid(14, 540672, null, 0, i10.CollapseToggleDirective, [[2, i6.CollapseProviderDirective]], { toggleAction: [0, "toggleAction"] }, null), i0.ɵdid(15, 114688, null, 0, i11.ButtonComponent, [i0.ElementRef, i0.Renderer2], { variant: [0, "variant"] }, null), (_l()(), i0.ɵted(-1, 0, [" + Add Screening History "])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "omg-expanded", [["disableAnimation", "true"], ["om-layout", "vertical"], ["om-layout-fill", ""], ["om-layout-gutter", ""]], [[2, "om-expanded", null]], null, null, i12.View_ExpandedComponent_0, i12.RenderType_ExpandedComponent)), i0.ɵdid(18, 573440, null, 0, i13.ExpandedComponent, [[2, i6.CollapseProviderDirective]], { disableAnimation: [0, "disableAnimation"] }, null), (_l()(), i0.ɵeld(19, 0, null, 0, 2, "omg-screening-form", [["class", "-subform"]], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onSaveNewScreening($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).collapse() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_ScreeningFormComponent_0, i14.RenderType_ScreeningFormComponent)), i0.ɵdid(20, 245760, [["newScreeningForm", 4]], 0, i15.ScreeningFormComponent, [], { healthGoalType: [0, "healthGoalType"], formValue: [1, "formValue"], formErrors: [2, "formErrors"] }, { save: "save", cancel: "cancel" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.screeningHistory$)); _ck(_v, 5, 0, currVal_0); _ck(_v, 8, 0); var currVal_6 = "true"; _ck(_v, 11, 0, currVal_6); var currVal_12 = ""; _ck(_v, 14, 0, currVal_12); var currVal_13 = "link"; _ck(_v, 15, 0, currVal_13); var currVal_15 = "true"; _ck(_v, 18, 0, currVal_15); var currVal_16 = _co.healthGoalType; var currVal_17 = _co.newScreeningFormValue; var currVal_18 = i0.ɵunv(_v, 20, 2, i0.ɵnov(_v, 21).transform(_co.newScreeningErrors$)); _ck(_v, 20, 0, currVal_16, currVal_17, currVal_18); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 8).collapseClass; var currVal_2 = i0.ɵnov(_v, 8).collapseAttribute; var currVal_3 = i0.ɵnov(_v, 8).expandedClass; var currVal_4 = i0.ɵnov(_v, 8).disabledClass; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v, 11).collapsedClass; _ck(_v, 10, 0, currVal_5); var currVal_7 = i0.ɵnov(_v, 14).toggleClass; var currVal_8 = i0.ɵnov(_v, 14).toggleCollapseClass; var currVal_9 = i0.ɵnov(_v, 14).toggleExpandClass; var currVal_10 = i0.ɵnov(_v, 15).disabled; var currVal_11 = i0.ɵnov(_v, 15).buttonClass; _ck(_v, 13, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_14 = i0.ɵnov(_v, 18).expandedClass; _ck(_v, 17, 0, currVal_14); }); }
export function View_ScreeningHistoryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-screening-history", [], null, null, null, View_ScreeningHistoryComponent_0, RenderType_ScreeningHistoryComponent)), i0.ɵdid(1, 245760, null, 0, i16.ScreeningHistoryComponent, [i3.Store, i17.PatientSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScreeningHistoryComponentNgFactory = i0.ɵccf("omg-screening-history", i16.ScreeningHistoryComponent, View_ScreeningHistoryComponent_Host_0, { healthGoalId: "healthGoalId", healthGoalType: "healthGoalType", healthGoalActionId: "healthGoalActionId", screeningHistory: "screeningHistory" }, {}, []);
export { ScreeningHistoryComponentNgFactory as ScreeningHistoryComponentNgFactory };
