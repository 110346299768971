import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardBodyComponent } from './card-body.component';
import { CardFooterComponent } from './card-footer.component';
import { CardTitleComponent } from './card-title.component';
import { CardComponent } from './card.component';

const declarations = [
  CardComponent,
  CardTitleComponent,
  CardBodyComponent,
  CardFooterComponent,
];

@NgModule({
  imports: [CommonModule],
  exports: [...declarations],
  declarations: [...declarations],
  providers: [],
})
export class CardModule {}
