import { escapeRegExp, remove, sortBy, union } from 'lodash/fp';

import { SummaryProblem } from '@app/features/summaries/shared/summaries.type';

import { Problem, ProblemForm, ProblemType } from './problems.type';

export const setCustomProblemCode = (problem: Problem): Problem => {
  const updatedProblem = { ...problem };
  updatedProblem.customProblemCodeSelection = {
    code: updatedProblem.customProblemTypeCode,
    detailDescription: updatedProblem.customProblemTypeCodeDescription,
    id: updatedProblem.id.toString(),
  };

  return updatedProblem;
};

export const sortProblems = (problems: Problem[]): Problem[] => {
  return sortBy(
    problem => [
      !problem.includedInMedicalHistory,
      problem.problemType.clinicalDescription.toLowerCase(),
    ],
    problems,
  );
};

export const problemCodeHighlight = (problemCode, query) => {
  const escapedQuery = escapeRegExp(query);
  const keywords = escapedQuery.split(/\s/);
  const pattern = new RegExp(`(${keywords.join('|')})`, 'ig');
  const code = problemCode.code || '';
  const detailDescription = problemCode.detail_description || '';
  const formatProblemCode = (description, extra) => `${description} ${extra}`;

  if (pattern.test(code)) {
    return formatProblemCode(detailDescription, code);
  }
  return formatProblemCode(detailDescription, code);
};

export const setIncludedInMedicalHistory = (problemType: ProblemType) => {
  return !(problemType && problemType.importance === 3);
};

export const problemTypeHighlight = (problemType, query) => {
  const escapedQuery = escapeRegExp(query);
  const re = new RegExp(`\\b(${escapedQuery})`, 'i');

  const keywords = escapedQuery.split(/\s/);
  const pattern = new RegExp(`(${keywords.join('|')})`, 'ig');

  const exactTagMatch = problemType.tags.find(tag => re.test(tag));

  const clinicalDescription = problemType.clinical_description || '';
  const clinicalAbbreviation = problemType.clinical_abbreviation || '';
  const layDescription = problemType.lay_description || '';
  const tags = problemType.tags || [];
  const displayWith = extra => `${clinicalDescription} (${extra})`;

  let returnString = clinicalDescription;

  if (exactTagMatch) {
    returnString = displayWith(tags);
  }

  if (pattern.test(clinicalDescription)) {
    return clinicalDescription;
  } else if (pattern.test(clinicalAbbreviation)) {
    returnString = displayWith(clinicalAbbreviation);
  } else if (pattern.test(layDescription)) {
    returnString = displayWith(layDescription);
  }
  return returnString;
};

export const mapProblemToForm = (problem: Problem): ProblemForm => {
  return {
    problemId: problem ? problem.problemType.id : null,
    locationId: problem ? problem.problemCodeLocation.id : null,
    includedInMedicalHistory: problem ? problem.includedInMedicalHistory : null,
    customProblemDescription: problem
      ? problem.customProblemTypeDescription
      : null,
    problemCode: problem ? problem.customProblemTypeCode : null,
    basicFollowUpOrder:
      problem && Object.keys(problem.autoCreationsFlags).length ? true : false,
    id: problem ? problem.id : null,
    isCustom: problem ? problem.useCustomProblemType : false,
    onset: problem ? problem.onset : null,
    resolution: problem ? problem.resolution : null,
    briefComment: problem ? problem.briefComment : null,
    summary: problem ? problem.summary : null,
    assessmentAndPlan: problem ? problem.aAndPDisplayText : null,
    addProblemToNote: false,
  };
};

export const getHistoryIds = (
  assessedProblems: SummaryProblem[],
  historyId: number,
  problemId: number,
  hasAssessedProblem: boolean,
) => {
  if (!assessedProblems) {
    return [];
  }
  const assessedProblemHistoryIds = assessedProblems.map(
    assessedProblem => assessedProblem.problemHistoryId,
  );
  if (hasAssessedProblem) {
    return assessedProblems.map(assessedProblem => {
      if (assessedProblem.id === problemId) {
        return historyId;
      }
      return assessedProblem.problemHistoryId;
    });
  }
  return union(assessedProblemHistoryIds, [historyId]);
};

export const removeHistoryId = (historyIds: number[], historyId: number): any =>
  remove(id => id === historyId, historyIds);
