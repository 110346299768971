import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Bridge to automatically trigger digests in AngularJS when
 * Angular becomes stable from a change detection.
 */
@Injectable()
export class ChangeDetectionBridgeService implements OnDestroy {
  private subscription: Subscription;

  constructor(@Inject('$rootScope') private rootScope, private zone: NgZone) {
    this.listenToOnStable();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private listenToOnStable() {
    // Ensure that Angular is able to trigger digests from within AngularJS
    this.zone.onStable.subscribe(() => {
      this.rootScope.$applyAsync();
    });
  }
}
