import { ErrorHandler } from '@angular/core';
import { LoggerService } from '@app/core/logger';
import { isPlainObject } from '@app/utils';
import { getErrorMessage } from './error-utils';
import * as i0 from "@angular/core";
import * as i1 from "../logger/logger.service";
var ErrorHandlerService = /** @class */ (function () {
    function ErrorHandlerService(logger) {
        this.logger = logger;
    }
    ErrorHandlerService.prototype.handleError = function (err) {
        this.logError(err);
        return err;
    };
    ErrorHandlerService.prototype.handleErrorSafe = function (err) {
        this.logError(err);
        if (isPlainObject(err)) {
            return err;
        }
        else {
            return getErrorMessage(err);
        }
    };
    ErrorHandlerService.prototype.logError = function (err) {
        this.logger.error(err);
    };
    ErrorHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.inject(i1.LoggerService)); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
