import { Component } from '@angular/core';

import { downgradeComponent } from '../shared/utils';

@Component({
  selector: 'omg-angular-highlight-toggle',
  template: `
    <ng-container>
      <span (click)="toggle()">
        <span *ngIf="!on">Show Angular 7</span>
        <span *ngIf="on">Hide Angular 7</span>
      </span>
    </ng-container>
  `,
})
export class AngularHightlightToggleComponent {
  on = false;

  toggle() {
    this.on = !this.on;
    const appRoot = document.querySelector('body');
    appRoot.classList.toggle('hightlight-angular');
  }
}

downgradeComponent(
  AngularHightlightToggleComponent,
  'omgAngularHighlightToggle',
);
