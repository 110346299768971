/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vitals-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "../../../shared/directives/tooltip/tooltip.directive";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/pipes/date.pipe";
import * as i6 from "./vitals-data.component";
import * as i7 from "../shared/vitals-data.service";
import * as i8 from "../shared/measurement-types.service";
import * as i9 from "../../../core/patient/store/patient.selectors";
var styles_VitalsDataComponent = [i0.styles];
var RenderType_VitalsDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VitalsDataComponent, data: {} });
export { RenderType_VitalsDataComponent as RenderType_VitalsDataComponent };
function View_VitalsDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "th", [["class", "padding-half"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit, "4y")); _ck(_v, 1, 0, currVal_0); }); }
function View_VitalsDataComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [["class", "left-align padding-half"], ["omgTooltip", ""], ["tooltipPosition", "bottom"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4931584, null, 0, i3.TooltipDirective, [i1.ElementRef, i2.DomHandler, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipText: [1, "tooltipText"] }, null), (_l()(), i1.ɵted(3, null, [" ", " ", " "]))], function (_ck, _v) { var currVal_0 = "bottom"; var currVal_1 = _v.context.$implicit.metadata; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.vital == null) ? null : _v.context.$implicit.vital.value)); var currVal_3 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.vital == null) ? null : _v.context.$implicit.vital.unit)); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_VitalsDataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "left-align padding-half"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VitalsDataComponent_5)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.vitalMeasurements; var currVal_2 = _co.trackByIndex; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.abbreviation); _ck(_v, 3, 0, currVal_0); }); }
function View_VitalsDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VitalsDataComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.measurementTypes; var currVal_1 = _co.trackByIndex; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VitalsDataComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "left-align padding-half"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No vitals recorded. "]))], null, null); }
function View_VitalsDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "th", [["class", "padding-half"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VitalsDataComponent_2)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VitalsDataComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VitalsDataComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.headers; var currVal_1 = _co.trackByIndex; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.hasData); _ck(_v, 8, 0, currVal_2); var currVal_3 = !((_v.context.ngIf == null) ? null : _v.context.ngIf.hasData); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_VitalsDataComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "table", [["class", "tableau-font-size"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VitalsDataComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.vitalsData)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_VitalsDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-vitals-data", [], null, null, null, View_VitalsDataComponent_0, RenderType_VitalsDataComponent)), i1.ɵdid(1, 114688, null, 0, i6.VitalsDataComponent, [i7.VitalsDataService, i8.MeasurementTypesService, i9.PatientSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VitalsDataComponentNgFactory = i1.ɵccf("omg-vitals-data", i6.VitalsDataComponent, View_VitalsDataComponent_Host_0, {}, {}, []);
export { VitalsDataComponentNgFactory as VitalsDataComponentNgFactory };
