import { OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FeatureFlagSelectors } from '@app/core';
var AppShellComponent = /** @class */ (function () {
    function AppShellComponent(featureFlagSelectors) {
        this.featureFlagSelectors = featureFlagSelectors;
    }
    AppShellComponent.prototype.ngOnInit = function () {
        this.setupSelectors();
    };
    AppShellComponent.prototype.setupSelectors = function () {
        var optimal = this.featureFlagSelectors
            .featureEnabled('font_inter_optimal')
            .pipe(take(1));
        var inter = this.featureFlagSelectors
            .featureEnabled('font_inter')
            .pipe(take(1));
        this.fontFeatureFlags$ = forkJoin(inter, optimal).pipe(map(function (_a) {
            var interEnabled = _a[0], interOptimalEnabled = _a[1];
            var fontClass = '';
            if (interEnabled) {
                fontClass = 'inter';
            }
            if (interOptimalEnabled) {
                fontClass = 'inter optimal';
            }
            return ("om-page " + fontClass).trim();
        }));
    };
    return AppShellComponent;
}());
export { AppShellComponent };
