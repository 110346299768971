import { Action } from '@ngrx/store';

import { FsaAction } from '@app/core';

import {
  HealthGoalScreening,
  HealthGoalScreeningErrors,
  HealthGoalScreeningForm,
} from '../shared/health-maintenance.type';
import { HealthMaintenanceError } from '../shared/health-maintenance.type';

type HealthGoalScreeningId = number;

export enum HealthGoalScreeningActionTypes {
  AddHealthGoalScreening = '[HealthGoalScreening] Add HealthGoalScreening',
  AddHealthGoalScreeningSuccess = '[HealthGoalScreening] Add HealthGoalScreening Success',
  AddHealthGoalScreeningError = '[HealthGoalScreening] Add HealthGoalScreening Error',
  UpdateHealthGoalScreening = '[HealthGoalScreening] Update HealthGoalScreening',
  UpdateHealthGoalScreeningSuccess = '[HealthGoalScreening] Update HealthGoalScreening Success',
  UpdateHealthGoalScreeningError = '[HealthGoalScreening] Update HealthGoalScreening Error',
  DeleteHealthGoalScreening = '[HealthGoalScreening] Delete HealthGoalScreening',
  DeleteHealthGoalScreeningSuccess = '[HealthGoalScreening] Delete HealthGoalScreening Success',
  DeleteHealthGoalScreeningError = '[HealthGoalScreening] Delete HealthGoalScreening Error',
  ClearHealthGoalScreenings = '[HealthGoalScreening] Clear HealthGoalScreenings',
}

export class AddHealthGoalScreening
  implements FsaAction<HealthGoalScreeningForm> {
  readonly type = HealthGoalScreeningActionTypes.AddHealthGoalScreening;

  constructor(public payload: HealthGoalScreeningForm) {}
}

export class AddHealthGoalScreeningSuccess
  implements FsaAction<HealthGoalScreening> {
  readonly type = HealthGoalScreeningActionTypes.AddHealthGoalScreeningSuccess;
  error = false;

  constructor(public payload: HealthGoalScreening) {}
}

export class AddHealthGoalScreeningError
  implements FsaAction<HealthGoalScreeningErrors> {
  readonly type = HealthGoalScreeningActionTypes.AddHealthGoalScreeningError;
  error = true;

  constructor(
    public payload: HealthGoalScreeningErrors,
    public meta: { id: number },
  ) {}
}

export class UpdateHealthGoalScreening
  implements FsaAction<HealthGoalScreeningForm> {
  readonly type = HealthGoalScreeningActionTypes.UpdateHealthGoalScreening;

  constructor(public payload: HealthGoalScreeningForm) {}
}

export class UpdateHealthGoalScreeningSuccess
  implements FsaAction<HealthGoalScreening> {
  readonly type =
    HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningSuccess;
  error = false;

  constructor(public payload: HealthGoalScreening, public meta?: any) {}
}

export class UpdateHealthGoalScreeningError
  implements FsaAction<HealthGoalScreeningErrors> {
  readonly type = HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningError;
  error = true;

  constructor(
    public payload: HealthGoalScreeningErrors,
    public meta: { id: number },
  ) {}
}

export class DeleteHealthGoalScreening
  implements FsaAction<HealthGoalScreeningId> {
  readonly type = HealthGoalScreeningActionTypes.DeleteHealthGoalScreening;

  constructor(
    public payload: HealthGoalScreeningId,
    public meta: { healthGoalId: number },
  ) {}
}

export class DeleteHealthGoalScreeningSuccess
  implements FsaAction<HealthGoalScreeningId> {
  readonly type =
    HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningSuccess;
  error = false;

  constructor(
    public payload: HealthGoalScreeningId,
    public meta: { healthGoalId: number },
  ) {}
}

export class DeleteHealthGoalScreeningError
  implements FsaAction<HealthMaintenanceError> {
  readonly type = HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningError;
  error = true;

  constructor(
    public payload: HealthMaintenanceError,
    public meta: { id: HealthGoalScreeningId },
  ) {}
}

export class ClearHealthGoalScreenings implements Action {
  readonly type = HealthGoalScreeningActionTypes.ClearHealthGoalScreenings;
}

export type HealthGoalScreeningActions =
  | AddHealthGoalScreening
  | AddHealthGoalScreeningSuccess
  | AddHealthGoalScreeningError
  | UpdateHealthGoalScreening
  | UpdateHealthGoalScreeningSuccess
  | UpdateHealthGoalScreeningError
  | DeleteHealthGoalScreening
  | DeleteHealthGoalScreeningSuccess
  | DeleteHealthGoalScreeningError
  | ClearHealthGoalScreenings;
