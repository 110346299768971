var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as patientActions from './patient.actions';
export var patientStatePath = 'patient';
export var patientInitialState = {
    entity: null,
    error: null,
    loading: false,
};
export function patientReducer(state, action) {
    if (state === void 0) { state = patientInitialState; }
    switch (action.type) {
        case patientActions.GET_PATIENT: {
            return __assign({}, state, { loading: true });
        }
        case patientActions.GET_PATIENT_SUCCESS: {
            return __assign({}, state, { error: null, loading: false, entity: action.payload });
        }
        case patientActions.GET_PATIENT_ERROR: {
            return __assign({}, state, { error: action.payload, loading: false, entity: null });
        }
        case patientActions.UPDATE_PATIENT: {
            return __assign({}, state, { loading: true });
        }
        case patientActions.UPDATE_PATIENT_SUCCESS: {
            return __assign({}, state, { error: null, loading: false, entity: action.payload });
        }
        case patientActions.UPDATE_PATIENT_ERROR: {
            return __assign({}, state, { error: action.payload, loading: false });
        }
        default: {
            return __assign({}, state);
        }
    }
}
