import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { CollapseDirective } from '@app/shared/components';
import { waitFor } from '@app/utils';

import { getDefaultScreeningEventId } from '../../shared/health-goal-type-options';
import { buildNewHealthGoalScreeningForm } from '../../shared/health-maintenance-utils';
import {
  HealthGoalScreening,
  HealthGoalScreeningErrors,
  HealthGoalScreeningForm,
  HealthGoalType,
  newEntityId,
} from '../../shared/health-maintenance.type';

import { AddHealthGoalScreening } from '../../store/health-goal-screening.actions';
import {
  selectHealthGoalScreeningError,
  selectHealthGoalScreeningsByHealthGoalId,
  selectHealthGoalScreeningWithMetadata,
} from '../../store/health-goal-screening.selectors';
import { HealthMainteanceState } from '../../store/health-maintenance.reducer';

@Component({
  selector: 'omg-screening-history',
  templateUrl: './screening-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningHistoryComponent implements OnInit, OnDestroy {
  @Input() healthGoalId: number;
  @Input() healthGoalType: HealthGoalType;
  @Input() healthGoalActionId: number;
  @Input() screeningHistory: HealthGoalScreening[];

  patientId: number;
  newScreeningFormValue: HealthGoalScreeningForm;
  screeningHistory$: Observable<HealthGoalScreening[]>;
  newScreeningErrors$: Observable<HealthGoalScreeningErrors>;

  @ViewChild('newScreeningCollapse') private newFormCollapse: CollapseDirective;

  private unsubscribe = new Subject();

  constructor(
    private store: Store<HealthMainteanceState>,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.setupSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  trackByFn = (index, value) => (value && value.id) || index;

  onNewScreeningFormExpanded(expanded: boolean) {
    if (expanded) {
      this.resetNewForm();
    }
  }

  onSaveNewScreening(value: HealthGoalScreeningForm) {
    this.saveHealthGoalScreening(value);
  }

  private resetNewForm() {
    // Get the appropriate default value
    const defaultEventId = getDefaultScreeningEventId(
      this.healthGoalType,
      this.healthGoalActionId,
    );

    this.newScreeningFormValue = buildNewHealthGoalScreeningForm({
      id: newEntityId, // hack to track entity status for new screening
      patientId: this.patientId,
      healthGoalId: this.healthGoalId,
      eventId: defaultEventId,
    });
  }

  private setupSelectors() {
    this.patientSelectors.patientId
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => (this.patientId = id));

    this.screeningHistory$ = this.store.pipe(
      select(selectHealthGoalScreeningsByHealthGoalId, {
        id: this.healthGoalId,
      }),
    );

    this.newScreeningErrors$ = this.store.pipe(
      select(selectHealthGoalScreeningError, { id: newEntityId }),
    );
  }

  private saveHealthGoalScreening(value: HealthGoalScreeningForm) {
    this.store.dispatch(new AddHealthGoalScreening(value));

    waitFor(
      this.store.pipe(
        select(selectHealthGoalScreeningWithMetadata, { id: value.id }),
      ),
      status => !status.pending && !status.error,
    ).subscribe(data => {
      this.resetNewForm();
      this.newFormCollapse.collapse();
    });
  }
}
