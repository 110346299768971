/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./collapsed.component";
import * as i3 from "./collapse-provider.directive";
var styles_CollapsedComponent = [];
var RenderType_CollapsedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapsedComponent, data: { "animation": [{ type: 7, name: "collapse", definitions: [{ type: 1, expr: ":enter", animation: { type: 10, animation: { type: 8, animation: [{ type: 6, styles: { height: 0, opacity: 0 }, offset: null }, { type: 4, styles: null, timings: "0.3s ease-out" }], options: null }, options: null }, options: null }, { type: 1, expr: ":leave", animation: { type: 10, animation: { type: 8, animation: [{ type: 4, styles: { type: 6, styles: { height: 0, opacity: 0 }, offset: null }, timings: "0.3s ease-out" }], options: null }, options: null }, options: null }, { type: 0, name: "*", styles: { type: 6, styles: { opacity: 1, overflow: "hidden" }, offset: null }, options: undefined }], options: {} }] } });
export { RenderType_CollapsedComponent as RenderType_CollapsedComponent };
function View_CollapsedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[24, "@.disabled", 0], [24, "@collapse", 0]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableAnimation; var currVal_1 = !((_co.collapseProvider == null) ? null : _co.collapseProvider.expanded); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CollapsedComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CollapsedComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(((_co.collapseProvider == null) ? null : _co.collapseProvider.expandedChange))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CollapsedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-collapsed", [], [[2, "om-collapsed", null]], null, null, View_CollapsedComponent_0, RenderType_CollapsedComponent)), i0.ɵdid(1, 573440, null, 0, i2.CollapsedComponent, [[2, i3.CollapseProviderDirective]], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).collapsedClass; _ck(_v, 0, 0, currVal_0); }); }
var CollapsedComponentNgFactory = i0.ɵccf("omg-collapsed, [omgCollapsed]", i2.CollapsedComponent, View_CollapsedComponent_Host_0, { collapseProvider: "collapseProvider", disableAnimation: "disableAnimation" }, {}, ["*"]);
export { CollapsedComponentNgFactory as CollapsedComponentNgFactory };
