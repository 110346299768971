var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { of } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';
import { PatientSelectors } from '../../patient';
import { mapTrackEventPropertiesToAnalyticsData } from './analytics-api-mappers';
import { trackEvent } from './analytics.actions';
var AnalyticsEffects = /** @class */ (function () {
    function AnalyticsEffects(action$, angulartics2MixPanel, patientSelectors) {
        var _this = this;
        this.action$ = action$;
        this.angulartics2MixPanel = angulartics2MixPanel;
        this.patientSelectors = patientSelectors;
        this.mixpanelActionTracking$ = this.action$.pipe(ofType(trackEvent), withLatestFrom(this.patientSelectors.patientId), tap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.angulartics2MixPanel.eventTrack(action.payload.action, mapTrackEventPropertiesToAnalyticsData(__assign({ patientId: patientId }, action.payload.properties)));
        }), catchError(function (error) { return of(error); }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AnalyticsEffects.prototype, "mixpanelActionTracking$", void 0);
    return AnalyticsEffects;
}());
export { AnalyticsEffects };
