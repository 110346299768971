import { EventEmitter, Inject, Injectable } from '@angular/core';
import { UpdateSectionsLinkable } from '@app/core/encounter/store/encounter.actions';
import { ExpandTimeline } from '@app/features/patient-chart/store/patient-chart.actions';
import { Store } from '@ngrx/store';
import { RefreshRenewalCount } from './../../modules/renewals/store/renewal.actions';

@Injectable()
export class RootScopeToStoreBridgeService {
  manualEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('$rootScope') private $rootScope,
    private store: Store<any>,
  ) {
    this.setRootScopeListeners();
  }

  private setRootScopeListeners() {
    this.$rootScope.$on('summaryClosed', (event, state) => {
      this.store.dispatch(new UpdateSectionsLinkable(!!state));
    });

    this.$rootScope.$on('summaryLoaded', (event, state) => {
      this.store.dispatch(new UpdateSectionsLinkable(!!state));
    });

    this.$rootScope.$on('updateRenewalCount', (event, count) => {
      this.store.dispatch(new RefreshRenewalCount());
    });

    this.$rootScope.$on('expandTimeline', (event, expanded) => {
      this.store.dispatch(new ExpandTimeline({ expanded }));
      this.manualEventEmitter.emit(event);
    });
  }
}
