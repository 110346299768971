var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, NgZone, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { DomHandler } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
var TooltipDirective = /** @class */ (function (_super) {
    __extends(TooltipDirective, _super);
    function TooltipDirective(el, domHandler, zone) {
        var _this = _super.call(this, el, domHandler, zone) || this;
        _this.showTooltip = true;
        _this.showDelay = 250;
        return _this;
    }
    TooltipDirective.prototype.ngOnInit = function () {
        this._text = this.tooltipText;
        this.disabled = !this.showTooltip;
    };
    TooltipDirective.prototype.ngOnChanges = function (changes) {
        if (changes.showTooltip) {
            this.disabled = !changes.showTooltip.currentValue;
        }
    };
    return TooltipDirective;
}(Tooltip));
export { TooltipDirective };
