/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insights-graph.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./insights-graph.component";
var styles_InsightsGraphComponent = [i0.styles];
var RenderType_InsightsGraphComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InsightsGraphComponent, data: {} });
export { RenderType_InsightsGraphComponent as RenderType_InsightsGraphComponent };
function View_InsightsGraphComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartName; _ck(_v, 1, 0, currVal_0); }); }
function View_InsightsGraphComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-tab-message ng-scope"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Data"]))], null, null); }
export function View_InsightsGraphComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { graph: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsightsGraphComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsightsGraphComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["graph", 1]], null, 1, "canvas", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartName; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.data[0] == null) ? null : ((_co.data[0].measurements == null) ? null : _co.data[0].measurements.length)) === 0); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.chart; _ck(_v, 7, 0, currVal_2); }); }
export function View_InsightsGraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-insights-graph", [], null, null, null, View_InsightsGraphComponent_0, RenderType_InsightsGraphComponent)), i1.ɵdid(1, 114688, null, 0, i3.InsightsGraphComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsightsGraphComponentNgFactory = i1.ɵccf("omg-insights-graph", i3.InsightsGraphComponent, View_InsightsGraphComponent_Host_0, { data: "data" }, {}, []);
export { InsightsGraphComponentNgFactory as InsightsGraphComponentNgFactory };
