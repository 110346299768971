var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService } from '../../errors/error-handler.service';
import { PatientApiService } from '../shared/patient-api.service';
import { GET_PATIENT, GetPatientError, GetPatientSuccess, UPDATE_PATIENT, UpdatePatientError, UpdatePatientSuccess, } from './patient.actions';
import { PatientSelectors } from './patient.selectors';
var PatientEffects = /** @class */ (function () {
    function PatientEffects(action$, patientApi, patientSelectors, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.patientApi = patientApi;
        this.patientSelectors = patientSelectors;
        this.errorHandler = errorHandler;
        this.getPatient$ = this.action$.pipe(ofType(GET_PATIENT), withLatestFrom(this.patientSelectors.patientId), filter(Boolean), filter(function (_a) {
            var action = _a[0], currentPatientId = _a[1];
            return action.payload.id !== currentPatientId || action.payload.forceGet;
        }), switchMap(function (_a) {
            var action = _a[0], currentPatientId = _a[1];
            return _this.patientApi.get(action.payload.id).pipe(map(function (patient) { return new GetPatientSuccess(patient); }), catchError(function (error) {
                return of(new GetPatientError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.updatePatient$ = this.action$.pipe(ofType(UPDATE_PATIENT), map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.patientApi.update(payload.id, payload.data).pipe(map(function (patient) { return new UpdatePatientSuccess(patient); }), catchError(function (error) {
                return of(new UpdatePatientError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PatientEffects.prototype, "getPatient$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PatientEffects.prototype, "updatePatient$", void 0);
    return PatientEffects;
}());
export { PatientEffects };
