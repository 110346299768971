<form [formGroup]="form"
      om-layout="vertical"
      om-layout-align="start start"
      om-layout-gutter>

  <div class="om-animate padding-normal"
       om-layout="vertical"
       om-layout-fill
       om-layout-gutter-mini>
    <div class="label">Vaccine (Given At Other Facility)
      <omg-auto-complete #vaccineNameInput
                         hideDropdownArrow="true"
                         [hideClearAll]="true"
                         placeholder="Find vaccine..."
                         [items]="autocompleteItems | async"
                         trackByKey="id"
                         (search)="getSearchResults($event)"
                         (change)="setVaccine($event)"
                         omgFocusOn="vaccinationFocus-create"
                         (focusOnRequest)="vaccineNameInput.focus()"
                         omgStopEvent="click"
                         formControlName="vaccineId"
                         bindLabel="longName"
                         bindValue="id"
                         om-layout-fill>
      </omg-auto-complete>
      <div class="alert"
           *ngIf="form.get('vaccineId').touched && form.get('vaccineId').invalid">
        Cannot be blank
      </div>
    </div>

    <div om-layout
         om-layout-gutter>
      <label om-flex="45"
             om-layout="vertical">Step
        <input omgInputText
               type="text"
               placeholder="e.g. 1 of 3"
               formControlName="step"
               maxlength="32" />
      </label>

      <!-- Date -->
      <label om-flex
             om-layout="vertical">Given On
        <input omgInputText
               class="-flush-bottom"
               type="text"
               placeholder="ex: 05/2012 or 2012"
               formControlName="givenOn" />

        <ng-container *ngIf="showGivenOnErrors">
          <div class="alert"
               *ngIf="showGivenOnRequiredError">
            Cannot be Blank
          </div>
          <div class="alert"
               *ngIf="showGivenOnFuzzyDateError">
            {{ form?.get('givenOn').getError('dateFuzzy') }}
          </div>
        </ng-container>

      </label>
    </div>

    <div>
      <label om-layout="vertical">Comments
        <textarea omgTextarea
                  rows="3"
                  formControlName="comments"></textarea>
      </label>
    </div>

  </div>

  <div class="action-bar"
       om-layout
       om-layout-gutter
       om-layout-fill>
    <button type="submit"
            omgButton
            variant="primary"
            [disabled]="form?.invalid || form?.pristine"
            (click)="onSave()">
      Add Historical Vaccine
    </button>
    <button omgButton
            variant="flat"
            (click)="onCancel()">Cancel</button>
  </div>
</form>
