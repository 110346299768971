import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { PcpCommentsComponent } from './components/pcp-comments/pcp-comments.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PcpCommentsComponent],
  exports: [PcpCommentsComponent],
  providers: [],
})
export class PcpCommentsModule {}
