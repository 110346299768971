import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';

import { AllergiesService } from '../../shared/allergies.service';
import {
  NoKnownAllergies,
  PatientAllergies,
  PatientAllergy,
} from '../../shared/allergies.type';
import {
  buildAllergySaveRequest,
  buildAllergyUpdateRequest,
  getActivePatientAllergies,
  getInactivePatientAllergies,
} from './../../shared/allergies-utils';

@Component({
  selector: 'omg-allergies',
  templateUrl: './allergies.component.html',
})
export class AllergiesComponent implements OnInit, OnDestroy {
  patientAllergies: PatientAllergies;
  activePatientAllergies: Array<PatientAllergy> = [];
  inactivePatientAllergies: Array<PatientAllergy> = [];
  patientId: number;
  isSummaryActive: Observable<boolean>;

  private unsubscribe = new Subject();

  constructor(
    private allergiesService: AllergiesService,
    private patientSelectors: PatientSelectors,
    private summariesSelectors: SummariesSelectors,
  ) {}

  ngOnInit() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        tap(patientId => {
          this.patientId = patientId;
        }),
        switchMap(patientId => this.allergiesService.getAll(patientId)),
        takeUntil(this.unsubscribe),
      )
      .subscribe((res: PatientAllergies) => this.updatePatientAllergies(res));

    this.isSummaryActive = this.summariesSelectors.hasActiveSummary;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  trackByFn = (index, patientAllergy: PatientAllergy) =>
    patientAllergy.id || index;

  activateAllergy(allergy: PatientAllergy) {
    const resolvedAllergy = {
      id: allergy.id,
      active: allergy.active,
    };

    this.allergiesService
      .update(this.patientId, resolvedAllergy)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res =>
        this.updatePatientAllergies(
          this.mapActivationResponseToPatientAllergy(res),
        ),
      );
  }

  addAllergy(formValue: any) {
    const request = buildAllergySaveRequest({
      active: true,
      allergy: {
        drugAllergyCheckCompatible: false,
        id: formValue.allergyId,
      },
      comment: formValue.comment,
      reaction: formValue.reaction,
    });

    this.allergiesService
      .save(this.patientId, request)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: PatientAllergies) =>
        this.updatePatientAllergies(this.mapPostResponseToPatientAllergy(res)),
      );
  }

  updateAllergy(event: { value: any; onSuccess: () => void }) {
    const { value: formValue, onSuccess } = event;

    const request = buildAllergyUpdateRequest({
      allergy: {
        id: formValue.allergyId,
      },
      comment: formValue.comment,
      id: formValue.id,
      reaction: formValue.reaction,
    });

    this.allergiesService
      .update(this.patientId, request)
      .pipe(
        tap(() => onSuccess()),
        takeUntil(this.unsubscribe),
      )
      .subscribe((res: PatientAllergies) =>
        this.updatePatientAllergies(
          this.mapUpdateResponseToPatientAllergy(res),
        ),
      );
  }

  deleteAllergy(event: { id: number; onSuccess: () => void }) {
    const { id: allergyId, onSuccess } = event;

    this.allergiesService
      .delete(this.patientId, allergyId)
      .pipe(
        tap(() => onSuccess()),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() =>
        this.updatePatientAllergies({
          ...this.patientAllergies,
          items: this.patientAllergies.items.filter(
            item => item.id !== allergyId,
          ),
        }),
      );
  }

  setPatientNoKnownAllergy() {
    this.allergiesService
      .noKnownAllergy(this.patientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: NoKnownAllergies) => {
        const updated = {
          ...this.patientAllergies,
          noKnownAllergies: { ...res },
        };

        this.updatePatientAllergies(updated);
      });
  }

  addToNote() {
    this.allergiesService.linkSection(
      this.patientId,
      getActivePatientAllergies(this.patientAllergies),
    );
  }

  private updatePatientAllergies(patientAllergies: PatientAllergies) {
    this.patientAllergies = patientAllergies;

    this.activePatientAllergies = getActivePatientAllergies(
      this.patientAllergies,
    );

    this.inactivePatientAllergies = getInactivePatientAllergies(
      this.patientAllergies,
    );
  }

  private mapUpdateResponseToPatientAllergy(
    response: PatientAllergies,
  ): PatientAllergies {
    const updatedItem = response.items && response.items[0];
    const updatedItems = this.patientAllergies.items.map(item => {
      if (item.id === updatedItem.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });

    return {
      ...this.patientAllergies,
      items: [...updatedItems],
      noKnownAllergies: { ...response.noKnownAllergies },
    };
  }

  private mapPostResponseToPatientAllergy(
    response: PatientAllergies,
  ): PatientAllergies {
    return {
      items: [...this.patientAllergies.items, ...response.items],
      noKnownAllergies: {
        ...this.patientAllergies.noKnownAllergies,
        ...response.noKnownAllergies,
      },
    };
  }

  private mapActivationResponseToPatientAllergy(
    response: PatientAllergies,
  ): PatientAllergies {
    const updatedItem = response.items && response.items[0];

    return {
      ...this.patientAllergies,
      items: this.patientAllergies.items.map(item => {
        if (item.id === updatedItem.id) {
          return { ...item, active: updatedItem.active };
        }

        return item;
      }),
    };
  }
}
