import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  HealthGoalActionAllowedResults,
  HealthGoalScreeningResult,
} from '../../shared/health-maintenance.type';

@Component({
  selector: 'omg-screening-result',
  templateUrl: './screening-result.component.html',
  styleUrls: ['./screening-result.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ScreeningResultComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningResultComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  @Input() normalize: boolean;
  @Input() allowedResults: HealthGoalActionAllowedResults;
  @Input() disabled = false;

  inputType: string;

  private _result: HealthGoalScreeningResult;

  @Input()
  get result() {
    return this._result;
  }

  set result(value: HealthGoalScreeningResult) {
    if (this._result !== value) {
      this._result = value;
      this.cd.markForCheck();
    }
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setInputType();
  }

  onResultChange(value: string) {
    this.result = value;
    this.onChange(value);
  }

  /* istanbul ignore next */
  writeValue(value: any) {
    this.result = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn: (value: any) => {}) {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private setInputType() {
    const allowedInput = ['radio', 'integer'];

    this.inputType =
      allowedInput.includes(this.allowedResults.type) && this.normalize
        ? this.allowedResults.type
        : 'text';
  }
}
