import { RouterBridgeService } from '../services/router-bridge.service';
import { useUpgradeRoute } from '../shared/constants';

const appStateName = useUpgradeRoute ? 'upgrade' : 'app';
const rootPath = useUpgradeRoute ? '/upgrade' : '';

export const upgradeRouteResolver = (
  $rootScope,
  $state,
  $location,
  $transitions,
) => {
  $rootScope.$on('$stateChangeStart', (...args) => {
    RouterBridgeService.isAngularJSTransitioning.next(true);
  });

  $rootScope.$on('$stateChangeSuccess', (...args) => {
    RouterBridgeService.isAngularJSTransitioning.next(false);
  });

  $rootScope.$on('$stateChangeCancel', (...args) => {
    RouterBridgeService.isAngularJSTransitioning.next(false);
  });

  $rootScope.$on('$stateChangeError', (...args) => {
    RouterBridgeService.isAngularJSTransitioning.next(false);
  });

  const isOrdersRoute = state => state.name.startsWith('app.chart.orders');

  const migratedWorkspaceRoutes = [
    'app.chart.workspace.newWorkspaceItem',
    'app.chart.workspace.summaries',
    'app.chart.workspace.patientTimelinePosts',
    'app.chart.workspace.notes',
  ];
  const isWorkspaceRoute = state =>
    migratedWorkspaceRoutes.findIndex(route => route === state.name) > -1;

  const isLetterRoute = state =>
    state.name.startsWith('app.chart.workspace.letters');

  const criteria = {
    to: state =>
      isWorkspaceRoute(state) || isOrdersRoute(state) || isLetterRoute(state),
  };

  $transitions.onBefore(criteria, transition => {
    const params = Object.assign({}, transition.params());

    if (params.patientId === undefined) {
      // Get patient id from url
      const patientId = $location.path().match(/patients\/([0-9]+?)\//);

      if (useUpgradeRoute && patientId && patientId.length > 0) {
        // abort current transition
        transition.abort();

        const upgradedRoute = transition.to().name.replace('app.', 'upgrade.');

        // return new transition containing correct patientId
        return $state.transitionTo(
          upgradedRoute,
          { ...params, patientId: patientId[1] },
          { notify: false },
        );
      }
    }
  });
};

upgradeRouteResolver.$inject = [
  '$rootScope',
  '$state',
  '$location',
  '$transitions',
];

export const upgradeStates = $stateProvider => {
  $stateProvider
    .state(`${appStateName}`, {
      template: '',
      controller: [
        'profile',
        function app(profile) {
          this.profile = profile;
          return undefined;
        },
      ],
      controllerAs: 'app',
      data: {
        authenticate: true,
      },
      resolve: {
        profile: ['ProfileService', ProfileService => ProfileService.get()],
      },
    })
    .state(`${appStateName}.chart`, {
      url: `${rootPath}/patients/:patientId/chart`,
      abstract: true,
      resolve: {
        patient: [
          '$stateParams',
          'PatientService',
          ($stateParams, PatientService) =>
            PatientService.get($stateParams.patientId),
        ],
        medications: [
          '$stateParams',
          'PatientMedicationsService',
          ($stateParams, PatientMedications) =>
            PatientMedications.getAllForPatient($stateParams.patientId),
        ],
        newRxCart: [
          '$stateParams',
          'NewRxCart',
          ($stateParams, NewRxCart) =>
            NewRxCart.get({ patientId: $stateParams.patientId }).$promise,
        ],
        patientPharmacies: [
          '$stateParams',
          'PatientPharmacyService',
          ($stateParams, PatientPharmacyService) =>
            PatientPharmacyService.query($stateParams.patientId),
        ],
        renewalsCount: [
          '$stateParams',
          'RefillRequest',
          function renewalsCount($stateParams, RefillRequest) {
            return RefillRequest.count({
              patientId: $stateParams.patientId,
            }).$promise;
          },
        ],
        chartAccess: [
          '$stateParams',
          'ChartAccessService',
          function chartAccess($stateParams, ChartAccessService) {
            if ($stateParams.patientId) {
              ChartAccessService.create($stateParams.patientId);
            }
          },
        ],
      },
      controller: 'EncounterController',
      controllerAs: 'encounter',
      template: '',
    })
    .state(`${appStateName}.chart.orders`, {
      url: '/orders',
      abstract: true,
      sticky: true,
      dsr: {
        default: { state: `${appStateName}.chart.orders.list` },
      },
      views: {
        'orders_list@app.chart': {
          template: '<ui-view/>',
        },
        'orders_edit@app.chart': {
          template: '<ui-view/>',
        },
        'consult_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'follow_up_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'follow_up_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'vaccine_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'vaccine_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'survey_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'survey_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'procedure_order_edit@app.chart': {
          template: '<ui-view/>',
        },
      },
    })
    .state(`${appStateName}.chart.orders.list`, {
      url: '/list',
      template: '',
    })
    .state(`${appStateName}.chart.orders.edit`, {
      url: '/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.orders.editConsultOrder`, {
      url: '/:id/edit_consult_order',
      template: '',
    })
    .state(`${appStateName}.chart.orders.editProcedureOrder`, {
      url: '/procedure_orders/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.orders.editVaccineOrder`, {
      url: '/vaccine_orders/:orderId/edit',
      template: '',
    })
    .state(`${appStateName}.chart.orders.newFollowUpOrder`, {
      url: '/follow_up_orders/new',
      template: '',
    })
    .state(`${appStateName}.chart.orders.editFollowUpOrder`, {
      url: '/follow_up_orders/:orderType/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.orders.editSurveyOrder`, {
      url: '/survey_orders/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.orders.newSurveyOrder`, {
      url: '/survey_orders/new',
      template: '',
    })
    .state(`${appStateName}.chart.renewals`, {
      url: '/renewals',
      abstract: true,
      sticky: true,
      dsr: {
        default: { state: `${appStateName}.chart.renewals.list` },
      },
      views: {
        'renewals_list@app.chart': {
          template: '<ui-view/>',
        },
      },
    })
    .state(`${appStateName}.chart.renewals.list`, {
      url: '/list',
      template: '',
      resolve: {
        patient: [
          '$stateParams',
          'PatientService',
          ($stateParams, PatientService) =>
            PatientService.get($stateParams.patientId),
        ],
        profile: ['ProfileService', ProfileService => ProfileService.get()],
        cart: [
          '$stateParams',
          'RefillRequest',
          function refillRequest($stateParams, RefillRequest) {
            return RefillRequest.latestCart({
              patientId: $stateParams.patientId,
            });
          },
        ],
      },
    })
    .state(`${appStateName}.chart.workspace`, {
      abstract: true,
      sticky: true,
    })
    .state(`${appStateName}.chart.workspace.newWorkspaceItem`, {
      url: '/summaries/new',
      template: '',
    })
    .state(`${appStateName}.chart.workspace.patientTimelinePosts`, {
      url: '/patient_timeline_posts/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.workspace.notes`, {
      url: '/notes/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.workspace.summaries`, {
      url: '/summaries/:id/edit',
      template: '',
    })
    .state(`${appStateName}.chart.workspace.letters`, {
      url: '/letters/:id/edit',
      template: '',
    })
    /**
     * chart.newrx is a dummy route necessary so that angularJS
     * can pick up patientId
     * https://www.pivotaltracker.com/story/show/164610881
     */
    .state(`${appStateName}.chart.newrx`, {
      url: '/new-rxs',
      template: '',
    });

  if (useUpgradeRoute) {
    $stateProvider
      // NOTE: This makes patientId available to upgraded components
      .state('upgrade-all', {
        url: '/upgrade/patients/:patientId/*path',
        template: '',
      });
  }

  $stateProvider
    // NOTE: This is to allow for sibling routing during angular migration
    .state('sink', {
      url: '/*path',
      dontStorePath: true,
      template: '',
    });
};

upgradeStates.$inject = ['$stateProvider'];
