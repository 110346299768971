var mapHealthGoalTypeOption = function (healthGoalType) { return ({
    label: healthGoalType.name,
    value: healthGoalType.id,
}); };
var ɵ0 = mapHealthGoalTypeOption;
var mapHealthGoalActionOption = function (healthGoalAction) { return ({
    label: healthGoalAction.name,
    value: healthGoalAction.id,
}); };
var ɵ1 = mapHealthGoalActionOption;
var findHealthGoalType = function (healthGoalTypes, healthGoalTypeId) { return healthGoalTypes.find(function (i) { return i.id === healthGoalTypeId; }); };
var ɵ2 = findHealthGoalType;
var getFirstHealthGoalActionId = function (healthGoalType) {
    return healthGoalType && healthGoalType.healthGoalActions.length > 0
        ? healthGoalType.healthGoalActions[0].id
        : null;
};
var ɵ3 = getFirstHealthGoalActionId;
export var buildGoalTypeOptions = function (healthGoalTypes) { return (healthGoalTypes || []).map(mapHealthGoalTypeOption).slice(); };
export var buildGoalActionOptions = function (actions) { return [
    { label: 'Undetermined', value: null }
].concat(actions.map(mapHealthGoalActionOption)); };
export var buildScreeningEventOptions = function (actions) { return [
    { label: 'Select an Event', value: null, disabled: true }
].concat(actions.map(mapHealthGoalActionOption)); };
export var getDefaultHealthGoalActionId = function (healthGoalTypes, healthGoalTypeId) {
    // Return the first action id as the default or null
    var healthGoalType = findHealthGoalType(healthGoalTypes, healthGoalTypeId);
    return getFirstHealthGoalActionId(healthGoalType);
};
export var getDefaultScreeningEventId = function (healthGoalType, healthGoalActionId) {
    if (healthGoalActionId === void 0) { healthGoalActionId = null; }
    if (!healthGoalType) {
        return null;
    }
    // Return the parent health goals action id or if null the first action
    if (healthGoalActionId) {
        return healthGoalActionId;
    }
    else {
        return getFirstHealthGoalActionId(healthGoalType);
    }
};
var buildInitialState = function () { return ({
    healthGoalTypes: [],
    healthGoalTypeOptions: [],
    healthGoalActionOptions: [],
    selectedHealthGoalType: null,
}); };
var ɵ4 = buildInitialState;
var HealthGoalTypeOptions = /** @class */ (function () {
    function HealthGoalTypeOptions() {
        this._state = buildInitialState();
    }
    Object.defineProperty(HealthGoalTypeOptions.prototype, "options", {
        get: function () {
            return this._state.healthGoalTypeOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalTypeOptions.prototype, "actionOptions", {
        get: function () {
            return this._state.healthGoalActionOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalTypeOptions.prototype, "healthGoalTypes", {
        get: function () {
            return this._state.healthGoalTypes;
        },
        set: function (value) {
            if (this._state.healthGoalTypes !== value) {
                this._state.healthGoalTypes = value;
                this._state.healthGoalTypeOptions = buildGoalTypeOptions(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalTypeOptions.prototype, "selectedGoalType", {
        get: function () {
            return this._state.selectedHealthGoalType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalTypeOptions.prototype, "selectedTypeId", {
        get: function () {
            return (this._state.selectedHealthGoalType &&
                this._state.selectedHealthGoalType.id);
        },
        set: function (value) {
            if (this.selectedTypeId !== value) {
                var healthGoalType = findHealthGoalType(this.healthGoalTypes, value);
                this._state.selectedHealthGoalType = healthGoalType;
                var healthGoalActions = (healthGoalType && healthGoalType.healthGoalActions) || [];
                this._state.healthGoalActionOptions = buildGoalActionOptions(healthGoalActions);
            }
        },
        enumerable: true,
        configurable: true
    });
    return HealthGoalTypeOptions;
}());
export { HealthGoalTypeOptions };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
