import { Injectable } from '@angular/core';
import { CookieService as CookiesService } from 'ngx-cookie';

@Injectable()
export class CookieService {
  constructor(private cookie: CookiesService) {}

  get() {
    return this.cookie.get('token');
  }

  set(token) {
    this.cookie.put('token', token);
  }

  delete() {
    this.cookie.remove('token');
  }

  isPresent() {
    return !!this.cookie.get('token');
  }
}
