import { FuzzyDatePrecision } from '@app/utils';

import { PatientHealthMaintenanceNote } from '@app/core';

export type HealthMaintenanceError = any;
export const newEntityId = -1;

export interface HealthGoalActionAllowedResults {
  type: 'text' | 'radio' | 'integer';
  min?: number;
  max?: number;
  values?: string[];
}

export interface HealthGoalAction {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  uniqueName: string;
  allowedResults?: HealthGoalActionAllowedResults;
}

export interface HealthGoalType {
  id: number;
  name: string;
  healthGoalActions: HealthGoalAction[];
  decisionSupport: string;
}

export type HealthGoalScreeningResult = string;

export interface HealthGoalScreening {
  id: number;
  date: Date;
  dateFuzzy: string;
  datePrecision: FuzzyDatePrecision;
  eventId: number;
  healthGoalId: number;
  normalized: boolean;
  patientId: number;
  patientSubmitted: boolean;
  result: HealthGoalScreeningResult;
  screeningHistoryId: number;
  url: string;
}

export interface HealthGoal {
  id: number;
  comment: string;
  declined: boolean;
  dueAt: Date;
  editedBy: string;
  editedById: number;
  healthGoalActionId: number;
  healthGoalHistoryId: number;
  healthGoalTypeId: number;
  indicated: boolean;
  patientId: number;
  screeningHistoryIds: number[];
  updatedAt: string;
  url: string;
}

export interface HealthGoalWithScreenings extends HealthGoal {
  screeningHistory?: HealthGoalScreening[];
}

/**
 * Projected Views
 */

export interface HealthGoalSummary extends HealthGoal {
  healthGoalActionName: string;
  healthGoalTypeName: string;
  isPastDue: boolean;
}

export interface HealthGoalScreeningSummary extends HealthGoalScreening {
  eventName: string;
}

export interface HealthGoalsSectionLinkPayload {
  healthGoalHistoryIds: number[];
  healthGoalScreeningHistoryIds: number[];
  healthMaintenanceNoteHistoryId: number;
  // NOTE: This to support backwards compatibity during the Angular
  // upgrade. It can be removed
  legacyData: {
    healthGoals: HealthGoal[];
    healthMaintenanceNote: PatientHealthMaintenanceNote;
  };
}

/**
 * Form
 */

export interface HealthGoalForm {
  id: number;
  comment: string;
  dueAt: Date;
  declined: boolean;
  healthGoalActionId: number;
  healthGoalTypeId: number;
  indicated: boolean;
  screeningHistory: Partial<HealthGoalScreening>[];
}

export interface HealthGoalScreeningForm {
  id: number;
  dateFuzzy: string;
  eventId: number;
  healthGoalId: number;
  normalized: boolean;
  patientId: number;
  patientSubmitted: boolean;
  result: HealthGoalScreeningResult;
}

export interface HealthMaintenanceNoteForm {
  content: string;
}

/**
 * Errors
 */

export interface HealthGoalErrors {
  healthGoalTypeId?: string[];
  healthGoalActionId?: string[];
  dueAt?: string[];
  declined?: string[];
  comment?: string[];
}

export interface HealthGoalScreeningErrors {
  id?: string[];
  dateFuzzy?: string[];
  reason?: string[];
}
