/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./angular-highlight-toggle.component";
var styles_AngularHightlightToggleComponent = [];
var RenderType_AngularHightlightToggleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AngularHightlightToggleComponent, data: {} });
export { RenderType_AngularHightlightToggleComponent as RenderType_AngularHightlightToggleComponent };
function View_AngularHightlightToggleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Show Angular 7"]))], null, null); }
function View_AngularHightlightToggleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hide Angular 7"]))], null, null); }
export function View_AngularHightlightToggleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AngularHightlightToggleComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AngularHightlightToggleComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.on; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.on; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AngularHightlightToggleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-angular-highlight-toggle", [], null, null, null, View_AngularHightlightToggleComponent_0, RenderType_AngularHightlightToggleComponent)), i0.ɵdid(1, 49152, null, 0, i2.AngularHightlightToggleComponent, [], null, null)], null, null); }
var AngularHightlightToggleComponentNgFactory = i0.ɵccf("omg-angular-highlight-toggle", i2.AngularHightlightToggleComponent, View_AngularHightlightToggleComponent_Host_0, {}, {}, []);
export { AngularHightlightToggleComponentNgFactory as AngularHightlightToggleComponentNgFactory };
