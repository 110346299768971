/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popover.component";
var styles_PopoverComponent = [];
var RenderType_PopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopoverComponent, data: {} });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
function View_PopoverComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_PopoverComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { content: 0 }), (_l()(), i0.ɵand(0, [[1, 2]], null, 0, null, View_PopoverComponent_1))], null, null); }
export function View_PopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i0.ɵdid(1, 49152, null, 0, i1.PopoverComponent, [], null, null)], null, null); }
var PopoverComponentNgFactory = i0.ɵccf("omg-popover", i1.PopoverComponent, View_PopoverComponent_Host_0, { title: "title", placement: "placement", width: "width", height: "height", enabled: "enabled" }, {}, ["*"]);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
