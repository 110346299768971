import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { AppBridgeSelectors } from '../services/app-bridge.selectors';

interface LegacyData {
  profile: any;
  patient: any;
}

@Injectable()
export class LegacyDataResolver implements Resolve<Observable<LegacyData>> {
  constructor(private selectors: AppBridgeSelectors) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.selectors.profile.pipe(
      withLatestFrom(this.selectors.patient),
      map(([profile, patient]) => of({ profile, patient })),
      take(1),
    );
  }
}
