import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// For upgrade only
import { UpgradeModule } from '@angular/upgrade/static';

import { HotkeyModule } from 'angular2-hotkeys';
import { MarkdownModule } from 'ngx-markdown';

import { CoreModule } from '@app/core/core.module';
import { AppShellModule } from '@app/features/app-shell/app-shell.module';
import { DirectivesModule, SharedModule } from '@app/shared';
import { ToastModule } from '@app/shared/components/toast/toast.module';

import { AppPreloader } from './app-preloader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppUpgradeModule } from './upgrade/app-upgrade.module';
import {
  downgradeComponents,
  downgradedComponentModules,
  downgradedComponents,
  downgradeInjectables,
} from './upgrade/downgraded';

downgradeInjectables();
downgradeComponents();

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    CoreModule,
    SharedModule,
    ToastModule.forRoot(),
    HotkeyModule.forRoot(),
    MarkdownModule.forRoot(),
    DirectivesModule.forRoot(),
    // NOTE: The AppRoutingModule will replace the UpgradeRoutingModule after
    // migration is complete. The upgrade modules can then be removed.
    AppUpgradeModule,
    AppShellModule,
    ...downgradedComponentModules,
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  exports: [SharedModule, AppUpgradeModule],
  providers: [AppPreloader],
  // NOTE: The hybrid downgradeModule approach requires Angular to be manually
  // boostrapped. After the migration is completed the AppComponent can be
  // used as the boostrap and entryComponents can be removed.
  // bootstrap: [AppComponent],
  entryComponents: [AppComponent, ...downgradedComponents],
})
export class AppModule {
  // NOTE: This is only required for the hybrid downgradeModule setup.
  // It can be removed once the migration is completed.
  ngDoBootstrap() {}
}
