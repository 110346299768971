import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SummariesApiService } from '../shared/summaries-api.service';

import { SummariesActions } from './summaries.actions';
import { SummariesEffects } from './summaries.effects';
import { summariesReducer, summariesStatePath } from './summaries.reducer';
import { SummariesSelectors } from './summaries.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(summariesStatePath, summariesReducer),
    EffectsModule.forFeature([SummariesEffects]),
  ],
  providers: [SummariesApiService, SummariesActions, SummariesSelectors],
})
export class SummariesStoreModule {}
