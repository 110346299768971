var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* istanbul ignore next */
export var mapTodoResponseToEntity = function (data, associatedWithId, associatedWithType) {
    if (!data) {
        return {
            assignee: {
                id: null,
                identifier: null,
                name: null,
                role: null,
                label: null,
            },
            associatedWithId: null,
            associatedWithType: null,
            comments: null,
            highPriority: null,
            id: null,
            name: null,
            ownerId: null,
            state: null,
            isManual: null,
            finishLabel: null,
            finishedAt: null,
            finishedBy: null,
        };
    }
    var assignee = data.assignee || {};
    var finishedBy = data.finished_by || {};
    return {
        assignee: {
            id: assignee.id,
            identifier: assignee.identifier,
            name: assignee.name,
            role: assignee.role,
            label: assignee.name + " (" + assignee.role + ")",
        },
        associatedWithId: associatedWithId,
        associatedWithType: associatedWithType,
        comments: data.comments,
        highPriority: data.high_priority,
        id: data.id,
        name: data.name,
        ownerId: data.owner_id,
        state: data.state,
        isManual: data.is_manual,
        finishLabel: data.finish_label,
        finishedAt: data.finished_at,
        finishedBy: {
            id: finishedBy.id,
            name: finishedBy.name,
            role: finishedBy.role,
        },
    };
};
/* istanbul ignore next */
export var mapTodoEntityToUpdateData = function (data) {
    var assignee = data.assignee;
    return {
        assignee: {
            id: assignee.id,
            identifier: assignee.identifier,
            name: assignee.name,
            role: assignee.role,
        },
        comments: data.comments,
        high_priority: data.highPriority,
        id: data.id,
        name: data.name,
        owner_id: data.ownerId,
        state: data.state,
        via_note: data.viaNote || null,
    };
};
export var mapTaskAssigneeSearchResultToEntity = function (data) { return (__assign({}, data._source, { databaseId: data._source.database_id, outUntil: data._source.out_until, label: data._source.name + " (" + data._source.role + ")" })); };
