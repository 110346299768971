<div class="data-table"
     omgCollapse
     #historyCollapse="omgCollapse">
  <!-- ISSUE https://www.pivotaltracker.com/story/show/163981837
    When closing the problem form and reopening, clicking omg-collapse-toggle
    renders both expanded and collapsed states for a second. -->

  <!-- <omg-collapse-toggle>
    <omg-collapsed disableAnimation="true">
      <h4 class="heading">
        <span angulartics2On="click"
              angularticsAction="Problem History Expanded">
          <i class="fa fa-chevron-down"></i>
          Expand problem history
        </span>
      </h4>
    </omg-collapsed>
    <omg-expanded disableAnimation="true">
      <h4 class="heading">
        <span angulartics2On="click"
              angularticsAction="Problem History Collapsed">
          <i class="fa fa-chevron-up"></i>
          Collapse problem history
        </span>
      </h4>
    </omg-expanded>
  </omg-collapse-toggle> -->

  <h4 class="heading"
      *ngIf="!expanded"
      (click)="setExpanded(true)">
    <span angulartics2On="click"
          angularticsAction="Problem History Expanded">
      <i class="fa fa-chevron-down"></i>
      Expand problem history
    </span>
  </h4>
  <h4 class="heading"
      *ngIf="expanded"
      (click)="setExpanded(false)">
    <span angulartics2On="click"
          angularticsAction="Problem History Collapsed">
      <i class="fa fa-chevron-up"></i>
      Collapse problem history
    </span>
  </h4>

  <div class="row"
       *ngIf="editedBy">
    <span *ngIf="editedBy !== 'Patient'">
      Last modified by: {{ editedBy }} at {{ updatedAt | omgDate: 'withTime2y'}}
    </span>
    <span *ngIf="editedBy === 'Patient'">
      {{ aAndP }} at {{ updatedAt | omgDate: 'withTime2y' }}
    </span>
  </div>

  <ng-container *ngIf="history && expanded">
    <div class="row"
         om-layout-gutter
         *ngFor="let hist of history.items">
      <div>
        <span>{{ hist.createdAt | omgDateAgo}} by {{ hist.editedBy.name || 'an unknown user' }}</span>
      </div>
      <strong>{{ hist.problemType.clinicalDescription }}</strong>
      <div *ngIf="hist.problemCodeLocation">{{ hist.problemCodeLocation.name }}</div>
      <div>{{ hist.briefComment }}</div>
      <div>{{ hist.aAndP }}</div>
    </div>

    <div *ngIf="history.total > 50"
         class="row">
      <strong>{{ history.total - 50 }} older edits not shown</strong>
    </div>
    <div *ngIf="historyStartDate"
         class="row">
      <strong>Edits to this problem made before {{ historyStateDate | omgDate: '4y' }} are not available</strong>
    </div>
  </ng-container>
</div>
