import {
  HealthBackground,
  HealthBackgroundNote,
  HealthBackgroundNoteType,
  healthBackgroundNoteTypeMap,
  HealthBackgroundResponse,
  HealthBackgroundResponseNote,
} from './health-background.type';

/* istanbul ignore next */
export const buildNote = (
  healthBackground: HealthBackground,
  noteType: HealthBackgroundNoteType,
  reviewed = false,
): HealthBackgroundNote => ({
  ...healthBackground[noteType],
  reviewed,
});

/* istanbul ignore next */
export const mapHealthBackgroundResponseToEntity = (
  data: HealthBackgroundResponse,
): HealthBackground => {
  const mapToNote = (
    note: HealthBackgroundResponseNote,
  ): HealthBackgroundNote => ({
    notes: note.notes || '',
    updatedBy: note.updated_by || '',
    updatedAt: note.updated_at ? new Date(note.updated_at) : null,
    reviewed: note.reviewed || false,
  });

  return {
    healthBackgroundHistoryId: data.health_background_history_id,
    illnessesAndSurgeries: mapToNote(data.illnesses_and_surgeries),
    familyData: mapToNote(data.family_data),
    socialData: mapToNote(data.social_data),
  };
};

/* istanbul ignore next */
export const mapToHealthBackgroundResponse = (
  hb: HealthBackground,
): HealthBackgroundResponse => {
  const mapToNote = (
    note: HealthBackgroundNote,
  ): HealthBackgroundResponseNote => ({
    notes: note.notes || '',
    updated_by: note.updatedBy || '',
    updated_at: note.updatedAt ? note.updatedAt.toISOString() : null,
    reviewed: note.reviewed || false,
  });

  return {
    health_background_history_id: hb.healthBackgroundHistoryId,
    illnesses_and_surgeries: mapToNote(hb.illnessesAndSurgeries),
    family_data: mapToNote(hb.familyData),
    social_data: mapToNote(hb.socialData),
  };
};

export const mapToUpdateHealthBackgroundPayload = (
  noteType: HealthBackgroundNoteType,
  notes: string,
) => ({
  [healthBackgroundNoteTypeMap[noteType]]: notes,
});
