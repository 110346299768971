import { difference, flow, get, join } from '@app/utils';
export var registerFormControls = function (form, controls) {
    if (controls === void 0) { controls = {}; }
    for (var key in controls) {
        if (key) {
            var control = controls[key];
            if (control && !form.controls[key]) {
                form.registerControl(key, control);
            }
        }
    }
};
export var setFormErrors = function (form, errors, errorKey) {
    if (errorKey === void 0) { errorKey = 'apiError'; }
    var _a;
    var mapApiError = function (error) {
        return Array.isArray(error) ? error.join(' ') : error;
    };
    for (var key in errors) {
        if (errors.hasOwnProperty(key)) {
            var error = errors[key];
            var control = form.controls[key];
            if (error && control) {
                control.setErrors((_a = {}, _a[errorKey] = mapApiError(error), _a));
            }
        }
    }
};
export var updateFormArray = function (array, items, comparisonField, itemMapper) {
    if (!array) {
        return;
    }
    if (array.controls.length === 0) {
        items.map(function (item) { return array.push(itemMapper(item)); });
    }
    var inFormArray = array.controls.map(function (ctrl) { return ctrl.get(comparisonField).value; });
    var inItems = items.map(function (ctrl) { return ctrl[comparisonField]; });
    var toRemove = difference(inFormArray, inItems);
    var toAdd = difference(inItems, inFormArray);
    toRemove.forEach(function (value) {
        return array.removeAt(array.value.findIndex(function (item) { return item[comparisonField] === value; }));
    });
    toAdd.forEach(function (value) {
        return array.push(itemMapper(items.find(function (item) { return item[comparisonField] === value; })));
    });
};
export var mapApiResponseError = function (error) {
    return flow(get('data.errors'), join(' '))(error);
};
