/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./problem-insights.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dropdown/dropdown.component.ngfactory";
import * as i3 from "../../../../shared/components/dropdown/dropdown.component";
import * as i4 from "@angular/forms";
import * as i5 from "../insights-graph/insights-graph.component.ngfactory";
import * as i6 from "../insights-graph/insights-graph.component";
import * as i7 from "@angular/common";
import * as i8 from "./problem-insights.component";
import * as i9 from "../../../../core/patient/store/patient.selectors";
import * as i10 from "../../shared/insights.service";
var styles_ProblemInsightsComponent = [i0.styles];
var RenderType_ProblemInsightsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProblemInsightsComponent, data: {} });
export { RenderType_ProblemInsightsComponent as RenderType_ProblemInsightsComponent };
function View_ProblemInsightsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "omg-dropdown", [["flex", "true"], ["om-flex", "75"], ["placeholder", "Select an Insight"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedInsight = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.insightSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DropdownComponent_0, i2.RenderType_DropdownComponent)), i1.ɵdid(1, 573440, null, 0, i3.DropdownComponent, [i1.NgZone], { options: [0, "options"], placeholder: [1, "placeholder"], flex: [2, "flex"], flexValue: [3, "flexValue"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DropdownComponent]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _v.context.ngIf; var currVal_8 = "Select an Insight"; var currVal_9 = "true"; var currVal_10 = "75"; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.selectedInsight; _ck(_v, 3, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ProblemInsightsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-lg fa-spinner fa-pulse padding-half-vertical"]], null, null, null, null, null))], null, null); }
function View_ProblemInsightsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-tab-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Insights available for patient."]))], null, null); }
function View_ProblemInsightsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-tab-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select an insight."]))], null, null); }
function View_ProblemInsightsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-insights-graph", [], null, null, null, i5.View_InsightsGraphComponent_0, i5.RenderType_InsightsGraphComponent)), i1.ɵdid(1, 114688, null, 0, i6.InsightsGraphComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProblemInsightsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemInsightsComponent_7)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.insightDataTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProblemInsightsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "spinner empty-tab-message"], ["om-layout", ""], ["om-layout-align", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-lg fa-spinner fa-pulse padding-half-vertical"]], null, null, null, null, null))], null, null); }
function View_ProblemInsightsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProblemInsightsComponent_6)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["graphLoading", 2]], null, 0, null, View_ProblemInsightsComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.groupedData)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProblemInsightsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "om-tab-subheading"], ["om-layout", ""], ["om-layout-align", "space-between stretch"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProblemInsightsComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_ProblemInsightsComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemInsightsComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemInsightsComponent_4)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProblemInsightsComponent_5)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.insightsDropdownOptions)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.insights && (((_co.insights == null) ? null : _co.insights.length) === 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.selectedInsight && (((_co.insights == null) ? null : _co.insights.length) !== 0)); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.selectedInsight; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_ProblemInsightsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-problem-insights", [], null, null, null, View_ProblemInsightsComponent_0, RenderType_ProblemInsightsComponent)), i1.ɵdid(1, 245760, null, 0, i8.ProblemInsightsComponent, [i9.PatientSelectors, i10.InsightsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProblemInsightsComponentNgFactory = i1.ɵccf("omg-problem-insights", i8.ProblemInsightsComponent, View_ProblemInsightsComponent_Host_0, {}, {}, []);
export { ProblemInsightsComponentNgFactory as ProblemInsightsComponentNgFactory };
