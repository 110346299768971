<omg-card>
  <omg-card-title heading="Allergies & Intolerances"
                  omgCollapse
                  #cardCollapse="omgCollapse"
                  omgFocusProvider
                  #focus="omgFocusProvider">
    <button omgTooltip
            tooltipText="Add Allergy [ A ] "
            omgButton
            omgShortcutClick="a"
            variant="invisible"
            omgCollapseToggle="expand"
            tooltipPosition="top"
            (click)="focus.setFocus('patientAllergiesFocus-create')">
      <span class="om-icon clickable icon-add-new"></span>
    </button>
    <button omgButton
            variant="invisible"
            (click)="addToNote()"
            omgTooltip
            tooltipText="Add to Note"
            tooltipPosition="top"
            angulartics2On="click"
            angularticsAction="Allergies Section Added"
            [disabled]="!(isSummaryActive | async)">
      <span class="om-icon clickable icon-push-to-note"></span>
    </button>
  </omg-card-title>

  <omg-expanded [collapseProvider]="cardCollapse">
    <omg-allergy-form class="-subform"
                      (post)="addAllergy($event)"
                      (close)="cardCollapse.collapse()"
                      [isAddingAllergy]="true">
    </omg-allergy-form>
  </omg-expanded>

  <div class="om-list"
       om-card-content
       *ngIf="patientAllergies">
    <div *ngIf="activePatientAllergies.length === 0">
      <div class="om-item"
           *ngIf="!patientAllergies?.noKnownAllergies?.editedBy">
        <div om-layout
             om-layout-gutter>
          <div om-flex><span class="om-label">Not yet asked</span></div>
          <div>
            <button omgButton
                    variant="secondary"
                    (click)="setPatientNoKnownAllergy()"
                    data-qaid="mark-no-known-allergies">
              Mark as No Known Allergies
            </button>
          </div>
        </div>
      </div>

      <div class="om-item"
           *ngIf="patientAllergies?.noKnownAllergies?.editedBy">
        <div om-layout
             om-layout-gutter>
          <div om-flex>
            <strong class="inline"
                    data-qaid="no-known-allergies"> No Known Allergies </strong>
            <div>
              Reviewed by {{ patientAllergies.noKnownAllergies.editedBy }} on
              {{ patientAllergies.noKnownAllergies.updatedAt | omgDate: '2y' }}
            </div>
          </div>
          <div>
            <button omgButton
                    variant="flat"
                    (click)="setPatientNoKnownAllergy()">
              Mark Reviewed
            </button>
          </div>
        </div>
      </div>
    </div>

    <omg-allergy-item class="om-item clickable"
                      om-collapse
                      name="patientAllergyEditor"
                      data-qaid="active-allergy"
                      *ngFor="let allergyItem of activePatientAllergies; trackBy: trackByFn"
                      [allergyItem]="allergyItem"
                      (delete)="deleteAllergy($event)"
                      (update)="updateAllergy($event)"
                      (activate)="activateAllergy($event)">
    </omg-allergy-item>
  </div>

  <omg-collapse *ngIf="inactivePatientAllergies.length > 0">
    <omg-collapse-toggle class="-separator">
      <omg-collapsed data-qaid="toggle-resolved-allergies"
                     disableAnimation="true">Show Resolved Allergies ({{ inactivePatientAllergies.length }})</omg-collapsed>
      <omg-expanded disableAnimation="true">Hide Resolved Allergies</omg-expanded>
    </omg-collapse-toggle>

    <omg-expanded>
      <ng-container *ngIf="inactivePatientAllergies">
        <omg-allergy-item class="om-item clickable"
                          om-collapse
                          *ngFor="let allergyItem of inactivePatientAllergies; trackBy: trackByFn"
                          data-qaid="inactive-allergy"
                          [allergyItem]="allergyItem"
                          (update)="updateAllergy($event)"
                          (activate)="activateAllergy($event)"
                          (delete)="deleteAllergy($event)">
        </omg-allergy-item>
      </ng-container>
    </omg-expanded>
  </omg-collapse>
</omg-card>
