var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService } from '../../errors/error-handler.service';
import { GET_PROFILE } from '../../profile/store/profile.actions';
import { FeatureFlagApiService } from '../shared/feature-flag-api.service';
import { GET_FEATURE_FLAGS, GetFeatureFlagsError, GetFeatureFlagsSuccess, } from './feature-flag.actions';
import { selectFeatureFlags } from './feature-flag.selectors';
var FeatureFlagEffects = /** @class */ (function () {
    function FeatureFlagEffects(action$, store, featureFlagApi, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.store = store;
        this.featureFlagApi = featureFlagApi;
        this.errorHandler = errorHandler;
        /** Effect also listens to getProfile to handle the initial load of feature flags. */
        this.getFeatureFlags$ = this.action$.pipe(ofType(GET_PROFILE, GET_FEATURE_FLAGS), withLatestFrom(this.store.pipe(select(selectFeatureFlags))), 
        /** Check if the features flags has been loaded before. */
        filter(function (_a) {
            var action = _a[0], featureFlags = _a[1];
            return !featureFlags;
        }), mergeMap(function () {
            return _this.featureFlagApi.get().pipe(map(function (response) { return new GetFeatureFlagsSuccess(response); }), catchError(function (error) {
                return of(new GetFeatureFlagsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], FeatureFlagEffects.prototype, "getFeatureFlags$", void 0);
    return FeatureFlagEffects;
}());
export { FeatureFlagEffects };
