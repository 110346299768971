<form [formGroup]="formModel.form"
      [omgForm]="formModel"
      om-layout="vertical">
  <label om-layout="vertical">
    {{ formTitle }}
    <textarea omgTextarea
              autosize
              rows="3"
              formControlName="notes"></textarea>
  </label>
  <div om-layout
       om-layout="space-between center">
    <omg-form-state class="caption"
                    om-flex="75"
                    om-layout-fill>
      <ng-template let-change>
        <span *ngIf="change.state !== 'saving' && updatedAt">
          Updated by {{ updatedBy }} at {{ updatedAt | omgDate:'withTime2y' }}
        </span>
      </ng-template>
      <omg-form-state when="saving"></omg-form-state>
    </omg-form-state>
    <a class="right-align"
       type="button"
       om-layout-fill
       *ngIf="!reviewed"
       (click)="markAsReviewedClick()">
      Mark Reviewed
    </a>
    <strong class="right-align"
            om-layout-fill
            *ngIf="reviewed">
      <i class="fa fa-check"></i> Reviewed
    </strong>
  </div>
</form>
