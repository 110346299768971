<nav>
  <ul class="om-nav">
    <li class="om-logo">
      <img src="img/om-atom.ad772541.svg"
           alt="One Medical" />
    </li>
    <li class="om-nav-item"
        omgLegacyUiHref="/admin">
      <h4>Home</h4>
    </li>
    <li class="om-nav-item">
      <a *ngIf="!!(adminUiTasksEnabled$ | async)"
         [href]="adminAppURL">
        <h4>Tasks</h4>
      </a>
      <a *ngIf="!(adminUiTasksEnabled$ | async)"
         omgLegacyUiHref="/admin/task?current_user">
        <h4>Tasks</h4>
      </a>
    </li>
    <li class="om-nav-item"
        *ngIf="isEngineer$ | async">
      <a href="/storybook/index.html"
         target="_blank">
        <h4>Storybook</h4>
      </a>
    </li>
    <p-menu #profileMenu
            styleClass="signout-button"
            [model]="profileOptions | async"
            [popup]="true"></p-menu>
    <li (click)="toggleProfileMenu($event)"
        class="om-nav-item pull-right">
      <h4>{{ primaryIdentityName | async }}</h4>
      <i class="fa fa-caret-down"></i>
    </li>
  </ul>
</nav>
