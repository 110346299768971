import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { upgradeTestRoutePath } from '../routing/custom-url-handling-strategy';
import { downgradeComponent } from '../shared/utils';

@Component({
  selector: 'omg-upgrade-route-link',
  template: `
    <a title="WORK IN PROGRESS" (click)="onClick()">Angular 7 Chart</a>
  `,
})
export class UpgradeRouteLinkComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  constructor(
    private patientSelectors: PatientSelectors,
    private router: Router,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onClick() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        tap(patientId => {
          this.router.navigateByUrl(
            `${upgradeTestRoutePath}/patients/${patientId}/chart`,
          );
        }),
        take(1),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }
}

downgradeComponent(UpgradeRouteLinkComponent, 'omgUpgradeRouteLink');
