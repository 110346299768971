import { Subject } from 'rxjs';
var FocusService = /** @class */ (function () {
    function FocusService() {
        this._subject = new Subject();
        this._focusChanges = this._subject.asObservable();
    }
    Object.defineProperty(FocusService.prototype, "focusChanges", {
        get: function () {
            return this._focusChanges;
        },
        enumerable: true,
        configurable: true
    });
    FocusService.prototype.setFocus = function (key) {
        var _this = this;
        /* Workaround to ensure *ngIf has rendered the element and is able to utilize
         * the omgFocusOn directive properly.
         */
        setTimeout(function () { return _this._subject.next(key); });
    };
    return FocusService;
}());
export { FocusService };
