/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "primeng/components/checkbox/checkbox";
import * as i5 from "./checkbox.component";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { checkbox: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "p-checkbox", [["binary", "true"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Checkbox_0, i2.RenderType_Checkbox)), i1.ɵdid(2, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵdid(4, 49152, [[1, 4]], 0, i4.Checkbox, [i1.ChangeDetectorRef], { name: [0, "name"], disabled: [1, "disabled"], binary: [2, "binary"], label: [3, "label"], tabindex: [4, "tabindex"], styleClass: [5, "styleClass"] }, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Checkbox]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.required; _ck(_v, 2, 0, currVal_8); var currVal_9 = _co.name; var currVal_10 = _co.disabled; var currVal_11 = "true"; var currVal_12 = _co.label; var currVal_13 = _co.tabindex; var currVal_14 = (_co.plainLabel ? "plain-text" : ""); _ck(_v, 4, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.name; var currVal_16 = _co.disabled; var currVal_17 = _co.value; _ck(_v, 6, 0, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).required ? "" : null); var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "omg-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxComponent]), i1.ɵdid(2, 49152, null, 0, i5.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("omg-checkbox", i5.CheckboxComponent, View_CheckboxComponent_Host_0, { name: "name", label: "label", plainLabel: "plainLabel", tabindex: "tabindex", disabled: "disabled", required: "required", value: "value" }, {}, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
