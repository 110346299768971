import { NgZone, OnChanges, SimpleChanges, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent(ngZone) {
        this.ngZone = ngZone;
        this.optionLabel = null;
        this.optionKey = null;
        this.placeholder = null;
        this.filterBy = 'label';
        this.filterPlaceholder = null;
        this.disabled = false;
        this.readonly = false;
        this.required = false;
        this.flex = false;
        this.appendTo = 'body';
        // tslint:disable-next-line:no-input-rename
        this.flexValue = null;
        this._value = false;
        this.styleClass = '';
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(DropdownComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            if (this._value !== value) {
                this._value = value;
                this.onChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownComponent.prototype, "inlineStyle", {
        // This is a workaround to use the primeng dropdown in a flexbox
        get: function () {
            var inlineStyle = {};
            if (this.flexValue) {
                inlineStyle.width = this.flexValue + "%";
            }
            else if (this.flex) {
                inlineStyle.width = '100%';
            }
            return inlineStyle;
        },
        enumerable: true,
        configurable: true
    });
    DropdownComponent.prototype.ngOnChanges = function (changes) {
        if (changes.options &&
            changes.options.currentValue !== changes.options.previousValue) {
            this.setCalculatedReadOnly();
        }
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.onShow = function (event) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            return window.addEventListener('scroll', _this.closeDropdown.bind(_this), true);
        });
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.onHide = function (event) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            return window.removeEventListener('scroll', _this.closeDropdown.bind(_this), true);
        });
    };
    DropdownComponent.prototype.focus = function () {
        this.ref.focus();
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.writeValue = function (value) {
        this._value = value;
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    /* istanbul ignore next */
    DropdownComponent.prototype.closeDropdown = function (event) {
        if (this.ref && this.ref.overlayVisible) {
            this.ref.alignOverlay();
        }
    };
    DropdownComponent.prototype.setCalculatedReadOnly = function () {
        this.calculatedReadOnly =
            this.readonly || (!!this.options && [0, 1].includes(this.options.length));
        if (this.calculatedReadOnly) {
            if (!this.styleClass.includes('ui-state-readonly')) {
                this.styleClass += ' ui-state-readonly';
            }
        }
        else {
            this.styleClass = this.styleClass.replace('ui-state-readonly', '');
        }
    };
    return DropdownComponent;
}());
export { DropdownComponent };
