var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createEntityAdapter, } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { TodoActionTypes } from './todo.actions';
export var todoStatePath = 'todo';
export var selectTodoState = createFeatureSelector(todoStatePath);
export function selectTodoId(todo) {
    return todo.id;
}
export var adapter = createEntityAdapter({
    selectId: selectTodoId,
});
export var todoInitialState = adapter.getInitialState({
    loading: false,
    error: null,
});
export var selectEntities = (_a = adapter.getSelectors(), _a.selectEntities), selectAll = _a.selectAll;
export function todoReducer(state, action) {
    if (state === void 0) { state = todoInitialState; }
    switch (action.type) {
        case TodoActionTypes.LOAD_TODO:
        case TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID: {
            return __assign({}, state, { loading: true });
        }
        case TodoActionTypes.LOAD_TODO_SUCCESS: {
            return adapter.upsertOne(action.payload, __assign({}, state, { loading: false }));
        }
        case TodoActionTypes.LOAD_TODO_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case TodoActionTypes.UPDATE_TODO: {
            return __assign({}, state, { loading: true });
        }
        case TodoActionTypes.UPDATE_TODO_SUCCESS: {
            var update = {
                id: action.payload.id,
                changes: action.payload,
            };
            return adapter.updateOne(update, __assign({}, state, { loading: false }));
        }
        case TodoActionTypes.UPDATE_TODO_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case TodoActionTypes.COMPLETE_TODO: {
            return __assign({}, state, { loading: true });
        }
        case TodoActionTypes.COMPLETE_TODO_SUCCESS: {
            var update = {
                id: action.payload.id,
                changes: action.payload,
            };
            return adapter.updateOne(update, __assign({}, state, { loading: false }));
        }
        case TodoActionTypes.COMPLETE_TODO_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case TodoActionTypes.REOPEN_TODO: {
            return __assign({}, state, { loading: true });
        }
        case TodoActionTypes.REOPEN_TODO_SUCCESS: {
            var update = {
                id: action.payload.id,
                changes: action.payload,
            };
            return adapter.updateOne(update, __assign({}, state, { loading: false }));
        }
        case TodoActionTypes.REOPEN_TODO_ERROR: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        default: {
            return __assign({}, state);
        }
    }
}
