import { createSelector } from '@ngrx/store';

import { filter, isPastDue, map, pipe } from '@app/utils';
import { createEntityMetadataSelectors } from '@app/utils/store';

import {
  getGoalTypeAndActionNames,
  sortHealthGoals,
} from '../shared/health-maintenance-utils';
import {
  HealthGoal,
  HealthGoalSummary,
  HealthGoalType,
} from '../shared/health-maintenance.type';
import { selectAllHealthGoalTypes } from './health-goal-type.selectors';
import * as fromHealthGoal from './health-goal.reducer';
import { selectHealthMaintenanceState } from './health-maintenance-shared';

// selects the state slice
export const selectHealthGoalState = createSelector(
  selectHealthMaintenanceState,
  state => state.healthGoals,
);

// selects array of ids
export const selectHealthGoalIds = createSelector(
  selectHealthGoalState,
  fromHealthGoal.selectHealthGoalIds,
);

// selects the dictionary
export const selectHealthGoalEntities = createSelector(
  selectHealthGoalState,
  fromHealthGoal.selectHealthGoalEntities,
);

// selects the array
export const selectAllHealthGoals = createSelector(
  selectHealthGoalState,
  fromHealthGoal.selectAllHealthGoals,
);

// selects by item id
export const selectHealthGoalById = createSelector(
  selectHealthGoalState,
  (state, { id }) => state.entities[id],
);

// selects the total number count
export const selectTotalHealthGoals = createSelector(
  selectHealthGoalState,
  fromHealthGoal.selectHealthGoalTotal,
);

// selects loading
export const selectLoadingHealthGoals = createSelector(
  selectHealthGoalState,
  state => state && state.loading,
);

// selects the general state error
export const selectHealthGoalsError = createSelector(
  selectHealthGoalState,
  state => state && state.error,
);

/**
 * Projected Views
 */

const mapToSummary = (
  goal: HealthGoal,
  healthGoalTypes: HealthGoalType[],
): HealthGoalSummary => {
  const {
    healthGoalTypeName,
    healthGoalActionName,
  } = getGoalTypeAndActionNames(
    healthGoalTypes,
    goal.healthGoalTypeId,
    goal.healthGoalActionId,
  );

  return {
    ...goal,
    healthGoalTypeName,
    healthGoalActionName,
    isPastDue: isPastDue(goal.dueAt),
  };
};

// selects summary view
export const selectAllHealthGoalSummmaries = createSelector(
  selectAllHealthGoals,
  selectAllHealthGoalTypes,
  (healthGoals, healthGoalTypes) =>
    pipe(
      filter((i: HealthGoal) => i.id > 0),
      map(i => mapToSummary(i, healthGoalTypes)),
    )(healthGoals),
);

// selects indicated
export const selectIndicatedHealthGoals = createSelector(
  selectAllHealthGoalSummmaries,
  healthGoals =>
    pipe(
      filter((i: HealthGoalSummary) => i.indicated),
      sortHealthGoals,
    )(healthGoals) as HealthGoalSummary[],
);

// selects not indicated
export const selectNotIndicatedHealthGoals = createSelector(
  selectAllHealthGoalSummmaries,
  healthGoals =>
    pipe(
      filter((i: HealthGoalSummary) => !i.indicated),
      sortHealthGoals,
    )(healthGoals) as HealthGoalSummary[],
);

/**
 * Entity Metadata
 */

const {
  selectEntityMetadata,
  selectEntityWithMetadata,
  selectEntityError,
} = createEntityMetadataSelectors(selectHealthGoalState, selectHealthGoalById);

export const selectHealthGoalMetadata = selectEntityMetadata;
export const selectHealthGoalWithMetadata = selectEntityWithMetadata;
export const selectHealthGoalError = selectEntityError;

export const selectHealthGoalSummaryWithMetadata = createSelector(
  selectHealthGoalWithMetadata,
  selectAllHealthGoalTypes,
  (data, healthGoalTypes) => ({
    ...data,
    entity: mapToSummary(data.entity, healthGoalTypes) as HealthGoalSummary,
  }),
);
