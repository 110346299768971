/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./allergy-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "../../../../shared/directives/tooltip/tooltip.directive";
import * as i4 from "../../../../shared/directives/stop-event.directive";
import * as i5 from "../../../../shared/components/collapse/collapse.directive";
import * as i6 from "../../../../shared/components/collapse/collapse-provider.directive";
import * as i7 from "../../../../shared/components/collapse/collapse-toggle.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../../../shared/components/collapse/expanded.component.ngfactory";
import * as i10 from "../../../../shared/components/collapse/expanded.component";
import * as i11 from "../allergy-form/allergy-form.component.ngfactory";
import * as i12 from "../allergy-form/allergy-form.component";
import * as i13 from "../../shared/allergies.service";
import * as i14 from "@angular/forms";
import * as i15 from "./allergy-item.component";
import * as i16 from "../../../../core/feature-flag/store/feature-flag.selectors";
var styles_AllergyItemComponent = [i0.styles];
var RenderType_AllergyItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AllergyItemComponent, data: {} });
export { RenderType_AllergyItemComponent as RenderType_AllergyItemComponent };
function View_AllergyItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["omgTooltip", ""], ["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4931584, null, 0, i3.TooltipDirective, [i1.ElementRef, i2.DomHandler, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipText: [1, "tooltipText"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "om-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drug-Allergy Incompatible"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top"; var currVal_1 = _co.notCheckedWarning; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AllergyItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["data-qaid", "resolve-allergy-button"], ["omgStopEvent", "click"], ["omgTooltip", ""], ["tooltipPosition", "top"], ["tooltipText", "Resolve Allergy"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateAllergy(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i4.StopEventDirective, [i1.Renderer2, i1.ElementRef], { omgStopEvent: [0, "omgStopEvent"] }, null), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(3, 4931584, null, 0, i3.TooltipDirective, [i1.ElementRef, i2.DomHandler, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipText: [1, "tooltipText"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "om-icon icon-dismiss -gray clickable"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "click"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "top"; var currVal_2 = "Resolve Allergy"; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
function View_AllergyItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["data-qaid", "reactivate-allergy-button"], ["omgStopEvent", "click"], ["omgTooltip", ""], ["tooltipPosition", "top"], ["tooltipText", "Reactivate Allergy"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateAllergy(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i4.StopEventDirective, [i1.Renderer2, i1.ElementRef], { omgStopEvent: [0, "omgStopEvent"] }, null), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(3, 4931584, null, 0, i3.TooltipDirective, [i1.ElementRef, i2.DomHandler, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipText: [1, "tooltipText"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "om-icon fa fa-undo clickable"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "click"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "top"; var currVal_2 = "Reactivate Allergy"; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_AllergyItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { collapseRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "omg-collapse", [["omgCollapse", ""]], [[2, "om-collapse", null], [1, "om-collapse", 0], [2, "om-collapse-expanded", null], [2, "-disabled", null]], null, null, null, null)), i1.ɵdid(2, 81920, [[1, 4], ["collapseRef", 4]], 0, i5.CollapseDirective, [], null, null), i1.ɵdid(3, 540672, null, 0, i6.CollapseProviderDirective, [[2, i5.CollapseDirective]], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["om-layout", ""], ["omgCollapseToggle", ""]], [[2, "om-collapse-toggle", null], [2, "om-collapse-collapse", null], [2, "om-collapse-expand", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 540672, null, 0, i7.CollapseToggleDirective, [[2, i6.CollapseProviderDirective]], { toggleAction: [0, "toggleAction"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["om-flex", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllergyItemComponent_1)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllergyItemComponent_2)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllergyItemComponent_3)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "omg-expanded", [], [[2, "om-expanded", null]], null, null, i9.View_ExpandedComponent_0, i9.RenderType_ExpandedComponent)), i1.ɵdid(18, 573440, null, 0, i10.ExpandedComponent, [[2, i6.CollapseProviderDirective]], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 1, "omg-allergy-form", [["class", "-subform"]], null, [[null, "close"], [null, "update"], [null, "delete"], [null, "reactionModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } if (("update" === en)) {
        var pd_1 = (_co.onUpdate($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.onDelete($event) !== false);
        ad = (pd_2 && ad);
    } if (("reactionModelChange" === en)) {
        var pd_3 = (_co.updateReaction($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i11.View_AllergyFormComponent_0, i11.RenderType_AllergyFormComponent)), i1.ɵdid(20, 245760, null, 0, i12.AllergyFormComponent, [i13.AllergiesService, i14.FormBuilder], { allergyItem: [0, "allergyItem"] }, { update: "update", delete: "delete", close: "close", reactionModelChange: "reactionModelChange" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_7 = ""; _ck(_v, 5, 0, currVal_7); var currVal_10 = _co.notCheckedWarning; _ck(_v, 12, 0, currVal_10); var currVal_11 = _co.allergyItem.active; _ck(_v, 14, 0, currVal_11); var currVal_12 = !_co.allergyItem.active; _ck(_v, 16, 0, currVal_12); var currVal_14 = _co.allergyItem; _ck(_v, 20, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).collapseClass; var currVal_1 = i1.ɵnov(_v, 2).collapseAttribute; var currVal_2 = i1.ɵnov(_v, 2).expandedClass; var currVal_3 = i1.ɵnov(_v, 2).disabledClass; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 5).toggleClass; var currVal_5 = i1.ɵnov(_v, 5).toggleCollapseClass; var currVal_6 = i1.ɵnov(_v, 5).toggleExpandClass; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.allergyItem.allergy.name; _ck(_v, 9, 0, currVal_8); var currVal_9 = _co.reactionText; _ck(_v, 10, 0, currVal_9); var currVal_13 = i1.ɵnov(_v, 18).expandedClass; _ck(_v, 17, 0, currVal_13); }); }
export function View_AllergyItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-allergy-item", [], null, null, null, View_AllergyItemComponent_0, RenderType_AllergyItemComponent)), i1.ɵdid(1, 638976, null, 0, i15.AllergyItemComponent, [i16.FeatureFlagSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AllergyItemComponentNgFactory = i1.ɵccf("omg-allergy-item", i15.AllergyItemComponent, View_AllergyItemComponent_Host_0, { allergyItem: "allergyItem" }, { delete: "delete", update: "update", activate: "activate" }, []);
export { AllergyItemComponentNgFactory as AllergyItemComponentNgFactory };
