import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import { PatientUpdateData } from '../shared/patient-update.type';
import { Patient } from '../shared/patient.type';
import { PatientState } from './patient.reducer';

export const GET_PATIENT = '[Patient] Get Patient';
export const GET_PATIENT_SUCCESS = '[Patient] Get Patient Success';
export const GET_PATIENT_ERROR = '[Patient] Get Patient Error';
export const UPDATE_PATIENT = '[Patient] Update Patient';
export const UPDATE_PATIENT_SUCCESS = '[Patient] Update Patient Success';
export const UPDATE_PATIENT_ERROR = '[Patient] Update Patient Error';

interface GetPatientPayload {
  id: number;
  forceGet?: boolean;
}

interface UpdatePatientPayload {
  id: number;
  data: PatientUpdateData;
}

type PatientErrorPayload = any;

export class GetPatient implements FsaAction<GetPatientPayload> {
  readonly type = GET_PATIENT;

  constructor(public payload: GetPatientPayload) {}
}

export class GetPatientSuccess implements FsaAction<Patient> {
  readonly type = GET_PATIENT_SUCCESS;
  error = false;

  constructor(public payload: Patient) {}
}

export class GetPatientError implements FsaAction<any> {
  readonly type = GET_PATIENT_ERROR;
  error = true;

  constructor(public payload: PatientErrorPayload) {}
}

export class UpdatePatient implements FsaAction<UpdatePatientPayload> {
  readonly type = UPDATE_PATIENT;

  constructor(public payload: UpdatePatientPayload) {}
}

export class UpdatePatientSuccess implements FsaAction<Patient> {
  readonly type = UPDATE_PATIENT_SUCCESS;
  error = false;

  constructor(public payload: Patient) {}
}

export class UpdatePatientError implements FsaAction<any> {
  readonly type = UPDATE_PATIENT_ERROR;
  error = true;

  constructor(public payload: PatientErrorPayload) {}
}

export type PatientAction =
  | GetPatient
  | GetPatientSuccess
  | GetPatientError
  | UpdatePatient
  | UpdatePatientSuccess
  | UpdatePatientError;

@Injectable()
export class PatientActions {
  constructor(private store: Store<PatientState>) {}

  getPatient(id: number, forceGet?: boolean) {
    this.store.dispatch(new GetPatient({ id, forceGet }));
  }

  updatePatient(id: number, data: PatientUpdateData) {
    this.store.dispatch(new UpdatePatient({ id, data }));
  }
}
