<form [formGroup]="form"
      class="om-form"
      (ngSubmit)="markSubmitted(true)">
  <div class="padding-normal"
       om-layout-align="start start"
       om-layout="vertical"
       om-layout-gutter>
    <div class="label"
         om-layout
         om-layout-align="space-between">Allergen</div>

    <omg-auto-complete #allergenInput
                       placeholder="Find allergen...."
                       [items]="autocompleteItems"
                       trackByKey="id"
                       (search)="getSearchResults($event)"
                       [omgFocusOn]="focusKey"
                       (focusOnRequest)="allergenInput.focus()"
                       omgStopEvent
                       formControlName="allergyId"
                       bindLabel="name"
                       bindValue="id"
                       data-qaid="allergy-input"
                       om-layout-fill>
    </omg-auto-complete>

    <div class="alert"
         *ngIf="attemptedSubmit && form.get('allergyId').invalid">
      <div>
        Cannot be blank. To ensure clinical quality, please pick from list.
      </div>
    </div>

    <label om-layout-fill>
      Reaction
      <input class="om-input"
             formControlName="reaction"
             data-qaid="allergy-reaction-input"
             om-layout-fill
             type="text"
             maxlength="32"
             omgStopEvent
             placeholder="e.g. rash, anaphylaxis, hives"
             [ngClass]="{ caption: !form.get('reaction').value }"
             (ngModelChange)="onReactionChange()" />
    </label>

    <label om-layout-fill>
      Comments
      <textarea omgTextarea
                autosize
                rows="2"
                formControlName="comment"
                omgStopEvent
                om-layout-fill></textarea>
    </label>
  </div>

  <div class="action-bar"
       om-layout
       om-layout-gutter
       om-layout-fill>
    <ng-container *ngIf="isAddingAllergy">
      <button type="submit"
              data-qaid="add-allergy-button"
              omgButton
              variant="primary"
              (click)="onPost()"
              [disabled]="form.pristine">
        Add
      </button>
      <button type="button"
              omgButton
              variant="flat"
              (click)="closeAndResetForm()">
        Cancel
      </button>
    </ng-container>

    <ng-container *ngIf="!isAddingAllergy">
      <button type="submit"
              omgButton
              variant="primary"
              (click)="onUpdate()"
              [disabled]="form.pristine">
        Update
      </button>
      <button type="button"
              omgButton
              variant="flat"
              (click)="closeAndResetForm()">
        Cancel
      </button>
      <button type="submit"
              omgButton
              data-qaid="delete-allergy-button"
              variant="critical-link"
              (click)="onDelete()">
        Delete
      </button>
    </ng-container>
  </div>
</form>
