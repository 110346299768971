import { BACKSPACE } from '@angular/cdk/keycodes';
import { NgZone, OnDestroy, OnInit, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { InjectSlashPipe } from '@app/shared/pipes/inject-slash.pipe';
var dateFormats = {
    fullYear: { format: 'mm/dd/yy', placeholder: 'mm/dd/yyyy' },
    shortYear: { format: 'mm/dd/y', placeholder: 'mm/dd/yy' },
};
var hourFormats = {
    '24': { placeholder: 'HH:mm' },
    '12': { placeholder: 'hh:mm' },
};
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(ngZone, injectSlash) {
        this.ngZone = ngZone;
        this.injectSlash = injectSlash;
        this.yearNavigator = true;
        this.monthNavigator = true;
        this.dateFormat = 'fullYear';
        this.showTime = false;
        this.hourFormat = '12';
        this.readonlyInput = false;
        this.disabled = false;
        this.required = false;
        this.appendTo = 'body';
        this.name = '';
        this.showButtonBar = true;
        this.cssClass = 'om-date-picker';
    }
    Object.defineProperty(DatePickerComponent.prototype, "inputStyleClass", {
        get: function () {
            if (this.required && !this.ref.inputFieldValue) {
                return this.cssClass + " ng-invalid";
            }
            return this.cssClass;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setDateFormat();
        this.setYearRange();
        /* istanbul ignore next */
        this.ngZone.runOutsideAngular(function () {
            return window.addEventListener('scroll', _this.closeDatePicker.bind(_this), true);
        });
    };
    DatePickerComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        /* istanbul ignore next */
        this.ngZone.runOutsideAngular(function () {
            return window.removeEventListener('scroll', _this.closeDatePicker.bind(_this), true);
        });
    };
    /* istanbul ignore next */
    DatePickerComponent.prototype.writeValue = function (obj) {
        this.ref.writeValue(obj);
    };
    DatePickerComponent.prototype.focus = function () {
        this.ref.inputfieldViewChild.nativeElement.focus();
    };
    DatePickerComponent.prototype.onKeyup = function (key) {
        var inputField = this.ref.inputfieldViewChild.nativeElement;
        var value = inputField.value;
        if (key === BACKSPACE) {
            return;
        }
        inputField.value = this.injectSlash.transform(value);
    };
    /* istanbul ignore next */
    DatePickerComponent.prototype.registerOnChange = function (fn) {
        this.ref.registerOnChange(fn);
    };
    /* istanbul ignore next */
    DatePickerComponent.prototype.registerOnTouched = function (fn) {
        this.ref.registerOnTouched(fn);
    };
    /* istanbul ignore next */
    DatePickerComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        this.ref.disabled = isDisabled;
    };
    DatePickerComponent.prototype.setDateFormat = function () {
        this.dateFormatString = dateFormats[this.dateFormat].format;
        this.setPlaceholder();
    };
    DatePickerComponent.prototype.setPlaceholder = function () {
        this.placeholder = dateFormats[this.dateFormat].placeholder;
        if (this.showTime) {
            this.placeholder = this.placeholder + " " + hourFormats[this.hourFormat].placeholder;
        }
    };
    DatePickerComponent.prototype.setYearRange = function () {
        var minDate = this.minDate ? this.minDate.getFullYear() : '2000';
        var maxDate = this.maxDate ? this.maxDate.getFullYear() : '2030';
        this.yearRange = minDate + ":" + maxDate;
    };
    /* istanbul ignore next */
    DatePickerComponent.prototype.closeDatePicker = function () {
        if (this.ref && this.ref.overlayVisible) {
            this.ref.alignOverlay();
        }
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
