import { Injectable } from '@angular/core';
import {
  Message as PrimeNgMessage,
  MessageService as PrimeNgMessageService,
} from 'primeng/api';

export type ToastSeverity = 'info' | 'warn' | 'success' | 'error';

export interface ToastMessage extends PrimeNgMessage {
  severity?: ToastSeverity;
}

@Injectable()
export class ToastMessageService {
  constructor(private messagService: PrimeNgMessageService) {}

  add(message: ToastMessage): void {
    this.messagService.add(message);
  }

  clear(key?: string): void {
    this.messagService.clear(key);
  }
}
