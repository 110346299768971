import { Injectable } from '@angular/core';

@Injectable()
export class IsMiscNoteTypeService {
  private miscNoteTypes = ['Misc Clinical', 'Misc Task'];

  isAnyMiscType(noteTypeName: string) {
    return this.miscNoteTypes.indexOf(noteTypeName) > -1;
  }

  isMiscTask(noteTypeName: string) {
    return noteTypeName === 'Misc Task';
  }
}
