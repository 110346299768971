import { VaccineResponse } from './vaccinations-api.type';

export const newEntityId = -1;

export enum VaccinesActionTypes {
  LoadVaccineHistory = '[Vaccines] Load Vaccine History',
  LoadVaccineHistorySuccess = '[Vaccines] Load Vaccine History Success',
  LoadVaccineHistoryError = '[Vaccines] Load Vaccine History Error',
  LoadAllVaccineHistories = '[Vaccines] Load All Vaccine Histories',
  LoadAllVaccineHistoriesSuccess = '[Vaccines] Load All Vaccine Histories Success',
  LoadAllVaccineHistoriesError = '[Vaccines] Load All Vaccine Histories Error',
  SaveVaccineHistory = '[Vaccines] Save Vaccine History',
  SaveVaccineHistorySuccess = '[Vaccines] Save Vaccine History Success',
  SaveVaccineHistoryError = '[Vaccines] Save Vaccine History Error',
  DeleteVaccineHistory = '[Vaccines] Delete Vaccine History',
  DeleteVaccineHistorySuccess = '[Vaccines] Delete Vaccine History Success',
  DeleteVaccineHistoryError = '[Vaccines] Delete Vaccine History Error',
}

export type Vaccine = Partial<{
  id: number;
  isOrderable: boolean;
  abbreviations: string;
  longName: string;
  prefName: string;
  steps: number;
  stepOptions: string[];
  tags: string[];
}>;

export interface FhirSource {
  sourceSenderName: string;
  createdAt: string;
}

export type VaccineHistory = Partial<{
  comments: string;
  givenAt: string;
  givenOn: string;
  step: string;
  stepInSeriesPhrase: string;
  vaccine: Vaccine;
  id: number;
  lotNumber: number;
  expiresOn: string;
  side: string;
  vaccineBrandId: number;
  clinicalRouteId: number;
  vaccineLocationId: number;
  wasAdministered: boolean;
  givenOnWithPrecision: string;
  name: string;
  historyVaccineHistoryId: number;
  events: VaccineOrderEvent[];
  vaccineBrandName: string;
  vaccineRouteName: string;
  vaccineLocationName: string;
  fhirSource: FhirSource;
  // vaccineHistory is fullyLoaded when UI item is expanded
  hasFullyLoaded: boolean;
}>;

export type GroupedVaccines = Partial<{
  [name: string]: VaccineHistory[];
}>;

export interface VaccinationsSectionLinkPayload {
  legacyData: VaccineHistory[];
  vaccineHistoryIds: number[];
}

export interface VaccineForm {
  vaccineId: number;
  vaccine: VaccineResponse;
  step: string;
  givenOn: string;
  givenAt: string;
  comments: string;
}

export type VaccineOrderEvent = Partial<{
  created_at: string;
  statusChange: string;
  onBehalfOf: string;
  updatedBy: string;
}>;

export interface VaccineSearchOptions {
  isOrderable: boolean;
}
