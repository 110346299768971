import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Chart } from 'chart.js';
import { InsightMeasurement, InsightType } from '../../shared/insights.type';

@Component({
  selector: 'omg-insights-graph',
  templateUrl: './insights-graph.component.html',
  styleUrls: ['./insights-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsGraphComponent implements OnInit {
  @Input() data: InsightType[];
  chartName: string;
  chart;

  @ViewChild('graph') graph: ElementRef;
  context: CanvasRenderingContext2D;

  constructor() {}

  ngOnInit() {
    this.chartName =
      this.data[0].measurementTypeName.includes('pressure') &&
      this.data.length > 1
        ? 'Blood Pressure'
        : this.data[0].measurementTypeName;

    this.createChart(this.data);
  }

  private createChart(data: InsightType[]): Chart {
    const datasets = this.transformData(data);
    const firstDatum = data[0].measurements[0];
    const hasOneDatum = data[0].measurements.length === 1;

    if (!this.context) {
      this.context = (<HTMLCanvasElement>this.graph.nativeElement).getContext(
        '2d',
      );
    }

    Chart.defaults.global.defaultFontColor = '#434343';
    Chart.defaults.global.defaultFontFamily = 'Open Sans';

    this.chart = new Chart(
      this.context,
      this.createConfig(datasets, firstDatum, hasOneDatum),
    );
  }

  private createConfig(
    datasets: any,
    firstDatum: InsightMeasurement,
    hasOneDatum: boolean,
  ) {
    const chartUnits = firstDatum.isMetric
      ? firstDatum.measurementUnit.metric
      : firstDatum.measurementUnit.imperial;
    return {
      type: 'line',
      data: { datasets },
      options: {
        legend: { display: false },
        scales: {
          xAxes: [
            {
              offset: hasOneDatum,
              ticks: { autoSkip: true, maxTicksLimit: 9 },
              time: { tooltipFormat: 'lll' },
              type: 'time',
              scaleLabel: { display: true, labelString: 'Collected At' },
            },
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: chartUnits },
            },
          ],
        },
      },
    };
  }

  private transformData(data: InsightType[]) {
    return data.reduce((accumulator, value) => {
      const chartData = value.measurements.map(measurement => ({
        x: new Date(measurement.collectedAt),
        y: measurement.value,
      }));

      const color = Chart.helpers.color;

      const dataset = {
        label: value.measurementTypeName,
        backgroundColor: color('#1E988B')
          .alpha(0.35)
          .rgbString(),
        borderColor: '#1E988B',
        lineTension: 0,
        data: chartData,
      };

      return accumulator.concat(dataset);
    }, []);
  }
}
