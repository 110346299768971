import { ProfileResponse } from './profile-response.type';
import {
  Profile,
  ProfileIdentity,
  ProfileOffice,
  ProfileRoleDetail,
} from './profile.type';

const mapProfileResponseOfficeToEntity = (r): ProfileOffice => ({
  id: r.id || null,
  name: r.name || '',
  latitude: r.latitude || null,
  longitude: r.longitude || null,
});

const mapProfileResponseIdentityToEntity = (r): ProfileIdentity => ({
  name: r.name || '',
  email: r.email || '',
  primary: r.primary || null,
  login: r.login || null,
});

const mapProfileResponseRoleDetailToEntity = (r): ProfileRoleDetail => ({
  id: r.id || null,
  assignable: r.assignable || null,
  cam: r.cam || null,
  clinical: r.clinical || null,
  createdAt: r.created_at || null,
  externalName: r.external_name || null,
  kind: r.kind || null,
  name: r.name || null,
  updatedAt: r.updated_at || null,
});

export const mapProfileResponseToEntity = (
  profileResponse: ProfileResponse,
): Profile => ({
  id: profileResponse.id,
  epcsEulaMissing: profileResponse.epcs_eula_missing,
  hasSignatureImage: profileResponse.has_signature_image,
  identities:
    profileResponse.identities &&
    profileResponse.identities.map(mapProfileResponseIdentityToEntity),
  initials: profileResponse.initials,
  office: mapProfileResponseOfficeToEntity(profileResponse.office),
  profileImageUrl: profileResponse.profile_image_url,
  roles: profileResponse.roles,
  roleDetails:
    profileResponse.role_details &&
    profileResponse.role_details.map(mapProfileResponseRoleDetailToEntity),
});
