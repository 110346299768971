import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FeatureFlagSelectors } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  VaccineHistory,
  VaccineOrderEvent,
} from '../../shared/vaccinations.type';
import { VaccinationsActions } from '../../store/vaccinations.actions';

@Component({
  selector: 'omg-vaccine-history',
  templateUrl: './vaccine-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaccineHistoryComponent implements OnInit, OnChanges {
  @Input() vaccineHistory: VaccineHistory;
  @Input() hasFullyLoaded: boolean;
  @Input() groupingEnabled: boolean;

  @Output() delete = new EventEmitter<number>();

  displayedStep: string;
  displayedGivenAt: string;

  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    private vaccinationsActions: VaccinationsActions,
  ) {}

  canDelete$: Observable<boolean>;
  vaccineHistoryEvents: VaccineOrderEvent[];

  ngOnInit() {
    this.canDelete$ = this.featureFlagSelectors
      .featureEnabled('vaccine_order_delete_and_edit')
      .pipe(map(enabled => enabled || !this.vaccineHistory.wasAdministered));

    if (this.vaccineHistory.stepInSeriesPhrase) {
      this.displayedStep = ', ' + this.vaccineHistory.stepInSeriesPhrase;
    }
    this.displayedGivenAt =
      this.vaccineHistory.givenAt === 'One Medical Group'
        ? 'One Medical'
        : this.vaccineHistory.givenAt;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vaccineHistory) {
      const { events } = changes.vaccineHistory.currentValue;
      if (events) {
        this.vaccineHistoryEvents = events.filter(event =>
          ['signed', 'completed'].includes(event.statusChange),
        );
      }
    }
  }

  get noInfoToShow() {
    return (
      this.vaccineHistory.vaccineBrandName == null &&
      this.vaccineHistory.vaccineRouteName == null &&
      this.vaccineHistory.vaccineLocationName == null &&
      this.vaccineHistory.lotNumber == null &&
      this.vaccineHistory.comments == null &&
      this.vaccineHistory.fhirSource == null
    );
  }

  get hasNoAdministrationDetails() {
    return (
      this.hasFullyLoaded &&
      this.isExplicitlyFalse([
        this.vaccineHistory.vaccineBrandName,
        this.vaccineHistory.vaccineRouteName,
        this.vaccineHistory.vaccineLocationName,
        this.vaccineHistory.lotNumber,
      ])
    );
  }

  trackByFn(index, item) {
    return item.id || index;
  }

  onDelete() {
    const confirmation = window.confirm(
      'Are you sure you want to delete this vaccine?',
    );

    if (confirmation) {
      this.vaccinationsActions.deleteVaccineHistory(this.vaccineHistory.id);
    }
  }

  private isExplicitlyFalse(values: (string | number)[]) {
    return values.reduce((result, value) => {
      if (value !== null && !value) {
        return true;
      }
      return result;
    }, false);
  }
}
