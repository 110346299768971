import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';

import { CollapseDirective } from '@app/shared/components/collapse';
import { waitFor } from '@app/utils';

import { mapHealthGoalScreeningToFormValue } from '../../shared/health-maintenance-utils';
import {
  HealthGoalScreening,
  HealthGoalScreeningErrors,
  HealthGoalScreeningForm,
  HealthGoalScreeningSummary,
  HealthGoalType,
} from '../../shared/health-maintenance.type';

import { Observable } from 'rxjs';
import {
  DeleteHealthGoalScreening,
  UpdateHealthGoalScreening,
} from '../../store/health-goal-screening.actions';
import {
  selectHealthGoalScreeningError,
  selectHealthGoalScreeningSummaryWithMetadata,
} from '../../store/health-goal-screening.selectors';
import { HealthMainteanceState } from '../../store/health-maintenance.reducer';

@Component({
  selector: 'omg-screening-item',
  templateUrl: './screening-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningItemComponent implements OnInit {
  @Input() healthGoalType: HealthGoalType;

  formLoaded = false;
  screeningFormValue: HealthGoalScreeningForm;
  screeningErrors$: Observable<HealthGoalScreeningErrors>;

  @ViewChild('collapseRef') private collapseRef: CollapseDirective;
  private _screening: HealthGoalScreeningSummary;

  @Input()
  get screening() {
    return this._screening;
  }

  set screening(value: HealthGoalScreeningSummary) {
    if (this._screening !== value) {
      this._screening = value;
    }
  }

  constructor(private store: Store<HealthMainteanceState>) {}

  ngOnInit() {}

  onExpanded(expanded: boolean) {
    if (expanded) {
      this.loadForm();
    }
  }

  onCancel() {
    this.collapseRef.collapse();
  }

  onSave(value: HealthGoalScreeningForm) {
    this.saveScreening(value);
  }

  onDelete(value: HealthGoalScreeningForm) {
    this.deleteScreening(value);
  }

  private loadForm() {
    if (!this.formLoaded) {
      this.setFormValue(this.screening);
      this.setupSelectors();
      this.formLoaded = true;
    }
  }

  private setFormValue(value: HealthGoalScreening) {
    this.screeningFormValue = mapHealthGoalScreeningToFormValue(value);
  }

  private setupSelectors() {
    this.screeningErrors$ = this.store.pipe(
      select(selectHealthGoalScreeningError, { id: this.screening.id }),
    );
  }

  private saveScreening(value: HealthGoalScreeningForm) {
    this.store.dispatch(new UpdateHealthGoalScreening(value));

    waitFor(
      this.store.pipe(
        select(selectHealthGoalScreeningSummaryWithMetadata, {
          id: value.id,
        }),
      ),
      data => !data.pending && !data.error,
    ).subscribe(data => {
      this.screening = data.entity;
      this.setFormValue(this.screening);
      this.collapseRef.collapse();
    });
  }

  private deleteScreening(value: HealthGoalScreeningForm) {
    const confirmation = window.confirm(
      'Are you sure you want to delete this health goal screening?',
    );

    if (confirmation) {
      this.store.dispatch(
        new DeleteHealthGoalScreening(value.id, {
          healthGoalId: value.healthGoalId,
        }),
      );
    }
  }
}
