import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';

import {
  mapVitalsDataError,
  mapVitalsDataResponseToEntity,
} from './vitals-data-mappers';
import { VitalsDataResponse } from './vitals-data-response.type';

const vitalsDataPath = patientId => patientRoute(patientId, '/vitals');

@Injectable()
export class VitalsDataService {
  constructor(private apiService: ApiService) {}

  query(patientId) {
    return this.apiService
      .get<VitalsDataResponse>(vitalsDataPath(patientId))
      .pipe(
        map(vitalsDataResponse =>
          mapVitalsDataResponseToEntity(vitalsDataResponse),
        ),
        catchError((error: HttpErrorResponse) =>
          throwError(mapVitalsDataError(error)),
        ),
      );
  }
}
