import { createFeatureSelector, createSelector, select, Store, } from '@ngrx/store';
import { find } from 'lodash/fp';
import { getCurrentSummaryId, selectEntities, summariesStatePath, } from './summaries.reducer';
export var selectSummariesState = createFeatureSelector(summariesStatePath);
export var selectSummaries = createSelector(selectSummariesState, selectEntities);
export var selectSummary = createSelector(selectSummaries, function (summaries, _a) {
    var id = _a.id, problemId = _a.problemId;
    return summaries[id];
});
export var selectCurrentSummaryId = createSelector(selectSummariesState, getCurrentSummaryId);
export var selectCurrentSummary = createSelector(selectSummaries, selectCurrentSummaryId, function (summaries, id) { return summaries[id]; });
export var selectHasAssessedProblemById = createSelector(selectCurrentSummary, function (summary, _a) {
    var problemId = _a.problemId;
    return summary && find({ id: problemId }, summary.assessedProblems);
});
export var hasActiveSummary = createSelector(selectCurrentSummaryId, function (id) { return id !== null; });
export var selectRefreshTimeline = createSelector(selectSummariesState, function (state) { return state.refreshTimeline; });
export var selectCloseTimelineItem = createSelector(selectSummariesState, function (state) { return state.closeTimelineItem; });
export var selectAddendums = createSelector(selectCurrentSummary, function (summary) { return summary.summaryAddendums; });
export var selectAssessedProblemHistoryIds = createSelector(selectCurrentSummary, function (summary) {
    return summary &&
        summary.assessedProblems &&
        summary.assessedProblems.map(function (problem) { return problem.problemHistoryId; });
});
export var selectAssessedProblems = createSelector(selectCurrentSummary, function (summary) { return summary && summary.assessedProblems; });
var SummariesSelectors = /** @class */ (function () {
    function SummariesSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(SummariesSelectors.prototype, "state", {
        get: function () {
            return this.store.pipe(select(selectSummariesState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "summaries", {
        get: function () {
            return this.store.pipe(select(selectSummaries));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "currentSummaryId", {
        get: function () {
            return this.store.pipe(select(selectCurrentSummaryId));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "currentSummary", {
        get: function () {
            return this.store.pipe(select(selectCurrentSummary));
        },
        enumerable: true,
        configurable: true
    });
    SummariesSelectors.prototype.getSummaryById = function (id) {
        return this.store.pipe(select(selectSummary, { id: id, problemId: null }));
    };
    SummariesSelectors.prototype.hasAssessedProblem = function (problemId) {
        return this.store.pipe(select(selectHasAssessedProblemById, { problemId: problemId }));
    };
    Object.defineProperty(SummariesSelectors.prototype, "hasActiveSummary", {
        get: function () {
            return this.store.pipe(select(hasActiveSummary));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "refreshTimeline", {
        get: function () {
            return this.store.pipe(select(selectRefreshTimeline));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "closeTimelineItem", {
        get: function () {
            return this.store.pipe(select(selectCloseTimelineItem));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "addendums", {
        get: function () {
            return this.store.pipe(select(selectAddendums));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "assessedProblemHistoryIds", {
        get: function () {
            return this.store.pipe(select(selectAssessedProblemHistoryIds));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummariesSelectors.prototype, "assessedProblems", {
        get: function () {
            return this.store.pipe(select(selectAssessedProblems));
        },
        enumerable: true,
        configurable: true
    });
    return SummariesSelectors;
}());
export { SummariesSelectors };
