import { FsaAction } from '@app/core';

import {
  HealthGoalType,
  HealthMaintenanceError,
} from '../shared/health-maintenance.type';

export enum HealthGoalTypeActionTypes {
  LoadHealthGoalTypes = '[HealthGoalType] Load HealthGoalTypes',
  LoadHealthGoalTypesSuccess = '[HealthGoalType] Load HealthGoalTypes Success',
  LoadHealthGoalTypesError = '[HealthGoalType] Load HealthGoalTypes Error',
  ClearHealthGoalTypes = '[HealthGoalType] Clear HealthGoalTypes',
}

export class LoadHealthGoalTypes implements FsaAction<void> {
  readonly type = HealthGoalTypeActionTypes.LoadHealthGoalTypes;

  constructor() {}
}

export class LoadHealthGoalTypesSuccess implements FsaAction<HealthGoalType[]> {
  readonly type = HealthGoalTypeActionTypes.LoadHealthGoalTypesSuccess;

  constructor(public payload: HealthGoalType[]) {}
}

export class LoadHealthGoalTypesError
  implements FsaAction<HealthMaintenanceError> {
  readonly type = HealthGoalTypeActionTypes.LoadHealthGoalTypesError;
  error = true;

  constructor(public payload: HealthMaintenanceError) {}
}

export class ClearHealthGoalTypes implements FsaAction<void> {
  readonly type = HealthGoalTypeActionTypes.ClearHealthGoalTypes;
}

export type HealthGoalTypeActions =
  | LoadHealthGoalTypes
  | LoadHealthGoalTypesSuccess
  | LoadHealthGoalTypesError
  | ClearHealthGoalTypes;
