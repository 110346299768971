import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CollapseDirective } from '@app/shared/components';
import { waitFor } from '@app/utils';

import { mapHealthGoalToFormValue } from '../../shared/health-maintenance-utils';
import {
  HealthGoalErrors,
  HealthGoalForm,
  HealthGoalSummary,
  HealthGoalType,
} from '../../shared/health-maintenance.type';

import {
  DeleteHealthGoal,
  UpdateHealthGoal,
} from '../../store/health-goal.actions';
import {
  selectHealthGoalError,
  selectHealthGoalSummaryWithMetadata,
} from '../../store/health-goal.selectors';
import { HealthMainteanceState } from '../../store/health-maintenance.reducer';

@Component({
  selector: 'omg-health-goal-item',
  templateUrl: './health-goal-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthGoalItemComponent implements OnInit {
  @Input() healthGoalTypes: HealthGoalType[];

  formLoaded = false;
  healthGoalFormValue: HealthGoalForm;
  healthGoalErrors$: Observable<HealthGoalErrors>;

  @ViewChild('collapseRef') private collapseRef: CollapseDirective;
  private _healthGoal: HealthGoalSummary;

  @Input()
  get healthGoal() {
    return this._healthGoal;
  }

  set healthGoal(value: HealthGoalSummary) {
    if (this._healthGoal !== value) {
      this._healthGoal = value;
    }
  }

  constructor(private store: Store<HealthMainteanceState>) {}

  ngOnInit() {}

  onExpanded(expanded: boolean) {
    if (expanded) {
      this.loadForm();
    }
  }

  onCancel() {
    this.collapseRef.collapse();
  }

  onSave(value: HealthGoalForm) {
    this.saveHealthGoal(value);
  }

  onDelete(value: HealthGoalForm) {
    this.deleteHealthGoal(value);
  }

  onIndicatedChange() {
    this.loadForm(); // ensure form is loaded
    const formValue = this.healthGoalFormValue;
    this.store.dispatch(
      new UpdateHealthGoal({
        ...formValue,
        indicated: !formValue.indicated,
      }),
    );
  }

  private loadForm() {
    if (!this.formLoaded) {
      this.setFormValue(this.healthGoal);
      this.setupSelectors();
      this.formLoaded = true;
    }
  }

  private setFormValue(value: HealthGoalSummary) {
    this.healthGoalFormValue = mapHealthGoalToFormValue(value);
  }

  private setupSelectors() {
    this.healthGoalErrors$ = this.store.pipe(
      select(selectHealthGoalError, { id: this.healthGoal.id }),
    );
  }

  private saveHealthGoal(value: HealthGoalForm) {
    this.store.dispatch(new UpdateHealthGoal(value));

    waitFor(
      this.store.pipe(
        select(selectHealthGoalSummaryWithMetadata, {
          id: value.id,
        }),
      ),
      status => !status.pending && !status.error,
    ).subscribe(data => {
      this.healthGoal = data.entity;
      this.setFormValue(data.entity);
      this.collapseRef.collapse();
    });
  }

  private deleteHealthGoal(value: HealthGoalForm) {
    const confirmation = window.confirm(
      'Are you sure you want to delete this health goal?',
    );

    if (confirmation) {
      this.store.dispatch(new DeleteHealthGoal(value.id));
    }
  }
}
