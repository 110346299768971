import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { CollapseDirective } from '@app/shared';

import { VaccinationsActions } from '../../store/vaccinations.actions';

import { waitFor } from '@app/utils';
import { VaccineHistory } from '../../shared/vaccinations.type';
import { LoadVaccineHistory } from '../../store/vaccinations.actions';
import { VaccinationsState } from '../../store/vaccinations.reducer';
import {
  selectEntityMetadata,
  selectHasVaccineHistoryFullyLoaded,
  selectVaccineHistoryById,
} from '../../store/vaccinations.selectors';

@Component({
  selector: 'omg-vaccine-history-item',
  templateUrl: './vaccine-history-item.component.html',
  styleUrls: ['./vaccine-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaccineHistoryItemComponent implements OnInit, OnDestroy {
  vaccineHistory$: Observable<VaccineHistory>;
  vaccineHistoryHasFullyLoaded$: Observable<boolean>;

  @Input() vaccineHistory: VaccineHistory;
  @Input() groupedVaccineHistory: VaccineHistory[];
  @Input() patientId: number;
  @Input() groupingEnabled: boolean;

  @ViewChild(CollapseDirective)
  private vaccineHistoryItemCollapse: CollapseDirective;

  private unsubscribe = new Subject();

  constructor(
    private store: Store<VaccinationsState>,
    private vaccinationsActions: VaccinationsActions,
  ) {}

  ngOnInit() {
    if (!this.groupingEnabled) {
      this.setupSelectors();
      this.loadVaccineHistoryUponExpand();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onDelete() {
    const confirmation = window.confirm(
      'Are you sure you want to delete this vaccine?',
    );

    if (confirmation) {
      this.vaccinationsActions.deleteVaccineHistory(this.vaccineHistory.id);

      waitFor(
        this.store.pipe(
          select(selectEntityMetadata, { id: this.vaccineHistory.id }),
        ),
        metadata => metadata && (!metadata.pending && !metadata.error),
      ).subscribe(() => {
        this.vaccineHistoryItemCollapse.collapse();
      });
    }
  }

  private loadVaccineHistoryUponExpand() {
    this.vaccineHistoryItemCollapse.expandedChange
      .pipe(
        withLatestFrom(
          this.store.pipe(
            select(selectHasVaccineHistoryFullyLoaded, {
              id: this.vaccineHistory.id,
            }),
          ),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe(([isExpanded, hasFullyLoaded]: [boolean, boolean]) => {
        if (isExpanded && !hasFullyLoaded) {
          this.loadVaccineHistory();
        }
      });
  }

  private loadVaccineHistory() {
    this.store.dispatch(
      new LoadVaccineHistory({
        vaccineHistoryId: this.vaccineHistory.id,
      }),
    );
  }

  private setupSelectors() {
    this.vaccineHistoryHasFullyLoaded$ = this.store.pipe(
      select(selectHasVaccineHistoryFullyLoaded, {
        id: this.vaccineHistory.id,
      }),
    );
    this.vaccineHistory$ = this.store.pipe(
      select(selectVaccineHistoryById, { id: this.vaccineHistory.id }),
    );
  }
}
