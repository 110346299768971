<omg-card>
  <omg-card-title heading="Health Maintenance"
                  omgCollapse
                  #newHealthGoalCollapse="omgCollapse"
                  (expandedChange)="onNewHealthGoalFormExpanded($event)"
                  omgFocusProvider
                  #focus="omgFocusProvider">
    <button omgButton
            variant="invisible"
            omgShortcutClick="g"
            omgTooltip
            tooltipText="Add Goal [ G ]"
            tooltipPosition="top"
            omgCollapseToggle="expand"
            (click)="focus.setFocus(focusOnNewFormKey)">
      <span class="om-icon clickable icon-add-new"></span>
    </button>
    <button omgButton
            variant="invisible"
            omgTooltip
            tooltipText="Add to Note"
            tooltipPosition="top"
            angulartics2On
            angularticsAction="Health Maintenance Section Added"
            [disabled]="!(allowAddToNote$ | async)"
            (click)="onAddToNote()">
      <span class="om-icon clickable icon-push-to-note"></span>
    </button>
  </omg-card-title>

  <!-- Card Content -->
  <div om-card-content
       *ngIf="healthGoalTypes$ | async as healthGoalTypes">

    <!-- New Health Goal -->
    <omg-expanded [collapseProvider]="newHealthGoalCollapse">
      <omg-health-goal-form class="-subform"
                            #newHealthGoalForm
                            [healthGoalTypes]="healthGoalTypes"
                            [formValue]="newHealthGoalFormValue"
                            [formErrors]="newHealthGoalErrors$ | async"
                            (cancel)="newHealthGoalCollapse.collapse()"
                            (save)="onSaveNewHealthGoal($event)">
      </omg-health-goal-form>
    </omg-expanded>

    <!-- Indicated Health Goals -->
    <ng-container *ngIf="indicatedHealthGoals$ | async as indicatedHealthGoals">
      <div class="om-list"
           *ngIf="indicatedHealthGoals?.length">
        <omg-health-goal-item class="om-item clickable"
                              om-collapse
                              *ngFor="let healthGoal of indicatedHealthGoals; trackBy: trackByFn"
                              [healthGoalTypes]="healthGoalTypes"
                              [healthGoal]="healthGoal">
        </omg-health-goal-item>
      </div>
    </ng-container>

    <!-- Not Indicated Health Goals -->
    <ng-container *ngIf="notIndicatedHealthGoals$ | async as notIndicatedHealthGoals">
      <div omgCollapse
           *ngIf="notIndicatedHealthGoals?.length">
        <omg-collapse-toggle class="-separator">
          <omg-collapsed disableAnimation="true">Show Not Indicated ({{ notIndicatedHealthGoals.length }})
          </omg-collapsed>
          <omg-expanded disableAnimation="true">Hide Not Indicated</omg-expanded>
        </omg-collapse-toggle>

        <omg-expanded>
          <div class="om-list">
            <omg-health-goal-item class="om-item clickable"
                                  om-collapse
                                  *ngFor="let healthGoal of notIndicatedHealthGoals; trackBy: trackByFn"
                                  [healthGoalTypes]="healthGoalTypes"
                                  [healthGoal]="healthGoal">
            </omg-health-goal-item>
          </div>
        </omg-expanded>
      </div>
    </ng-container>

    <!-- Health Maintenance Note -->
    <omg-health-maintenance-note [note]="healthMaintenanceNote"
                                 (save)="onSaveNote($event)">
    </omg-health-maintenance-note>
  </div>
</omg-card>
