import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import {
  AnalyticsActions,
  FeatureFlagSelectors,
  PatientSelectors,
} from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { CollapseDirective } from '@app/shared';
import { FocusProviderDirective } from '@app/shared/directives/focus/focus-provider.directive';

import { ProblemsApiService } from '../shared/problems-api.service';
import { removeHistoryId, sortProblems } from '../shared/problems-utils';
import { newEntityId, Problem, ProblemForm } from '../shared/problems.type';
import { ProblemActions } from '../store/problems.actions';
import { ProblemSelectors } from '../store/problems.selectors';
import { ProblemFormComponent } from './problem-form/problem-form.component';

@Component({
  selector: 'omg-problems',
  templateUrl: './problems.component.html',
  styleUrls: ['./problems.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProblemsComponent implements OnInit, OnDestroy {
  @ViewChildren(ProblemFormComponent) problemForms: QueryList<
    ProblemFormComponent
  >;
  patientId: number;
  activeProblems: Observable<Problem[]>;
  resolvedProblems: Observable<Problem[]>;
  autoCheckInCreationEnabled: Observable<boolean>;
  allowAddToNote: Observable<boolean>;
  addProblemSessionId: number;
  reset = false;
  disableAddNewProblem = new Subject();

  private unsubscribe = new Subject();

  constructor(
    private problemsApi: ProblemsApiService,
    private patientSelectors: PatientSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
    private problemSelectors: ProblemSelectors,
    private problemActions: ProblemActions,
    private analyticsActions: AnalyticsActions,
    private summariesSelectors: SummariesSelectors,
  ) {}

  ngOnInit() {
    this.loadProblems();
    this.setupSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  trackByFn = (index, problem: Problem) => problem.id || index;

  addToNote() {
    this.problemSelectors.problems.pipe(take(1)).subscribe(problems => {
      this.problemsApi.linkSection(this.patientId, sortProblems(problems));
    });
  }

  saveProblem(form: ProblemForm, collapseRef: CollapseDirective) {
    this.problemActions.saveProblem(this.patientId, form);
    this.problemSelectors
      .problemMetadata(newEntityId)
      .pipe(
        filter(metadata => !metadata.pending),
        withLatestFrom(this.problemSelectors.problemHistoryId),
        take(1),
      )
      .subscribe(([state, historyId]) => {
        if (!state.error) {
          this.problemsApi.linkAssessed(
            this.patientId,
            null,
            historyId,
            'problemSaved',
          );
          this.problemForms.first.resetForm();
          collapseRef.collapse();
        }
      });
  }

  updateProblem(
    form: ProblemForm,
    collapseRef: CollapseDirective,
    index: number,
  ) {
    this.problemActions.updateProblem(this.patientId, form);
    this.problemSelectors
      .problemMetadata(form.id)
      .pipe(
        filter(metadata => !metadata.pending),
        withLatestFrom(this.problemSelectors.problemHistoryId),
        take(1),
      )
      .subscribe(([state, historyId]) => {
        if (!state.error) {
          if (form.addProblemToNote) {
            this.problemsApi.linkAssessed(
              this.patientId,
              form.id,
              historyId,
              'problemUpdated',
            );
          }
          this.collapseHistory(index);
          collapseRef.collapse();
        }
      });
  }

  deleteProblem(problemId: number, collapseRef: CollapseDirective) {
    const confirmation = window.confirm(
      'Are you sure you want to delete this problem?',
    );
    if (!confirmation) {
      collapseRef.collapse();
      return;
    }

    this.problemActions.deleteProblem(this.patientId, problemId);

    this.problemSelectors
      .problemMetadata(problemId)
      .pipe(
        withLatestFrom(
          this.problemSelectors.state,
          this.summariesSelectors.assessedProblemHistoryIds,
        ),
        filter(
          ([metadata, state]) => !!(!metadata.pending && state.lastDeleted),
        ),
        take(1),
      )
      .subscribe(([metadata, state, assessedProblemHistoryIds]) => {
        const historyIds = removeHistoryId(
          assessedProblemHistoryIds,
          state.lastDeleted.problemHistoryId,
        );
        if (!metadata.error) {
          /** This will unlink the problem from the unsigned summary A&P
           * in the old app. This should be removed once section link
           * compatibility is no longer needed.
           */
          this.problemsApi.linkAssessed(
            this.patientId,
            state.lastDeleted.id,
            state.lastDeleted.problemHistoryId,
            'problemDeleted',
          );
          /** This will unlink the problem from Angular 7 unsigned
           * summary A&P.
           */
          this.problemsApi.unlinkAssessed(this.patientId, historyIds);
          collapseRef.collapse();
        }
      });
  }

  resolveProblem(
    problemId: number,
    collapseRef: CollapseDirective,
    index: number,
  ) {
    this.problemActions.resolveProblem(this.patientId, problemId);
    this.problemSelectors.problemMetadata(problemId).subscribe(() => {
      this.collapseHistory(index);
      collapseRef.collapse();
    });
  }

  reactivateProblem(
    problemId: number,
    collapseRef: CollapseDirective,
    index: number,
  ) {
    this.problemActions.reactivateProblem(this.patientId, problemId);
    this.problemSelectors.problemMetadata(problemId).subscribe(() => {
      this.collapseHistory(index);
      collapseRef.collapse();
    });
  }

  onAddProblemClick(
    collapseRef: CollapseDirective,
    focus: FocusProviderDirective,
  ) {
    focus.setFocus('problemFocus-create');
    collapseRef.expand();
    this.resetAddingProblemSessionId();
    this.trackEvent();
  }

  onExpandedChange(isExpanded: boolean, id: number) {
    if (isExpanded) {
      this.problemActions.getProblemHistory(this.patientId, id);
    }
  }

  collapseHistory(index: number) {
    this.problemForms.toArray()[index].problemHistory.setExpanded(false);
  }

  private trackEvent() {
    this.analyticsActions.trackEvent('Add Problem Clicked', {
      workflow: 'Charting',
      component: 'Problems',
      subWorkflowId: this.addProblemSessionId,
      subComponent: 'Add Problem Button',
    });
  }

  private resetAddingProblemSessionId() {
    this.addProblemSessionId = +new Date();
  }

  private loadProblems() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        tap(patientId => (this.patientId = patientId)),
        takeUntil(this.unsubscribe),
      )
      .subscribe(patientId => {
        this.problemActions.getProblems(patientId);
      });
  }

  private setupSelectors() {
    this.allowAddToNote = this.summariesSelectors.hasActiveSummary;
    this.activeProblems = this.problemSelectors.activeProblems.pipe(
      takeUntil(this.unsubscribe),
    );
    this.resolvedProblems = this.problemSelectors.resolvedProblems.pipe(
      takeUntil(this.unsubscribe),
    );
    this.autoCheckInCreationEnabled = this.featureFlagSelectors.featureEnabled(
      'auto_check_in_creation',
    );
  }
}
