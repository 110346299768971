var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
var NoteIconComponent = /** @class */ (function () {
    function NoteIconComponent() {
        this.tags = [];
        this.small = false;
        this.transferable = false;
        this.inheritColor = true;
    }
    NoteIconComponent.prototype.ngOnInit = function () {
        this.iconClass = this.getIconClass();
        this.swapIconClass = "om-icon icon-ui-transfer";
        if (!this.inheritColor) {
            this.iconClass = this.color(this.iconClass, this.tags);
            this.swapIconClass = this.color(this.swapIconClass, this.tags);
        }
    };
    NoteIconComponent.prototype.getIconClass = function () {
        return "om-icon icon-" + this.mapKeysToCssClass(this.tags) + (this.small ? '' : '-large');
    };
    NoteIconComponent.prototype.color = function (iconClass, tags) {
        var keys = this.getTagKeys(tags);
        if (keys.results) {
            return iconClass + " -red";
        }
        else if (keys.order || keys.rx) {
            return iconClass + " -yellow";
        }
        else if (keys.travel || keys.clerical) {
            return iconClass + " -green";
        }
        return iconClass;
    };
    /* eslint-disable complexity */
    NoteIconComponent.prototype.mapKeysToCssClass = function (tags) {
        var keys = this.getTagKeys(tags);
        switch (true) {
            case keys.encounter && keys.office:
                return 'visit-office';
            case keys.encounter && keys.phone:
                return 'visit-phone';
            case keys.encounter && keys.triage:
                return 'visit-triage';
            case keys.encounter && keys.video:
                return 'visit-video';
            case keys.encounter && keys['mental health']:
                return 'visit-mental';
            case keys.encounter && keys.email:
                return 'message';
            case keys.results && (keys.laboratory || keys.test || keys.lab):
                return 'lab-report';
            case keys.results && keys.imaging:
                return 'imaging';
            case keys.order:
                return 'lab-order';
            case keys.rx || keys.prescription:
                return 'med';
            case keys.travel && keys.questionnaire:
                return 'survey';
            case keys.clerical &&
                ((keys.request && keys.questionnaire) || keys.release):
                return 'clerical';
            case this.noteType === 'Misc Task':
                return 'misc-task';
            case this.noteType === 'Official Letter':
                return 'letter';
            default:
                return 'visit-misc';
        }
    };
    /* eslint-enable complexity */
    NoteIconComponent.prototype.getTagKeys = function (tags) {
        return tags.reduce(function (accumulator, current) {
            var _a;
            return (__assign({}, accumulator, (_a = {}, _a[current] = true, _a)));
        }, {});
    };
    return NoteIconComponent;
}());
export { NoteIconComponent };
