import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AnalyticsActions, FeatureFlagSelectors } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { PatientInfo } from '@app/modules/patient-info/components/shared/patient-info.type';
import { Observable, Subject } from 'rxjs';
import { getPatientNavURLs } from '../shared/patient-nav-utils';

@Component({
  selector: 'omg-patient-nav',
  templateUrl: './patient-nav.component.html',
  styleUrls: ['./patient-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientNavComponent implements OnInit, OnDestroy {
  @Input() patient: PatientInfo;

  showMenu = false;
  clickedInside = false;

  patientUrls = {};
  showCCD$: Observable<boolean>;
  hideLegacyChartTabs$: Observable<boolean>;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private analyticsActions: AnalyticsActions,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.patientUrls = getPatientNavURLs(this.patient.id);
    this.showCCD$ = this.featureFlagSelectors.featureEnabled('ccd');
    this.hideLegacyChartTabs$ = this.featureFlagSelectors.featureEnabled(
      'hide_legacy_chart_tabs',
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  onMenuItemClick(menuItem) {
    this.showMenu = false;
    this.trackMenuClickEvent(menuItem);
  }

  @HostListener('click')
  onElementClick() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  onDocumentClick() {
    if (!this.clickedInside) {
      this.showMenu = false;
    }
    this.clickedInside = false;
  }

  private trackMenuClickEvent(menuItem) {
    this.analyticsActions.trackEvent(AnalyticsEvent.PatientMenuClicked, {
      workflow: 'General',
      component: 'Patient Level Menu',
      subComponent: menuItem,
      isNew: true,
    });
  }
}
