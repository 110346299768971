var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StateSelector } from '@app/core/store/shared/state';
import { isLabOrderDue, isProcedureOrderDue, isVaccineOrderDue, OrderTypes, } from '../shared/order-utils';
var OrdersSelector = /** @class */ (function (_super) {
    __extends(OrdersSelector, _super);
    function OrdersSelector() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OrdersSelector.prototype, "activeOrders", {
        get: function () {
            var _this = this;
            return this.filter(function (order) { return !_this.isInactiveOrder(order); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersSelector.prototype, "inactiveOrders", {
        get: function () {
            var _this = this;
            return this.filter(function (order) { return _this.isInactiveOrder(order); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersSelector.prototype, "dueOrders", {
        get: function () {
            var _this = this;
            return this.filter(function (order) { return _this.isOrderDue(order); });
        },
        enumerable: true,
        configurable: true
    });
    OrdersSelector.prototype.isOrderDue = function (order) {
        switch (order.type) {
            case OrderTypes.ConsultOrder:
            case OrderTypes.ProcedureOrder:
                return isProcedureOrderDue(order);
            case OrderTypes.VaccineOrder:
                return isVaccineOrderDue(order);
            case OrderTypes.LabOrder:
                return isLabOrderDue(order);
            case OrderTypes.BasicFollowUpOrder:
            default:
                return false;
        }
    };
    OrdersSelector.prototype.isInactiveOrder = function (order) {
        switch (order.type) {
            case OrderTypes.ConsultOrder:
                var inactive_states = [
                    'completed',
                    'redacted',
                    'declined',
                    'patient_completed',
                ];
                return inactive_states.includes(order.status);
            case OrderTypes.ProcedureOrder:
                return (!order.reviewable &&
                    (order.status === 'completed' ||
                        order.status === 'redacted' ||
                        order.status === 'reviewed'));
            case OrderTypes.VaccineOrder:
                return !order.isActive;
            case OrderTypes.LabOrder:
                return (order.status === 'cancelled' ||
                    order.status === 'collected' ||
                    order.status === 'declined');
            default:
                return false;
        }
    };
    return OrdersSelector;
}(StateSelector));
export { OrdersSelector };
