import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { HotkeyModule } from 'angular2-hotkeys';

import { SyncInputDirective } from '../components/sync-input/sync-input.directive';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { FocusOnDirective } from './focus/focus-on.directive';
import { FocusProviderDirective } from './focus/focus-provider.directive';
import { FocusService } from './focus/focus.service';
import { LegacyUiHrefDirective } from './legacy-ui-href.directive';
import { ShortcutClickDirective } from './shortcut-click/shortcut-click.directive';
import { StopEventDirective } from './stop-event.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';

const directives = [
  CopyToClipboardDirective,
  FocusOnDirective,
  FocusProviderDirective,
  LegacyUiHrefDirective,
  ShortcutClickDirective,
  StopEventDirective,
  SyncInputDirective,
  TooltipDirective,
];

@NgModule({
  imports: [HotkeyModule],
  declarations: [...directives],
  exports: [HotkeyModule, ...directives],
})
export class DirectivesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DirectivesModule,
      providers: [FocusService],
    };
  }
}
