var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PipeTransform } from '@angular/core';
// Adapted from
// https://github.com/jonschlinkert/word-wrap
var wrap = function (str, opts) {
    if (str === null) {
        return str;
    }
    var defaults = { width: 50, trim: true, cut: false };
    var options = __assign({}, defaults, opts);
    var newline = '\n';
    var regexString = ".{1," + options.width + "}";
    if (options.cut !== true) {
        regexString += '([\\s\u200B]+|$)|[^\\s\u200B]+?([\\s\u200B]+|$)';
    }
    var re = new RegExp(regexString, 'g');
    var lines = str.match(re) || [];
    var result = lines
        .map(function (line) {
        if (line.slice(-1) === '\n') {
            line = line.slice(0, line.length - 1);
        }
        return line;
    })
        .join(newline);
    if (options.trim === true) {
        return result.trim();
    }
    return result;
};
var ɵ0 = wrap;
var WrapTextPipe = /** @class */ (function () {
    function WrapTextPipe() {
    }
    WrapTextPipe.prototype.transform = function (text, width, cut, trim) {
        if (width === void 0) { width = 50; }
        if (cut === void 0) { cut = false; }
        if (trim === void 0) { trim = true; }
        return wrap(text, { width: width, cut: cut, trim: trim });
    };
    return WrapTextPipe;
}());
export { WrapTextPipe };
export { ɵ0 };
