import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { setFormErrors } from '@app/shared';
import { getDefaultHealthGoalActionId, HealthGoalTypeOptions, } from '../../shared/health-goal-type-options';
import { newEntityId, } from '../../shared/health-maintenance.type';
var HealthGoalFormComponent = /** @class */ (function () {
    function HealthGoalFormComponent() {
        this.save = new EventEmitter();
        this.delete = new EventEmitter();
        this.cancel = new EventEmitter();
        this.goalTypeOptions = new HealthGoalTypeOptions();
        this._unsubscribe = new Subject();
        this.buildForm();
    }
    Object.defineProperty(HealthGoalFormComponent.prototype, "healthGoalTypes", {
        get: function () {
            return this.goalTypeOptions.healthGoalTypes;
        },
        set: function (value) {
            if (value && value.length > 0) {
                this.goalTypeOptions.healthGoalTypes = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalFormComponent.prototype, "formValue", {
        get: function () {
            return this.form.value;
        },
        set: function (value) {
            if (this._formValue !== value) {
                this._formValue = value;
                this.setFormValue(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalFormComponent.prototype, "formErrors", {
        get: function () {
            return this.form.errors;
        },
        set: function (errors) {
            setFormErrors(this.form, errors, 'apiError');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalFormComponent.prototype, "isNewHealthGoal", {
        get: function () {
            var id = this.form.get('id').value;
            return id === null || id === newEntityId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HealthGoalFormComponent.prototype, "focusOnKey", {
        get: function () {
            var id = this.form.get('id').value;
            return "healthGoalFocus-" + (id || 'create');
        },
        enumerable: true,
        configurable: true
    });
    HealthGoalFormComponent.prototype.ngOnInit = function () { };
    HealthGoalFormComponent.prototype.ngOnChanges = function (changes) {
        var formValue = changes.formValue;
        /**
         * If form value is being set to default values
         * we should set the healthGoalType to untouched
         * so we don't get validation error
         */
        if (formValue &&
            formValue.currentValue &&
            formValue.currentValue.healthGoalTypeId === null) {
            if (this.form && this.form.get('healthGoalTypeId')) {
                this.form.get('healthGoalTypeId').markAsUntouched();
            }
        }
    };
    HealthGoalFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    };
    HealthGoalFormComponent.prototype.onCancel = function () {
        this.resetForm();
        this.cancel.emit();
    };
    HealthGoalFormComponent.prototype.onSave = function () {
        this.save.emit(this.form.value);
    };
    HealthGoalFormComponent.prototype.onDelete = function () {
        this.delete.emit(this.form.value);
    };
    HealthGoalFormComponent.prototype.buildForm = function () {
        this.form = new FormGroup({
            id: new FormControl(),
            comment: new FormControl(),
            declined: new FormControl(),
            dueAt: new FormControl(),
            healthGoalActionId: new FormControl(),
            healthGoalTypeId: new FormControl(null, Validators.required),
            indicated: new FormControl(),
            screeningHistory: new FormArray([]),
        });
        this.resetForm();
    };
    HealthGoalFormComponent.prototype.subscribeToValueChanges = function () {
        var _this = this;
        this.form
            .get('healthGoalTypeId')
            .valueChanges.pipe(tap(function (id) { return _this.updateHealthGoalType(id); }), takeUntil(this._unsubscribe))
            .subscribe();
    };
    HealthGoalFormComponent.prototype.unsubscribeToValueChanges = function () {
        this._unsubscribe.next();
    };
    HealthGoalFormComponent.prototype.setFormValue = function (value) {
        this.unsubscribeToValueChanges();
        this.form.setValue(value);
        this.goalTypeOptions.selectedTypeId = value.healthGoalTypeId;
        this.form.markAsPristine();
        this.subscribeToValueChanges();
    };
    HealthGoalFormComponent.prototype.resetForm = function () {
        this.unsubscribeToValueChanges();
        this.form.reset(this._formValue);
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.subscribeToValueChanges();
    };
    HealthGoalFormComponent.prototype.updateHealthGoalType = function (goalTypeId) {
        if (this.goalTypeOptions.selectedTypeId !== goalTypeId) {
            // Set the selected health goal type in order to refresh options
            this.goalTypeOptions.selectedTypeId = goalTypeId;
            // Get and set the new default action type id
            var defaultActionTypeId = getDefaultHealthGoalActionId(this.goalTypeOptions.healthGoalTypes, goalTypeId);
            this.form.get('healthGoalActionId').setValue(defaultActionTypeId);
        }
    };
    return HealthGoalFormComponent;
}());
export { HealthGoalFormComponent };
