import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { of } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '../../patient';
import { mapTrackEventPropertiesToAnalyticsData } from './analytics-api-mappers';
import { TrackEvent, trackEvent } from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private action$: Actions,
    private angulartics2MixPanel: Angulartics2Mixpanel,
    private patientSelectors: PatientSelectors,
  ) {}

  @Effect({ dispatch: false })
  mixpanelActionTracking$ = this.action$.pipe(
    ofType(trackEvent),
    withLatestFrom(this.patientSelectors.patientId),
    tap(([action, patientId]: [TrackEvent, number]) =>
      this.angulartics2MixPanel.eventTrack(
        action.payload.action,
        mapTrackEventPropertiesToAnalyticsData({
          patientId,
          ...action.payload.properties,
        }),
      ),
    ),
    catchError(error => of(error)),
  );
}
