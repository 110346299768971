var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ofType } from '@ngrx/effects';
import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector, select, } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
var StateSelector = /** @class */ (function () {
    function StateSelector(store, stateConfig) {
        this.store = store;
        this.stateConfig = stateConfig;
        this.adapter = createEntityAdapter();
        this.selectState = createFeatureSelector(this.stateConfig.statePath);
        this.selectEntities = createSelector(this.selectState, this.adapter.getSelectors().selectAll);
        this.selectEntitiesDictionary = createSelector(this.selectState, this.adapter.getSelectors().selectEntities);
        this.selectEntity = createSelector(this.selectEntitiesDictionary, function (entities, _a) {
            var id = _a.id;
            return entities[id];
        });
    }
    Object.defineProperty(StateSelector.prototype, "state", {
        get: function () {
            return this.store.pipe(select(this.selectState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateSelector.prototype, "entities", {
        get: function () {
            return this.store.pipe(select(this.selectEntities));
        },
        enumerable: true,
        configurable: true
    });
    StateSelector.prototype.getById = function (id) {
        return this.store.pipe(select(this.selectEntity, { id: id }));
    };
    StateSelector.prototype.filter = function (predicate) {
        return this.entities.pipe(map(function (entities) { return entities.filter(predicate); }));
    };
    return StateSelector;
}());
export { StateSelector };
export var ActionNames;
(function (ActionNames) {
    ActionNames["Create"] = "Create";
    ActionNames["CreateSuccess"] = "Create Success";
    ActionNames["CreateError"] = "Create Error";
    ActionNames["Load"] = "Load";
    ActionNames["LoadSuccess"] = "Load Success";
    ActionNames["LoadError"] = "Load Error";
    ActionNames["GetById"] = "Get By Id";
    ActionNames["GetByIdSuccess"] = "Get By Id Success";
    ActionNames["GetByIdError"] = "Get By Id Error";
    ActionNames["Update"] = "Update";
    ActionNames["UpdateSuccess"] = "Update Success";
    ActionNames["UpdateError"] = "Update Error";
    ActionNames["Delete"] = "Delete";
    ActionNames["DeleteSuccess"] = "Delete Success";
    ActionNames["DeleteError"] = "Delete Error";
})(ActionNames || (ActionNames = {}));
var isPluralAction = function (actionName) {
    return (actionName &&
        [ActionNames.Load, ActionNames.LoadSuccess, ActionNames.LoadError].includes(actionName));
};
var ɵ0 = isPluralAction;
var getAction = function (entityName, actionName, entityText) {
    return "[" + entityName + "] " + actionName + " - " + (entityText || entityName);
};
var ɵ1 = getAction;
export var ActionNameTypeMapFactory = function (stateConfig) {
    var actionNameTypeMap = Object.values(ActionNames).reduce(function (acc, actionNameValue) {
        acc[actionNameValue] = getAction(stateConfig.entityName, actionNameValue, isPluralAction(actionNameValue) ? stateConfig.pluralName : undefined);
        return acc;
    }, {});
    return actionNameTypeMap;
};
export var buildTokenName = function (stateConfig) {
    return stateConfig.entityName + " Reducer";
};
var ErrorAction = /** @class */ (function () {
    function ErrorAction(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = undefined;
        this.error = true;
    }
    return ErrorAction;
}());
export { ErrorAction };
var Create = /** @class */ (function () {
    function Create(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.Create];
    }
    return Create;
}());
export { Create };
var CreateSuccess = /** @class */ (function () {
    function CreateSuccess(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.CreateSuccess];
    }
    return CreateSuccess;
}());
export { CreateSuccess };
var CreateError = /** @class */ (function (_super) {
    __extends(CreateError, _super);
    function CreateError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = _this.actionNameTypeMap[ActionNames.CreateError];
        return _this;
    }
    return CreateError;
}(ErrorAction));
export { CreateError };
var GetById = /** @class */ (function () {
    function GetById(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.GetById];
    }
    return GetById;
}());
export { GetById };
var GetByIdSuccess = /** @class */ (function () {
    function GetByIdSuccess(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.GetByIdSuccess];
    }
    return GetByIdSuccess;
}());
export { GetByIdSuccess };
var GetByIdError = /** @class */ (function (_super) {
    __extends(GetByIdError, _super);
    function GetByIdError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = _this.actionNameTypeMap[ActionNames.GetByIdError];
        return _this;
    }
    return GetByIdError;
}(ErrorAction));
export { GetByIdError };
var Load = /** @class */ (function () {
    function Load(actionNameTypeMap) {
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.Load];
    }
    return Load;
}());
export { Load };
var LoadSuccess = /** @class */ (function () {
    function LoadSuccess(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.LoadSuccess];
    }
    return LoadSuccess;
}());
export { LoadSuccess };
var LoadError = /** @class */ (function (_super) {
    __extends(LoadError, _super);
    function LoadError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = _this.actionNameTypeMap[ActionNames.LoadError];
        return _this;
    }
    return LoadError;
}(ErrorAction));
export { LoadError };
var Update = /** @class */ (function () {
    function Update(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.Update];
    }
    return Update;
}());
export { Update };
var UpdateSuccess = /** @class */ (function () {
    function UpdateSuccess(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.UpdateSuccess];
    }
    return UpdateSuccess;
}());
export { UpdateSuccess };
var UpdateError = /** @class */ (function (_super) {
    __extends(UpdateError, _super);
    function UpdateError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = _this.actionNameTypeMap[ActionNames.UpdateError];
        return _this;
    }
    return UpdateError;
}(ErrorAction));
export { UpdateError };
var Delete = /** @class */ (function () {
    function Delete(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.Delete];
    }
    return Delete;
}());
export { Delete };
var DeleteSuccess = /** @class */ (function () {
    function DeleteSuccess(payload, actionNameTypeMap) {
        this.payload = payload;
        this.actionNameTypeMap = actionNameTypeMap;
        this.type = this.actionNameTypeMap[ActionNames.DeleteSuccess];
    }
    return DeleteSuccess;
}());
export { DeleteSuccess };
var DeleteError = /** @class */ (function (_super) {
    __extends(DeleteError, _super);
    function DeleteError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = _this.actionNameTypeMap[ActionNames.DeleteError];
        return _this;
    }
    return DeleteError;
}(ErrorAction));
export { DeleteError };
var StateActions = /** @class */ (function () {
    function StateActions(store, actionNameTypeMap) {
        this.store = store;
        this.actionNameTypeMap = actionNameTypeMap;
    }
    StateActions.prototype.create = function (entity) {
        this.store.dispatch(new Create(entity, this.actionNameTypeMap));
    };
    StateActions.prototype.load = function () {
        this.store.dispatch(new Load(this.actionNameTypeMap));
    };
    StateActions.prototype.update = function (payload) {
        this.store.dispatch(new Update(payload, this.actionNameTypeMap));
    };
    StateActions.prototype.getById = function (id) {
        this.store.dispatch(new GetById(id, this.actionNameTypeMap));
    };
    StateActions.prototype.delete = function (payload) {
        this.store.dispatch(new Delete(payload, this.actionNameTypeMap));
    };
    return StateActions;
}());
export { StateActions };
var StateEffects = /** @class */ (function () {
    function StateEffects(actions$, entityApiService, actionNameTypeMap, errorHandlerService) {
        var _this = this;
        this.actions$ = actions$;
        this.entityApiService = entityApiService;
        this.actionNameTypeMap = actionNameTypeMap;
        this.errorHandlerService = errorHandlerService;
        this.createEntity$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.Create]), switchMap(function (action) { return _this.entityApiService.save(action.payload); }), map(function (response) { return new CreateSuccess(response, _this.actionNameTypeMap); }), catchError(function (error) {
            return of(new CreateError(_this.errorHandlerService.handleErrorSafe(error), _this.actionNameTypeMap));
        }));
        this.loadEntities$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.Load]), switchMap(function (action) { return _this.entityApiService.getAll(); }), map(function (response) { return new LoadSuccess(response, _this.actionNameTypeMap); }), catchError(function (error) {
            return of(new LoadError(_this.errorHandlerService.handleErrorSafe(error), _this.actionNameTypeMap));
        }));
        this.getEntityById$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.GetById]), switchMap(function (action) { return _this.entityApiService.getById(action.payload); }), map(function (response) { return new GetByIdSuccess(response, _this.actionNameTypeMap); }), catchError(function (error) {
            return of(new GetByIdError(_this.errorHandlerService.handleErrorSafe(error), _this.actionNameTypeMap));
        }));
        this.updateEntity$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.Update]), switchMap(function (action) {
            return _this.entityApiService.update({
                id: action.payload.id,
                changes: action.payload.changes,
                options: action.payload.options,
            });
        }), map(function (response) { return new UpdateSuccess(response, _this.actionNameTypeMap); }), catchError(function (error) {
            return of(new UpdateError(_this.errorHandlerService.handleErrorSafe(error), _this.actionNameTypeMap));
        }));
        this.deleteEntity$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.Delete]), switchMap(function (action) { return _this.entityApiService.delete(action.payload); }), map(function (id) { return new DeleteSuccess(id, _this.actionNameTypeMap); }), catchError(function (error) {
            return of(new DeleteError(_this.errorHandlerService.handleErrorSafe(error), _this.actionNameTypeMap));
        }));
        this.deleteEntitySuccess$ = this.actions$.pipe(ofType(this.actionNameTypeMap[ActionNames.DeleteSuccess]));
    }
    return StateEffects;
}());
export { StateEffects };
export function stateReducerFactory(actionNameTypeMap, entityAdapter, customReducer) {
    var loadingState = { error: null, loading: true };
    var loadingSuccessState = { error: null, loading: false };
    var loadingErrorState = { loading: false };
    var updateErrorState = { loading: false };
    return function (state, action) {
        if (state === void 0) { state = entityAdapter.getInitialState({
            loading: false,
            error: null,
        }); }
        switch (action.type) {
            case actionNameTypeMap[ActionNames.Load]:
                return __assign({}, state, loadingState);
            case actionNameTypeMap[ActionNames.LoadSuccess]:
                return entityAdapter.addAll(action.payload, __assign({}, state, loadingSuccessState));
            case actionNameTypeMap[ActionNames.GetByIdSuccess]:
                return entityAdapter.upsertOne(action.payload, __assign({}, state, { loading: false, error: null }));
            case actionNameTypeMap[ActionNames.LoadError]:
                return __assign({}, state, loadingErrorState, { error: action.payload });
            case actionNameTypeMap[ActionNames.DeleteSuccess]:
                return entityAdapter.removeOne(action.payload, __assign({}, state, loadingSuccessState));
            case actionNameTypeMap[ActionNames.UpdateSuccess]:
                var updatedEntity = action.payload;
                var update = {
                    id: updatedEntity.id,
                    changes: updatedEntity,
                };
                return entityAdapter.updateOne(update, __assign({}, state, loadingSuccessState));
            case actionNameTypeMap[ActionNames.UpdateError]:
                return __assign({}, state, updateErrorState, { error: action.payload });
            default:
                return customReducer ? customReducer(state, action) : state;
        }
    };
}
export { ɵ0, ɵ1 };
