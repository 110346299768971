/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../app-navbar/app-navbar.component.ngfactory";
import * as i4 from "../app-navbar/app-navbar.component";
import * as i5 from "../../../../core/profile/store/profile.selectors";
import * as i6 from "../../../../core/feature-flag/store/feature-flag.selectors";
import * as i7 from "@angular/router";
import * as i8 from "./app-shell.component";
var styles_AppShellComponent = [i0.styles];
var RenderType_AppShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppShellComponent, data: {} });
export { RenderType_AppShellComponent as RenderType_AppShellComponent };
export function View_AppShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "omg-app-navbar", [], null, null, null, i3.View_AppNavbarComponent_0, i3.RenderType_AppNavbarComponent)), i1.ɵdid(4, 245760, null, 0, i4.AppNavbarComponent, [i5.ProfileSelectors, i6.FeatureFlagSelectors, i7.Router, i1.ChangeDetectorRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.fontFeatureFlags$)); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-app-shell", [], null, null, null, View_AppShellComponent_0, RenderType_AppShellComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppShellComponent, [i6.FeatureFlagSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppShellComponentNgFactory = i1.ɵccf("omg-app-shell", i8.AppShellComponent, View_AppShellComponent_Host_0, {}, {}, []);
export { AppShellComponentNgFactory as AppShellComponentNgFactory };
