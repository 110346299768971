import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormModel } from '@app/shared/forms';
import { HealthBackgroundNoteType } from '../../shared/health-background.type';

@Component({
  selector: 'omg-health-background-form',
  templateUrl: './health-background-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthBackgroundFormComponent implements OnInit {
  @Input() formModel: FormModel;
  @Input() formTitle: FormGroup;
  @Input() noteType: HealthBackgroundNoteType;
  @Input() updatedAt: Date;
  @Input() updatedBy: string;
  @Input() reviewed: boolean;
  @Output() markAsReviewed = new EventEmitter();

  ngOnInit() {}

  markAsReviewedClick() {
    this.markAsReviewed.emit();
  }
}
