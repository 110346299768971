import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { windowToken } from '@app/shared/window/window.service';

/*
  RouteTracker is used for the login/auth process.
  It tracks every route change and saves it (if valid path) to sessionStorage.
  The saved path is used as a path to redirect to after logging in.
*/
@Injectable()
export class RouteTracker implements OnDestroy {
  private unsubscribe = new Subject();
  private whitelistedRedirectPaths: string[] = [
    'schedule',
    'patients',
    'upgrade/patients',
  ];

  constructor(
    private router: Router,
    @Inject(windowToken) private window: Window,
  ) {
    this.trackAndSetRedirectPaths()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  trackAndSetRedirectPaths() {
    return this.router.events.pipe(
      filter(Boolean),
      filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => this.filterRedirectablePaths(event.url)),
      tap((event: NavigationEnd) => {
        this.setRedirectPath(event.url);
      }),
    );
  }

  private filterRedirectablePaths(url: string) {
    const shouldRedirect = this.whitelistedRedirectPaths.reduce(
      (willRedirect, whitelistedPath) => {
        if (url.startsWith(`/${whitelistedPath}`)) {
          return true;
        }
        return willRedirect;
      },
      false,
    );

    return shouldRedirect;
  }

  private setRedirectPath(url: string) {
    this.window.sessionStorage.setItem('path', url);
  }
}
