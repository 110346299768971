import { orderBy } from 'lodash';
import {
  NoKnownAllergies,
  NoKnownAllergyResponse,
  PatientAllergies,
  PatientAllergiesResponse,
  PatientAllergy,
  PatientAllergyResponse,
} from './allergies.type';

const allergyPath = '/v2/admin/';

export const allergiesRoute = (
  patientId: number,
  baseRoute: string,
  subRoute: string = '',
  allergyId?: number,
): string => {
  if (allergyId) {
    return `${allergyPath}${baseRoute}/${allergyId.toString()}?patientId=${patientId}`;
  }

  return `${allergyPath}${baseRoute}/${patientId}${subRoute}`;
};

/* istanbul ignore next */
const mapToAllergyResponse = (
  item: PatientAllergyResponse,
): Partial<PatientAllergyResponse> => {
  if (item.custom_allergen && !item.allergy) {
    return { allergy: { name: item.custom_allergen } };
  }
  return item;
};

/* istanbul ignore next */
export const mapAllergyResponseToEntity = (
  response: PatientAllergiesResponse,
): PatientAllergies => {
  let items;

  // Allergy data returns as an array (items) or as an object (patient_allergy)
  if (response.items) {
    items = response.items.map((item: PatientAllergyResponse) => {
      const mappedItem = mapToAllergyResponse(item);

      return {
        active: item.active,
        allergy: {
          drugAllergyCheckCompatible:
            mappedItem.allergy &&
            mappedItem.allergy.drug_allergy_check_compatible,
          id: mappedItem.allergy && mappedItem.allergy.id,
          name: mappedItem.allergy && mappedItem.allergy.name,
          clinicalAbbreviation:
            mappedItem.allergy && mappedItem.allergy.clinical_abbreviation,
          isActive: mappedItem.allergy && mappedItem.allergy.is_active,
        },
        comment: item.comment,
        customAllergen: item.custom_allergen,
        id: item.id,
        patientAllergyHistoryId: item.patient_allergy_history_id,
        reaction: item.reaction,
      };
    });
  } else {
    items = [
      {
        active: response.patient_allergy && response.patient_allergy.active,
        allergy: {
          drugAllergyCheckCompatible:
            response.patient_allergy &&
            response.patient_allergy.allergy &&
            response.patient_allergy.allergy.drug_allergy_check_compatible,
          id:
            response.patient_allergy &&
            response.patient_allergy.allergy &&
            response.patient_allergy.allergy.id,
          name:
            response.patient_allergy &&
            response.patient_allergy.allergy &&
            response.patient_allergy.allergy.name,
          isActive:
            response.patient_allergy &&
            response.patient_allergy.allergy &&
            response.patient_allergy.allergy.is_active,
        },
        comment: response.patient_allergy && response.patient_allergy.comment,
        customAllergen:
          response.patient_allergy && response.patient_allergy.custom_allergen,
        id: response.patient_allergy && response.patient_allergy.id,
        patientAllergyHistoryId:
          response.patient_allergy &&
          response.patient_allergy.patient_allergy_history_id,
        reaction: response.patient_allergy && response.patient_allergy.reaction,
      },
    ];
  }

  return {
    items,
    noKnownAllergies:
      (response.patient_no_known_allergy && {
        editedBy: response.patient_no_known_allergy.edited_by,
        updatedAt: response.patient_no_known_allergy.updated_at,
      }) ||
      ({} as any),
  };
};

export const buildAllergyUpdateRequest = (
  entity: Partial<PatientAllergy>,
): PatientAllergyResponse => ({
  active: entity.active,
  allergy: {
    clinical_abbreviation:
      entity.allergy && entity.allergy.clinicalAbbreviation,
    id: entity.allergy && entity.allergy.id,
    name: entity.allergy && entity.allergy.name,
  },
  comment: entity.comment,
  custom_allergen: entity.customAllergen,
  id: entity.id,
  patient_allergy_history_id: entity.patientAllergyHistoryId,
  reaction: entity.reaction,
});

export const buildAllergySaveRequest = (
  entity: Partial<PatientAllergy>,
): PatientAllergyResponse => ({
  allergy: {
    clinical_abbreviation:
      entity.allergy && entity.allergy.clinicalAbbreviation,
    id: entity.allergy && entity.allergy.id,
    name: entity.allergy && entity.allergy.name,
    drug_allergy_check_compatible:
      entity.allergy && entity.allergy.drugAllergyCheckCompatible,
  },
  id: entity.id,
  comment: entity.comment,
  reaction: entity.reaction,
});

/* istanbul ignore next */
export const mapNoKnownAllergyResponseToEntity = (
  response: NoKnownAllergyResponse,
): NoKnownAllergies => ({
  editedBy: response.edited_by,
  updatedAt: response.updated_at,
});

export const getActivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];
  const activePatientAllergies = allergies.filter(
    (item: PatientAllergy) => item.active,
  );
  return orderBy(
    activePatientAllergies,
    activePatientAllergy => activePatientAllergy.allergy.name,
  );
};

export const getInactivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];

  return allergies.filter((item: PatientAllergy) => !item.active);
};

export const getAllergyNotCheckedWarning = (
  patientAllergy: PatientAllergy,
): string => {
  if (patientAllergy.customAllergen) {
    return 'Custom allergens are not included during automatic drug-allergy checks.';
  } else if (
    patientAllergy.allergy &&
    !patientAllergy.allergy.drugAllergyCheckCompatible
  ) {
    return 'Inactive ingredients or retired allergy concepts are not included during automatic drug-allergy checks.';
  }
};
