import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Observable, of, Subject } from 'rxjs';

import { FeatureFlagSelectors, ProfileSelectors } from '@app/core';
import { logoutPath } from '@app/core/auth/shared/auth-constants';
import { invoke } from '@app/utils';
import { environment } from '@environments/environment';

@Component({
  selector: 'omg-app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  @ViewChild(Menu) profileMenu: Menu;
  primaryIdentityName: Observable<string>;
  profileOptions: Observable<MenuItem[]>;
  isEngineer$: Observable<boolean>;
  adminUiTasksEnabled$: Observable<boolean>;
  adminAppURL = `${environment.adminApp.host}/tasks/mine`;

  private unsubscribe = new Subject();
  private documentClickListener: any;
  private menuOpen = false;

  constructor(
    private profile: ProfileSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.primaryIdentityName = this.profile.primaryIdentityName;
    this.adminUiTasksEnabled$ = this.featureFlagSelectors.featureEnabled(
      'admin_ui_tasks',
    );
    this.profileOptions = this.createProfileOptions();
    this.isEngineer$ = this.profile.hasRole('engineer');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  signout = () => {
    this.router.navigateByUrl(`/${logoutPath}`);
  };

  toggleProfileMenu($event: Event) {
    if (!this.menuOpen) {
      this.showProfileMenu($event);
    } else {
      this.hideProfileMenu();
    }
    this.changeDetectorRef.detectChanges();
  }

  private createProfileOptions() {
    return of([
      {
        label: 'Sign Out',
        command: this.signout,
      },
    ]);
  }

  private toggleMenuOpen() {
    this.menuOpen = !this.menuOpen;
  }

  private showProfileMenu($event: Event) {
    this.profileMenu.show($event);
    this.bindDocumentClickListener();
    setTimeout(() => {
      this.toggleMenuOpen();
    });
  }

  private hideProfileMenu() {
    this.profileMenu.hide();
    this.toggleMenuOpen();
    this.unbindDocumentClickListener();
  }

  private bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen(
        'document',
        'click',
        () => {
          if (this.menuOpen) {
            this.hideProfileMenu();
            this.changeDetectorRef.markForCheck();
          }
        },
      );
    }
  }

  private unbindDocumentClickListener() {
    invoke('documentClickListener', this);
    this.documentClickListener = null;
  }
}
