import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { pickBy } from '@app/utils';

@Directive({
  selector: '[omgSyncInput][control]',
})
export class SyncInputDirective implements OnInit {
  constructor(private el: ElementRef) {}
  // tslint:disable-next-line:no-input-rename
  @Input('control') formControl: FormControl;

  ngOnInit(): void {
    this.el.nativeElement.value = this.formControl.value;
  }

  @HostListener('input', ['$event']) private updateFormControl() {
    this.formControl.setErrors(null);

    if (this.el.nativeElement.validity.valid) {
      this.formControl.setValue(this.el.nativeElement.value);
    } else {
      const errors = pickBy(value => value, this.el.nativeElement.validity);
      this.formControl.setErrors(errors);
    }
  }
}
