import { createFeatureSelector, createSelector } from '@ngrx/store';
import { select, Store } from '@ngrx/store';
import { featureFlagStatePath } from './feature-flag.reducer';
export var selectFeatureFlagState = createFeatureSelector(featureFlagStatePath);
export var selectFeatureFlags = createSelector(selectFeatureFlagState, function (state) { return state && state.entity; });
export var selectFeatureFlagEnabled = createSelector(selectFeatureFlags, function (flags, name) { return flags && flags.includes(name); });
var FeatureFlagSelectors = /** @class */ (function () {
    function FeatureFlagSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(FeatureFlagSelectors.prototype, "featureFlags", {
        get: function () {
            return this.store.pipe(select(selectFeatureFlags));
        },
        enumerable: true,
        configurable: true
    });
    FeatureFlagSelectors.prototype.featureEnabled = function (name) {
        return this.store.pipe(select(selectFeatureFlagEnabled, name));
    };
    return FeatureFlagSelectors;
}());
export { FeatureFlagSelectors };
