<p-toast [position]="position">
  <ng-template let-message
               pTemplate="message">
    <div om-layout
         om-layout-align="start center"
         om-layout-gutter>
      <span class="ui-toast-icon pi"
            [ngClass]="{
        'pi-info-circle': message.severity == 'info',
        'pi-exclamation-triangle':
          message.severity == 'warn' || message.severity == 'error',
        'pi-check': message.severity == 'success'
      }"></span>

      <div class="ui-toast-message-text-content"
           om-layout="vertical"
           om-layout-gutter>
        <div class="ui-toast-summary"
             [innerHtml]="message.summary"></div>
        <div class="ui-toast-detail"
             om-layout="vertical">
          <span [innerHtml]="message.detail"></span>
          <a [routerLink]="message.data?.routerLink"
             *ngIf="message.data?.routerLink && message.data?.linkText">{{message.data.linkText}}</a></div>
      </div>

    </div>
  </ng-template>
</p-toast>
