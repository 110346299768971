import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var CollapseDirective = /** @class */ (function () {
    function CollapseDirective() {
        this.expandedChangeSubject = new BehaviorSubject(false);
        this.expandedChange = this.expandedChangeSubject.asObservable();
        // NOTE: These are to help preserve existing styling
        this.collapseClass = true;
        this.collapseAttribute = true;
    }
    Object.defineProperty(CollapseDirective.prototype, "expandedClass", {
        get: function () {
            return this.expanded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollapseDirective.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    CollapseDirective.prototype.ngOnInit = function () {
        if (this.expandedChangeSubject.value !== this.expanded) {
            this.expandedChangeSubject.next(this.expanded);
        }
    };
    CollapseDirective.prototype.expand = function () {
        this.setExpandedState(true);
    };
    CollapseDirective.prototype.collapse = function () {
        this.setExpandedState(false);
    };
    CollapseDirective.prototype.toggle = function () {
        if (!this.disabled) {
            this.setExpandedState(!this.expanded);
        }
    };
    CollapseDirective.prototype.setExpandedState = function (value) {
        if (this.expanded !== value) {
            this.expanded = value;
            this.expandedChangeSubject.next(value);
        }
    };
    return CollapseDirective;
}());
export { CollapseDirective };
