import { Attribute, ElementRef, HostBinding, Input } from '@angular/core';
import { camelCase } from '@app/utils';

export class BaseInput {
  @HostBinding('attr.autocomplete') autoComplete = 'off';
  @HostBinding('attr.name') nameAttr;

  constructor(
    private el: ElementRef,
    @Attribute('formControlName') private controlName?,
    @Attribute('name') private name?,
  ) {
    this.nameAttr =
      this.name ||
      this.controlName ||
      (this.el.nativeElement.parentElement &&
        camelCase(this.el.nativeElement.parentElement.textContent)) || // assuming parent is a label tag
      '';
  }
}
