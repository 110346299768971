import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { loginPath } from '@app/core/auth/shared/auth-constants';
import { AuthService } from '@app/core/auth/shared/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    const isAuthenticated = this.auth.isAuthenticated();
    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigateByUrl(`/${loginPath}`);
      return false;
    }
  }
}
