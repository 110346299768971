var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as fromActions from './active-routes.actions';
export var initialState = {
    orders: null,
    workspace: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromActions.ActiveRoutesActionTypes.SET_ORDERS: {
            return __assign({}, state, { orders: action.payload });
        }
        case fromActions.ActiveRoutesActionTypes.CLEAR_ORDERS: {
            return __assign({}, state, { orders: null });
        }
        case fromActions.ActiveRoutesActionTypes.SET_WORKSPACE: {
            return __assign({}, state, { workspace: action.payload });
        }
        case fromActions.ActiveRoutesActionTypes.CLEAR_WORKSPACE: {
            return __assign({}, state, { workspace: null });
        }
        default:
            return state;
    }
}
