import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '@app/core';

import {
  mapMeasurementTypeError,
  mapMeasurementTypeResponseToEntity,
} from './measurement-types-mappers';
import { MeasurementTypeResponseItem } from './measurement-types-response.type';

const vitalsPath = '/v2/admin/measurement_types/vitals';

@Injectable()
export class MeasurementTypesService {
  constructor(private api: ApiService) {}

  getAll() {
    return this.api.get<MeasurementTypeResponseItem[]>(vitalsPath).pipe(
      map(response => mapMeasurementTypeResponseToEntity(response)),
      catchError((error: HttpErrorResponse) =>
        throwError(mapMeasurementTypeError(error)),
      ),
    );
  }
}
