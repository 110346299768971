import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { HealthGoalType } from '../shared/health-maintenance.type';
import {
  HealthGoalTypeActions,
  HealthGoalTypeActionTypes,
} from './health-goal-type.actions';

export interface HealthGoalTypeState extends EntityState<HealthGoalType> {
  loading: boolean;
  error: any;
}

const sortComparer = (a: HealthGoalType, b: HealthGoalType) =>
  a.name && b.name && a.name.localeCompare(b.name);

export const adapter: EntityAdapter<HealthGoalType> = createEntityAdapter<
  HealthGoalType
>({
  selectId: (entity: HealthGoalType) => entity.id,
  sortComparer,
});

export const initialState: HealthGoalTypeState = adapter.getInitialState({
  loading: false,
  error: null,
});

export function reducer(
  state = initialState,
  action: HealthGoalTypeActions,
): HealthGoalTypeState {
  switch (action.type) {
    case HealthGoalTypeActionTypes.LoadHealthGoalTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case HealthGoalTypeActionTypes.LoadHealthGoalTypesSuccess: {
      return {
        ...adapter.addAll(action.payload, state),
        loading: false,
        error: null,
      };
    }

    case HealthGoalTypeActionTypes.LoadHealthGoalTypesError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case HealthGoalTypeActionTypes.ClearHealthGoalTypes: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of ids
export const selectHealthGoalTypeIds = selectIds;

// select the dictionary of entities
export const selectHealthGoalTypeEntities = selectEntities;

// select the array of items
export const selectAllHealthGoalTypes = selectAll;

// select the total count
export const selectHealthGoalTypeTotal = selectTotal;
