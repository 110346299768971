import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  updateEntityMetadata,
} from '@app/utils';

import { newEntityId, Problem } from '../shared/problems.type';
import { ProblemAction, ProblemActionTypes } from './problems.actions';

export const problemsStatePath = 'problems';

export interface ProblemState extends EntityState<Problem> {
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<Problem>;
  lastDeleted: Problem;
}

export function selectProblemId(problem: Problem): number {
  return problem.id;
}

export const adapter: EntityAdapter<Problem> = createEntityAdapter<Problem>({
  selectId: selectProblemId,
});

export const initialProblemsState: ProblemState = adapter.getInitialState({
  loading: false,
  error: null,
  lastDeleted: null,
  ...getEntityMetadataInitialState({}),
});

export function problemsReducer(
  state = initialProblemsState,
  action: ProblemAction,
): ProblemState {
  switch (action.type) {
    case ProblemActionTypes.GET_PROBLEMS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ProblemActionTypes.GET_PROBLEMS_SUCCESS: {
      return adapter.addAll(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case ProblemActionTypes.GET_PROBLEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ProblemActionTypes.SAVE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case ProblemActionTypes.SAVE_PROBLEM_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      });
    }

    case ProblemActionTypes.SAVE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM: {
      return {
        ...state,
        loading: true,
        error: null,
        ...updateEntityMetadata(
          action.payload.problem.id,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          {
            id: action.payload.id,
            changes: { ...action.payload },
          },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          { id: action.payload.id, changes: { ...action.payload } },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          { id: action.payload.id, changes: { ...action.payload } },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM_SUCCESS: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        loading: false,
        lastDeleted: action.payload,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          {
            pending: false,
            error: action.payload,
          },
          state,
        ),
      };
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.payload.problemId,
          changes: {
            history: action.payload,
          },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}
