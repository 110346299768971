<!-- Insight options dropdown -->
<div class="om-tab-subheading"
     om-layout
     om-layout-align="space-between stretch">
  <omg-dropdown *ngIf="(insightsDropdownOptions | async) as options; else loading"
                flex="true"
                om-flex="75"
                [options]="options"
                placeholder="Select an Insight"
                [(ngModel)]="selectedInsight"
                (ngModelChange)="insightSelected($event)">
  </omg-dropdown>

  <!-- Spinner for fetching insight options data -->
  <ng-template #loading
               om-layout
               om-layout-align="center"
               class="spinner">
    <i class="fa fa-lg fa-spinner fa-pulse padding-half-vertical"></i>
  </ng-template>
</div>

<!-- No data views -->
<div *ngIf="insights && insights?.length === 0"
     class="empty-tab-message">No Insights available for patient.</div>
<div *ngIf="!selectedInsight && insights?.length !== 0"
     class="empty-tab-message">Select an insight.</div>

<div *ngIf="selectedInsight">
  <ng-container *ngIf="(groupedData | async) as data; else graphLoading">
    <omg-insights-graph *ngFor="let insightData of data; trackBy: insightDataTrackBy"
                        [data]="insightData"></omg-insights-graph>
  </ng-container>

  <!-- Spinner for fetching chart data -->
  <ng-template #graphLoading>
    <div om-layout
         om-layout-align="center"
         class="spinner empty-tab-message">
      <i class="fa fa-lg fa-spinner fa-pulse padding-half-vertical"></i>
    </div>
  </ng-template>
</div>
