import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createEntityMetadataSelectors, orderBy, pipe } from '@app/utils';

import { groupBy } from 'lodash';
import {
  VaccinationsSectionLinkPayload,
  VaccineHistory,
} from '../shared/vaccinations.type';
import {
  VaccinationsState,
  vaccinationsStatePath,
} from './vaccinations.reducer';
import * as fromVaccineHistories from './vaccine-histories.reducer';

export const selectVaccinationsState = createFeatureSelector<VaccinationsState>(
  vaccinationsStatePath,
);

export const selectVaccineHistoriesState = createSelector(
  selectVaccinationsState,
  state => state.vaccineHistories,
);

export const selectVaccineHistoriesIds = createSelector(
  selectVaccineHistoriesState,
  fromVaccineHistories.selectVaccineHistoriesIds,
);

export const selectVaccineHistoriesEntities = createSelector(
  selectVaccineHistoriesState,
  fromVaccineHistories.selectVaccineHistoriesEntities,
);

const _selectAllVaccineHistories = createSelector(
  selectVaccineHistoriesState,
  fromVaccineHistories.selectAllVaccineHistories,
);

export const selectAllVaccineHistories = createSelector(
  _selectAllVaccineHistories,
  (vaccineHistories: VaccineHistory[]): VaccineHistory[] =>
    pipe(
      orderBy('id', 'asc'),
      orderBy('givenOn', 'desc'),
      orderBy('name', 'asc'),
    )(vaccineHistories),
);

export const selectAllVaccineHistoryIds = createSelector(
  selectAllVaccineHistories,
  vaccineHistories =>
    vaccineHistories.map(
      vaccineHistory => vaccineHistory.historyVaccineHistoryId,
    ),
);

export const selectVaccinationsSectionLinkPayload = createSelector(
  selectAllVaccineHistories,
  selectAllVaccineHistoryIds,
  (vaccineHistories, ids): VaccinationsSectionLinkPayload => ({
    legacyData: vaccineHistories,
    vaccineHistoryIds: ids,
  }),
);

export const selectVaccineHistoryById = createSelector(
  selectVaccineHistoriesState,
  (state, { id }) => state.entities[id],
);

export const selectHasVaccineHistoryFullyLoaded = createSelector(
  selectVaccineHistoryById,
  state => state && state.hasFullyLoaded,
);

export const selectHasVaccineHistories = createSelector(
  selectAllVaccineHistories,
  state => state && state.length >= 1,
);

export const {
  selectEntityMetadata,
  selectEntityWithMetadata,
  selectEntityError,
} = createEntityMetadataSelectors(
  selectVaccineHistoriesState,
  selectVaccineHistoryById,
);

export const selectAllGroupedVaccineHistories = createSelector(
  selectAllVaccineHistories,
  allHistories => groupBy(allHistories, item => item.name),
);
