import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { ActiveRoutesState } from './active-routes.reducer';

const getState = state => <ActiveRoutesState>state.activeRoutes;

export const selectActiveOrders = createSelector(
  getState,
  state => state.orders,
);

export const selectActiveWorkspace = createSelector(
  getState,
  state => state.workspace,
);

/* istanbul ignore next */
@Injectable()
export class ActiveRoutesSelectors {
  constructor(private store: Store<ActiveRoutesState>) {}

  get activeOrders() {
    return this.store.pipe(select(selectActiveOrders));
  }

  get activeWorkspace() {
    return this.store.pipe(select(selectActiveWorkspace));
  }
}
