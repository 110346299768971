<table class="tableau-font-size">
  <ng-container *ngIf="vitalsData | async as data">
    <thead>
      <tr>
        <th class="padding-half"></th>
        <th class="padding-half"
            *ngFor="let header of data.headers; trackBy: trackByIndex">
          {{ header | omgDate: '4y'}}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="data?.hasData">
        <tr *ngFor="let measurementType of data.measurementTypes; trackBy: trackByIndex">
          <td class="left-align padding-half">
            <strong>{{ measurementType?.abbreviation}}</strong>
          </td>
          <td class="left-align padding-half"
              *ngFor="let measurement of measurementType.vitalMeasurements; trackBy: trackByIndex"
              omgTooltip
              [tooltipText]="measurement.metadata"
              tooltipPosition="bottom">
            {{ measurement?.vital?.value }} {{ measurement?.vital?.unit }}
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="!data?.hasData">
        <td class="left-align padding-half">
          No vitals recorded.
        </td>
      </tr>
    </tbody>
  </ng-container>
</table>
