import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AppBridgeSelectors } from '../services/app-bridge.selectors';

type LegacyPatient = any;

@Injectable()
export class LegacyPatientResolver
  implements Resolve<Observable<LegacyPatient>> {

  constructor(private selectors: AppBridgeSelectors) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.selectors.patient.pipe(
      filter(Boolean),
      map(patient => of(patient)),
      take(1),
    );
  }
}
