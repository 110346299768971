var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@ngrx/store';
import { selectHealthMaintenanceNote } from '@app/core/patient/store/patient.selectors';
import { pluckEntitiesByIds } from '@app/utils/store';
import { selectAllHealthGoalScreenings, selectHealthGoalScreeningEntities, } from './health-goal-screening.selectors';
import { selectAllHealthGoals, selectHealthGoalById, } from './health-goal.selectors';
// selects a health goal with screenings
export var selectHealthGoalWithScreenings = createSelector(selectHealthGoalById, selectHealthGoalScreeningEntities, function (healthGoal, screenings) { return (__assign({}, healthGoal, { screeningHistory: pluckEntitiesByIds(screenings, healthGoal.screeningHistoryIds) })); });
// selects all the health maintenance data
export var selectHealthGoalsSectionLinkPayload = createSelector(selectAllHealthGoals, selectAllHealthGoalScreenings, selectHealthMaintenanceNote, 
// required for legacy data only
selectHealthGoalScreeningEntities, function (healthGoals, screenings, healthMaintenanceNote, screeningEntities) {
    if (healthMaintenanceNote === void 0) { healthMaintenanceNote = {}; }
    if (screeningEntities === void 0) { screeningEntities = {}; }
    var legacyData = {
        healthGoals: healthGoals.map(function (healthGoal) { return (__assign({}, healthGoal, { screeningHistory: pluckEntitiesByIds(screeningEntities, healthGoal.screeningHistoryIds) })); }),
        healthMaintenanceNote: healthMaintenanceNote,
    };
    return {
        healthGoalHistoryIds: healthGoals.map(function (i) { return i.healthGoalHistoryId; }),
        healthGoalScreeningHistoryIds: screenings.map(function (i) { return i.screeningHistoryId; }),
        healthMaintenanceNoteHistoryId: healthMaintenanceNote.healthMaintenanceNoteHistoryId,
        legacyData: legacyData,
    };
});
