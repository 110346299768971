var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { createSelector } from '@ngrx/store';
var initialItemState = { pending: false, error: null };
export var createStoreMetadataAdapter = function (statePath) {
    if (statePath === void 0) { statePath = 'metadata'; }
    var getInitialState = function (state) {
        var _a;
        return (__assign({}, state, (_a = {}, _a[statePath] = {}, _a)));
    };
    var getSelectors = function (selectParentState) {
        var selectMap = createSelector(selectParentState, function (state) { return state[statePath]; });
        var selectItemByKey = createSelector(selectMap, function (meta, _a) {
            var key = _a.key;
            return meta[key];
        });
        return {
            selectMap: selectMap,
            selectItemByKey: selectItemByKey,
        };
    };
    var upsertOne = function (key, changes, state) {
        var _a, _b;
        return (_a = {},
            _a[statePath] = __assign({}, state[statePath], (_b = {}, _b[key] = __assign({}, initialItemState, state[key], changes), _b)),
            _a);
    };
    var removeOne = function (key, state) {
        var _a;
        var _b = state[statePath], _c = key, item = _b[_c], rest = __rest(_b, [typeof _c === "symbol" ? _c : _c + ""]);
        return _a = {},
            _a[statePath] = __assign({}, rest),
            _a;
    };
    return {
        getInitialState: getInitialState,
        getSelectors: getSelectors,
        upsertOne: upsertOne,
        removeOne: removeOne,
    };
};
