import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';
import { catchHttpError422 } from '@app/utils';

import {
  mapHealthGoalFormToSaveRequest,
  mapHealthGoalResponse,
  mapHealthGoalSaveErrorResponse,
} from './health-maintenance-api-mappers';
import { HealthGoalResponse } from './health-maintenance-api.type';
import { HealthGoalForm } from './health-maintenance.type';

const healthGoalsPath = '/v2/admin/health_goals';

const createHealthGoalRoute = (patientId: number) =>
  patientRoute(patientId, '/health_goals');

@Injectable()
export class HealthGoalService {
  constructor(private api: ApiService) {}

  getAll(patientId: number) {
    return this.api
      .get<HealthGoalResponse[]>(createHealthGoalRoute(patientId))
      .pipe(map(items => items.map(mapHealthGoalResponse)));
  }

  add(patientId: number, healthGoal: HealthGoalForm) {
    return this.api
      .save<HealthGoalResponse>(
        createHealthGoalRoute(patientId),
        mapHealthGoalFormToSaveRequest(healthGoal),
      )
      .pipe(
        map(mapHealthGoalResponse),
        catchHttpError422(error =>
          throwError(mapHealthGoalSaveErrorResponse(error.error)),
        ),
      );
  }

  update(patientId: number, healthGoal: HealthGoalForm) {
    return this.api
      .update<HealthGoalResponse>(
        `${healthGoalsPath}/${healthGoal.id}`,
        mapHealthGoalFormToSaveRequest(healthGoal),
        {
          params: { patientId },
        },
      )
      .pipe(
        map(mapHealthGoalResponse),
        catchHttpError422(error =>
          throwError(mapHealthGoalSaveErrorResponse(error.error)),
        ),
      );
  }

  delete(patientId: number, healthGoalId: number) {
    return this.api
      .delete(`${healthGoalsPath}/${healthGoalId}`, {
        params: {
          patientId,
        },
      })
      .pipe(
        catchHttpError422(error =>
          throwError(mapHealthGoalSaveErrorResponse(error.error)),
        ),
      );
  }
}
