var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentRef, ElementRef, EmbeddedViewRef, } from '@angular/core';
import { FocusTrapFactory } from '@angular/cdk/a11y';
import { BasePortalOutlet, CdkPortalOutlet, } from '@angular/cdk/portal';
import { DialogConfig } from './dialog-config';
var throwContentAlreadyAttached = function () {
    throw Error('Attempting to attach dialog content after content is already attached');
};
var ɵ0 = throwContentAlreadyAttached;
// tslint:disable component-class-suffix
// tslint:disable use-host-property-decorator
/**
 * Internal component that wraps user-provided dialog content.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog-container.ts
 */
var DialogContainer = /** @class */ (function (_super) {
    __extends(DialogContainer, _super);
    function DialogContainer(elementRef, focusTrapFactory, _document, _config) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        _this.focusTrapFactory = focusTrapFactory;
        _this._document = _document;
        _this._config = _config;
        /** Element that was focused before the dialog was opened. Save this to restore upon close. */
        _this.elementFocusedBeforeDialogWasOpened = null;
        /** ID of the element that should be considered as the dialog's label. */
        _this._ariaLabelledBy = null;
        return _this;
    }
    DialogContainer.prototype.dispose = function () {
        this.restoreFocus();
    };
    /**
     * Attach a ComponentPortal as content to this dialog container.
     * @param portal Portal to be attached as the dialog content.
     */
    DialogContainer.prototype.attachComponentPortal = function (portal) {
        if (this.portalOutlet.hasAttached()) {
            throwContentAlreadyAttached();
        }
        this.savePreviouslyFocusedElement();
        var viewRef = this.portalOutlet.attachComponentPortal(portal);
        this.trapFocus();
        return viewRef;
    };
    /**
     * Attach a TemplatePortal as content to this dialog container.
     * @param portal Portal to be attached as the dialog content.
     */
    DialogContainer.prototype.attachTemplatePortal = function (portal) {
        if (this.portalOutlet.hasAttached()) {
            throwContentAlreadyAttached();
        }
        this.savePreviouslyFocusedElement();
        var viewRef = this.portalOutlet.attachTemplatePortal(portal);
        this.trapFocus();
        return viewRef;
    };
    /** Moves the focus inside the focus trap. */
    DialogContainer.prototype.trapFocus = function () {
        if (!this.focusTrap) {
            this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
        }
        // If were to attempt to focus immediately, then the content of the dialog would not yet be
        // ready in instances where change detection has to run first. To deal with this, we simply
        // wait for the microtask queue to be empty.
        if (this._config.autoFocus) {
            this.focusTrap.focusInitialElementWhenReady();
        }
    };
    /** Restores focus to the element that was focused before the dialog opened. */
    DialogContainer.prototype.restoreFocus = function () {
        var toFocus = this.elementFocusedBeforeDialogWasOpened;
        // We need the extra check, because IE can set the `activeElement` to null in some cases.
        if (this._config.restoreFocus &&
            toFocus &&
            typeof toFocus.focus === 'function') {
            toFocus.focus();
        }
        if (this.focusTrap) {
            this.focusTrap.destroy();
        }
    };
    DialogContainer.prototype.savePreviouslyFocusedElement = function () {
        var _this = this;
        if (this._document) {
            this.elementFocusedBeforeDialogWasOpened = this._document
                .activeElement;
            // Note that there is no focus method when rendering on the server.
            if (this.elementRef.nativeElement.focus) {
                // Move focus onto the dialog immediately in order to prevent the user from accidentally
                // opening multiple dialogs at the same time. Needs to be async, because the element
                // may not be focusable immediately.
                Promise.resolve().then(function () { return _this.elementRef.nativeElement.focus(); });
            }
        }
    };
    return DialogContainer;
}(BasePortalOutlet));
export { DialogContainer };
export { ɵ0 };
