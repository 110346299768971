import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { flatMap, getProperty } from '@app/utils';
import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  selectIdsBy,
  updateEntityMetadata,
} from '@app/utils/store';

import {
  HealthGoalScreening,
  newEntityId,
} from '../shared/health-maintenance.type';

import {
  HealthGoalScreeningActions,
  HealthGoalScreeningActionTypes,
} from './health-goal-screening.actions';
import {
  HealthGoalActions,
  HealthGoalActionTypes,
} from './health-goal.actions';

export interface HealthGoalScreeningState
  extends EntityState<HealthGoalScreening> {
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<HealthGoalScreening>;
}

export const adapter: EntityAdapter<HealthGoalScreening> = createEntityAdapter<
  HealthGoalScreening
>({
  selectId: (entity: HealthGoalScreening) => entity.id,
});

export const initialState: HealthGoalScreeningState = adapter.getInitialState({
  loading: false,
  error: null,
  ...getEntityMetadataInitialState({}),
});

export function reducer(
  state = initialState,
  action: HealthGoalActions | HealthGoalScreeningActions,
): HealthGoalScreeningState {
  switch (action.type) {
    case HealthGoalScreeningActionTypes.AddHealthGoalScreening: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          newEntityId,
          { pending: true, error: null },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.AddHealthGoalScreeningSuccess: {
      // Re-associate screening with new health goal (if applicable)
      const healthGoalId =
        action.payload.healthGoalId === null
          ? newEntityId
          : action.payload.healthGoalId;

      return {
        ...adapter.addOne({ ...action.payload, healthGoalId }, state),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.AddHealthGoalScreeningError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.UpdateHealthGoalScreening: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.id,
          { pending: true, error: null },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningSuccess: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          action.payload.id,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.UpdateHealthGoalScreeningError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.DeleteHealthGoalScreening: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningSuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload, state),
      };
    }

    case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case HealthGoalScreeningActionTypes.ClearHealthGoalScreenings: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    /**
     * Normalization for HealthGoals
     */

    case HealthGoalActionTypes.InitNewHealthGoal: {
      // remove existing screenings mapped to new health goal
      // this means these may be orphaned in the database
      // TODO: Confirm this behaviour is expected
      return {
        ...adapter.removeMany(
          selectIdsBy(state, i => i.healthGoalId === -1),
          state,
        ),
      };
    }

    case HealthGoalActionTypes.LoadHealthGoalsSuccess: {
      const entities = flatMap(i => i.screeningHistory, action.payload);
      return {
        ...adapter.addAll(entities, state),
      };
    }

    case HealthGoalActionTypes.AddHealthGoalSuccess: {
      return {
        ...adapter.upsertMany(
          getProperty(action.payload, 'screeningHistory'),
          state,
        ),
      };
    }

    case HealthGoalActionTypes.DeleteHealthGoalSuccess: {
      const ids = selectIdsBy(state, i => i.healthGoalId === action.payload);
      return {
        ...adapter.removeMany(ids, state),
      };
    }

    case HealthGoalActionTypes.ClearHealthGoals: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of ids
export const selectHealthGoalScreeningIds = selectIds;

// select the dictionary of entities
export const selectHealthGoalScreeningEntities = selectEntities;

// select the array of items
export const selectAllHealthGoalScreenings = selectAll;

// select the total count
export const selectHealthGoalScreeningTotal = selectTotal;
