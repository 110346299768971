import { Injectable } from '@angular/core';

import { ChangeDetectionBridgeService } from './change-detection-bridge.service';
import { RootScopeToStoreBridgeService } from './root-scope-to-store-bridge.service';
import { RouterBridgeService } from './router-bridge.service';
import { StoreToRootScopeBridgeService } from './store-to-root-scope-bridge.service';

@Injectable()
export class AppBridgeService {
  constructor(
    // NOTE: The change detection bridge is currently not needed
    // public changeDetection: ChangeDetectionBridgeService,
    public routerBridge: RouterBridgeService,
    public storeToRootScope: StoreToRootScopeBridgeService,
    public rootScopeToStore: RootScopeToStoreBridgeService,
  ) {}
}
