import { Inject, Injectable, OnDestroy } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';

import { SectionLinkedQueueService } from '@app/core/section-linked-queue/section-linked-queue.service';
import { SectionLinkedItem } from '@app/core/section-linked-queue/store';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';

import { getSectionLinkedTransform } from './root-scope-transforms';

@Injectable()
export class StoreToRootScopeBridgeService implements OnDestroy {
  private unsubscribe = new Subject();

  constructor(
    @Inject('$rootScope') private rootScope,
    private sectionLinkedQueue: SectionLinkedQueueService,
    private summariesSelectors: SummariesSelectors,
    private summariesActions: SummariesActions,
  ) {
    this.setupStoreListeners();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private emit(key: string, ...params) {
    return this.rootScope.$emit(key, ...params);
  }

  private setupStoreListeners() {
    // Add subscriptions to the store that need to be emitted to rootScope here
    this.subscribeToSectionLinked();
    this.subscribeToTimeline();
  }

  private subscribeToTimeline() {
    this.summariesSelectors.refreshTimeline
      .pipe(
        filter(refresh => refresh),
        takeUntil(this.unsubscribe),
      )
      .subscribe(refresh => {
        this.emit('refreshTimeline');
        this.summariesActions.refreshTimelineDone();
      });

    this.summariesSelectors.closeTimelineItem
      .pipe(
        filter(close => close),
        takeUntil(this.unsubscribe),
      )
      .subscribe(close => {
        this.emit('unset-timeline-note');
        this.summariesActions.closeTimelineItemDone();
      });
  }

  private subscribeToSectionLinked() {
    this.sectionLinkedQueue.sectionLinkedItem$
      .pipe(
        filter(Boolean),
        takeUntil(this.unsubscribe),
      )
      .subscribe((item: SectionLinkedItem) => {
        const transform = getSectionLinkedTransform(item.sectionKey);
        if (item.eventKey === 'sectionLinked') {
          this.emit(item.eventKey, item.sectionKey, transform(item.payload));
        } else {
          this.emit(item.eventKey, transform(item.payload));
        }
        this.sectionLinkedQueue.removeSectionLink();
      });
  }
}
