export const getPatientNavURLs = (patientId: number) => {
  return {
    chart: {
      legacyChart: `/${patientId}/chart`,
    },
    tasks: `/${patientId}/task`,
    appointments: {
      new: `/admin/patients/${patientId}/appointments/new`,
      upcoming: `/admin/patients/${patientId}/appointments`,
      walkin: `/admin/patients/${patientId}/appointments/walk_in_visit`,
    },
    account: {
      info: `/${patientId}/account`,
      membership: `/admin/${patientId}/membership-dashboard`,
      office: `/${patientId}/account/panel`,
    },
    documents: {
      ccd: `/api/v2/admin/ccd.xml?patient_id=${patientId}`,
      upload: `/${patientId}/direct_upload`,
      fax: `/ref/note/fax_records?patient_id=${patientId}`,
    },
    misc: {
      addVitals: `/${patientId}/chart`,
      archiveEmail: `/${patientId}/chart`,
      accessedBy: `/${patientId}/account/accessed_by`,
      clincalPrograms: `/admin/patients/${patientId}/clinical_program_enrollments`,
      phs: `/admin/phs_physician?patient_id=${patientId}`,
      rxHistory: `/${patientId}/chart`,
      renewalRequest: `/${patientId}/rx_admin_renewal/new`,
    },
  };
};
