var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEntityAdapter } from '@ngrx/entity';
import { HealthGoalTypeActionTypes, } from './health-goal-type.actions';
var sortComparer = function (a, b) {
    return a.name && b.name && a.name.localeCompare(b.name);
};
var ɵ0 = sortComparer;
export var adapter = createEntityAdapter({
    selectId: function (entity) { return entity.id; },
    sortComparer: sortComparer,
});
export var initialState = adapter.getInitialState({
    loading: false,
    error: null,
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HealthGoalTypeActionTypes.LoadHealthGoalTypes: {
            return __assign({}, state, { loading: true });
        }
        case HealthGoalTypeActionTypes.LoadHealthGoalTypesSuccess: {
            return __assign({}, adapter.addAll(action.payload, state), { loading: false, error: null });
        }
        case HealthGoalTypeActionTypes.LoadHealthGoalTypesError: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case HealthGoalTypeActionTypes.ClearHealthGoalTypes: {
            return __assign({}, adapter.removeAll(state), { loading: false, error: null });
        }
        default: {
            return state;
        }
    }
}
// get the selectors
var _a = adapter.getSelectors(), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
// select the array of ids
export var selectHealthGoalTypeIds = selectIds;
// select the dictionary of entities
export var selectHealthGoalTypeEntities = selectEntities;
// select the array of items
export var selectAllHealthGoalTypes = selectAll;
// select the total count
export var selectHealthGoalTypeTotal = selectTotal;
export { ɵ0 };
