var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { ApiService, patientRoute } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { mapHealthBackgroundResponseToEntity, mapToUpdateHealthBackgroundPayload, } from './health-background-utils';
var healthBackgroundRoute = function (patientId) {
    return patientRoute(patientId, '/health_background');
};
var ɵ0 = healthBackgroundRoute;
var HealthBackgroundService = /** @class */ (function () {
    function HealthBackgroundService(api, summariesActions) {
        this.api = api;
        this.summariesActions = summariesActions;
    }
    HealthBackgroundService.prototype.get = function (patientId) {
        return this.api
            .get(healthBackgroundRoute(patientId))
            .pipe(map(mapHealthBackgroundResponseToEntity));
    };
    HealthBackgroundService.prototype.update = function (patientId, note, noteType) {
        return this.api
            .update(healthBackgroundRoute(patientId), mapToUpdateHealthBackgroundPayload(noteType, note.notes))
            .pipe(map(mapHealthBackgroundResponseToEntity));
    };
    HealthBackgroundService.prototype.linkSection = function (patientId, entity) {
        this.summariesActions.linkHealthBackground({
            patientId: patientId,
            healthBackground: __assign({}, entity),
        });
    };
    return HealthBackgroundService;
}());
export { HealthBackgroundService };
export { ɵ0 };
