var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, } from '@angular/core';
import { RadioControlValueAccessor } from '@angular/forms';
var ButtonGroupComponent = /** @class */ (function (_super) {
    __extends(ButtonGroupComponent, _super);
    function ButtonGroupComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ButtonGroupComponent.prototype.checked = function (option) {
        return this.value === option.value;
    };
    /* istanbul ignore next */
    ButtonGroupComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    /* istanbul ignore next */
    ButtonGroupComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ButtonGroupComponent.prototype.focus = function () {
        this.button.nativeElement.focus();
    };
    return ButtonGroupComponent;
}(RadioControlValueAccessor));
export { ButtonGroupComponent };
