/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../health-background-form/health-background-form.component.ngfactory";
import * as i2 from "../health-background-form/health-background-form.component";
import * as i3 from "../../../../shared/components/card/card.component.ngfactory";
import * as i4 from "../../../../shared/components/card/card.component";
import * as i5 from "../../../../shared/components/card/card-title.component.ngfactory";
import * as i6 from "../../../../shared/components/card/card-title.component";
import * as i7 from "../../../../shared/components/button/button.component.ngfactory";
import * as i8 from "angulartics2";
import * as i9 from "../../../../shared/components/button/button.component";
import * as i10 from "@angular/common";
import * as i11 from "primeng/components/dom/domhandler";
import * as i12 from "../../../../shared/directives/tooltip/tooltip.directive";
import * as i13 from "./health-background.component";
import * as i14 from "../../../../core/patient/store/patient.selectors";
import * as i15 from "../../shared/health-background.service";
import * as i16 from "@angular/forms";
import * as i17 from "../../../../features/summaries/store/summaries.selectors";
var styles_HealthBackgroundComponent = [];
var RenderType_HealthBackgroundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HealthBackgroundComponent, data: {} });
export { RenderType_HealthBackgroundComponent as RenderType_HealthBackgroundComponent };
function View_HealthBackgroundComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "padding-normal"], ["om-card-content", ""], ["om-layout", "vertical"], ["om-layout-gutter-large", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "omg-health-background-form", [["data-qaid", "events-surgeries"], ["formTitle", "Important Events & Surgeries"], ["noteType", "illnessesAndSurgeries"]], null, [[null, "markAsReviewed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markAsReviewed" === en)) {
        var pd_0 = (_co.markAsReviewed("illnessesAndSurgeries", _co.illnessesAndSurgeriesFormModel.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_HealthBackgroundFormComponent_0, i1.RenderType_HealthBackgroundFormComponent)), i0.ɵdid(2, 114688, null, 0, i2.HealthBackgroundFormComponent, [], { formModel: [0, "formModel"], formTitle: [1, "formTitle"], noteType: [2, "noteType"], updatedAt: [3, "updatedAt"], updatedBy: [4, "updatedBy"], reviewed: [5, "reviewed"] }, { markAsReviewed: "markAsReviewed" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "omg-health-background-form", [["data-qaid", "family-data"], ["formTitle", "Family Data"], ["noteType", "familyData"]], null, [[null, "markAsReviewed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markAsReviewed" === en)) {
        var pd_0 = (_co.markAsReviewed("familyData", _co.familyDataFormModel.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_HealthBackgroundFormComponent_0, i1.RenderType_HealthBackgroundFormComponent)), i0.ɵdid(4, 114688, null, 0, i2.HealthBackgroundFormComponent, [], { formModel: [0, "formModel"], formTitle: [1, "formTitle"], noteType: [2, "noteType"], updatedAt: [3, "updatedAt"], updatedBy: [4, "updatedBy"], reviewed: [5, "reviewed"] }, { markAsReviewed: "markAsReviewed" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "omg-health-background-form", [["data-qaid", "social-data"], ["formTitle", "Social Data"], ["noteType", "socialData"]], null, [[null, "markAsReviewed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markAsReviewed" === en)) {
        var pd_0 = (_co.markAsReviewed("socialData", _co.socialDataFormModel.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_HealthBackgroundFormComponent_0, i1.RenderType_HealthBackgroundFormComponent)), i0.ɵdid(6, 114688, null, 0, i2.HealthBackgroundFormComponent, [], { formModel: [0, "formModel"], formTitle: [1, "formTitle"], noteType: [2, "noteType"], updatedAt: [3, "updatedAt"], updatedBy: [4, "updatedBy"], reviewed: [5, "reviewed"] }, { markAsReviewed: "markAsReviewed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.illnessesAndSurgeriesFormModel; var currVal_1 = "Important Events & Surgeries"; var currVal_2 = "illnessesAndSurgeries"; var currVal_3 = _co.illnessesAndSurgeries.updatedAt; var currVal_4 = _co.illnessesAndSurgeries.updatedBy; var currVal_5 = _co.illnessesAndSurgeries.reviewed; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.familyDataFormModel; var currVal_7 = "Family Data"; var currVal_8 = "familyData"; var currVal_9 = _co.familyData.updatedAt; var currVal_10 = _co.familyData.updatedBy; var currVal_11 = _co.familyData.reviewed; _ck(_v, 4, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.socialDataFormModel; var currVal_13 = "Social Data"; var currVal_14 = "socialData"; var currVal_15 = _co.socialData.updatedAt; var currVal_16 = _co.socialData.updatedBy; var currVal_17 = _co.socialData.reviewed; _ck(_v, 6, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }, null); }
export function View_HealthBackgroundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "omg-card", [], null, null, null, i3.View_CardComponent_0, i3.RenderType_CardComponent)), i0.ɵdid(1, 49152, null, 0, i4.CardComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 8, "omg-card-title", [["heading", "Health Background"]], null, null, null, i5.View_CardTitleComponent_0, i5.RenderType_CardTitleComponent)), i0.ɵdid(3, 49152, null, 0, i6.CardTitleComponent, [], { heading: [0, "heading"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 6, "button", [["angulartics2On", ""], ["angularticsAction", "Health Background Section Added"], ["omgButton", ""], ["variant", "invisible"]], [[8, "disabled", 0], [2, "om-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkSectionClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i0.ɵdid(5, 1064960, null, 0, i8.Angulartics2On, [i0.ElementRef, i8.Angulartics2, i0.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"] }, null), i0.ɵdid(6, 114688, null, 0, i9.ButtonComponent, [i0.ElementRef, i0.Renderer2], { variant: [0, "variant"], disabled: [1, "disabled"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "span", [["class", "om-icon clickable icon-push-to-note"], ["omgTooltip", ""], ["tooltipPosition", "top"], ["tooltipText", "Add to Note"]], null, null, null, null, null)), i0.ɵprd(512, null, i11.DomHandler, i11.DomHandler, []), i0.ɵdid(10, 4931584, null, 0, i12.TooltipDirective, [i0.ElementRef, i11.DomHandler, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipText: [1, "tooltipText"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_HealthBackgroundComponent_1)), i0.ɵdid(12, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Health Background"; _ck(_v, 3, 0, currVal_0); var currVal_3 = ""; var currVal_4 = "Health Background Section Added"; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = "invisible"; var currVal_6 = !i0.ɵunv(_v, 6, 1, i0.ɵnov(_v, 7).transform(_co.isSummaryActive)); _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_7 = "top"; var currVal_8 = "Add to Note"; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = _co.isLoaded; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 6).disabled; var currVal_2 = i0.ɵnov(_v, 6).buttonClass; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_HealthBackgroundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-health-background", [], null, null, null, View_HealthBackgroundComponent_0, RenderType_HealthBackgroundComponent)), i0.ɵdid(1, 245760, null, 0, i13.HealthBackgroundComponent, [i14.PatientSelectors, i15.HealthBackgroundService, i16.FormBuilder, i17.SummariesSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HealthBackgroundComponentNgFactory = i0.ɵccf("omg-health-background", i13.HealthBackgroundComponent, View_HealthBackgroundComponent_Host_0, {}, {}, []);
export { HealthBackgroundComponentNgFactory as HealthBackgroundComponentNgFactory };
