var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { FeatureFlagSelectors } from '@app/core';
import { CollapseDirective } from '@app/shared/components/collapse';
import { filter, take } from 'rxjs/operators';
import { getAllergyNotCheckedWarning } from '../../shared/allergies-utils';
var AllergyItemComponent = /** @class */ (function () {
    function AllergyItemComponent(featureFlagSelectors) {
        this.featureFlagSelectors = featureFlagSelectors;
        this.delete = new EventEmitter();
        this.update = new EventEmitter();
        this.activate = new EventEmitter();
    }
    AllergyItemComponent.prototype.ngOnInit = function () {
        this.loadFeatureFlag();
        if (this.allergyItem) {
            this.updateReaction(this.allergyItem.reaction);
            this.loadAlerts();
        }
    };
    AllergyItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.allergyItem) {
            this.loadAlerts();
        }
    };
    AllergyItemComponent.prototype.activateAllergy = function (isActive) {
        this.activate.emit(__assign({}, this.allergyItem, { active: isActive }));
    };
    AllergyItemComponent.prototype.onDelete = function (allergyId) {
        var _this = this;
        var message = 'Are you sure you want to delete this allergy?';
        var isConfirmed = this.getConfirmation(message);
        if (!isConfirmed) {
            return;
        }
        this.delete.emit({ id: allergyId, onSuccess: function () { return _this.collapse(); } });
    };
    AllergyItemComponent.prototype.onUpdate = function (formValue) {
        var _this = this;
        this.update.emit({ value: formValue, onSuccess: function () { return _this.collapse(); } });
    };
    AllergyItemComponent.prototype.onCancel = function () {
        this.collapse();
    };
    AllergyItemComponent.prototype.updateReaction = function (reaction) {
        this.reactionText = reaction;
    };
    AllergyItemComponent.prototype.loadAlerts = function () {
        if (this.showAllergyAlerts) {
            this.notCheckedWarning = getAllergyNotCheckedWarning(this.allergyItem);
        }
    };
    AllergyItemComponent.prototype.collapse = function () {
        this.collapseRef.collapse();
    };
    AllergyItemComponent.prototype.getConfirmation = function (message) {
        // Native window confirm box will be replaced in future
        if (typeof confirm === 'function') {
            return !!confirm(message);
        }
        return false;
    };
    AllergyItemComponent.prototype.loadFeatureFlag = function () {
        var _this = this;
        this.featureFlagSelectors
            .featureEnabled('drug_allergy_checking')
            .pipe(filter(Boolean), take(1))
            .subscribe(function (value) { return (_this.showAllergyAlerts = value); });
    };
    return AllergyItemComponent;
}());
export { AllergyItemComponent };
