import * as keyCode from 'keycode';
export var createHotkeyHandler = function (type, el) {
    if (type === 'focusClick') {
        return function () {
            el.nativeElement.click();
            return false;
        };
    }
};
export var parseHotkeyStringToEventProperties = function (hotkey) {
    var ctrlIsMeta = /Mac/i.test(window.navigator.platform);
    var MODIFIERS = {
        ctrlKey: /ctrl\+/i,
        altKey: /alt\+/i,
        metaKey: /meta\+/i,
        shiftKey: /shift\+/i,
    };
    var eventProperties = {};
    Object.entries(MODIFIERS).forEach(function (_a) {
        var modifier = _a[0], modifierPattern = _a[1];
        var newPattern = hotkey.replace(modifierPattern, '');
        if (newPattern !== hotkey) {
            eventProperties[modifier] = true;
        }
        hotkey = newPattern;
    });
    if (ctrlIsMeta && eventProperties.ctrlKey && !eventProperties.metaKey) {
        eventProperties.metaKey = true;
        delete eventProperties.ctrlKey;
    }
    if (hotkey !== '') {
        eventProperties.key = keyCode(hotkey);
    }
    return eventProperties;
};
