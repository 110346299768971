var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@ngrx/store';
import { filter, isPastDue, map, pipe } from '@app/utils';
import { createEntityMetadataSelectors } from '@app/utils/store';
import { getGoalTypeAndActionNames, sortHealthGoals, } from '../shared/health-maintenance-utils';
import { selectAllHealthGoalTypes } from './health-goal-type.selectors';
import * as fromHealthGoal from './health-goal.reducer';
import { selectHealthMaintenanceState } from './health-maintenance-shared';
// selects the state slice
export var selectHealthGoalState = createSelector(selectHealthMaintenanceState, function (state) { return state.healthGoals; });
// selects array of ids
export var selectHealthGoalIds = createSelector(selectHealthGoalState, fromHealthGoal.selectHealthGoalIds);
// selects the dictionary
export var selectHealthGoalEntities = createSelector(selectHealthGoalState, fromHealthGoal.selectHealthGoalEntities);
// selects the array
export var selectAllHealthGoals = createSelector(selectHealthGoalState, fromHealthGoal.selectAllHealthGoals);
// selects by item id
export var selectHealthGoalById = createSelector(selectHealthGoalState, function (state, _a) {
    var id = _a.id;
    return state.entities[id];
});
// selects the total number count
export var selectTotalHealthGoals = createSelector(selectHealthGoalState, fromHealthGoal.selectHealthGoalTotal);
// selects loading
export var selectLoadingHealthGoals = createSelector(selectHealthGoalState, function (state) { return state && state.loading; });
// selects the general state error
export var selectHealthGoalsError = createSelector(selectHealthGoalState, function (state) { return state && state.error; });
/**
 * Projected Views
 */
var mapToSummary = function (goal, healthGoalTypes) {
    var _a = getGoalTypeAndActionNames(healthGoalTypes, goal.healthGoalTypeId, goal.healthGoalActionId), healthGoalTypeName = _a.healthGoalTypeName, healthGoalActionName = _a.healthGoalActionName;
    return __assign({}, goal, { healthGoalTypeName: healthGoalTypeName,
        healthGoalActionName: healthGoalActionName, isPastDue: isPastDue(goal.dueAt) });
};
var ɵ0 = mapToSummary;
// selects summary view
export var selectAllHealthGoalSummmaries = createSelector(selectAllHealthGoals, selectAllHealthGoalTypes, function (healthGoals, healthGoalTypes) {
    return pipe(filter(function (i) { return i.id > 0; }), map(function (i) { return mapToSummary(i, healthGoalTypes); }))(healthGoals);
});
// selects indicated
export var selectIndicatedHealthGoals = createSelector(selectAllHealthGoalSummmaries, function (healthGoals) {
    return pipe(filter(function (i) { return i.indicated; }), sortHealthGoals)(healthGoals);
});
// selects not indicated
export var selectNotIndicatedHealthGoals = createSelector(selectAllHealthGoalSummmaries, function (healthGoals) {
    return pipe(filter(function (i) { return !i.indicated; }), sortHealthGoals)(healthGoals);
});
/**
 * Entity Metadata
 */
var _a = createEntityMetadataSelectors(selectHealthGoalState, selectHealthGoalById), selectEntityMetadata = _a.selectEntityMetadata, selectEntityWithMetadata = _a.selectEntityWithMetadata, selectEntityError = _a.selectEntityError;
export var selectHealthGoalMetadata = selectEntityMetadata;
export var selectHealthGoalWithMetadata = selectEntityWithMetadata;
export var selectHealthGoalError = selectEntityError;
export var selectHealthGoalSummaryWithMetadata = createSelector(selectHealthGoalWithMetadata, selectAllHealthGoalTypes, function (data, healthGoalTypes) { return (__assign({}, data, { entity: mapToSummary(data.entity, healthGoalTypes) })); });
export { ɵ0 };
