import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { loginPath, logoutPath } from './core';
import { AccessTokenResolver } from './core/auth/shared/access-token-resolver';
import { AuthGuard } from './core/auth/shared/auth.guard';
import { CodeToAccessTokenResolver } from './core/auth/shared/code-to-access-token-resolver';
import { LogoutGuard } from './core/auth/shared/logout.guard';
import { AppShellComponent } from './features/app-shell/components/app-shell/app-shell.component';
import { LoginComponent } from './features/app-shell/components/login/login.component';
import { ScheduleComponent } from './features/app-shell/components/schedule/schedule.component';
import { UnauthorizedComponent } from './features/app-shell/components/unauthorized/unauthorized.component';
import { ProfileGuard } from './features/app-shell/shared/profile.guard';
import { EmptyComponent } from './upgrade/components/empty.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
    resolve: { redirectTo: CodeToAccessTokenResolver },
  },
  {
    path: loginPath,
    component: LoginComponent,
  },
  {
    path: logoutPath,
    canActivate: [LogoutGuard],
    component: LoginComponent,
  },
  {
    path: 'access_token',
    component: EmptyComponent,
    resolve: { redirectTo: AccessTokenResolver },
  },
  {
    path: 'schedule',
    component: AppShellComponent,
    children: [
      {
        path: '',
        component: ScheduleComponent,
      },
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    // NOTE: The `path` must be manually toggled in order to toggle the new
    // patient chart. The `upgrade/` version can be removed completly once
    // the toggle is no longer needed.
    // - See the note on the CustomUrlHandlingStrategy provider below for more
    // path: 'upgrade/patients/:id/chart',
    path: 'patients/:id/chart',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    resolve: [ProfileGuard],
    loadChildren:
      './features/patient-chart/patient-chart.module#PatientChartModule',
  },
  // NOTE: This is a sink route to an empty component during the angular
  // migration effort. Once either the updated patient route is in place
  // this can be either remove or changed to a 404 page/component (etc.)
  { path: '**', component: EmptyComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [
    // NOTE: This provider is only required when using the upgrade path for
    // the migrated Angular 7 chart. Once the new patient chart is promoted
    // it can be removed completly as it's no longer needed.
    //
    // This must be manually commented out if you want to toggle the patient
    // chart due to some issue with the Angular AOT compiler and webpacks
    // DefinePlugin not allowing this to be conditional on a build flag.
    // - https://github.com/angular/angular-cli/issues/9874
    // { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
  ],
})
export class AppRoutingModule {}
