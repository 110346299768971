var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEntityAdapter } from '@ngrx/entity';
import { getEntityMetadataInitialState, resetEntityMetadata, updateEntityMetadata, } from '@app/utils';
import { newEntityId, VaccinesActionTypes, } from '../shared/vaccinations.type';
var sortComparer = function (a, b) {
    return a.name && b.name && a.name.localeCompare(b.name);
};
var ɵ0 = sortComparer;
export var adapter = createEntityAdapter({
    selectId: function (entity) { return entity.id; },
    sortComparer: sortComparer,
});
export var initialState = adapter.getInitialState(__assign({ loading: false, error: null }, getEntityMetadataInitialState({})));
export function vaccineHistoriesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case VaccinesActionTypes.LoadVaccineHistory: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.vaccineHistoryId, { pending: true }, state));
        }
        case VaccinesActionTypes.LoadVaccineHistorySuccess: {
            return __assign({}, adapter.upsertOne(__assign({}, action.payload, { hasFullyLoaded: true }), state), { loading: false, error: null }, updateEntityMetadata(action.payload.id, { pending: false, error: null }, state));
        }
        case VaccinesActionTypes.LoadVaccineHistoryError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case VaccinesActionTypes.LoadAllVaccineHistories: {
            return __assign({}, state, { loading: true });
        }
        case VaccinesActionTypes.LoadAllVaccineHistoriesSuccess: {
            return __assign({}, adapter.addAll(action.payload, state), { loading: false, error: null });
        }
        case VaccinesActionTypes.LoadAllVaccineHistoriesError: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case VaccinesActionTypes.SaveVaccineHistory: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(newEntityId, { pending: true }, state));
        }
        case VaccinesActionTypes.SaveVaccineHistorySuccess: {
            return __assign({}, adapter.addOne(action.payload, state), { loading: false, error: null }, updateEntityMetadata(newEntityId, { pending: false, error: null }, state));
        }
        case VaccinesActionTypes.SaveVaccineHistoryError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(newEntityId, { pending: false, error: action.payload }, state));
        }
        case VaccinesActionTypes.DeleteVaccineHistory: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.vaccineHistoryId, { pending: true }, state));
        }
        case VaccinesActionTypes.DeleteVaccineHistorySuccess: {
            return __assign({}, adapter.removeOne(action.payload, state), { loading: false }, resetEntityMetadata(action.payload, state));
        }
        case VaccinesActionTypes.DeleteVaccineHistoryError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        default: {
            return state;
        }
    }
}
var _a = adapter.getSelectors(), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var selectVaccineHistoriesIds = selectIds;
export var selectVaccineHistoriesEntities = selectEntities;
export var selectAllVaccineHistories = selectAll;
export var selectVaccineHistoriesTotal = selectTotal;
export { ɵ0 };
