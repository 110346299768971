var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApiService, SearchService } from '@app/core';
import { Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { mapToSummaryAssessedProblemsUpdateRequest } from '@app/features/summaries/shared/summaries-api-mappers';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { mapProblemCodeSearchResponseToEntity, mapProblemHistoryResponseToEntity, mapProblemResponseToEntity, mapProblemToSaveRequest, mapProblemToUpdateRequest, mapProblemTypeSearchResponseToEntity, } from './problems-api-mappers';
var adminRoute = '/v2/admin';
export var problemRoute = function (subRoute, id, suffix) {
    var resultRoute = adminRoute + "/" + subRoute + "/" + id;
    if (suffix) {
        resultRoute = resultRoute + "/" + suffix;
    }
    return resultRoute;
};
var ProblemsApiService = /** @class */ (function () {
    function ProblemsApiService(api, summariesActions, searchService, config, store, summariesSelectors) {
        this.api = api;
        this.summariesActions = summariesActions;
        this.searchService = searchService;
        this.config = config;
        this.store = store;
        this.summariesSelectors = summariesSelectors;
    }
    ProblemsApiService.prototype.query = function (patientId, params) {
        return this.api
            .get(problemRoute('patients', patientId, 'problems'), params)
            .pipe(map(function (response) { return response.map(mapProblemResponseToEntity); }));
    };
    ProblemsApiService.prototype.save = function (patientId, problem) {
        return this.api
            .save(problemRoute('patients', patientId, 'problems'), mapProblemToSaveRequest(problem))
            .pipe(map(mapProblemResponseToEntity));
    };
    ProblemsApiService.prototype.update = function (patientId, problem) {
        return this.api
            .update(problemRoute('problems', problem.id), mapProblemToUpdateRequest(problem), { patientId: patientId })
            .pipe(map(mapProblemResponseToEntity));
    };
    ProblemsApiService.prototype.resolve = function (patientId, problemId) {
        return this.api
            .update(problemRoute('problems', problemId, 'resolve'), null, { patientId: patientId })
            .pipe(map(mapProblemResponseToEntity));
    };
    ProblemsApiService.prototype.reactivate = function (patientId, problemId) {
        return this.api
            .update(problemRoute('problems', problemId, 'reactivate'), null, { patientId: patientId })
            .pipe(map(mapProblemResponseToEntity));
    };
    ProblemsApiService.prototype.delete = function (patientId, problemId) {
        return this.api
            .delete(problemRoute('problems', problemId), {
            patientId: patientId,
        })
            .pipe(map(mapProblemResponseToEntity));
    };
    ProblemsApiService.prototype.queryProblemHistories = function (patientId, problemId) {
        return this.api
            .get(problemRoute('problems', problemId, 'problem_histories'), { patientId: patientId })
            .pipe(
        /* Attach the problemId to identify which problem the history belongs to. */
        map(function (response) { return mapProblemHistoryResponseToEntity(response, problemId); }));
    };
    ProblemsApiService.prototype.linkSection = function (patientId, problems) {
        this.summariesActions.linkProblems({
            patientId: patientId,
            problems: problems,
        });
    };
    ProblemsApiService.prototype.queryProblemSearch = function (term) {
        var query = new QueryBuilder('function_score_v6').build(term, {
            size: '8',
            fields: [
                'clinical_abbreviation^5',
                'clinical_description^4',
                'lay_description^2',
                'tags',
            ],
            operator: 'and',
            sort: ['_score', 'clinical_description.keyword'],
            index: [this.config.searchIndex('problem_types')],
        });
        return this.searchService.search(query).pipe(map(function (response) {
            var hits = response.hits || {};
            var items = hits.hits || [];
            return items.map(function (hit) { return hit._source; });
        }), map(function (items) {
            return items.map(function (item) { return mapProblemTypeSearchResponseToEntity(item, term); });
        }));
    };
    ProblemsApiService.prototype.queryProblemCodeSearch = function (term) {
        var query = new QueryBuilder('query_string_with_fields_v6').build(term, {
            size: '8',
            fields: ['detail_description.words^4', 'code^3', 'extensions'],
            sort: ['_score'],
            operator: 'and',
            index: [this.config.searchIndex('problem_codes')],
        });
        return this.searchService.search(query).pipe(map(function (response) {
            var hits = response.hits || {};
            var items = hits.hits || [];
            return items.map(function (hit) { return hit._source; });
        }), map(function (items) {
            return items.map(function (item) { return mapProblemCodeSearchResponseToEntity(item, term); });
        }));
    };
    ProblemsApiService.prototype.linkAssessed = function (patientId, problemId, historyId, eventKey) {
        var _this = this;
        this.store
            .pipe(withLatestFrom(this.summariesSelectors.hasActiveSummary, this.summariesSelectors.hasAssessedProblem(problemId)), take(1))
            .subscribe(function (_a) {
            var state = _a[0], hasActiveSummary = _a[1], hasAssessedProblem = _a[2];
            if (hasActiveSummary) {
                _this.summariesActions.linkAssessedProblems({
                    patientId: patientId,
                    problemId: problemId,
                    historyId: historyId,
                    hasAssessedProblem: !!hasAssessedProblem,
                    eventKey: eventKey,
                });
            }
        });
    };
    ProblemsApiService.prototype.unlinkAssessed = function (patientId, historyIds) {
        this.summariesActions.unlink({
            patientId: patientId,
            type: 'Problems',
            extraFields: __assign({}, mapToSummaryAssessedProblemsUpdateRequest(historyIds)),
        });
    };
    return ProblemsApiService;
}());
export { ProblemsApiService };
