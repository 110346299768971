import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Checkbox } from 'primeng/checkbox';

@Component({
  selector: 'omg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
  // NOTE: OnPush causes some issues with the underlying PrimeNg component
  // - In particular the disabled state sometimes doesn't change until the
  //   next change dectection check.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() label: string;
  @Input() plainLabel = false;
  @Input() tabindex: number;
  @Input() disabled = false;
  @Input() required = false;

  @ViewChild(Checkbox) private checkbox: Checkbox;

  private _value = false;

  onChange = (value: any) => {};
  onTouched = () => {};

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onChange(value);
    }
  }

  /* istanbul ignore next */
  writeValue(value: any) {
    this._value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn: (value: any) => {}) {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.checkbox.disabled = isDisabled;
  }
}
