import { createFeatureSelector, createSelector, select, Store, } from '@ngrx/store';
import { sortBy } from 'lodash/fp';
import { createEntityMetadataSelectors } from '@app/utils';
import { sortProblems } from '../shared/problems-utils';
import { adapter, problemsStatePath } from './problems.reducer';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export var selectProblemState = createFeatureSelector(problemsStatePath);
var _a = adapter.getSelectors(), selectEntities = _a.selectEntities, selectAll = _a.selectAll;
export var selectProblemEntities = createSelector(selectProblemState, selectEntities);
export var selectAllProblems = createSelector(selectProblemState, selectAll);
export var selectProblemById = createSelector(selectProblemState, function (state, _a) {
    var id = _a.id;
    return state && state.entities && state.entities[id];
});
export var selectActiveProblems = createSelector(selectAllProblems, function (problems) { return sortProblems(problems.filter(function (problem) { return problem.active; })); });
export var selectResolvedProblems = createSelector(selectAllProblems, function (problems) { return sortProblems(problems.filter(function (problem) { return !problem.active; })); });
export var selectIncludedInMedicalHistoryProblems = createSelector(selectAllProblems, function (problems) { return problems.filter(function (problem) { return problem.includedInMedicalHistory; }); });
export var selectActiveProblemHistoryIds = createSelector(selectIncludedInMedicalHistoryProblems, function (problems) {
    return sortProblems(problems)
        .filter(function (problem) { return problem.active; })
        .map(function (problem) { return problem.problemHistoryId; });
});
export var selectResolvedProblemHistoryIds = createSelector(selectIncludedInMedicalHistoryProblems, function (problems) {
    return sortProblems(problems)
        .filter(function (problem) { return !problem.active; })
        .map(function (problem) { return problem.problemHistoryId; });
});
export var selectProblemHistory = createSelector(selectProblemById, function (problem) { return problem && problem.history; });
export var selectRecentProblem = createSelector(selectAllProblems, function (problems) {
    return sortBy(function (problem) { return new Date(problem.updatedAt); }, problems).reverse()[0];
});
export var selectProblemHistoryId = createSelector(selectRecentProblem, function (problem) { return problem && problem.problemHistoryId; });
var _b = createEntityMetadataSelectors(selectProblemState, selectProblemById), selectEntityMetadata = _b.selectEntityMetadata, selectEntityWithMetadata = _b.selectEntityWithMetadata, selectEntityError = _b.selectEntityError;
export var selectProblemMetadata = selectEntityMetadata;
export var selectProblemError = selectEntityError;
export var selectProblemWithMetadata = selectEntityWithMetadata;
var ProblemSelectors = /** @class */ (function () {
    function ProblemSelectors(store) {
        this.store = store;
    }
    Object.defineProperty(ProblemSelectors.prototype, "state", {
        get: function () {
            return this.store.pipe(select(selectProblemState));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "entities", {
        get: function () {
            return this.store.pipe(select(selectProblemEntities));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "problems", {
        get: function () {
            return this.store.pipe(select(selectAllProblems));
        },
        enumerable: true,
        configurable: true
    });
    ProblemSelectors.prototype.problem = function (id) {
        return this.store.pipe(select(selectProblemById, { id: id }));
    };
    Object.defineProperty(ProblemSelectors.prototype, "activeProblems", {
        get: function () {
            return this.store.pipe(select(selectActiveProblems));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "resolvedProblems", {
        get: function () {
            return this.store.pipe(select(selectResolvedProblems));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "includedInMedicalHistoryProblems", {
        get: function () {
            return this.store.pipe(select(selectIncludedInMedicalHistoryProblems));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "activeProblemHistoryIds", {
        get: function () {
            return this.store.pipe(select(selectActiveProblemHistoryIds));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProblemSelectors.prototype, "resolvedProblemHistoryIds", {
        get: function () {
            return this.store.pipe(select(selectResolvedProblemHistoryIds));
        },
        enumerable: true,
        configurable: true
    });
    ProblemSelectors.prototype.problemMetadata = function (id) {
        return this.store.pipe(select(selectProblemWithMetadata, { id: id }));
    };
    ProblemSelectors.prototype.problemHistory = function (id) {
        return this.store.pipe(select(selectProblemHistory, { id: id }));
    };
    Object.defineProperty(ProblemSelectors.prototype, "problemHistoryId", {
        get: function () {
            return this.store.pipe(select(selectProblemHistoryId));
        },
        enumerable: true,
        configurable: true
    });
    ProblemSelectors.ngInjectableDef = i0.defineInjectable({ factory: function ProblemSelectors_Factory() { return new ProblemSelectors(i0.inject(i1.Store)); }, token: ProblemSelectors, providedIn: "root" });
    return ProblemSelectors;
}());
export { ProblemSelectors };
