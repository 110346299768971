import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';

import { InsightsService } from '../../shared/insights.service';
import { Insight, InsightType } from '../../shared/insights.type';

@Component({
  selector: 'omg-problem-insights',
  templateUrl: './problem-insights.component.html',
  styleUrls: ['./problem-insights.component.scss'],
})
export class ProblemInsightsComponent implements OnInit, OnDestroy {
  insightsDropdownOptions: Observable<SelectItem[]>;
  insights: Insight[];
  patientId: number;
  groupedData: Observable<InsightType[][]>;
  selectedInsight = null;

  private unsubscribe = new Subject();

  constructor(
    private patientSelectors: PatientSelectors,
    private insightsService: InsightsService,
  ) {}

  ngOnInit() {
    this.insightsDropdownOptions = this.patientSelectors.patientId.pipe(
      takeUntil(this.unsubscribe),
      tap(patientId => (this.patientId = patientId)),
      switchMap(patientId =>
        this.insightsService.getAll(patientId).pipe(
          takeUntil(this.unsubscribe),
          tap(insights => (this.insights = insights)),
          map(this.mapInsightsDropdownOptions),
        ),
      ),
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  insightSelected(insight: Insight) {
    this.groupedData = this.insightsService
      .getItem(this.patientId, insight.itemType, insight.itemId)
      .pipe(
        takeUntil(this.unsubscribe),
        map(this.mapInsightTypeToGroupedData),
      );
  }

  insightDataTrackBy(index: number, item: InsightType) {
    return item.measurementTypeName;
  }

  private mapInsightTypeToGroupedData(
    insightType: InsightType,
  ): InsightType[][] {
    let groupedData = [];
    if (insightType.measurementGroups) {
      const bloodPressureValues = ['systolic pressure', 'diastolic pressure'];
      const bloodPressureGroup = insightType.measurementGroups.filter(group =>
        bloodPressureValues.includes(group.measurementTypeName),
      );
      if (bloodPressureGroup.length) {
        groupedData.push(bloodPressureGroup);
      }

      const nonGrouped = insightType.measurementGroups
        .filter(
          group => !bloodPressureValues.includes(group.measurementTypeName),
        )
        .map(group => [group]);

      if (nonGrouped.length) {
        groupedData = groupedData.concat(nonGrouped);
      }
    } else {
      groupedData = [[insightType]];
    }

    return groupedData;
  }

  private mapInsightsDropdownOptions(insights: Insight[]) {
    return insights.reduce(
      (accumulator, value) =>
        accumulator.concat({
          label: value.itemName,
          value: value,
        }),
      [],
    );
  }
}
