// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.error(err));

// ----------------------------------------------------------------------------
// Hybrid downgradeModule hybrid mode
//   Upgrading performance optimization for hybrid app
//   https://angular.io/guide/upgrade-performance
//
// Once migration is complete this can be removed and replaced with the code
// commented out above.
// ----------------------------------------------------------------------------

import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppComponent } from './app/app.component';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { downgradeComponent, downgradeModule } from '@angular/upgrade/static';
import {
  upgradeRouteResolver,
  upgradeStates,
} from '@app/upgrade/upgraded/upgraded-routing';
import { angularJsModuleName } from './app/upgrade/shared/constants';

if (environment.production) {
  enableProdMode();
}

// The Angular boostrap function
const bootstrapAngular = (extraProviders: StaticProvider[]): any =>
  platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);

// The AngularJS app module
const hybridAppModuleName = 'onelife-ui-hybrid';
const angularJsAppModule = angular.module(angularJsModuleName);
const downgradedAppModule = downgradeModule(bootstrapAngular);

// Create a hybrid app module
const hybridAppModule = angular.module(hybridAppModuleName, [
  angularJsAppModule.name,
  downgradedAppModule,
]);

// Downgrade the app component
hybridAppModule.directive(
  'omgRoot',
  downgradeComponent({ component: AppComponent, propagateDigest: false }),
);

// Handles transitions between app.xxx - states and upgrade.xxx - states
angular
  .module(hybridAppModuleName)
  .run(upgradeRouteResolver)
  .config(upgradeStates);

// Boostrap the hybrid app module
angular.bootstrap(document.body, [hybridAppModule.name]);
