var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { healthBackgroundNoteTypeMap, } from './health-background.type';
/* istanbul ignore next */
export var buildNote = function (healthBackground, noteType, reviewed) {
    if (reviewed === void 0) { reviewed = false; }
    return (__assign({}, healthBackground[noteType], { reviewed: reviewed }));
};
/* istanbul ignore next */
export var mapHealthBackgroundResponseToEntity = function (data) {
    var mapToNote = function (note) { return ({
        notes: note.notes || '',
        updatedBy: note.updated_by || '',
        updatedAt: note.updated_at ? new Date(note.updated_at) : null,
        reviewed: note.reviewed || false,
    }); };
    return {
        healthBackgroundHistoryId: data.health_background_history_id,
        illnessesAndSurgeries: mapToNote(data.illnesses_and_surgeries),
        familyData: mapToNote(data.family_data),
        socialData: mapToNote(data.social_data),
    };
};
/* istanbul ignore next */
export var mapToHealthBackgroundResponse = function (hb) {
    var mapToNote = function (note) { return ({
        notes: note.notes || '',
        updated_by: note.updatedBy || '',
        updated_at: note.updatedAt ? note.updatedAt.toISOString() : null,
        reviewed: note.reviewed || false,
    }); };
    return {
        health_background_history_id: hb.healthBackgroundHistoryId,
        illnesses_and_surgeries: mapToNote(hb.illnessesAndSurgeries),
        family_data: mapToNote(hb.familyData),
        social_data: mapToNote(hb.socialData),
    };
};
export var mapToUpdateHealthBackgroundPayload = function (noteType, notes) {
    var _a;
    return (_a = {},
        _a[healthBackgroundNoteTypeMap[noteType]] = notes,
        _a);
};
