var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as encounterActions from './encounter.actions';
export var encounterInitialState = {
    sectionsLinkable: false,
};
export function encounterReducer(state, action) {
    if (state === void 0) { state = encounterInitialState; }
    switch (action.type) {
        case encounterActions.updateSectionsLinkable: {
            return __assign({}, state, { sectionsLinkable: action.payload });
        }
        default: {
            return __assign({}, state);
        }
    }
}
