/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "omg-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i2.CardComponent, [], null, null)], null, null); }
var CardComponentNgFactory = i1.ɵccf("omg-card", i2.CardComponent, View_CardComponent_Host_0, {}, {}, ["*"]);
export { CardComponentNgFactory as CardComponentNgFactory };
