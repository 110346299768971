import { HttpErrorResponse } from '@angular/common/http';

import {
  MeasurementResponseType,
  VitalsDataResponse,
  VitalsDataResponseItem,
  VitalsDataResponseMeasurement,
} from './vitals-data-response.type';
import { VitalsDataItem } from './vitals-data.type';

/* istanbul ignore next */
const mapVitalsDataResponseMeasurementType = (
  measurementType: MeasurementResponseType,
) => ({
  id: measurementType.id || undefined,
  abbreviation: measurementType.abbreviation || undefined,
});

/* istanbul ignore next */
const mapVitalsDataResponseMeasurement = (
  measurements: VitalsDataResponseMeasurement,
) => ({
  measurementType:
    (measurements.measurement_type &&
      mapVitalsDataResponseMeasurementType(measurements.measurement_type)) ||
    undefined,
  unit: measurements.unit || undefined,
  value: measurements.value || undefined,
});

/* istanbul ignore next */
export const mapVitalsDataResponseItem = (
  vitalsDataResponseItem: VitalsDataResponseItem,
) => ({
  collectedAt: vitalsDataResponseItem.collected_at || undefined,
  comment: vitalsDataResponseItem.comment || undefined,
  internalUser: vitalsDataResponseItem.internal_user || undefined,
  measurements:
    (vitalsDataResponseItem.measurements &&
      vitalsDataResponseItem.measurements.map(
        mapVitalsDataResponseMeasurement,
      )) ||
    undefined,
  withings: vitalsDataResponseItem.withings || undefined,
});

/* istanbul ignore next */
export const mapVitalsDataResponseToEntity = (
  vitalsDataResponse: VitalsDataResponse,
): VitalsDataItem[] => vitalsDataResponse.vitals.map(mapVitalsDataResponseItem);

/* istanbul ignore next */
export const mapVitalsDataError = (response: HttpErrorResponse) => response;
