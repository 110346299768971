import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { TabComponent } from './tab.component';

/**
 * @note
 * This tab component is loosely based on:
 *  - https://juristr.com/blog/2016/02/learning-ng2-creating-tab-component/
 * Dynamic tabs could be implemented following this as a general guide:
 * - https://juristr.com/blog/2017/07/ng2-dynamic-tab-component/
 */
@Component({
  selector: 'omg-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // Should be OnPush but while in hybrid/upgrade mode we need the use default
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(TabComponent) readonly tabs: QueryList<TabComponent>;
  @Output() selectedTabChange = new EventEmitter<TabComponent>();

  private _tabs: TabComponent[];
  private _selectedTab: TabComponent = null;
  private _initialized = false;
  private _unsubscribe = new Subject();

  constructor(private cd: ChangeDetectorRef) {}

  @Input()
  get selectedTab() {
    return this._selectedTab;
  }

  set selectedTab(tab: TabComponent) {
    if (this._tabs && this._selectedTab !== tab) {
      this._tabs.forEach(t => (t.isActive = false));
      if (tab) {
        tab.isActive = true;
      }
      this._selectedTab = tab;
      this.selectedTabChange.emit(tab);
    }
  }

  selectTab(tab: TabComponent) {
    this.selectedTab = tab;
  }

  trackByFn = (index, tab: TabComponent) => tab.key || index;

  ngAfterContentInit() {
    if (!this._initialized) {
      // Changing these values after change detection has run
      // since the checked content may contain references to them.
      Promise.resolve().then(() => {
        this.initTabs();
      });
      this._initialized = true;
    }

    this.tabs.changes.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
      this.initTabs();
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private initTabs() {
    if (!this.tabs) {
      return;
    }

    const currentTabCount = this._tabs ? this._tabs.length : 0;
    this._tabs = this.tabs.toArray();

    if (currentTabCount !== this._tabs.length) {
      this.cd.markForCheck();
    }

    if (!this.selectedTab) {
      this.setInitialSelectedTab();
    }
  }

  private setInitialSelectedTab() {
    const activeTabs = this._tabs.filter(t => t.isActive);
    const routerActiveTabs = this._tabs.filter(
      t => t.routerLinkActive && t.routerLinkActive.isActive,
    );

    if (routerActiveTabs.length > 0) {
      this.selectedTab = routerActiveTabs[0];
    } else if (activeTabs.length > 0) {
      this.selectedTab = activeTabs[0];
    } else if (this._tabs.length > 0) {
      this.selectedTab = this._tabs[0];
    }
  }
}
