var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { formatDate, orderBy } from '@app/utils';
import { newEntityId, } from './health-maintenance.type';
export var sortHealthGoals = function (healthGoals) {
    var compareFn = function (healthGoal) {
        var dueAt = healthGoal.dueAt || -1;
        var healthGoalType = healthGoal.healthGoalTypeName
            ? healthGoal.healthGoalTypeName.toLowerCase()
            : -1;
        return [dueAt, healthGoalType];
    };
    return orderBy(['declined', compareFn], ['asc', 'asc', 'asc'])(healthGoals);
};
export var getGoalTypeAndActionNames = function (healthGoalTypes, healthGoalTypeId, healthGoalActionId) {
    var healthGoalType = healthGoalTypes.find(function (t) { return t.id === healthGoalTypeId; });
    var healthActionType = healthGoalType &&
        healthGoalType.healthGoalActions.find(function (a) { return a.id === healthGoalActionId; });
    var healthGoalTypeName = healthGoalType && healthGoalType.name;
    var healthGoalActionName = healthActionType && healthActionType.name;
    return { healthGoalTypeName: healthGoalTypeName, healthGoalActionName: healthGoalActionName };
};
export var buildNewHealthGoalForm = function (partialHealthGoal) {
    if (partialHealthGoal === void 0) { partialHealthGoal = {}; }
    return (__assign({ id: newEntityId, comment: null, dueAt: null, declined: false, healthGoalActionId: null, healthGoalTypeId: null, indicated: true, screeningHistory: [] }, partialHealthGoal));
};
export var buildNewHealthGoal = function (partialHealthGoal) {
    if (partialHealthGoal === void 0) { partialHealthGoal = {}; }
    return (__assign({ editedBy: null, editedById: null, patientId: null, healthGoalHistoryId: null, screeningHistoryIds: [], updatedAt: null, url: null }, buildNewHealthGoalForm(partialHealthGoal)));
};
export var buildNewHealthGoalScreeningForm = function (partialScreening) {
    if (partialScreening === void 0) { partialScreening = {}; }
    return (__assign({ id: newEntityId, dateFuzzy: formatDate(new Date(), 'MM/dd/yyyy'), eventId: null, healthGoalId: null, normalized: false, patientId: null, patientSubmitted: false, result: null }, partialScreening));
};
export var mapHealthGoalToFormValue = function (healthGoal) { return ({
    id: healthGoal.id,
    comment: healthGoal.comment,
    dueAt: healthGoal.dueAt,
    declined: healthGoal.declined,
    healthGoalActionId: healthGoal.healthGoalActionId,
    healthGoalTypeId: healthGoal.healthGoalTypeId,
    indicated: healthGoal.indicated,
    screeningHistory: [],
}); };
export var mapHealthGoalScreeningToFormValue = function (screening) { return ({
    id: screening.id,
    dateFuzzy: screening.dateFuzzy,
    eventId: screening.eventId,
    healthGoalId: screening.healthGoalId,
    normalized: screening.normalized,
    patientId: screening.patientId,
    patientSubmitted: screening.patientSubmitted,
    result: screening.result,
}); };
export var normalizeHealthGoal = function (data) {
    var screeningHistory = data.screeningHistory, healthGoal = __rest(data, ["screeningHistory"]);
    return __assign({}, healthGoal, { screeningHistoryIds: data.screeningHistory.map(function (s) { return s.id; }) });
};
