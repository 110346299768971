var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@ngrx/store';
import { flatMap, sortBy } from '@app/utils';
import * as fromHealthGoalType from './health-goal-type.reducer';
import { selectHealthMaintenanceState } from './health-maintenance-shared';
// selects the state slice
export var selectHealthGoalTypeState = createSelector(selectHealthMaintenanceState, function (state) { return state.healthGoalTypes; });
// selects array of ids
export var selectHealthGoalTypeIds = createSelector(selectHealthGoalTypeState, fromHealthGoalType.selectHealthGoalTypeIds);
// selects the dictionary
export var selectHealthGoalTypeEntities = createSelector(selectHealthGoalTypeState, fromHealthGoalType.selectHealthGoalTypeEntities);
// selects the array
export var selectAllHealthGoalTypes = createSelector(selectHealthGoalTypeState, fromHealthGoalType.selectAllHealthGoalTypes);
// selected by item id
export var selectHealthGoalTypeById = createSelector(selectHealthGoalTypeState, function (state, _a) {
    var id = _a.id;
    return state.entities[id];
});
// selects the total number count
export var selectTotalHealthGoalTypes = createSelector(selectHealthGoalTypeState, fromHealthGoalType.selectHealthGoalTypeTotal);
// selects loading
export var selectLoadingHealthGoalTypes = createSelector(selectHealthGoalTypeState, function (state) { return state && state.loading; });
// selects any error
export var selectHealthGoalTypesError = createSelector(selectHealthGoalTypeState, function (state) { return state && state.error; });
/**
 * Projected Views
 * NOTE: at some point it may make sense to normalize (un nest) the health goal
 * actions from their type parents. This may improve performance and make lookups
 * by id easier
 */
export var selectAllHealthGoalTypesSorted = createSelector(selectAllHealthGoalTypes, function (healthGoalTypes) {
    return healthGoalTypes.map(function (healthGoalType) { return (__assign({}, healthGoalType, { healthGoalActions: sortBy(function (a) { return a.name; }, healthGoalType.healthGoalActions) })); });
});
// selects array of all actions
export var selectAllHealthGoalActions = createSelector(selectAllHealthGoalTypes, function (healthGoalTypes) { return flatMap(function (i) { return i.healthGoalActions; }, healthGoalTypes); });
// selects the dictionary of all actions
export var selectHealthGoalActionEntities = createSelector(selectAllHealthGoalActions, function (actions) { return actions.reduce(function (accum, i) {
    var _a;
    return (__assign({}, accum, (_a = {}, _a[i.id] = i, _a)));
}, {}); });
