/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./form-state.component";
import * as i3 from "./form.directive";
var styles_FormStateComponent = ["./form-state.component.scss"];
var RenderType_FormStateComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FormStateComponent, data: {} });
export { RenderType_FormStateComponent as RenderType_FormStateComponent };
function View_FormStateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FormStateComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "om-messages"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "-progress": 0, "-success": 1, "-warning": 2 }), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "om-messages"; var currVal_1 = _ck(_v, 2, 0, (_v.parent.parent.context.ngIf.state === "saving"), (_v.parent.parent.context.ngIf.state === "saved"), (_v.parent.parent.context.ngIf.state === "saveError")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.stateMessage(_v.parent.parent.context.ngIf.state); _ck(_v, 3, 0, currVal_2); }); }
function View_FormStateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["content", 1]], null, 1, "span", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormStateComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !i0.ɵnov(_v, 1).innerHTML.trim(); _ck(_v, 4, 0, currVal_0); }, null); }
function View_FormStateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FormStateComponent_2)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormStateComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.ngIf); var currVal_1 = _co.stateTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.stateCondition(_v.context.ngIf.state); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FormStateComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_FormStateComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.stateChanges)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormStateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "omg-form-state", [], null, null, null, View_FormStateComponent_0, RenderType_FormStateComponent)), i0.ɵdid(1, 114688, null, 1, i2.FormStateComponent, [[2, i3.FormDirective]], null, null), i0.ɵqud(335544320, 1, { stateTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormStateComponentNgFactory = i0.ɵccf("omg-form-state, [omgFormState]", i2.FormStateComponent, View_FormStateComponent_Host_0, { model: "model", when: "when", not: "not", stateOptions: "stateOptions", stateTemplate: "stateTemplate" }, {}, ["*"]);
export { FormStateComponentNgFactory as FormStateComponentNgFactory };
