/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./button.component";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["omgButton", ""]], [[8, "disabled", 0], [2, "om-button", null]], null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i1.ButtonComponent, [i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).disabled; var currVal_1 = i0.ɵnov(_v, 1).buttonClass; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ButtonComponentNgFactory = i0.ɵccf("button[omgButton]", i1.ButtonComponent, View_ButtonComponent_Host_0, { variant: "variant", disabled: "disabled" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
