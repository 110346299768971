import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { StickyStatesService } from '@app/core/services/sticky-states.service';

// For upgrade only
import { DocumentTitleSetterService } from './core/services';
import { RouteTracker } from './core/services';
import { AppBridgeService } from './upgrade/services/app-bridge.service';

@Component({
  selector: 'omg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private unsubscribe = new Subject();

  // NOTE: The app bridge is only required for hybrid mode
  constructor(
    private appBridgeService: AppBridgeService,
    private stickyStatesService: StickyStatesService,
    private documentTitleSetter: DocumentTitleSetterService,
    private routeTracker: RouteTracker,
  ) {}
}
