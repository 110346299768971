var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { createEntityAdapter } from '@ngrx/entity';
import { getEntityMetadataInitialState, removeKeyFromList, resetEntityMetadata, selectEntityById, updateEntityMetadata, } from '@app/utils/store';
import { buildNewHealthGoal } from '../shared/health-maintenance-utils';
import { newEntityId, } from '../shared/health-maintenance.type';
import { HealthGoalScreeningActionTypes, } from './health-goal-screening.actions';
import { HealthGoalActionTypes, } from './health-goal.actions';
export var adapter = createEntityAdapter();
export var initialState = adapter.getInitialState(__assign({ loading: false, error: null }, getEntityMetadataInitialState({})));
var pluckHealthGoal = function (data) {
    var screeningHistory = data.screeningHistory, healthGoal = __rest(data, ["screeningHistory"]);
    return healthGoal;
};
var ɵ0 = pluckHealthGoal;
var selectScreeningHistoryIds = function (state, entityId) {
    var entity = selectEntityById(state, entityId);
    return (entity && entity.screeningHistoryIds) || [];
};
var ɵ1 = selectScreeningHistoryIds;
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HealthGoalActionTypes.InitNewHealthGoal: {
            return __assign({}, adapter.upsertOne(buildNewHealthGoal(), state));
        }
        case HealthGoalActionTypes.LoadHealthGoals: {
            return __assign({}, state, { loading: true });
        }
        case HealthGoalActionTypes.LoadHealthGoalsSuccess: {
            return __assign({}, adapter.addAll(action.payload.map(pluckHealthGoal), state), { loading: false, error: null });
        }
        case HealthGoalActionTypes.LoadHealthGoalsError: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case HealthGoalActionTypes.LoadHealthGoalsError: {
            return __assign({}, state, { loading: false, error: action.payload });
        }
        case HealthGoalActionTypes.AddHealthGoal: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(newEntityId, { pending: true }, state));
        }
        case HealthGoalActionTypes.AddHealthGoalSuccess: {
            return __assign({}, adapter.addOne(pluckHealthGoal(action.payload), state), { loading: false, error: null }, updateEntityMetadata(newEntityId, { pending: false, error: null }, state));
        }
        case HealthGoalActionTypes.AddHealthGoalError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(newEntityId, { pending: false, error: action.payload }, state));
        }
        case HealthGoalActionTypes.UpdateHealthGoal: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload.id, { pending: true }, state));
        }
        case HealthGoalActionTypes.UpdateHealthGoalSuccess: {
            return __assign({}, adapter.upsertOne(pluckHealthGoal(action.payload), state), { loading: false, error: null }, resetEntityMetadata(action.payload.id, state));
        }
        case HealthGoalActionTypes.UpdateHealthGoalError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case HealthGoalActionTypes.DeleteHealthGoal: {
            return __assign({}, state, { loading: true }, updateEntityMetadata(action.payload, { pending: true }, state));
        }
        case HealthGoalActionTypes.DeleteHealthGoalSuccess: {
            return __assign({}, adapter.removeOne(action.payload, state), { loading: false }, resetEntityMetadata(action.payload, state));
        }
        case HealthGoalActionTypes.DeleteHealthGoalError: {
            return __assign({}, state, { loading: false, error: action.payload }, updateEntityMetadata(action.meta.id, { pending: false, error: action.payload }, state));
        }
        case HealthGoalActionTypes.ClearHealthGoals: {
            return __assign({}, adapter.removeAll(state), { loading: false, error: null });
        }
        /**
         * Normalize screenings
         */
        case HealthGoalScreeningActionTypes.AddHealthGoalScreeningSuccess: {
            // Add health goal screening history id
            var healthGoalId = action.payload.healthGoalId || newEntityId;
            var screeningHistory = selectScreeningHistoryIds(state, healthGoalId);
            return __assign({}, adapter.updateOne({
                id: healthGoalId,
                changes: {
                    screeningHistoryIds: screeningHistory.concat([action.payload.id]),
                },
            }, state));
        }
        case HealthGoalScreeningActionTypes.DeleteHealthGoalScreeningSuccess: {
            // Remove health goal screening history id
            var healthGoalId = action.meta.healthGoalId;
            var ids = selectScreeningHistoryIds(state, healthGoalId);
            return __assign({}, adapter.updateOne({
                id: healthGoalId,
                changes: {
                    screeningHistoryIds: removeKeyFromList(action.payload, ids),
                },
            }, state));
        }
        default: {
            return state;
        }
    }
}
// get the selectors
var _a = adapter.getSelectors(), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
// select the array of ids
export var selectHealthGoalIds = selectIds;
// select the dictionary of entities
export var selectHealthGoalEntities = selectEntities;
// select the array of items
export var selectAllHealthGoals = selectAll;
// select the total count
export var selectHealthGoalTotal = selectTotal;
export { ɵ0, ɵ1 };
