import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigService } from '@app/core/config';
import { windowToken } from '@app/shared/window/window.service';
import { snakeCase } from '@app/utils';

import { CookieService } from './cookie.service';

export interface AccessTokenResponse {
  access_token: string;
  created_at: number;
  scope: string;
  token_type: string;
}

interface AccessTokenRequestParams {
  code: string;
  clientId: string;
  grantType: 'authorization_code';
  redirectUri: string;
  codeVerifier: 'codechallenge';
}

interface AuthCodeRequestParams {
  clientId: string;
  redirectUri: string;
  responseType: 'code';
  codeChallenge: 'codechallenge';
  codeChallengeMethod: 'plain';
}

const logoutPath = '/oauth/revoke';
const defaultPath = '/schedule';

@Injectable()
export class AuthService {
  private baseUrl = this.config.environment.oauth2.providerUrl;
  private oauth2 = this.config.environment.oauth2;

  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private config: ConfigService,
    @Inject(windowToken) private window: Window,
    private router: Router,
  ) {}

  isAuthenticated() {
    return this.cookie.isPresent();
  }

  extractTokenFromUrl(url: string) {
    // e.g.: localhost:4000/#/access_token=dcb13231123&token_type=Bearer
    if (url && url.length > 0) {
      const matches = url.match(/(?<=(access_token=))(.*?)&/);
      const token = (matches && matches.length >= 3 && matches[2]) || '';
      return token;
    }
    return '';
  }

  private setAuthToken(token: string) {
    this.cookie.set(token);
  }

  setAuthTokenFromUrl(url: string) {
    const token = this.extractTokenFromUrl(url);
    this.setAuthToken(token);
  }

  login() {
    this.window.location.href = this.buildLoginUrl();
  }

  buildLoginUrl() {
    const url = `${this.baseUrl}/oauth/authorize?`;
    const paramsObject: AuthCodeRequestParams = {
      clientId: this.oauth2.clientId,
      redirectUri: this.window.location.origin,
      responseType: 'code',
      codeChallenge: 'codechallenge',
      codeChallengeMethod: 'plain',
    };
    const params = new HttpParams({ fromObject: snakeCase(paramsObject) });
    return url + params.toString();
  }

  logout() {
    const token = this.cookie.get();
    return this.http
      .post(
        this.createLogoutUrl(),
        this.createLogoutData(token),
        this.createLogoutConfig(token),
      )
      .pipe(
        tap(() => {
          this.cookie.delete();
        }),
      );
  }

  private createLogoutUrl() {
    return `${this.baseUrl}${logoutPath}`;
  }

  private createLogoutData(token: string) {
    return {
      token,
    };
  }

  private createLogoutConfig(token: string) {
    return {
      headers: {
        Authorization: `Bearer: ${token}`,
      },
    };
  }

  loginWithCode(code: string): Observable<string> {
    return this.getAccessToken(code);
  }

  private getAccessToken(code: string): Observable<string> {
    const accessTokenRequestParams: AccessTokenRequestParams = {
      code,
      clientId: this.oauth2.clientId,
      grantType: 'authorization_code',
      redirectUri: this.window.location.origin,
      codeVerifier: 'codechallenge',
    };
    return this.http
      .post<AccessTokenResponse>(
        `${this.baseUrl}/oauth/token`,
        snakeCase(accessTokenRequestParams),
      )
      .pipe(
        map(response => response.access_token),
        tap(token => {
          this.setAuthToken(token);
          this.router.navigateByUrl(this.getRedirectPath());
        }),
        catchError(error => of(error)),
      );
  }

  getRedirectPath() {
    const savedPath = this.window.sessionStorage.getItem('path');
    const redirectPath =
      !savedPath || savedPath === '/' ? defaultPath : savedPath;
    return redirectPath;
  }
}
