import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Todo } from '../shared/todo.type';

export enum TodoActionTypes {
  LOAD_TODO = '[Todos] Load Todo',
  LOAD_TODO_BY_SUMMARY_ID = '[Todos] Load Todo By Summary Id',
  LOAD_TODO_SUCCESS = '[Todos] Load Todo Success',
  LOAD_TODO_ERROR = '[Todos] Load Todo Error',
  UPDATE_TODO = '[Todos] Update Todo',
  UPDATE_TODO_SUCCESS = '[Todos] Update Todo Success',
  UPDATE_TODO_ERROR = '[Todos] Update Todo Error',
  COMPLETE_TODO = '[Todos] Complete Todo',
  COMPLETE_TODO_SUCCESS = '[Todos] Complete Todo Success',
  COMPLETE_TODO_ERROR = '[Todos] Complete Todo Error',
  REOPEN_TODO = '[Todos] Reopen Todo',
  REOPEN_TODO_SUCCESS = '[Todos] Reopen Todo Success',
  REOPEN_TODO_ERROR = '[Todos] Reopen Todo Error',
}

type TodoId = number;
type TodoErrorPayload = any;
type SummaryId = number;

export class LoadTodo implements FsaAction<TodoId> {
  readonly type = TodoActionTypes.LOAD_TODO;

  constructor(public payload: TodoId) {}
}

export class LoadTodoBySummaryId implements FsaAction<SummaryId> {
  readonly type = TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID;

  constructor(public payload: SummaryId) {}
}
export class LoadTodoSuccess implements FsaAction<Todo> {
  readonly type = TodoActionTypes.LOAD_TODO_SUCCESS;
  error = false;

  constructor(public payload: Todo) {}
}

export class LoadTodoError implements FsaAction<TodoErrorPayload> {
  readonly type = TodoActionTypes.LOAD_TODO_ERROR;
  error = true;

  constructor(public payload: TodoErrorPayload) {}
}

export class UpdateTodo implements FsaAction<Todo> {
  readonly type = TodoActionTypes.UPDATE_TODO;

  constructor(public payload: Todo) {}
}

export class UpdateTodoSuccess implements FsaAction<Todo> {
  readonly type = TodoActionTypes.UPDATE_TODO_SUCCESS;
  error = false;

  constructor(public payload: Todo) {}
}

export class UpdateTodoError implements FsaAction<TodoErrorPayload> {
  readonly type = TodoActionTypes.UPDATE_TODO_ERROR;
  error = true;

  constructor(public payload: TodoErrorPayload) {}
}

export class CompleteTodo implements FsaAction<Todo> {
  readonly type = TodoActionTypes.COMPLETE_TODO;

  constructor(public payload: Todo) {}
}

export class CompleteTodoSuccess implements FsaAction<Todo> {
  readonly type = TodoActionTypes.COMPLETE_TODO_SUCCESS;
  error = false;

  constructor(public payload: Todo) {}
}

export class CompleteTodoError implements FsaAction<TodoErrorPayload> {
  readonly type = TodoActionTypes.COMPLETE_TODO_ERROR;
  error = true;

  constructor(public payload: TodoErrorPayload) {}
}

export class ReopenTodo implements FsaAction<Todo> {
  readonly type = TodoActionTypes.REOPEN_TODO;

  constructor(public payload: Todo) {}
}

export class ReopenTodoSuccess implements FsaAction<Todo> {
  readonly type = TodoActionTypes.REOPEN_TODO_SUCCESS;
  error = false;

  constructor(public payload: Todo) {}
}

export class ReopenTodoError implements FsaAction<TodoErrorPayload> {
  readonly type = TodoActionTypes.REOPEN_TODO_ERROR;
  error = true;

  constructor(public payload: TodoErrorPayload) {}
}

export type TodoAction =
  | LoadTodo
  | LoadTodoBySummaryId
  | LoadTodoSuccess
  | LoadTodoError
  | UpdateTodo
  | UpdateTodoSuccess
  | UpdateTodoError
  | CompleteTodo
  | CompleteTodoSuccess
  | CompleteTodoError
  | ReopenTodo
  | ReopenTodoSuccess
  | ReopenTodoError;

@Injectable()
export class TodoActions {
  constructor(private store: Store<any>) {}

  loadTodo(id: number) {
    this.store.dispatch(new LoadTodo(id));
  }

  loadTodoBySummaryId(summaryId: number) {
    this.store.dispatch(new LoadTodoBySummaryId(summaryId));
  }

  loadTodoSuccess(todo: Todo) {
    this.store.dispatch(new LoadTodoSuccess(todo));
  }

  updateTodo(todo: Todo) {
    this.store.dispatch(new UpdateTodo(todo));
  }

  completeTodo(todo: Todo) {
    this.store.dispatch(new CompleteTodo(todo));
  }

  completeWithoutSigningNote(todo: Todo) {
    this.store.dispatch(
      new CompleteTodo({
        ...todo,
        viaNote: false,
      }),
    );
  }

  completeAndSignNote(todo: Todo) {
    this.store.dispatch(
      new CompleteTodo({
        ...todo,
        viaNote: true,
      }),
    );
  }

  reopenTodo(todo: Todo) {
    this.store.dispatch(new ReopenTodo(todo));
  }
}
