import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TaskAssignmentService } from '../shared/task-assignment.service';
import { TodoApiService } from '../shared/todo-api.service';

import { TodoActions } from './todo.actions';
import { TodoEffects } from './todo.effects';
import { todoReducer, todoStatePath } from './todo.reducer';
import { TodoSelectors } from './todo.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(todoStatePath, todoReducer),
    EffectsModule.forFeature([TodoEffects]),
  ],
  providers: [
    TodoApiService,
    TaskAssignmentService,
    TodoActions,
    TodoSelectors,
  ],
})
export class TodoStoreModule {}
