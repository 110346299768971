import { ActionReducerMap } from '@ngrx/store';

import {
  vaccineHistoriesReducer,
  VaccineHistoriesState,
} from './vaccine-histories.reducer';

export const vaccinationsStatePath = 'vaccinations';

export interface VaccinationsState {
  vaccineHistories: VaccineHistoriesState;
}

export const reducers: ActionReducerMap<VaccinationsState> = {
  vaccineHistories: vaccineHistoriesReducer,
};
