<omg-card>
  <omg-card-title heading="Problems"
                  omgCollapse
                  #cardCollapse="omgCollapse"
                  omgFocusProvider
                  #focus="omgFocusProvider">
    <button omgTooltip
            tooltipText="Add Problem [P]"
            tooltipPosition="top"
            omgButton
            omgShortcutClick="p"
            variant="invisible"
            omgCollapseToggle="expand"
            data-qaid="add-problem-button"
            (click)="onAddProblemClick(cardCollapse, focus)">
      <span class="om-icon clickable icon-add-new"></span>
    </button>
    <button omgButton
            variant="invisible"
            omgTooltip
            tooltipText="Add to Note"
            tooltipPosition="top"
            angulartics2On="click"
            angularticsAction="Problems Section Added"
            data-qaid="push-problem-to-note"
            (click)="addToNote()"
            [disabled]="!(allowAddToNote | async)">
      <span class="om-icon clickable icon-push-to-note"></span>
    </button>
  </omg-card-title>

  <omg-expanded [collapseProvider]="cardCollapse">
    <omg-problem-form [autoCheckInCreationEnabled]="autoCheckInCreationEnabled | async"
                      [addProblemSessionId]="addProblemSessionId"
                      (save)="saveProblem($event, cardCollapse)"
                      (close)="cardCollapse.collapse()"></omg-problem-form>
  </omg-expanded>

  <div class="om-list"
       om-card-content
       *ngIf="(activeProblems | async)?.length && (activeProblems | async) as problems">
    <omg-collapse class="om-item"
                  om-layout="vertical"
                  om-layout-gutter
                  *ngFor="let problem of problems; let i = index; trackBy: trackByFn"
                  data-qaid="problem-entry"
                  omgCollapse
                  #toggleCollapse="omgCollapse"
                  (expandedChange)="onExpandedChange($event, problem?.id)"
                  [ngClass]="{'-contrasted-dark': !problem.includedInMedicalHistory}">
      <omg-collapse-toggle>
        <div om-layout
             om-layout-gutter>
          <div om-flex>
            <strong>{{ problem.problemType.clinicalDescription }}</strong>
            <span *ngIf="problem.problemCodeLocation.name"> ({{ problem.problemCodeLocation.name }})</span>
          </div>

          <div>
            <span *ngIf="problem.code">{{ problem.code }}</span>
            <span class="om-label"
                  *ngIf="!problem.code">No code</span>
          </div>
          <div>
            <span omgTooltip
                  tooltipText="Resolve Problem"
                  tooltipPosition="left"
                  class="om-icon clickable icon-dismiss -gray"
                  omgStopEvent="click"
                  (click)="resolveProblem(problem.id,toggleCollapse, i + 1)"
                  data-qaid="resolve-problem-button"></span>
          </div>
        </div>
        <p> {{ problem.briefComment }}</p>
      </omg-collapse-toggle>

      <omg-expanded class="om-item"
                    om-layout="vertical"
                    om-layout-gutter>
        <omg-problem-form [problem]="problem"
                          [autoCheckInCreationEnabled]="autoCheckInCreationEnabled | async"
                          [addProblemSessionId]="addProblemSessionId"
                          [showHistory]="true"
                          (close)="toggleCollapse.collapse()"
                          (save)="updateProblem($event, toggleCollapse, i + 1)"
                          (delete)="deleteProblem($event, toggleCollapse, i + 1)"></omg-problem-form>
      </omg-expanded>

    </omg-collapse>
  </div>

  <omg-collapse omgCollapse
                #resolvedCollapse="omgCollapse"
                *ngIf="(resolvedProblems | async)?.length && (resolvedProblems | async) as problems"
                disableAnimation="true">
    <omg-collapse-toggle class="-separator">
      <omg-collapsed disableAnimation="true">Show Resolved Problems ({{ problems.length }})</omg-collapsed>
      <omg-expanded disableAnimation="true">Hide Resolved Problems</omg-expanded>
    </omg-collapse-toggle>

    <omg-expanded>
      <div class="om-list"
           om-card-content>
        <omg-collapse class="om-item -contrasted-dark"
                      data-qaid="resolved-problem"
                      *ngFor="let problem of problems; let i = index; trackBy: trackByFn"
                      omgCollapse
                      #resolvedCollapse="omgCollapse"
                      (expandedChange)="onExpandedChange($event, problem?.id)">
          <omg-collapse-toggle>
            <div om-layout
                 om-layout-gutter>
              <div om-flex>
                <strong data-qaid="resolved-problem-name">{{ problem.problemType.clinicalDescription }}</strong>
                <span *ngIf="problem.problemCodeLocation.name"> ({{ problem.problemCodeLocation.name }})</span>
              </div>

              <span *ngIf="problem.code">{{ problem.code }}</span>
              <span class="om-label"
                    *ngIf="!problem.code">No code</span>
              <div>
                <span omgTooltip
                      tooltipText="Reactivate Problem"
                      tooltipPosition="left"
                      class="om-icon clickable fa fa-undo"
                      omgStopEvent="click"
                      (click)="reactivateProblem(problem.id, resolvedCollapse, i + 1)"
                      data-qaid="reactivate-problem"></span>
              </div>
            </div>
            <p>{{ problem.briefComment }}</p>
          </omg-collapse-toggle>

          <omg-expanded>
            <omg-problem-form [problem]="problem"
                              [autoCheckInCreationEnabled]="autoCheckInCreationEnabled | async"
                              [addProblemSessionId]="addProblemSessionId"
                              [showHistory]="true"
                              (close)="resolvedCollapse.collapse()"
                              (save)="updateProblem($event, resolvedCollapse, i + 1)"
                              (delete)="deleteProblem($event, resolvedCollapse)"></omg-problem-form>
          </omg-expanded>

        </omg-collapse>
      </div>
    </omg-expanded>
  </omg-collapse>

</omg-card>
