import { AfterContentInit, ChangeDetectorRef, EventEmitter, OnDestroy, QueryList, } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabComponent } from './tab.component';
/**
 * @note
 * This tab component is loosely based on:
 *  - https://juristr.com/blog/2016/02/learning-ng2-creating-tab-component/
 * Dynamic tabs could be implemented following this as a general guide:
 * - https://juristr.com/blog/2017/07/ng2-dynamic-tab-component/
 */
var TabsComponent = /** @class */ (function () {
    function TabsComponent(cd) {
        this.cd = cd;
        this.selectedTabChange = new EventEmitter();
        this._selectedTab = null;
        this._initialized = false;
        this._unsubscribe = new Subject();
        this.trackByFn = function (index, tab) { return tab.key || index; };
    }
    Object.defineProperty(TabsComponent.prototype, "selectedTab", {
        get: function () {
            return this._selectedTab;
        },
        set: function (tab) {
            if (this._tabs && this._selectedTab !== tab) {
                this._tabs.forEach(function (t) { return (t.isActive = false); });
                if (tab) {
                    tab.isActive = true;
                }
                this._selectedTab = tab;
                this.selectedTabChange.emit(tab);
            }
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.selectTab = function (tab) {
        this.selectedTab = tab;
    };
    TabsComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (!this._initialized) {
            // Changing these values after change detection has run
            // since the checked content may contain references to them.
            Promise.resolve().then(function () {
                _this.initTabs();
            });
            this._initialized = true;
        }
        this.tabs.changes.pipe(takeUntil(this._unsubscribe)).subscribe(function () {
            _this.initTabs();
        });
    };
    TabsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    };
    TabsComponent.prototype.initTabs = function () {
        if (!this.tabs) {
            return;
        }
        var currentTabCount = this._tabs ? this._tabs.length : 0;
        this._tabs = this.tabs.toArray();
        if (currentTabCount !== this._tabs.length) {
            this.cd.markForCheck();
        }
        if (!this.selectedTab) {
            this.setInitialSelectedTab();
        }
    };
    TabsComponent.prototype.setInitialSelectedTab = function () {
        var activeTabs = this._tabs.filter(function (t) { return t.isActive; });
        var routerActiveTabs = this._tabs.filter(function (t) { return t.routerLinkActive && t.routerLinkActive.isActive; });
        if (routerActiveTabs.length > 0) {
            this.selectedTab = routerActiveTabs[0];
        }
        else if (activeTabs.length > 0) {
            this.selectedTab = activeTabs[0];
        }
        else if (this._tabs.length > 0) {
            this.selectedTab = this._tabs[0];
        }
    };
    return TabsComponent;
}());
export { TabsComponent };
