import { formatFuzzyDate, formatToLegacyFuzzyDatePrecision } from '@app/utils';

import {
  FhirSource,
  VaccineHistoryResponse,
  VaccineHistorySaveRequestData,
} from './vaccinations-api.type';
import { VaccineHistory } from './vaccinations.type';

export const mapFhirSourceToEntity = (fhirSource: FhirSource) => ({
  sourceSenderName: (fhirSource && fhirSource.source_sender_name) || null,
  createdAt: (fhirSource && fhirSource.created_at) || null,
});

const mapEventsToEntity = events =>
  events.map(event => ({
    statusChange: event.status_change,
    updatedBy: event.updated_by,
    createdAt: event.created_at,
  }));

export const mapVaccineHistoryResponseToVaccineHistory = (
  response: VaccineHistoryResponse,
): VaccineHistory => ({
  id: response.id || null,
  step: response.step || null,
  stepInSeriesPhrase: response.step_in_series_phrase || null,
  givenOn: response.given_on || null,
  givenAt: response.given_at || null,
  expiresOn: response.expires_on || null,
  side: response.side || null,
  comments: response.comments || null,
  vaccineBrandId: response.vaccine_brand_id || null,
  clinicalRouteId: response.clinical_route_id || null,
  vaccineLocationId: response.vaccine_location_id || null,
  wasAdministered: response.was_administered || false,
  givenOnWithPrecision: response.given_on_with_precision || null,
  name: response.name || null,
  historyVaccineHistoryId: response.history_vaccine_history_id || null,
  events: (response.events && mapEventsToEntity(response.events)) || null,
  lotNumber: response.lot_number,
  vaccineBrandName: response.vaccine_brand_name,
  vaccineRouteName: response.vaccine_route_name,
  vaccineLocationName: response.vaccine_location_name,
  fhirSource: response.fhir_source
    ? mapFhirSourceToEntity(response.fhir_source)
    : null,
});

export const mapMultipleVaccineHistoriesResponseToVaccineHistories = (
  response: VaccineHistoryResponse[],
): VaccineHistory[] =>
  response.map(vaccineHistory =>
    mapVaccineHistoryResponseToVaccineHistory(vaccineHistory),
  );

export const mapVaccineHistoryToSaveRequest = (
  vaccineHistory: VaccineHistory,
): VaccineHistorySaveRequestData => {
  const fuzzyDate = formatFuzzyDate(vaccineHistory.givenOn);

  return {
    history_vaccine: {
      comments: vaccineHistory.comments || undefined,
      given_at: vaccineHistory.givenAt || undefined,
      given_on: fuzzyDate.dateFuzzy || undefined,
      given_on_precision: formatToLegacyFuzzyDatePrecision(fuzzyDate.precision),
      vaccine: vaccineHistory.vaccine,
      step: vaccineHistory.step || undefined,
    },
  };
};
