export const newEntityId = -1;

export interface ProblemCodeExtension {
  id: number;
  description: string;
  character?: string;
}

export interface ProblemCode {
  id: number;
  detailDescription: string;
  code?: string;
  label?: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemCodeLocation {
  id: number;
  name: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemAutoCreation {
  basicFollowUpOrder?: {
    intervalValue: number;
    intervalType: string;
  };
}

export interface ProblemType {
  id: number;
  clinicalDescription: string;
  clinicalAbbreviation: string; // Set with results from elastic search
  layDescription: string;
  importance: number;
  locations: ProblemCodeLocation[];
  decisionSupport: string;
  autoCreations: ProblemAutoCreation[];
  tags: string[]; // Set with results from elastic search
  label?: string; // Custom label for the autocomplete component
  isCustom?: boolean;
}

export interface ProblemActions {
  delete: {
    allowed: boolean;
    reason: string;
  };
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface ProblemCustomCodeSelection {
  code: string;
  detailDescription: string;
  id: string;
  label?: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
}

export interface Problem {
  id: number;
  patientId: number;
  problemHistoryId: number;
  status: number;
  active: boolean;
  code: string;
  briefComment: string;
  onset: string;
  resolution: string;
  summary: string;
  aAndP: string;
  aAndPDisplayText: string;
  editedBy: string;
  updatedAt: string;
  historyStartDate: string;
  problemType: ProblemType;
  problemCodeLocation: ProblemCodeLocation;
  useCustomProblemType: boolean;
  includedInMedicalHistory: boolean;
  actions: ProblemActions;
  customProblemTypeCode: string;
  customProblemTypeCodeDescription: string;
  customProblemTypeDescription: string;
  customProblemCodeSelection: ProblemCustomCodeSelection;
  notificationText: string;
  autoCreationsFlags: ProblemAutoCreationFlags;
  isMinorChange: boolean;
  history: ProblemHistory;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
}

export interface Problem {
  id: number;
  patientId: number;
  problemHistoryId: number;
  status: number;
  active: boolean;
  code: string;
  briefComment: string;
  onset: string;
  resolution: string;
  summary: string;
  aAndP: string;
  aAndPDisplayText: string;
  editedBy: string;
  updatedAt: string;
  historyStartDate: string;
  problemType: ProblemType;
  problemCodeLocation: ProblemCodeLocation;
  useCustomProblemType: boolean;
  includedInMedicalHistory: boolean;
  actions: ProblemActions;
  customProblemTypeCode: string;
  customProblemTypeCodeDescription: string;
  customProblemTypeDescription: string;
  customProblemCodeSelection: ProblemCustomCodeSelection;
  notificationText: string;
  autoCreationsFlags: ProblemAutoCreationFlags;
  isMinorChange: boolean;
  history: ProblemHistory;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface Problem {
  id: number;
  patientId: number;
  problemHistoryId: number;
  status: number;
  active: boolean;
  code: string;
  briefComment: string;
  onset: string;
  resolution: string;
  summary: string;
  aAndP: string;
  aAndPDisplayText: string;
  editedBy: string;
  updatedAt: string;
  historyStartDate: string;
  problemType: ProblemType;
  problemCodeLocation: ProblemCodeLocation;
  useCustomProblemType: boolean;
  includedInMedicalHistory: boolean;
  actions: ProblemActions;
  customProblemTypeCode: string;
  customProblemTypeCodeDescription: string;
  customProblemTypeDescription: string;
  customProblemCodeSelection: ProblemCustomCodeSelection;
  notificationText: string;
  autoCreationsFlags: ProblemAutoCreationFlags;
  isMinorChange: boolean;
  history: ProblemHistory;
}

export interface ProblemSectionLinkPayload {
  activeProblemHistoryIds: number[];
  resolvedProblemHistoryIds: number[];
  medicalHistory: boolean;
}

/**
 * Form
 */

export interface ProblemForm {
  assessmentAndPlan: string;
  basicFollowUpOrder: boolean;
  briefComment: string;
  customProblemDescription: string;
  problemCode: string;
  problemId: number;
  onset: string;
  resolution: string;
  summary: string;
  locationId: number;
  includedInMedicalHistory: boolean;
  isCustom: boolean;
  id?: number;
  addProblemToNote: boolean;
}
