var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService } from '@app/core';
import { HealthGoalTypeService } from '../shared/health-goal-type.service';
import { HealthGoalTypeActionTypes, LoadHealthGoalTypesError, LoadHealthGoalTypesSuccess, } from './health-goal-type.actions';
import { selectTotalHealthGoalTypes } from './health-goal-type.selectors';
var HealthGoalTypeEffects = /** @class */ (function () {
    function HealthGoalTypeEffects(actions$, service, store, errorHandler) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.errorHandler = errorHandler;
        this.loadHealthGoalTypes$ = this.actions$.pipe(ofType(HealthGoalTypeActionTypes.LoadHealthGoalTypes), withLatestFrom(this.store.pipe(select(selectTotalHealthGoalTypes))), filter(function (_a) {
            var action = _a[0], count = _a[1];
            return count <= 0;
        }), switchMap(function (action) {
            return _this.service.getAll().pipe(map(function (healthGoalTypes) { return new LoadHealthGoalTypesSuccess(healthGoalTypes); }), catchError(function (err) {
                return of(new LoadHealthGoalTypesError(_this.errorHandler.handleError(err)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalTypeEffects.prototype, "loadHealthGoalTypes$", void 0);
    return HealthGoalTypeEffects;
}());
export { HealthGoalTypeEffects };
