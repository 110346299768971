var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@ngrx/store';
var metadataStatePath = 'metadata';
var initialEntityState = { pending: false, error: null };
/**
 * Gets the initial state for the entity metadata state slice
 *
 * @param state The EntityState<T> + metadata state slice
 */
export var getEntityMetadataInitialState = function (state) { return (__assign({}, state, { metadata: {} })); };
/**
 * Updates a particular entities metadata state
 *
 * @param id The entity key
 * @param changes The changes to reflect in the entities metadata
 * @param state The EntityState<T> + metadata state slice
 */
export var updateEntityMetadata = function (id, changes, state) {
    var _a, _b;
    return (_a = {},
        _a[metadataStatePath] = __assign({}, state.metadata, (_b = {}, _b[id] = __assign({}, state[id], initialEntityState, changes), _b)),
        _a);
};
/**
 * Resets a particular entities metadata state
 *
 * @param id The entity key
 * @param state The EntityState<T> + metadata state slice
 */
export var resetEntityMetadata = function (id, state) {
    var _a, _b;
    return (_a = {},
        _a[metadataStatePath] = __assign({}, state.metadata, (_b = {}, _b[id] = {
            pending: false,
            error: null,
        }, _b)),
        _a);
};
/**
 * Creates a series of helpers to select entity metadata
 *
 * @param selectEntityState The EntityState<T> base selector
 * @param selectEntityById The selector that selects an entity by it's key
 */
export var createEntityMetadataSelectors = function (selectEntityState, selectEntityById) {
    var selectEntityMetadataState = createSelector(selectEntityState, function (state) { return state.metadata; });
    var selectEntityMetadata = createSelector(selectEntityMetadataState, function (state, _a) {
        var id = _a.id;
        return state[id];
    });
    var selectEntityWithMetadata = createSelector(selectEntityById, selectEntityMetadata, function (entity, status) { return (__assign({ entity: entity }, status)); });
    var selectEntityError = createSelector(selectEntityMetadata, function (status) { return status && status.error; });
    return {
        selectEntityMetadataState: selectEntityMetadataState,
        selectEntityMetadata: selectEntityMetadata,
        selectEntityWithMetadata: selectEntityWithMetadata,
        selectEntityError: selectEntityError,
    };
};
