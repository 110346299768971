<div class="data-table">
  <h4 class="heading">Screening History</h4>

  <ng-container *ngIf="screeningHistory$ | async as screeningHistory">
    <div class="row"
         *ngIf="!screeningHistory?.length">
      No screening history for this health goal.
    </div>

    <omg-screening-item class="row"
                        om-layout-gutter
                        *ngFor="let screening of screeningHistory; trackBy: trackByFn"
                        [healthGoalType]="healthGoalType"
                        [screening]="screening">
    </omg-screening-item>
  </ng-container>
</div>

<omg-collapse #newScreeningCollapse="omgCollapse"
              (expandedChange)="onNewScreeningFormExpanded($event)">
  <omg-collapsed om-layout="vertical"
                 om-layout-fill
                 disableAnimation="true">
    <div om-flex>
      <button omgButton
              variant="link"
              omgCollapseToggle>
        + Add Screening History
      </button>
    </div>
  </omg-collapsed>

  <omg-expanded om-layout="vertical"
                om-layout-fill
                om-layout-gutter
                disableAnimation="true">
    <omg-screening-form class="-subform"
                        #newScreeningForm
                        [healthGoalType]="healthGoalType"
                        [formValue]="newScreeningFormValue"
                        [formErrors]="newScreeningErrors$ | async"
                        (save)="onSaveNewScreening($event)"
                        (cancel)="newScreeningCollapse.collapse()">
    </omg-screening-form>
  </omg-expanded>
</omg-collapse>
