/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./radio-button.component";
var styles_RadioButtonComponent = [i0.styles];
var RenderType_RadioButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioButtonComponent, data: {} });
export { RenderType_RadioButtonComponent as RenderType_RadioButtonComponent };
export function View_RadioButtonComponent_0(_l) { return i1.ɵvid(2, [i1.ɵncd(null, 0)], null, null); }
export function View_RadioButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "input", [["omgRadioButton", ""]], [[1, "type", 0]], null, null, View_RadioButtonComponent_0, RenderType_RadioButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.RadioButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).type; _ck(_v, 0, 0, currVal_0); }); }
var RadioButtonComponentNgFactory = i1.ɵccf("input[omgRadioButton]", i2.RadioButtonComponent, View_RadioButtonComponent_Host_0, {}, {}, ["*"]);
export { RadioButtonComponentNgFactory as RadioButtonComponentNgFactory };
