import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '@app/core';

import { HealthGoalTypeService } from '../shared/health-goal-type.service';
import {
  HealthGoalTypeActionTypes,
  LoadHealthGoalTypes,
  LoadHealthGoalTypesError,
  LoadHealthGoalTypesSuccess,
} from './health-goal-type.actions';

import { selectTotalHealthGoalTypes } from './health-goal-type.selectors';
import { HealthMainteanceState } from './health-maintenance.reducer';

@Injectable()
export class HealthGoalTypeEffects {
  constructor(
    private actions$: Actions,
    private service: HealthGoalTypeService,
    private store: Store<HealthMainteanceState>,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  loadHealthGoalTypes$: Observable<Action> = this.actions$.pipe(
    ofType<LoadHealthGoalTypes>(HealthGoalTypeActionTypes.LoadHealthGoalTypes),
    withLatestFrom(this.store.pipe(select(selectTotalHealthGoalTypes))),
    filter(([action, count]) => count <= 0),
    switchMap(action =>
      this.service.getAll().pipe(
        map(healthGoalTypes => new LoadHealthGoalTypesSuccess(healthGoalTypes)),
        catchError(err =>
          of(new LoadHealthGoalTypesError(this.errorHandler.handleError(err))),
        ),
      ),
    ),
  );
}
