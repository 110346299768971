import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';

import * as utils from '../shared/patient-utils';
import { PatientState, patientStatePath } from './patient.reducer';

export const selectPatientState = createFeatureSelector<PatientState>(
  patientStatePath,
);

export const selectPatient = createSelector(
  selectPatientState,
  state => state && state.entity,
);

export const selectPatientId = createSelector(
  selectPatient,
  p => p && p.id,
);

export const selectPcpComments = createSelector(
  selectPatient,
  p => p && p.pcpComments,
);

export const selectHealthMaintenanceNote = createSelector(
  selectPatient,
  p => p && p.healthMaintenanceNote,
);

export const selectFullName = createSelector(
  selectPatient,
  p => p && `${p.firstName} ${p.lastName}`,
);

export const selectDateOfBirth = createSelector(
  selectPatient,
  p => p && p.dateOfBirth,
);

export const selectGender = createSelector(
  selectPatient,
  p => p && p.gender,
);

export const selectPastOrCurrentElectronicTosAccepted = createSelector(
  selectPatient,
  p => p && p.pastOrCurrentElectronicTosAccepted,
);

export const selectPatientStateName = createSelector(
  selectPatient,
  p =>
    p &&
    p.office &&
    p.office.address &&
    p.office.address.state &&
    p.office.address.state.name,
);

export const selectFormattedAge = createSelector(
  selectPatient,
  p => utils.formattedAge(p),
);

export const selectageToNearestMonth = createSelector(
  selectPatient,
  p => p && p.ageToNearestMonth,
);

export const selectIsMinor = createSelector(
  selectPatient,
  p => utils.isMinor(p),
);

export const selectIsTeen = createSelector(
  selectPatient,
  p => utils.isTeen(p),
);

export const selectIsPreteen = createSelector(
  selectPatient,
  p => utils.isPreteen(p),
);

export const selectHasIncompleteDemographics = createSelector(
  selectPatient,
  p => utils.hasIncompleteDemographics(p),
);

export const selectAcceptsDigitalCommunications = createSelector(
  selectPatient,
  p => utils.acceptsDigitalCommunications(p),
);

export const selectLimitedAccessMembership = createSelector(
  selectPatient,
  p => utils.limitedAccessMembership(p),
);

export const selectExpiredMembership = createSelector(
  selectPatient,
  p => utils.expiredMembership(p),
);

export const selectPatientWarnings = createSelector(
  selectPatient,
  p => utils.buildPatientWarnings(p),
);

export const selectPatientInfo = createSelector(
  selectPatient,
  selectFormattedAge,
  selectIsTeen,
  selectIsPreteen,
  selectAcceptsDigitalCommunications,
  selectHasIncompleteDemographics,
  selectExpiredMembership,
  selectLimitedAccessMembership,
  (
    patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  ) => ({
    ...patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  }),
);

/* istanbul ignore next */
@Injectable()
export class PatientSelectors {
  constructor(private store: Store<PatientState>) {}

  get state() {
    return this.store.pipe(select(selectPatientState));
  }

  get patientId() {
    return this.store.pipe(select(selectPatientId));
  }

  get patient() {
    return this.store.pipe(select(selectPatient));
  }

  get pcpComments() {
    return this.store.pipe(select(selectPcpComments));
  }

  get healthMaintenanceNote() {
    return this.store.pipe(select(selectHealthMaintenanceNote));
  }

  get fullName() {
    return this.store.pipe(select(selectFullName));
  }

  get dateOfBirth() {
    return this.store.pipe(select(selectDateOfBirth));
  }

  get gender() {
    return this.store.pipe(select(selectGender));
  }

  get formattedAge() {
    return this.store.pipe(select(selectFormattedAge));
  }

  get ageToNearestMonth() {
    return this.store.pipe(select(selectageToNearestMonth));
  }

  get isMinor() {
    return this.store.pipe(select(selectIsMinor));
  }

  get isTeen() {
    return this.store.pipe(select(selectIsTeen));
  }

  get isPreteen() {
    return this.store.pipe(select(selectIsPreteen));
  }

  get hasIncompleteDemographics() {
    return this.store.pipe(select(selectHasIncompleteDemographics));
  }

  get acceptsDigitalCommunications() {
    return this.store.pipe(select(selectAcceptsDigitalCommunications));
  }

  get limitedAccessMembership() {
    return this.store.pipe(select(selectLimitedAccessMembership));
  }

  get expiredMembership() {
    return this.store.pipe(select(selectExpiredMembership));
  }

  get patientInfo() {
    return this.store.pipe(select(selectPatientInfo));
  }

  get patientWarnings() {
    return this.store.pipe(select(selectPatientWarnings));
  }

  get pastOrCurrentElectronicTosAccepted() {
    return this.store.pipe(select(selectPastOrCurrentElectronicTosAccepted));
  }

  get patientStateName() {
    return this.store.pipe(select(selectPatientStateName));
  }
}
