import { ToastMessageService } from '@app/shared/components/toast';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/components/toast/toast-message.service";
var labelXMLString = "<?xml version=\"1.0\" encoding=\"utf-8\"?>\n    <DieCutLabel Version=\"8.0\" Units=\"twips\">\n        <PaperOrientation>Landscape</PaperOrientation>\n        <Id>ReturnAddress</Id>\n        <PaperName>30330 Return Address</PaperName>\n        <DrawCommands/>\n        <ObjectInfo>\n            <TextObject>\n                <Name>Text</Name>\n                <ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" />\n                <BackColor Alpha=\"0\" Red=\"255\" Green=\"255\" Blue=\"255\" />\n                <LinkedObjectName></LinkedObjectName>\n                <Rotation>Rotation0</Rotation>\n                <IsMirrored>False</IsMirrored>\n                <IsVariable>True</IsVariable>\n                <HorizontalAlignment>Left</HorizontalAlignment>\n                <VerticalAlignment>Middle</VerticalAlignment>\n                <TextFitMode>ShrinkToFit</TextFitMode>\n                <UseFullFontHeight>True</UseFullFontHeight>\n                <Verticalized>False</Verticalized>\n                <StyledText/>\n            </TextObject>\n            <Bounds X=\"326\" Y=\"165\" Width=\"2242\" Height=\"795\" />\n        </ObjectInfo>\n    </DieCutLabel>";
var LabelPrinterService = /** @class */ (function () {
    function LabelPrinterService(toast) {
        this.toast = toast;
    }
    Object.defineProperty(LabelPrinterService.prototype, "printers", {
        get: function () {
            return dymo.label.framework.getPrinters();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelPrinterService.prototype, "labelPrinter", {
        get: function () {
            return (this.printers &&
                this.printers.find(function (printer) { return printer.printerType === 'LabelWriterPrinter'; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabelPrinterService.prototype, "printerLoaded", {
        get: function () {
            return this.printers && this.printers.length > 0 && !!this.labelPrinter;
        },
        enumerable: true,
        configurable: true
    });
    LabelPrinterService.prototype.makeLabel = function (text) {
        var label = dymo.label.framework.openLabelXml(labelXMLString);
        label.setObjectText('Text', text);
        return label;
    };
    LabelPrinterService.prototype.print = function (text, count) {
        if (count === void 0) { count = 1; }
        if (!this.printerLoaded) {
            return this.toast.add({
                severity: 'warn',
                summary: 'Dymo printer could not be found. Please reconnect and try again.',
            });
        }
        this.makeLabel(text).print(this.labelPrinter.name, dymo.label.framework.createLabelWriterPrintParamsXml({
            copies: count,
        }), '');
        this.toast.add({
            severity: 'success',
            summary: "Printing " + count + " label" + (count > 1 ? 's' : '') + ".",
        });
    };
    LabelPrinterService.ngInjectableDef = i0.defineInjectable({ factory: function LabelPrinterService_Factory() { return new LabelPrinterService(i0.inject(i1.ToastMessageService)); }, token: LabelPrinterService, providedIn: "root" });
    return LabelPrinterService;
}());
export { LabelPrinterService };
