import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { components, entryComponents, exportComponents } from './components';
import { VaccinationsApiService, VaccinationsService } from './shared';
import { effects, reducers, statePath } from './store';
import { VaccinationsActions } from './store/vaccinations.actions';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(statePath, reducers),
    EffectsModule.forFeature([...effects]),
  ],
  declarations: [...components],
  exports: [...exportComponents],
  providers: [VaccinationsApiService, VaccinationsService, VaccinationsActions],
  entryComponents,
})
export class VaccinationsModule {}
