import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';

import { InsightsGraphComponent } from './components/insights-graph/insights-graph.component';
import { ProblemInsightsComponent } from './components/problem-insights/problem-insights.component';
import { InsightsService } from './shared/insights.service';

@NgModule({
  declarations: [ProblemInsightsComponent, InsightsGraphComponent],
  exports: [ProblemInsightsComponent],
  imports: [SharedModule, FormsModule],
  providers: [InsightsService],
})
export class InsightsModule {}
