import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/shared/auth.service';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    return this.auth.logout().pipe(
      map(() => {
        this.router.navigateByUrl('');
        return true;
      }),
      catchError(() => of(false)),
    );
  }
}
