var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var first = args[0], rest = args.slice(1);
        /* tslint:disable-next-line: no-console */
        console.info(first, rest);
    };
    LoggerService.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var first = args[0], rest = args.slice(1);
        /* tslint:disable-next-line: no-console */
        console.log(first, rest);
    };
    LoggerService.prototype.warning = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var first = args[0], rest = args.slice(1);
        console.warn(first, rest);
    };
    LoggerService.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var first = args[0], rest = args.slice(1);
        console.error(first, rest);
    };
    return LoggerService;
}());
export { LoggerService };
