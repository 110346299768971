var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PatientSelectors } from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { AllergiesService } from '../../shared/allergies.service';
import { buildAllergySaveRequest, buildAllergyUpdateRequest, getActivePatientAllergies, getInactivePatientAllergies, } from './../../shared/allergies-utils';
var AllergiesComponent = /** @class */ (function () {
    function AllergiesComponent(allergiesService, patientSelectors, summariesSelectors) {
        this.allergiesService = allergiesService;
        this.patientSelectors = patientSelectors;
        this.summariesSelectors = summariesSelectors;
        this.activePatientAllergies = [];
        this.inactivePatientAllergies = [];
        this.unsubscribe = new Subject();
        this.trackByFn = function (index, patientAllergy) {
            return patientAllergy.id || index;
        };
    }
    AllergiesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patientSelectors.patientId
            .pipe(filter(Boolean), tap(function (patientId) {
            _this.patientId = patientId;
        }), switchMap(function (patientId) { return _this.allergiesService.getAll(patientId); }), takeUntil(this.unsubscribe))
            .subscribe(function (res) { return _this.updatePatientAllergies(res); });
        this.isSummaryActive = this.summariesSelectors.hasActiveSummary;
    };
    AllergiesComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    AllergiesComponent.prototype.activateAllergy = function (allergy) {
        var _this = this;
        var resolvedAllergy = {
            id: allergy.id,
            active: allergy.active,
        };
        this.allergiesService
            .update(this.patientId, resolvedAllergy)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (res) {
            return _this.updatePatientAllergies(_this.mapActivationResponseToPatientAllergy(res));
        });
    };
    AllergiesComponent.prototype.addAllergy = function (formValue) {
        var _this = this;
        var request = buildAllergySaveRequest({
            active: true,
            allergy: {
                drugAllergyCheckCompatible: false,
                id: formValue.allergyId,
            },
            comment: formValue.comment,
            reaction: formValue.reaction,
        });
        this.allergiesService
            .save(this.patientId, request)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (res) {
            return _this.updatePatientAllergies(_this.mapPostResponseToPatientAllergy(res));
        });
    };
    AllergiesComponent.prototype.updateAllergy = function (event) {
        var _this = this;
        var formValue = event.value, onSuccess = event.onSuccess;
        var request = buildAllergyUpdateRequest({
            allergy: {
                id: formValue.allergyId,
            },
            comment: formValue.comment,
            id: formValue.id,
            reaction: formValue.reaction,
        });
        this.allergiesService
            .update(this.patientId, request)
            .pipe(tap(function () { return onSuccess(); }), takeUntil(this.unsubscribe))
            .subscribe(function (res) {
            return _this.updatePatientAllergies(_this.mapUpdateResponseToPatientAllergy(res));
        });
    };
    AllergiesComponent.prototype.deleteAllergy = function (event) {
        var _this = this;
        var allergyId = event.id, onSuccess = event.onSuccess;
        this.allergiesService
            .delete(this.patientId, allergyId)
            .pipe(tap(function () { return onSuccess(); }), takeUntil(this.unsubscribe))
            .subscribe(function () {
            return _this.updatePatientAllergies(__assign({}, _this.patientAllergies, { items: _this.patientAllergies.items.filter(function (item) { return item.id !== allergyId; }) }));
        });
    };
    AllergiesComponent.prototype.setPatientNoKnownAllergy = function () {
        var _this = this;
        this.allergiesService
            .noKnownAllergy(this.patientId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (res) {
            var updated = __assign({}, _this.patientAllergies, { noKnownAllergies: __assign({}, res) });
            _this.updatePatientAllergies(updated);
        });
    };
    AllergiesComponent.prototype.addToNote = function () {
        this.allergiesService.linkSection(this.patientId, getActivePatientAllergies(this.patientAllergies));
    };
    AllergiesComponent.prototype.updatePatientAllergies = function (patientAllergies) {
        this.patientAllergies = patientAllergies;
        this.activePatientAllergies = getActivePatientAllergies(this.patientAllergies);
        this.inactivePatientAllergies = getInactivePatientAllergies(this.patientAllergies);
    };
    AllergiesComponent.prototype.mapUpdateResponseToPatientAllergy = function (response) {
        var updatedItem = response.items && response.items[0];
        var updatedItems = this.patientAllergies.items.map(function (item) {
            if (item.id === updatedItem.id) {
                return __assign({}, item, updatedItem);
            }
            return item;
        });
        return __assign({}, this.patientAllergies, { items: updatedItems.slice(), noKnownAllergies: __assign({}, response.noKnownAllergies) });
    };
    AllergiesComponent.prototype.mapPostResponseToPatientAllergy = function (response) {
        return {
            items: this.patientAllergies.items.concat(response.items),
            noKnownAllergies: __assign({}, this.patientAllergies.noKnownAllergies, response.noKnownAllergies),
        };
    };
    AllergiesComponent.prototype.mapActivationResponseToPatientAllergy = function (response) {
        var updatedItem = response.items && response.items[0];
        return __assign({}, this.patientAllergies, { items: this.patientAllergies.items.map(function (item) {
                if (item.id === updatedItem.id) {
                    return __assign({}, item, { active: updatedItem.active });
                }
                return item;
            }) });
    };
    return AllergiesComponent;
}());
export { AllergiesComponent };
