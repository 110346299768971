import { AfterViewInit, EventEmitter, NgZone, OnChanges, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
var AutoCompleteComponent = /** @class */ (function () {
    function AutoCompleteComponent(ngZone) {
        var _this = this;
        this.ngZone = ngZone;
        this.placeholder = 'Select items';
        this.customItem = false;
        this.clearable = true;
        this.resetItemsOnClear = true;
        this.addItemText = 'Add custom item';
        this.dropdownPosition = 'auto';
        this.fillLayout = true;
        this.highlightMatch = true;
        this.hideDropdownArrow = false;
        this.hideClearAll = false;
        this.searchable = true;
        this.openOnChange = true;
        // tslint:disable-next-line:no-input-rename
        this.flexValue = null;
        this.opened = new EventEmitter();
        this.closed = new EventEmitter();
        this.change = new EventEmitter();
        this.search = new EventEmitter();
        this.blur = new EventEmitter();
        this.clear = new EventEmitter();
        this.loading = false;
        this.trackByFn = function (index, item) { return item[_this.trackByKey] || index; };
    }
    Object.defineProperty(AutoCompleteComponent.prototype, "inlineStyle", {
        // This is a workaround to use the ng-select in a flexbox
        get: function () {
            var inlineStyle = {};
            if (this.flexValue) {
                inlineStyle.width = this.flexValue + "%";
            }
            return inlineStyle;
        },
        enumerable: true,
        configurable: true
    });
    AutoCompleteComponent.prototype.ngOnInit = function () {
        this.checkAutoWidth();
    };
    AutoCompleteComponent.prototype.ngOnChanges = function (changes) {
        if (!this.openOnChange) {
            return;
        }
        if (changes.items.previousValue === null &&
            changes.items.currentValue !== null) {
            this.open();
        }
        if (changes.items.previousValue &&
            changes.items.currentValue &&
            changes.items.previousValue.length !==
                changes.items.currentValue.length &&
            changes.items.currentValue.length > 1) {
            this.open();
        }
    };
    AutoCompleteComponent.prototype.toggle = function () {
        this.ref.toggle();
    };
    AutoCompleteComponent.prototype.open = function () {
        this.ref.open();
    };
    AutoCompleteComponent.prototype.close = function () {
        this.ref.close();
    };
    AutoCompleteComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.initialItems = this.items;
        var defaultOpen = this.ref.open;
        this.ref.open = function () {
            if (!_this.items) {
                return false;
            }
            return defaultOpen.bind(_this.ref)();
        };
    };
    /* istanbul ignore next */
    AutoCompleteComponent.prototype.onOpen = function (event) {
        var _this = this;
        this.opened.emit();
        this.ngZone.runOutsideAngular(function () {
            return window.addEventListener('scroll', _this.closeDropdown.bind(_this), true);
        });
    };
    /* istanbul ignore next */
    AutoCompleteComponent.prototype.onClose = function (event) {
        var _this = this;
        this.closed.emit();
        this.ngZone.runOutsideAngular(function () {
            return window.removeEventListener('scroll', _this.closeDropdown.bind(_this), true);
        });
    };
    AutoCompleteComponent.prototype.focus = function () {
        this.ref.focus();
    };
    AutoCompleteComponent.prototype.registerOnChange = function (fn) {
        this.ref.registerOnChange(fn);
    };
    AutoCompleteComponent.prototype.registerOnTouched = function (fn) {
        this.ref.registerOnTouched(fn);
    };
    AutoCompleteComponent.prototype.setDisabledState = function (isDisabled) {
        this.ref.setDisabledState(isDisabled);
    };
    AutoCompleteComponent.prototype.writeValue = function (obj) {
        this.ref.writeValue(obj);
    };
    AutoCompleteComponent.prototype.onSearch = function (text) {
        if (!!text) {
            this.search.emit(text);
        }
        else {
            if (this.resetItemsOnClear) {
                this.resetItems(this.initialItems);
            }
            this.close();
        }
    };
    AutoCompleteComponent.prototype.onBlur = function (text) {
        this.blur.emit(text);
    };
    AutoCompleteComponent.prototype.resetItems = function (items) {
        this.items = items ? items : null;
    };
    AutoCompleteComponent.prototype.checkAutoWidth = function () {
        var autoWidthClassName = 'auto-width';
        if (this.autoWidth) {
            if (this.ref.classes.indexOf(autoWidthClassName) === -1) {
                this.ref.classes = this.ref.classes + " " + autoWidthClassName;
            }
            else {
                this.ref.classes = this.ref.classes;
            }
        }
    };
    /* istanbul ignore next */
    AutoCompleteComponent.prototype.closeDropdown = function (event) {
        if (this.ref && this.ref.isOpen) {
            this.ref.updateDropdownPosition();
        }
    };
    return AutoCompleteComponent;
}());
export { AutoCompleteComponent };
