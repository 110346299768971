var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { ToastMessageService } from '@app/shared/components/toast';
import { TodoApiService } from '../shared/todo-api.service';
import { CompleteTodoError, CompleteTodoSuccess, LoadTodoError, LoadTodoSuccess, ReopenTodoError, ReopenTodoSuccess, TodoActionTypes, UpdateTodoError, UpdateTodoSuccess, } from './todo.actions';
var TodoEffects = /** @class */ (function () {
    function TodoEffects(action$, todoApi, toastMessageService, summariesActions, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.todoApi = todoApi;
        this.toastMessageService = toastMessageService;
        this.summariesActions = summariesActions;
        this.errorHandler = errorHandler;
        this.loadTodoBySummaryId$ = this.action$.pipe(ofType(TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID), switchMap(function (action) {
            return _this.todoApi.getSummaryTodo(action.payload).pipe(map(function (todo) { return new LoadTodoSuccess(todo); }), catchError(function (error) {
                return of(new LoadTodoError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.updateTodo$ = this.action$.pipe(ofType(TodoActionTypes.UPDATE_TODO), switchMap(function (action) {
            return _this.todoApi.update(action.payload).pipe(map(function (todo) { return new UpdateTodoSuccess(todo); }), catchError(function (error) {
                return of(new UpdateTodoError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.completeTodo$ = this.action$.pipe(ofType(TodoActionTypes.COMPLETE_TODO), switchMap(function (action) {
            return _this.todoApi.complete(action.payload).pipe(map(function (todo) { return new CompleteTodoSuccess(todo); }), catchError(function (error) {
                return of(new CompleteTodoError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.completeTodoSuccess$ = this.action$.pipe(ofType(TodoActionTypes.COMPLETE_TODO_SUCCESS), tap(function (action) {
            _this.toastMessageService.add({
                severity: 'success',
                detail: 'Your task has been completed',
            });
            _this.summariesActions.refreshTimeline();
            _this.summariesActions.closeWorkspaceItem();
        }));
        this.reopenTodo$ = this.action$.pipe(ofType(TodoActionTypes.REOPEN_TODO), switchMap(function (action) {
            return _this.todoApi.reopenTodo(action.payload).pipe(map(function (todo) { return new ReopenTodoSuccess(todo); }), catchError(function (error) {
                return of(new ReopenTodoError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TodoEffects.prototype, "loadTodoBySummaryId$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TodoEffects.prototype, "updateTodo$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TodoEffects.prototype, "completeTodo$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TodoEffects.prototype, "completeTodoSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TodoEffects.prototype, "reopenTodo$", void 0);
    return TodoEffects;
}());
export { TodoEffects };
