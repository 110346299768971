import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var ScreeningResultComponent = /** @class */ (function () {
    function ScreeningResultComponent(cd) {
        this.cd = cd;
        this.disabled = false;
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(ScreeningResultComponent.prototype, "result", {
        get: function () {
            return this._result;
        },
        set: function (value) {
            if (this._result !== value) {
                this._result = value;
                this.cd.markForCheck();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScreeningResultComponent.prototype.ngOnInit = function () { };
    ScreeningResultComponent.prototype.ngOnChanges = function (changes) {
        this.setInputType();
    };
    ScreeningResultComponent.prototype.onResultChange = function (value) {
        this.result = value;
        this.onChange(value);
    };
    /* istanbul ignore next */
    ScreeningResultComponent.prototype.writeValue = function (value) {
        this.result = value;
    };
    /* istanbul ignore next */
    ScreeningResultComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /* istanbul ignore next */
    ScreeningResultComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /* istanbul ignore next */
    ScreeningResultComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    ScreeningResultComponent.prototype.setInputType = function () {
        var allowedInput = ['radio', 'integer'];
        this.inputType =
            allowedInput.includes(this.allowedResults.type) && this.normalize
                ? this.allowedResults.type
                : 'text';
    };
    return ScreeningResultComponent;
}());
export { ScreeningResultComponent };
