import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';

import { snakeCase } from '@app/utils';

@Injectable()
export class AppBridgeSelectors {
  constructor(
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
  ) {}

  get profile() {
    return this.profileSelectors.profileInfo.pipe(
      filter(profile => !!profile),
      map(profile => ({
        ...snakeCase(profile),
        hasRole: profile.hasRole,
      })),
    );
  }

  get patient() {
    return this.patientSelectors.patientInfo.pipe(
      filter(patient => !!patient),
      map(patient => ({
        ...patient,
        primaryAddress: patient.primaryAddress && {
          ...patient.primaryAddress,
          state:
            patient.primaryAddress.state &&
            patient.primaryAddress.state.name &&
            (patient.primaryAddress.state.shortName ||
              patient.primaryAddress.state.name),
        },
      })),
      map(patient => {
        const snakeCased = snakeCase(patient);

        return {
          ...snakeCased,
          primary_address: {
            ...snakeCased.primary_address,
            address1: patient.primaryAddress && patient.primaryAddress.street,
          },
          incompleteDemographics: () => patient.hasIncompleteDemographics,
          formattedAge: () => patient.formattedAge,
          isTeen: () => patient.isTeen,
          isPreteen: () => patient.isPreteen,
          acceptsDigitalCommunications: () =>
            patient.acceptsDigitalCommunications,
        };
      }),
    );
  }
}
