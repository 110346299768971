<ng-container>
  <div class="om-card-title">
    <div class="om-title"
         om-layout
         om-layout-align="space-between center">
      <h4 om-flex
          class="inline om-title-text">{{ heading }}</h4>
      <div om-layout
           om-layout-align="start stretch">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>
