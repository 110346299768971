var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isString } from '@app/utils';
/* istanbul ignore next */
export var mapAddressResponseToEntity = function (address) {
    if (!address) {
        return null;
    }
    var state = isString(address.state) ? { name: address.state } : address.state;
    state = state || {};
    return {
        street: address.street || address.address1 || '',
        fullAddress: address.address || '',
        address2: address.address2 || '',
        city: address.city || '',
        state: {
            name: state.name || '',
            shortName: state.short_name || '',
        },
        zipCode: address.zip_code || '',
        kind: address.kind || '',
    };
};
/* istanbul ignore next */
export var mapPatientGetResponseToEntity = function (response) { return ({
    id: response.id || null,
    addresses: (response.addresses || []).map(mapAddressResponseToEntity),
    age: response.age || null,
    contactEmail: response.contact_email && {
        flavor: response.contact_email.flavor || '',
        address: response.contact_email.address || '',
    },
    contactPhone: response.contact_phone && {
        flavor: response.contact_phone.flavor || '',
        number: response.contact_phone.number || '',
    },
    dateOfBirth: response.dob || null,
    unborn: response.unborn || false,
    dueDate: response.due_date || null,
    enterprise: response.enterprise && {
        b2bCompanyName: response.enterprise.b2b_company_name || '',
        benefitsFaqUrl: response.enterprise.benefits_faq_url || '',
        dependentPlan: response.enterprise.dependent_plan,
        visitorPlan: response.enterprise.visitor_plan,
    },
    firstName: response.first_name || '',
    formerLastName: response.former_last_name || null,
    gender: response.gender || null,
    genderDetails: response.gender_details && {
        description: response.gender_details.description || null,
    },
    healthMaintenanceNote: response.health_maintenance_note && {
        id: response.health_maintenance_note.id || null,
        content: response.health_maintenance_note.content || null,
        healthMaintenanceNoteHistoryId: response.health_maintenance_note.health_maintenance_note_history_id ||
            null,
    },
    infantAge: response.infant_age || null,
    ageToNearestMonth: response.age_to_nearest_month || null,
    isB2b: response.is_b2b || null,
    lastName: response.last_name || '',
    middleName: response.middle_name || null,
    memberSince: response.member_since || null,
    membership: response.membership && {
        active: response.membership.active || null,
        limitedAccess: response.membership.limited_access || null,
        status: response.membership.status || null,
    },
    nickname: response.nickname || null,
    office: response.office && {
        id: response.office.id || null,
        address: mapAddressResponseToEntity(response.office.address),
        name: response.office.name,
    },
    pastOrCurrentElectronicTosAccepted: response.past_or_current_electronic_tos_accepted,
    patientPreferences: response.patient_preferences && {
        acceptsClinicalEmails: response.patient_preferences.accepts_clinical_emails || null,
        acceptsOnlineBills: response.patient_preferences.accepts_online_bills || null,
        fullTextNotifications: response.patient_preferences.full_text_notifications || null,
    },
    pcp: response.pcp || null,
    pcpComments: response.pcp_comments || null,
    photoUrl: response.photo_url || null,
    preferredName: response.preferred_name || null,
    preferredPharmacy: response.preferred_pharmacy || null,
    primaryAddress: mapAddressResponseToEntity(response.primary_address),
    primaryInsurance: response.primary_insurance && {
        carrierId: response.primary_insurance.carrier_id || null,
        name: response.primary_insurance.name || null,
        networkShort: response.primary_insurance.network_short || null,
        type: response.primary_insurance.type || null,
    },
    profileImageUrl: response.profile_image_url || null,
    pronouns: response.pronouns || null,
    suffix: response.suffix,
    initials: response.initials || null,
}); };
/* istanbul ignore next */
export var mapToPatientUpdateRequest = function (data) { return (__assign({}, (data.healthMaintenanceNoteAttributes && {
    health_maintenance_note_attributes: {
        content: data.healthMaintenanceNoteAttributes.content,
    },
}), ((data.pcpComments || data.pcpComments === '') && {
    pcp_comments: data.pcpComments,
}), (data.pronouns && {
    pronouns: data.pronouns,
}))); };
