import { HttpErrorResponse } from '@angular/common/http';

import { getHttpStatusCodeText, isError, isHttpError } from '@app/utils';

export const getHttpErrorMessage = (error: HttpErrorResponse) => {
  let message = '';
  let subTitle = '';

  if (error.error && typeof error.error === 'string') {
    message = error.error;
  } else if (error.message) {
    message = error.message;
  } else if (error.statusText) {
    message = error.statusText;
  } else if (error.status === 0) {
    message = `CORS error with url`;
  } else {
    message = getHttpStatusCodeText(error.status);
  }

  if (error.status !== 0) {
    subTitle = `HTTP Status ${error.status}`;
  }

  const logMessage = [message, subTitle].join('\n');
  return logMessage;
};

export const getErrorMessage = (err: any) => {
  if (isHttpError(err)) {
    return getHttpErrorMessage(err);
  } else if (isError(err)) {
    return `${err.name}: ${err.message}`;
  } else if (err) {
    return `${err}`;
  } else {
    return null;
  }
};
