<omg-collapse om-layout="vertical"
              om-layout-fill
              om-layout-gutter
              *ngIf="healthGoalType?.decisionSupport">

  <omg-collapse-toggle>
    <omg-collapsed disableAnimation="true">
      <button omgButton
              variant="link"
              angulartics2On
              angularticsAction="Health Goal Decision Support Expanded"
              [angularticsProperties]="{ 'health-goal-type': healthGoalType.name }">
        <i class="fa fa-chevron-down"></i> Show Tips for {{ healthGoalType.name }}
      </button>
    </omg-collapsed>
    <omg-expanded disableAnimation="true">
      <button omgButton
              variant="link"
              angulartics2On
              angularticsAction="Health Goal Decision Support Collapsed"
              [angularticsProperties]="{ 'health-goal-type': healthGoalType.name }">
        <i class="fa fa-chevron-up"></i> Hide Tips for {{ healthGoalType.name }}
      </button>
    </omg-expanded>
  </omg-collapse-toggle>

  <omg-expanded class="-important"
                om-layout="vertical"
                om-layout-gutter
                disableAnimation="true">
    <div markdown
         ngPreserveWhitespaces
         [data]="healthGoalType?.decisionSupport">
    </div>
  </omg-expanded>
</omg-collapse>
