import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { FormModel } from '@app/shared/forms';

import {
  HealthBackground,
  HealthBackgroundNote,
  HealthBackgroundNoteType,
  HealthBackgroundService,
} from '../../shared';

interface HealthBackgroundNoteFormValue {
  notes: string;
}

@Component({
  selector: 'omg-health-background',
  templateUrl: './health-background.component.html',
})
export class HealthBackgroundComponent implements OnInit, OnDestroy {
  private healthBackground: HealthBackground;
  private unsubscribe = new Subject();
  private patientId: number;

  illnessesAndSurgeries: HealthBackgroundNote;
  familyData: HealthBackgroundNote;
  socialData: HealthBackgroundNote;

  illnessesAndSurgeriesFormModel: FormModel;
  familyDataFormModel: FormModel;
  socialDataFormModel: FormModel;

  isSummaryActive: Observable<boolean>;
  isLoaded = false;

  constructor(
    private patientSelectors: PatientSelectors,
    private healthBackgroundService: HealthBackgroundService,
    private fb: FormBuilder,
    private summariesSelectors: SummariesSelectors,
  ) {}

  ngOnInit() {
    this.buildForms();
    this.loadData();
    this.isSummaryActive = this.summariesSelectors.hasActiveSummary;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  markAsReviewed(noteType: HealthBackgroundNoteType) {
    this.getFormModel(noteType).save();
  }

  linkSectionClick() {
    this.healthBackgroundService.linkSection(
      this.patientId,
      this.healthBackground,
    );
  }

  private buildForms() {
    this.illnessesAndSurgeriesFormModel = new FormModel(
      this.fb.group({ notes: '' }),
      { saveFunction: value => this.saveData('illnessesAndSurgeries', value) },
    );

    this.familyDataFormModel = new FormModel(this.fb.group({ notes: '' }), {
      saveFunction: value => this.saveData('familyData', value),
    });

    this.socialDataFormModel = new FormModel(this.fb.group({ notes: '' }), {
      saveFunction: value => this.saveData('socialData', value),
    });
  }

  private getFormModel(noteType: HealthBackgroundNoteType) {
    return this[`${noteType}FormModel`] as FormModel;
  }

  private loadData() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        tap(patientId => (this.patientId = patientId)),
        switchMap(patientId =>
          this.healthBackgroundService.get(patientId).pipe(
            tap(entity => (this.healthBackground = entity)),
            map(entity => entity),
          ),
        ),
        map(entity => this.loadNotes(entity)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private saveData(
    noteType: HealthBackgroundNoteType,
    note: HealthBackgroundNoteFormValue,
  ) {
    return this.healthBackgroundService
      .update(this.patientId, note, noteType)
      .pipe(
        takeUntil(this.unsubscribe),
        tap(entity => this.updateNoteDetails(entity, noteType)),
      );
  }

  private loadNotes(healthBackground: HealthBackground) {
    // Store the entities for binding & set the form values
    this.healthBackground = healthBackground;

    this.illnessesAndSurgeries = healthBackground.illnessesAndSurgeries;
    this.illnessesAndSurgeriesFormModel.setValue({
      notes: healthBackground.illnessesAndSurgeries.notes,
    });

    this.familyData = healthBackground.familyData;
    this.familyDataFormModel.setValue({
      notes: healthBackground.familyData.notes,
    });

    this.socialData = healthBackground.socialData;
    this.socialDataFormModel.setValue({
      notes: healthBackground.socialData.notes,
    });

    this.isLoaded = true;
  }

  private updateNoteDetails(
    healthBackground: HealthBackground,
    noteType: HealthBackgroundNoteType,
  ) {
    // We need to patch everything but notes back
    this.healthBackground = healthBackground;
    const note = {
      notes: this.getFormModel(noteType).get('notes').value,
      updatedAt: healthBackground[noteType].updatedAt,
      updatedBy: healthBackground[noteType].updatedBy,
      reviewed: true,
    };
    this[noteType] = note;
  }
}
