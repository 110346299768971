import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Router } from '@angular/router';
import {
  ErrorHandlerService,
  PatientSelectors,
  SectionLinkedQueueService,
} from '@app/core';
import { summariesPath } from '@app/features/workspace/shared/workspace-utils';
import { onUpgrade } from '@app/upgrade/routing/custom-url-handling-strategy';

import {
  mapToSummaryHealthBackgroundUpdateRequest,
  mapToSummaryHealthGoalsUpdateRequest,
  mapToSummaryMedicationsUpdateRequest,
  mapToSummaryPatientAllergiesRequest,
  mapToSummaryVaccinationsUpdateRequest,
} from '../shared/summaries-api-mappers';
import { SummariesApiService } from '../shared/summaries-api.service';
import { Summary } from '../shared/summaries.type';
import {
  CloseWorkspaceItem,
  DeleteSummary,
  DeleteSummaryError,
  DeleteSummarySuccess,
  LinkAssessedProblems,
  LinkAssessedProblemsError,
  LinkAssessedProblemsSuccess,
  LinkHealthBackground,
  LinkHealthBackgroundError,
  LinkHealthBackgroundSuccess,
  LinkHealthGoals,
  LinkHealthGoalsError,
  LinkHealthGoalsSuccess,
  LinkMedications,
  LinkMedicationsError,
  LinkMedicationsSuccess,
  LinkPatientAllergies,
  LinkPatientAllergiesError,
  LinkPatientAllergiesSuccess,
  LinkProblems,
  LinkProblemsError,
  LinkProblemsSuccess,
  LinkVaccinations,
  LinkVaccinationsError,
  LinkVaccinationsSuccess,
  LoadSummary,
  LoadSummaryError,
  LoadSummarySuccess,
  RedactSummary,
  RedactSummaryError,
  RedactSummarySuccess,
  SaveAddendum,
  SaveAddendumError,
  SaveAddendumSuccess,
  SaveSummary,
  SaveSummaryError,
  SaveSummarySuccess,
  SignSummary,
  SignSummaryError,
  SignSummarySuccess,
  SummariesActions,
  SummaryActionTypes,
  Unlink,
  UnlinkError,
  UnlinkSuccess,
  UpdateSummary,
  UpdateSummaryError,
  UpdateSummarySuccess,
} from './summaries.actions';
import { SummariesSelectors } from './summaries.selectors';

/* The filter using onUpgrade should be removed once the upgrade path is removed. */

@Injectable()
export class SummariesEffects {
  constructor(
    private action$: Actions,
    private summariesApi: SummariesApiService,
    private summariesSelectors: SummariesSelectors,
    private sectionLinkedQueue: SectionLinkedQueueService,
    private patientSelectors: PatientSelectors,
    private router: Router,
    private summariesActions: SummariesActions,
    private errorHandler: ErrorHandlerService,
  ) {}

  @Effect()
  loadSummary$: Observable<Action> = this.action$.pipe(
    ofType<LoadSummary>(SummaryActionTypes.LOAD_SUMMARY),
    filter(() => onUpgrade()),
    switchMap(action =>
      this.summariesApi
        .get(action.payload.patientId, action.payload.summaryId)
        .pipe(
          map(summary => new LoadSummarySuccess(summary)),
          catchError(error =>
            of(new LoadSummaryError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect()
  saveSummary$: Observable<Action> = this.action$.pipe(
    ofType<SaveSummary>(SummaryActionTypes.SAVE_SUMMARY),
    filter(() => onUpgrade()),
    switchMap(action =>
      this.summariesApi
        .save(action.payload.patientId, action.payload.noteTypeId)
        .pipe(
          map(summary => new SaveSummarySuccess(summary)),
          catchError(error =>
            of(new SaveSummaryError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect()
  updateSummary$: Observable<Action> = this.action$.pipe(
    ofType<UpdateSummary>(SummaryActionTypes.UPDATE_SUMMARY),
    filter(() => onUpgrade()),
    switchMap(action =>
      this.summariesApi
        .update(
          action.payload.changes.patientId,
          action.payload.changes.summaryId,
          action.payload.changes.data,
        )
        .pipe(
          map(
            (summary: Summary) =>
              new UpdateSummarySuccess({
                id: summary.id,
                changes: { ...summary },
              }),
          ),
          catchError(error =>
            of(
              new UpdateSummaryError(this.errorHandler.handleErrorSafe(error)),
            ),
          ),
        ),
    ),
  );

  @Effect()
  signSummary$: Observable<Action> = this.action$.pipe(
    ofType<SignSummary>(SummaryActionTypes.SIGN_SUMMARY),
    filter(() => onUpgrade()),
    switchMap(action =>
      this.summariesApi
        .sign(
          action.payload.changes.patientId,
          action.payload.changes.summaryId,
          action.payload.changes.summary,
        )
        .pipe(
          map(
            summary =>
              new SignSummarySuccess({
                id: summary.id,
                changes: { ...summary },
              }),
          ),
          catchError(error =>
            of(new SignSummaryError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  signSummarySuccess$: Observable<Action> = this.action$.pipe(
    ofType<SignSummarySuccess>(SummaryActionTypes.SIGN_SUMMARY_SUCCESS),
    filter(() => onUpgrade()),
    tap(() => {
      this.summariesActions.refreshTimeline();
      this.summariesActions.closeWorkspaceItem();
    }),
  );

  @Effect()
  deleteSummary$: Observable<Action> = this.action$.pipe(
    ofType<DeleteSummary>(SummaryActionTypes.DELETE_SUMMARY),
    filter(() => onUpgrade()),
    switchMap(action =>
      this.summariesApi
        .delete(action.payload.patientId, action.payload.summaryId)
        .pipe(
          map(summary => new DeleteSummarySuccess(summary)),
          catchError(error =>
            of(
              new DeleteSummaryError(this.errorHandler.handleErrorSafe(error)),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  deleteSummarySuccess$: Observable<Action> = this.action$.pipe(
    ofType<DeleteSummarySuccess>(SummaryActionTypes.DELETE_SUMMARY_SUCCESS),
    filter(() => onUpgrade()),
    tap(() => {
      this.summariesActions.refreshTimeline();
      this.summariesActions.closeWorkspaceItem();
    }),
  );

  @Effect()
  redactSummary$: Observable<Action> = this.action$.pipe(
    ofType<RedactSummary>(SummaryActionTypes.REDACT_SUMMARY),
    switchMap(action =>
      this.summariesApi
        .redact(
          action.payload.changes.patientId,
          action.payload.changes.summaryId,
        )
        .pipe(
          map(
            summary =>
              new RedactSummarySuccess({
                id: summary.id,
                changes: { ...summary },
              }),
          ),
          catchError(error =>
            of(
              new RedactSummaryError(this.errorHandler.handleErrorSafe(error)),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  redactSummarySuccess$: Observable<Action> = this.action$.pipe(
    ofType<RedactSummarySuccess>(SummaryActionTypes.REDACT_SUMMARY_SUCCESS),
    tap(() => {
      this.summariesActions.closeTimelineItem();
      this.summariesActions.refreshTimeline();
      this.summariesActions.closeWorkspaceItem();
    }),
  );

  @Effect()
  linkPatientAllergies$: Observable<Action> = this.action$.pipe(
    ofType<LinkPatientAllergies>(SummaryActionTypes.LINK_PATIENT_ALLERGIES),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'PatientAllergies',
        action.payload.patientAllergies,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkPatientAllergies(
          action.payload.patientId,
          currentSummaryId,
          mapToSummaryPatientAllergiesRequest(action.payload.patientAllergies),
        )
        .pipe(
          map((summary: Summary) => new LinkPatientAllergiesSuccess(summary)),
          catchError(error =>
            of(
              new LinkPatientAllergiesError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  linkProblem$: Observable<Action> = this.action$.pipe(
    ofType<LinkProblems>(SummaryActionTypes.LINK_PROBLEMS),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'Problems',
        action.payload.problems,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkProblems(
          action.payload.patientId,
          currentSummaryId,
          action.payload.problems,
        )
        .pipe(
          map(summary => new LinkProblemsSuccess(summary)),
          catchError(error =>
            of(new LinkProblemsError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect()
  linkAssessedProblem$: Observable<Action> = this.action$.pipe(
    ofType<LinkAssessedProblems>(SummaryActionTypes.LINK_ASSESSED_PROBLEMS),
    withLatestFrom(
      this.summariesSelectors.currentSummaryId,
      this.summariesSelectors.assessedProblems,
    ),
    tap(([action, currentSummaryId, assessedProblems]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'AssessedProblems',
        {
          id: action.payload.problemId,
          problemHistoryId: action.payload.historyId,
        },
        action.payload.eventKey,
      ),
    ),
    filter(
      ([action]) => onUpgrade() && action.payload.eventKey !== 'problemDeleted',
    ),
    switchMap(([action, currentSummaryId, assessedProblems]) =>
      this.summariesApi
        .linkAssessedProblems(
          action.payload.patientId,
          currentSummaryId,
          assessedProblems,
          action.payload.historyId,
          action.payload.problemId,
          action.payload.hasAssessedProblem,
        )
        .pipe(
          map(summary => new LinkAssessedProblemsSuccess(summary)),
          catchError(error =>
            of(
              new LinkAssessedProblemsError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  linkHealthGoals$: Observable<Action> = this.action$.pipe(
    ofType<LinkHealthGoals>(SummaryActionTypes.LINK_HEALTH_GOALS),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'HealthGoals',
        action.payload.healthGoals,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkHealthGoals(
          action.payload.patientId,
          currentSummaryId,
          /* Might be able to move the request mapping function call here instead of
           * during action creation. This will separate Summaries from HealthMaintenance more
           * cleanly. */
          mapToSummaryHealthGoalsUpdateRequest(action.payload.healthGoals),
        )
        .pipe(
          map(summary => new LinkHealthGoalsSuccess(summary)),
          catchError(error =>
            of(
              new LinkHealthGoalsError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  linkHealthBackground$: Observable<Action> = this.action$.pipe(
    ofType<LinkHealthBackground>(SummaryActionTypes.LINK_HEALTH_BACKGROUND),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'HealthBackground',
        action.payload.healthBackground,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkHealthBackground(
          action.payload.patientId,
          currentSummaryId,
          mapToSummaryHealthBackgroundUpdateRequest(
            action.payload.healthBackground,
          ),
        )
        .pipe(
          map(summary => new LinkHealthBackgroundSuccess(summary)),
          catchError(error =>
            of(
              new LinkHealthBackgroundError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  linkMedications$: Observable<Action> = this.action$.pipe(
    ofType<LinkMedications>(SummaryActionTypes.LINK_MEDICATIONS),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      // NOTE: This is for backwards compatibility for the AngularJS chart
      // It can be removed once the upgrade or AngularJS chart is removed.
      this.sectionLinkedQueue.addSectionLink(
        'Medications',
        action.payload.medications.legacyData,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkMedications(
          action.payload.patientId,
          currentSummaryId,
          mapToSummaryMedicationsUpdateRequest(
            action.payload.medications.summaryData.noMedications,
            action.payload.medications.summaryData.patientMedicationRegimenIds,
          ),
        )
        .pipe(
          map(summary => new LinkMedicationsSuccess(summary)),
          catchError(error =>
            of(
              new LinkMedicationsError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  linkVaccinations$: Observable<Action> = this.action$.pipe(
    ofType<LinkVaccinations>(SummaryActionTypes.LINK_VACCINATIONS),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    tap(([action, currentSummaryId]) =>
      this.sectionLinkedQueue.addSectionLink(
        'Vaccinations',
        /* Since Vaccinations has not been migrated yet, the payload shape
         * is not known - will have to be revised once Vaccinations work has started. */
        action.payload.vaccinations.legacyData,
      ),
    ),
    filter(() => onUpgrade()),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .linkVaccinations(
          action.payload.patientId,
          currentSummaryId,
          /* Might be able to move the request mapping function call here instead of
           * during action creation. This will separate Summaries from Vaccinations more
           * cleanly. */
          mapToSummaryVaccinationsUpdateRequest(
            action.payload.vaccinations.vaccineHistoryIds,
          ),
        )
        .pipe(
          map(summary => new LinkVaccinationsSuccess(summary)),
          catchError(error =>
            of(
              new LinkVaccinationsError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect()
  unlink$: Observable<Action> = this.action$.pipe(
    ofType<Unlink>(SummaryActionTypes.UNLINK),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi
        .update(action.payload.patientId, currentSummaryId, {
          ...action.payload.extraFields,
        })
        .pipe(
          map(summary => new UnlinkSuccess(summary)),
          catchError(error =>
            of(new UnlinkError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  closeWorkspaceItem$ = this.action$.pipe(
    ofType<CloseWorkspaceItem>(SummaryActionTypes.CLOSE_WORKSPACE_ITEM),
    withLatestFrom(this.patientSelectors.patientId),
    tap(([action, patientId]) =>
      this.router.navigateByUrl(summariesPath(patientId, null, 'new')),
    ),
  );

  @Effect()
  saveAddendum: Observable<Action> = this.action$.pipe(
    ofType<SaveAddendum>(SummaryActionTypes.SAVE_ADDENDUM),
    withLatestFrom(this.summariesSelectors.currentSummaryId),
    switchMap(([action, currentSummaryId]) =>
      this.summariesApi.saveAddendum(currentSummaryId, action.payload).pipe(
        map(addendum => new SaveAddendumSuccess(addendum)),
        catchError(error =>
          of(new SaveAddendumError(this.errorHandler.handleErrorSafe(error))),
        ),
      ),
    ),
  );
}
