<ng-container *ngIf="(stateChanges | async) as change">
  <ng-container *ngTemplateOutlet="stateTemplate; context: { $implicit: change }">
  </ng-container>
  <ng-container *ngIf="stateCondition(change.state)">
    <span #content>
      <ng-content></ng-content>
    </span>
    <span *ngIf="!content.innerHTML.trim()"
          class="om-messages"
          [ngClass]="{
            '-progress': change.state === 'saving',
            '-success': change.state === 'saved',
            '-warning': change.state === 'saveError'
          }">
      {{ stateMessage(change.state) }}
    </span>
  </ng-container>
</ng-container>
