export type HealthBackgroundNoteType =
  | 'illnessesAndSurgeries'
  | 'familyData'
  | 'socialData';

export const healthBackgroundNoteTypeMap: {
  [k in HealthBackgroundNoteType]: string
} = {
  illnessesAndSurgeries: 'illnesses_and_surgeries',
  familyData: 'family_data',
  socialData: 'social_data',
};

export interface HealthBackground {
  healthBackgroundHistoryId: number;
  illnessesAndSurgeries: HealthBackgroundNote;
  familyData: HealthBackgroundNote;
  socialData: HealthBackgroundNote;
}

export interface HealthBackgroundNote {
  notes?: string;
  updatedBy?: string;
  updatedAt?: Date;
  reviewed?: boolean;
}

export interface HealthBackgroundResponse {
  health_background_history_id: number;
  illnesses_and_surgeries: HealthBackgroundResponseNote;
  family_data: HealthBackgroundResponseNote;
  social_data: HealthBackgroundResponseNote;
}

export interface HealthBackgroundResponseNote {
  notes: string;
  updated_by?: string;
  updated_at?: string;
  reviewed?: boolean;
}
