import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';

import { mapHealthGoalTypeResponse } from './health-maintenance-api-mappers';
import { HealthGoalType } from './health-maintenance.type';

@Injectable()
export class HealthGoalTypeService {
  constructor(
    private searchService: SearchService,
    private configService: ConfigService,
  ) {}

  getAll(): Observable<HealthGoalType[]> {
    return this.searchService
      .search(this.buildSearchQuery())
      .pipe(map(this.mapResultsToHealthGoalType));
  }

  private mapResultsToHealthGoalType(data: any): HealthGoalType[] {
    const hits = data && data.hits && data.hits.hits ? data.hits.hits : [];
    const types = hits.map(item => mapHealthGoalTypeResponse(item._source));
    return types;
  }

  private buildSearchQuery() {
    return {
      index: this.configService.searchIndex('health_goal_types'),
      size: 50,
      body: {
        query: {
          match_all: {},
        },
      },
    };
  }
}
