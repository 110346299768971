<omg-collapse omgCollapse
              (expandedChange)="onExpanded($event)"
              #collapseRef="omgCollapse">

  <div om-layout
       om-layout-gutter
       omgCollapseToggle>

    <div om-flex="60">
      <strong>{{ healthGoal.healthGoalTypeName }}</strong>
    </div>

    <div om-flex
         class="right-align">
      <ng-container *ngIf="healthGoal.indicated">
        <span *ngIf="healthGoal.declined">declined by pt</span>
        <span *ngIf="!healthGoal.declined">
          <span [ngClass]="{ alert: healthGoal.isPastDue }"
                *ngIf="healthGoal.dueAt">
            due {{ healthGoal.dueAt | omgDate: '2y' }}
          </span>
          <span *ngIf="!healthGoal.dueAt">no due date</span>
        </span>
      </ng-container>
    </div>

    <div om-layout
         om-layout-align="start center">
      <span class="om-icon clickable"
            [ngClass]="{
              'icon-dismiss -gray': healthGoal.indicated,
              'fa fa-undo': !healthGoal.indicated
            }"
            omgTooltip
            [tooltipText]="healthGoal.indicated ? 'Move to Not Indicated' : 'Move to Indicated'"
            tooltipPosition="left"
            omgStopEvent="click"
            (click)="onIndicatedChange()"
            om-layout
            om-layout-align="start center">
      </span>
    </div>
  </div>

  <omg-expanded>
    <ng-container *ngIf="formLoaded; else formLoading">
      <omg-health-goal-form class="-subform"
                            [healthGoalTypes]="healthGoalTypes"
                            [formValue]="healthGoalFormValue"
                            [formErrors]="healthGoalErrors$ | async"
                            [editedBy]="healthGoal.editedBy"
                            [updatedAt]="healthGoal.updatedAt"
                            (cancel)="onCancel()"
                            (save)="onSave($event)"
                            (delete)="onDelete($event)">
      </omg-health-goal-form>
    </ng-container>
  </omg-expanded>

</omg-collapse>

<ng-template #formLoading>
  <div class="data-table"
       om-layout-fill>
    <div class="row"
         om-layout-align="center">
      <i class="fa fa-lg fa-spinner fa-pulse"></i>
    </div>
  </div>
</ng-template>
