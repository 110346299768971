import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import { RenewalCart } from '../shared';
import { RenewalRootState } from './renewal.reducer';

type RenewalError = any;

export enum RenewalActionTypes {
  LoadRenewalCart = '[Renewal] Load Renewal Cart',
  LoadRenewalCartSuccess = '[Renewal] Load Renewal Cart Success',
  LoadRenewalCartError = '[Renewal] Load Renewal Cart Error',
  RefreshRenewalCount = '[Renewal] Refresh Renewal Count',
  RefreshRenewalCountSuccess = '[Renewal] Refresh Renewal Count Success',
  RefreshRenewalCountError = '[Renewal] Refresh Renewal Count Error',
}

export class LoadRenewalCart implements FsaAction {
  readonly type = RenewalActionTypes.LoadRenewalCart;

  constructor() {}
}

export class LoadRenewalCartSuccess implements FsaAction<RenewalCart> {
  readonly type = RenewalActionTypes.LoadRenewalCartSuccess;
  error = false;

  constructor(public payload: RenewalCart) {}
}

export class LoadRenewalCartError implements FsaAction<RenewalError> {
  readonly type = RenewalActionTypes.LoadRenewalCartError;
  error = true;

  constructor(public payload: RenewalError) {}
}

export class RefreshRenewalCount implements FsaAction {
  readonly type = RenewalActionTypes.RefreshRenewalCount;

  constructor() {}
}

export class RefreshRenewalCountSuccess implements FsaAction<number> {
  readonly type = RenewalActionTypes.RefreshRenewalCountSuccess;
  error = false;

  constructor(public payload: number) {}
}

export class RefreshRenewalCountError implements FsaAction<RenewalError> {
  readonly type = RenewalActionTypes.RefreshRenewalCountError;
  error = true;

  constructor(public payload: RenewalError) {}
}

export type RenewalAction =
  | LoadRenewalCart
  | LoadRenewalCartSuccess
  | LoadRenewalCartError
  | RefreshRenewalCount
  | RefreshRenewalCountSuccess
  | RefreshRenewalCountError;

/**
 * Service
 */

@Injectable({
  providedIn: 'root',
})
export class RenewalActions {
  constructor(private store: Store<RenewalRootState>) {}

  loadRenewalCart() {
    this.store.dispatch(new LoadRenewalCart());
  }

  refreshRenewalCount() {
    this.store.dispatch(new RefreshRenewalCount());
  }
}
