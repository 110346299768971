import { isAfter } from 'date-fns';

/**
 * Re-export functions from date-fns
 */

export {
  addDays,
  format as formatDate,
  parse as parseDate,
  toDate,
  isAfter as isDateAfter,
  isBefore as isDateBefore,
  subDays,
} from 'date-fns';

export const isPastDue = (dueAt: Date | string) => isAfter(new Date(), dueAt);
