import { createSelector } from '@ngrx/store';

import { orderBy } from '@app/utils';
import {
  createEntityMetadataSelectors,
  pluckEntitiesByIds,
} from '@app/utils/store';

import {
  HealthGoalAction,
  HealthGoalScreening,
  HealthGoalScreeningSummary,
} from '../shared/health-maintenance.type';

import { selectHealthGoalActionEntities } from './health-goal-type.selectors';
import { selectHealthGoalById } from './health-goal.selectors';
import { selectHealthMaintenanceState } from './health-maintenance-shared';

import * as fromHealthGoalScreening from './health-goal-screening.reducer';

// selects the state slice
export const selectHealthGoalScreeningState = createSelector(
  selectHealthMaintenanceState,
  state => state && state.healthGoalScreenings,
);

// selects array of ids
export const selectHealthGoalScreeningIds = createSelector(
  selectHealthGoalScreeningState,
  fromHealthGoalScreening.selectHealthGoalScreeningIds,
);

// selects the dictionary
export const selectHealthGoalScreeningEntities = createSelector(
  selectHealthGoalScreeningState,
  fromHealthGoalScreening.selectHealthGoalScreeningEntities,
);

// selects the array
export const selectAllHealthGoalScreenings = createSelector(
  selectHealthGoalScreeningState,
  fromHealthGoalScreening.selectAllHealthGoalScreenings,
);

// selects the total number count
export const selectTotalHealthGoalScreenings = createSelector(
  selectHealthGoalScreeningState,
  fromHealthGoalScreening.selectHealthGoalScreeningTotal,
);

// selects by item id
export const selectHealthGoalScreeningById = createSelector(
  selectHealthGoalScreeningState,
  (state, { id }) => state && state.entities && state.entities[id],
);

// selects loading
export const selectLoadingHealthGoalScreenings = createSelector(
  selectHealthGoalScreeningState,
  state => state && state.loading,
);

// selects the general state error
export const selectHealthGoalScreeningsError = createSelector(
  selectHealthGoalScreeningState,
  state => state && state.error,
);

/**
 * Projected Views
 */

const mapToSummary = (
  actions: { [k: string]: HealthGoalAction },
  screening: HealthGoalScreening,
): HealthGoalScreeningSummary => {
  const action = actions[screening.eventId];
  return {
    ...screening,
    eventName: action && action.name,
  };
};

// selects items by health goal id
export const selectHealthGoalScreeningsByHealthGoalId = createSelector(
  selectHealthGoalById,
  selectHealthGoalScreeningEntities,
  selectHealthGoalActionEntities,
  (healthGoal, allScreenings, healthGoalActions) => {
    if (!healthGoal) {
      return null;
    }
    const screenings = pluckEntitiesByIds(
      allScreenings,
      healthGoal.screeningHistoryIds,
    );
    const result = screenings.map(i => mapToSummary(healthGoalActions, i));
    return orderBy('date', 'desc', result);
  },
);

/**
 * Entity Metadata
 */

export const {
  selectEntityWithMetadata: selectHealthGoalScreeningWithMetadata,
  selectEntityError: selectHealthGoalScreeningError,
} = createEntityMetadataSelectors(
  selectHealthGoalScreeningState,
  selectHealthGoalScreeningById,
);

export const selectHealthGoalScreeningSummaryWithMetadata = createSelector(
  selectHealthGoalScreeningWithMetadata,
  selectHealthGoalActionEntities,
  (data, actions) => ({
    ...data,
    entity: mapToSummary(actions, data.entity),
  }),
);
