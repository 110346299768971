import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  buildTokenName,
  StateConfig,
  StoreEntityState,
} from '@app/core/store/shared/state';

import { Order } from '../shared/order.type';
import { ActionNameTypeMapFactory } from './../../../core/store/shared/state';

export const ordersStateConfig: StateConfig = {
  statePath: 'orders',
  entityName: 'Order',
  pluralName: 'Orders',
};

export const ordersActionNameTypeMap = ActionNameTypeMapFactory(
  ordersStateConfig,
);

export const orderEntityAdapter: EntityAdapter<Order> = createEntityAdapter<
  Order
>();

export const initialState: StoreEntityState<
  Order
> = orderEntityAdapter.getInitialState({
  loading: false,
  error: null,
});

export const OrdersReducerToken = new InjectionToken<
  ActionReducerMap<StoreEntityState<Order>>
>(buildTokenName(ordersStateConfig));

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = orderEntityAdapter.getSelectors();
