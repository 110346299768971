export enum AnalyticsEvent {
  TiffAttachedToMessage = 'Tiff attached to message',
  FollowUpOrderEdited = 'Follow Up Order Edited',
  FollowUpOrderDeleted = 'Follow Up Order Deleted',
  FollowUpOrderViewed = 'Follow Up Order Viewed',
  DateFieldEdited = 'Date field edited',
  DatePickerButtonPressed = 'Date Picker Button Pressed',
  ProblemAdded = 'Problem added',
  ProblemRemoved = 'Problem removed',
  ReasonFieldEdited = 'Reason field edited',
  NewFollowUpOrderClicked = 'New Follow Up Order Clicked',
  SessionStarted = 'Session Started',
  SessionEnded = 'Session Ended',
  TabStarted = 'Tab Context Started',
  TabEnded = 'Tab Context Ended',
  NoteSigned = 'Note Signed',
  HealthMaintenanceSectionAdded = 'Health Maintenance Section Added',
  SummaryAdded = 'Summary Added',
  MiscNoteCreated = 'Miscellaneous Note Created',
  MiscNoteFinished = 'Miscellaneous Note Finished',
  PatientTimelinePostCreated = 'Patient Timeline Post Created',
  LetterCreated = 'Letter Created',
  TemplateInserted = 'Template Inserted',
  AddMedicationClicked = 'Add Medication Clicked',
  MedicationFieldChanged = 'Medication Field Changed',
  DrugInteractionDisplayed = 'Drug Interaction Displayed',
  MedicationChangesSaved = 'Medication Changes Saved',
  MedicationAddedToCart = 'Medication Added to Cart',
  DrugInteractionOverridden = 'Drug Interaction Overridden',
  DrugInteractionWarningExpanded = 'Drug Interaction Warning Expanded',
  MedicationChangesCancelled = 'Medication Changes Cancelled',
  PatientMenuClicked = 'Patient Level Menu Clicked',
}

export interface TrackEventProperties {
  patientId: number;
  application: string;
  type: string;
  quantity: number;
  unit: string;
  sessionFingerprint: number;
  userRole: string;
  workflow: string;
  component: string;
  subComponent: string;
  subWorkflowId: number;
  problemType: string;
  summaryId: number;
  noteId: number;
  patientTimelinePostId: number;
  letterId: number;
  templateId: number;
  templateName: string;
  templateType: string;
  medicationRouteId: number;
  isOverridden: boolean;
  interactingObjectId: number;
  interactingObjectType: string;
  severityLevel: number;
  isNew: boolean;
}

export type AnalyticsApiRequestData = any;
