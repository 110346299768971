<ng-container *ngIf="groupingEnabled; else displayAll">
  <omg-popover #vaccineDetailsPopover
               title="Vaccine Details"
               placement="right"
               width="380">
    <div class="om-list">
      <div class="om-item padding-normal">
        <div om-layout
             *ngIf="vaccineHistory?.givenAt">
          <strong om-flex="50">Given at</strong>
          <div om-flex="150">{{ displayedGivenAt }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.vaccineBrandName">
          <strong om-flex="50">Brand</strong>
          <div om-flex="150">{{ vaccineHistory?.vaccineBrandName }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.vaccineRouteName">
          <strong om-flex="50">Route</strong>
          <div om-flex="150">{{ vaccineHistory?.vaccineRouteName }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.vaccineLocationName">
          <strong om-flex="50">Location</strong>
          <div om-flex="150">{{ vaccineHistory?.vaccineLocationName }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.side">
          <strong om-flex="50">Side</strong>
          <div om-flex="150">{{ vaccineHistory?.side }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.lotNumber">
          <strong om-flex="50">Lot No</strong>
          <div om-flex="150">{{ vaccineHistory?.lotNumber }}</div>
        </div>
        <div om-layout
             *ngIf="vaccineHistory?.comments">
          <strong om-flex="50">Comments</strong>
          <div om-flex="150">{{ vaccineHistory?.comments }}</div>
        </div>
      </div>
      <div *ngIf="vaccineHistory?.fhirSource"
           class="om-item -inset">
        <span><b>Received on {{ vaccineHistory?.fhirSource?.createdAt | omgDate: '2y' }} </b></span>
        from <i>{{ vaccineHistory?.fhirSource?.sourceSenderName}}</i>
      </div>
    </div>
    <div *ngIf="vaccineHistory?.events?.length > 0">
      <h3 class="popover-title">Order details</h3>
      <div class="om-list">
        <div class="om-item"
             *ngFor="let event of vaccineHistoryEvents; trackBy: trackByFn">
          <span class="capitalize"><b> {{ event?.statusChange }} on {{ event.createdAt |
                    omgDate: '2y' }}</b></span>
          <div>{{ event?.updatedBy }}</div>
        </div>
      </div>
    </div>
  </omg-popover>

  <span om-flex="20">{{ vaccineHistory.givenOnWithPrecision }}</span>
  <span om-flex="50">
    <div *ngIf="noInfoToShow; else showPopoverLink">
      <span>Unknown Brand</span>
    </div>
    <ng-template #showPopoverLink>
      <a [omgPopoverTriggerFor]="vaccineDetailsPopover"
         omgPopoverTriggerOn="click"
         class="om-link -plain-text">
        <span>{{ vaccineHistory.vaccineBrandName || 'Unknown Brand' }}
          {{ displayedStep }} </span>
      </a>
    </ng-template>
  </span>
  <span om-flex="20">
    <span>{{ displayedGivenAt }}</span>
  </span>
  <span om-flex="10">
    <button omgButton
            variant="critical-link"
            *ngIf="canDelete$ | async"
            (click)="onDelete()">
      Delete
    </button>
    <button *ngIf="!(canDelete$ | async)"
            omgButton
            variant="critical-link"
            class="-disabled"
            omgTooltip
            tooltipText="Vaccines administered at One Medical cannot be deleted."
            tooltipPosition="left">
      Delete
    </button>
  </span>
</ng-container>

<ng-template #displayAll>
  <hr>
  <div *ngIf="vaccineHistory?.fhirSource">
    <i>Received from {{ vaccineHistory?.fhirSource?.sourceSenderName}} on
      {{ vaccineHistory?.fhirSource?.createdAt | omgDate: '2y' }}</i>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.givenAt">
    <strong om-flex="20">Given at</strong>
    <div>{{ vaccineHistory?.givenAt}}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.vaccineBrandName">
    <strong om-flex="20">Brand</strong>
    <div>{{ vaccineHistory?.vaccineBrandName }}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.vaccineRouteName">
    <strong om-flex="20">Route</strong>
    <div>{{ vaccineHistory?.vaccineRouteName }}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.vaccineLocationName">
    <strong om-flex="20">Location</strong>
    <div>{{ vaccineHistory?.vaccineLocationName }}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.side">
    <strong om-flex="20">Side</strong>
    <div>{{ vaccineHistory?.side }}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.lotNumber">
    <strong om-flex="20">Lot No</strong>
    <div>{{ vaccineHistory?.lotNumber }}</div>
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.comments">
    <strong om-flex="20">Comments</strong>
    <div>{{ vaccineHistory?.comments }}</div>
  </div>
  <div om-layout
       *ngIf="hasNoAdministrationDetails">
    No administration details
  </div>
  <div om-layout
       *ngIf="vaccineHistory?.events?.length > 0">
    <strong om-flex="20">Order Details</strong>
    <div om-layout="vertical">
      <div *ngFor="let event of vaccineHistoryEvents; trackBy: trackByFn">
        <span class="capitalize">{{ event?.statusChange }}</span> by {{ event?.updatedBy }} on {{ event.createdAt |
          omgDate: '2y' }}
      </div>
    </div>
  </div>

  <div om-layout
       om-layout-gutter
       om-layout-fill
       om-layout-align="end">
    <button omgButton
            variant="critical-link"
            *ngIf="canDelete$ | async"
            (click)="onDelete()">
      Delete Vaccine
    </button>
    <button *ngIf="!(canDelete$ | async)"
            omgButton
            variant="critical-link"
            class="-disabled"
            omgTooltip
            tooltipText="Vaccines administered at One Medical cannot be deleted."
            tooltipPosition="left">
      Delete Vaccine
    </button>
  </div>
</ng-template>
