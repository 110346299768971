import { LabOrder } from '@app/modules/orders/shared/lab-order.type';
import {
  Order,
  ProcedureOrder,
  VaccineOrderListItem,
} from '../shared/order.type';

export const followUpOrderTypes = {
  visit: {
    name: 'VisitFollowUpOrder',
    shortName: 'visit',
    label: 'In Office Visit',
    analyticsType: 'Visit',
  },
  basic: {
    name: 'BasicFollowUpOrder',
    shortName: 'basic',
    label: 'Specific Problem',
    analyticsType: 'Basic',
  },
  generic: {
    name: 'GenericFollowUpOrder',
    shortName: 'generic',
    label: 'General',
    analyticsType: 'Generic',
  },
};

export enum OrderTypes {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder',
}

export const orderEditRouteMap = {
  BasicFollowUpOrder: 'follow_up_orders/basic/:id/edit',
  ConsultOrder: ':id/edit_consult_order',
  GenericFollowUpOrder: 'follow_up_orders/generic/:id/edit',
  LabOrder: ':id/edit',
  ProcedureOrder: 'procedure_orders/:id/edit',
  SurveyOrder: 'survey_orders/:id/edit',
  VaccineOrder: 'vaccine_orders/:id/edit',
  VisitFollowUpOrder: 'follow_up_orders/visit/:id/edit',
};

export const orderCreateRouteMap = {
  BasicFollowUpOrder: 'follow_up_orders/new',
  ConsultOrder: '',
  GenericFollowUpOrder: 'follow_up_orders/new',
  LabOrder: 'lab_orders',
  ProcedureOrder: 'procedure_orders',
  SurveyOrder: 'survey_orders/new',
  VaccineOrder: 'vaccine_orders',
  VisitFollowUpOrder: 'follow_up_orders/new',
};

export const orderApiMap = {
  BasicFollowUpOrder: '/v2/admin/patients/:patientId/basic_follow_up_orders',
  ConsultOrder: '/v2/admin/consult_orders',
  GenericFollowUpOrder:
    '/v2/admin/patients/:patientId/generic_follow_up_orders',
  LabOrder: '/v2/admin/lab_orders',
  ProcedureOrder: '/v2/admin/procedure_orders',
  SurveyOrder: `/v2/admin/patients/:patientId/survey_orders`,
  VisitFollowUpOrder: '/v2/admin/patients/:patientId/visit_follow_up_orders',
};

export const orderCreateApiMap = {
  BasicFollowUpOrder: '/v2/admin/patients/:patientId/basic_follow_up_orders',
  ConsultOrder: '/v2/admin/patients/:patientId/consult_orders',
  GenericFollowUpOrder:
    '/v2/admin/patients/:patientId/generic_follow_up_orders',
  LabOrder: '/v2/admin/patients/:patientId/lab_orders',
  ProcedureOrder: '/v2/admin/patients/:patientId/procedure_orders',
  SurveyOrder: `/v2/admin/patients/:patientId/survey_orders`,
  VaccineOrder: '/v2/admin/patients/:patientId/vaccine_orders',
  VisitFollowUpOrder: '/v2/admin/patients/:patientId/visit_follow_up_orders',
};

const generateOrderRoute = (order: Order) =>
  orderEditRouteMap[order.type].replace(':id', order.id.toString());

export const getOrderEditPath = (patientId: number, order: Order) =>
  `patients/${patientId}/chart/orders/${generateOrderRoute(order)}`;

export const getOrderApiPath = (orderType: OrderTypes, patientId: number) => {
  const orderPath = orderApiMap[orderType];
  return orderPath.replace(':patientId', patientId.toString());
};

export const getCreateOrderApiPath = (
  orderType: OrderTypes,
  patientId: number,
) => {
  const orderPath = orderCreateApiMap[orderType];
  return orderPath.replace(':patientId', patientId.toString());
};

export const isLabOrderDue = (order: LabOrder): boolean => {
  let dueDate: Date;
  const today = new Date();

  if (
    !(<LabOrder>order).scheduledDate ||
    order.status === 'completed' ||
    order.status === 'redacted'
  ) {
    return false;
  }
  dueDate = new Date((<LabOrder>order).scheduledDate);

  return dueDate <= today && order.status === 'signed';
};

export const isProcedureOrderDue = (order: ProcedureOrder): boolean => {
  let dueDate: Date;
  const today = new Date();
  if (order.status === 'signed') {
    return true;
  }
  if (
    !(<ProcedureOrder>order).dueDate ||
    order.status === 'completed' ||
    order.status === 'redacted' ||
    order.status === 'reviewed'
  ) {
    return false;
  }
  dueDate = new Date((<ProcedureOrder>order).dueDate);

  return dueDate <= today;
};

export const isVaccineOrderDue = (order: VaccineOrderListItem): boolean => {
  let dueDate: Date;
  const today = new Date();
  if (!(<VaccineOrderListItem>order).dateDue || order.isActive === false) {
    return false;
  }
  dueDate = new Date((<VaccineOrderListItem>order).dateDue);

  return dueDate <= today && order.status === 'signed';
};
