import { AfterContentInit, EventEmitter, } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
var TabComponent = /** @class */ (function () {
    function TabComponent(routerLink, routerLinkActive) {
        this.routerLink = routerLink;
        this.routerLinkActive = routerLinkActive;
        this.key = null;
        this.heading = '';
        this.badgeCount = 0;
        this.isActiveChange = new EventEmitter();
        this._isActive = false;
        this._initialized = false;
    }
    Object.defineProperty(TabComponent.prototype, "isActive", {
        get: function () {
            return this.routerLinkActive
                ? this.routerLinkActive.isActive
                : this._isActive;
        },
        set: function (value) {
            if (this._isActive !== value) {
                this._isActive = value;
                this.isActiveChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "route", {
        get: function () {
            return this.routerLink ? this.routerLink.urlTree.toString() : null;
        },
        enumerable: true,
        configurable: true
    });
    TabComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (!this._initialized) {
            Promise.resolve().then(function () {
                _this.isActiveChange.emit(_this.isActive);
            });
            this._initialized = true;
        }
    };
    return TabComponent;
}());
export { TabComponent };
