import { createSelector } from '@ngrx/store';

import { PatientHealthMaintenanceNote } from '@app/core';
import { selectHealthMaintenanceNote } from '@app/core/patient/store/patient.selectors';
import { pluckEntitiesByIds } from '@app/utils/store';

import {
  HealthGoalsSectionLinkPayload,
  HealthGoalWithScreenings,
} from '../shared/health-maintenance.type';

import {
  selectAllHealthGoalScreenings,
  selectHealthGoalScreeningEntities,
} from './health-goal-screening.selectors';
import {
  selectAllHealthGoals,
  selectHealthGoalById,
} from './health-goal.selectors';

// selects a health goal with screenings
export const selectHealthGoalWithScreenings = createSelector(
  selectHealthGoalById,
  selectHealthGoalScreeningEntities,
  (healthGoal, screenings): HealthGoalWithScreenings => ({
    ...healthGoal,
    screeningHistory: pluckEntitiesByIds(
      screenings,
      healthGoal.screeningHistoryIds,
    ),
  }),
);

// selects all the health maintenance data
export const selectHealthGoalsSectionLinkPayload = createSelector(
  selectAllHealthGoals,
  selectAllHealthGoalScreenings,
  selectHealthMaintenanceNote,
  // required for legacy data only
  selectHealthGoalScreeningEntities,
  (
    healthGoals,
    screenings,
    healthMaintenanceNote = {} as PatientHealthMaintenanceNote,
    screeningEntities = {},
  ): HealthGoalsSectionLinkPayload => {
    const legacyData = {
      healthGoals: healthGoals.map(healthGoal => ({
        ...healthGoal,
        screeningHistory: pluckEntitiesByIds(
          screeningEntities,
          healthGoal.screeningHistoryIds,
        ),
      })),
      healthMaintenanceNote,
    };

    return {
      healthGoalHistoryIds: healthGoals.map(i => i.healthGoalHistoryId),
      healthGoalScreeningHistoryIds: screenings.map(i => i.screeningHistoryId),
      healthMaintenanceNoteHistoryId:
        healthMaintenanceNote.healthMaintenanceNoteHistoryId,
      legacyData,
    };
  },
);
