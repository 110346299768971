<omg-collapse omgCollapse
              #collapseRef="omgCollapse">

  <div om-layout
       omgCollapseToggle>
    <div om-flex>
      <div>
        <strong>{{ allergyItem.allergy.name }}</strong>
      </div>
      {{ reactionText }}
    </div>
    <div *ngIf="notCheckedWarning"
         [tooltipText]="notCheckedWarning"
         tooltipPosition="top"
         omgTooltip>
      <span class="om-label">Drug-Allergy Incompatible</span>
    </div>
    <div *ngIf="allergyItem.active"
         data-qaid="resolve-allergy-button"
         (click)="activateAllergy(false)"
         omgStopEvent="click"
         omgTooltip
         tooltipText="Resolve Allergy"
         tooltipPosition="top">
      <span class="om-icon icon-dismiss -gray clickable"></span>
    </div>
    <div *ngIf="!allergyItem.active"
         (click)="activateAllergy(true)"
         omgStopEvent="click"
         data-qaid="reactivate-allergy-button"
         tooltipPosition="top"
         omgTooltip
         tooltipText="Reactivate Allergy">
      <span class="om-icon fa fa-undo clickable"></span>
    </div>
  </div>
  <omg-expanded>
    <omg-allergy-form class="-subform"
                      [allergyItem]="allergyItem"
                      (close)="onCancel()"
                      (update)="onUpdate($event)"
                      (delete)="onDelete($event)"
                      (reactionModelChange)="updateReaction($event)">
    </omg-allergy-form>
  </omg-expanded>

</omg-collapse>
