import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { FeatureFlagSelectors, PatientSelectors } from '@app/core';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { newEntityId } from '@app/modules/health-maintenance/shared/health-maintenance.type';
import { CollapseDirective } from '@app/shared';
import { waitFor } from '@app/utils';

import { VaccinationsService } from '../../shared';
import {
  GroupedVaccines,
  VaccineForm,
  VaccineHistory,
} from '../../shared/vaccinations.type';
import {
  LoadAllVaccineHistories,
  SaveVaccineHistory,
} from '../../store/vaccinations.actions';
import { VaccinationsState } from '../../store/vaccinations.reducer';
import {
  selectAllGroupedVaccineHistories,
  selectAllVaccineHistories,
  selectEntityMetadata,
  selectHasVaccineHistories,
} from '../../store/vaccinations.selectors';

@Component({
  selector: 'omg-vaccinations',
  styleUrls: ['./vaccinations.component.scss'],
  templateUrl: './vaccinations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaccinationsComponent implements OnInit, OnDestroy {
  hasVaccineHistories$: Observable<boolean>;
  vaccineHistories$: Observable<VaccineHistory[]>;
  groupedVaccines$: Observable<GroupedVaccines>;
  patientId$: Observable<number>;
  sectionsLinkable: Observable<boolean>;
  isSummaryActive: Observable<boolean>;
  vaccinePrintHref: string;
  groupingEnabled$: Observable<boolean>;

  private unsubscribe = new Subject();

  @ViewChild(CollapseDirective)
  private vaccineFormCollapse: CollapseDirective;

  constructor(
    private store: Store<VaccinationsState>,
    private patientSelectors: PatientSelectors,
    private summariesSelectors: SummariesSelectors,
    private vaccinationsService: VaccinationsService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.patientSelectors.patientId
      .pipe(
        filter(Boolean),
        takeUntil(this.unsubscribe),
      )
      .subscribe(id => {
        this.vaccinePrintHref = `/vaccines/print?patient_id=${id}`;
        this.loadAllVaccineHistories();
      });
    this.setupSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onLinkSection() {
    this.vaccinationsService.addToNote();
  }

  trackByFn(index, vaccination: VaccineHistory) {
    return vaccination.id || index;
  }

  onSave(form: VaccineForm) {
    this.store.dispatch(
      new SaveVaccineHistory({
        vaccineHistory: form,
      }),
    );

    waitFor(
      this.store.pipe(select(selectEntityMetadata, { id: newEntityId })),
      metadata => metadata && (!metadata.pending && !metadata.error),
    ).subscribe(() => {
      this.vaccineFormCollapse.collapse();
    });
  }

  onCancel() {
    this.vaccineFormCollapse.collapse();
  }

  private loadAllVaccineHistories() {
    this.store.dispatch(new LoadAllVaccineHistories());
  }

  private setupSelectors() {
    this.patientId$ = this.patientSelectors.patientId;
    this.isSummaryActive = this.summariesSelectors.hasActiveSummary;
    this.hasVaccineHistories$ = this.store.pipe(
      select(selectHasVaccineHistories),
    );
    this.vaccineHistories$ = this.store.pipe(select(selectAllVaccineHistories));

    this.groupedVaccines$ = this.store.pipe(
      select(selectAllGroupedVaccineHistories),
    );

    this.groupingEnabled$ = this.featureFlagSelectors.featureEnabled(
      'new_1life_vaccine_history_nesting',
    );
  }
}
