var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ErrorHandlerService } from '../../errors/error-handler.service';
import { ProfileApiService } from '../shared/profile-api.service';
import { GET_PROFILE, GetProfileError, GetProfileSuccess, } from './profile.actions';
import { selectProfileId } from './profile.selectors';
var ProfileEffects = /** @class */ (function () {
    function ProfileEffects(action$, store, profileApiService, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.store = store;
        this.profileApiService = profileApiService;
        this.errorHandler = errorHandler;
        this.getProfile$ = this.action$.pipe(ofType(GET_PROFILE), withLatestFrom(this.store.pipe(select(selectProfileId))), filter(function (_a) {
            var action = _a[0], profileId = _a[1];
            return !profileId;
        }), switchMap(function () {
            return _this.profileApiService.get().pipe(map(function (data) { return new GetProfileSuccess(data); }), catchError(function (error) {
                return of(new GetProfileError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProfileEffects.prototype, "getProfile$", void 0);
    return ProfileEffects;
}());
export { ProfileEffects };
