var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { PatientSelectors } from '@app/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ErrorHandlerService } from '@app/core';
import { HealthGoalScreeningService } from '../shared/health-goal-screening.service';
import { AddHealthGoalScreeningError, AddHealthGoalScreeningSuccess, DeleteHealthGoalScreeningError, DeleteHealthGoalScreeningSuccess, HealthGoalScreeningActionTypes, UpdateHealthGoalScreeningError, UpdateHealthGoalScreeningSuccess, } from './health-goal-screening.actions';
var HealthGoalScreeningEffects = /** @class */ (function () {
    function HealthGoalScreeningEffects(actions$, service, patientSelectors, errorHandler) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.patientSelectors = patientSelectors;
        this.errorHandler = errorHandler;
        this.addHealthGoalScreening$ = this.actions$.pipe(ofType(HealthGoalScreeningActionTypes.AddHealthGoalScreening), withLatestFrom(this.patientSelectors.patientId), switchMap(function (_a) {
            var action = _a[0], patientId = _a[1];
            return _this.service.add(patientId, action.payload).pipe(map(function (screening) { return new AddHealthGoalScreeningSuccess(screening); }), catchError(function (err) {
                return of(new AddHealthGoalScreeningError(_this.errorHandler.handleErrorSafe(err), {
                    id: action.payload.id,
                }));
            }));
        }));
        this.updateHealthGoalScreening$ = this.actions$.pipe(ofType(HealthGoalScreeningActionTypes.UpdateHealthGoalScreening), switchMap(function (action) {
            return _this.service.update(action.payload).pipe(map(function (screening) { return new UpdateHealthGoalScreeningSuccess(screening); }), catchError(function (err) {
                return of(new UpdateHealthGoalScreeningError(_this.errorHandler.handleErrorSafe(err), {
                    id: action.payload.id,
                }));
            }));
        }));
        this.deleteHealthGoalScreening$ = this.actions$.pipe(ofType(HealthGoalScreeningActionTypes.DeleteHealthGoalScreening), switchMap(function (action) {
            return _this.service.delete(action.payload).pipe(map(function () {
                return new DeleteHealthGoalScreeningSuccess(action.payload, {
                    healthGoalId: action.meta.healthGoalId,
                });
            }), catchError(function (err) {
                return of(new DeleteHealthGoalScreeningError(_this.errorHandler.handleErrorSafe(err), { id: action.payload }));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalScreeningEffects.prototype, "addHealthGoalScreening$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalScreeningEffects.prototype, "updateHealthGoalScreening$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HealthGoalScreeningEffects.prototype, "deleteHealthGoalScreening$", void 0);
    return HealthGoalScreeningEffects;
}());
export { HealthGoalScreeningEffects };
