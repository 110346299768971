<form class="om-form"
      [formGroup]="form"
      om-layout="vertical"
      om-layout-gutter>

  <div class="padding-normal"
       om-layout-align="start start"
       om-layout="vertical"
       om-layout-gutter
       om-layout-fill>

    <!-- Health Goal Type / Declined -->
    <div om-layout
         om-layout-fill>
      <div om-flex="50">
        <label>
          Goal

          <!--
            Having both an auto-complete and dropdown because
            auto-complete are missing readonly functionality
            see issue:
            https://github.com/ng-select/ng-select/issues/786
          -->
          <omg-auto-complete *ngIf="isNewHealthGoal"
                             formControlName="healthGoalTypeId"
                             [fillLayout]="false"
                             [hideClearAll]="true"
                             [resetItemsOnClear]="false"
                             om-flex="95"
                             #healthGoalTypeInput
                             placeholder="Select a Health Goal"
                             [items]="goalTypeOptions.options"
                             [omgFocusOn]="focusOnKey"
                             bindValue="value"
                             bindLabel="label"
                             (focusOnRequest)="healthGoalTypeInput.focus()">
          </omg-auto-complete>
          <omg-dropdown *ngIf="!isNewHealthGoal"
                        formControlName="healthGoalTypeId"
                        om-flex="95"
                        #healthGoalTypeInput
                        [readonly]="true"
                        [options]="goalTypeOptions.options"
                        [omgFocusOn]="focusOnKey"
                        (focusOnRequest)="healthGoalTypeInput.focus()">
          </omg-dropdown>
        </label>
        <div class="alert"
             *ngIf="!form?.pristine && form?.get('healthGoalTypeId').hasError('apiError')">
          {{ form?.get('healthGoalTypeId').getError('apiError') }}
        </div>
      </div>
      <div om-flex="50"
           om-layout="vertical"
           om-layout-align="center start">
        <omg-checkbox label="Declined"
                      formControlName="declined">
        </omg-checkbox>
      </div>
    </div>

    <!-- Health Goal Actions / Due Date -->
    <div om-layout
         om-layout-fill
         om-layout-gutter>
      <div om-flex="50">
        <label om-layout="vertical">
          Action
          <omg-dropdown formControlName="healthGoalActionId"
                        om-flex="95"
                        [options]="goalTypeOptions.actionOptions">
          </omg-dropdown>
        </label>
      </div>
      <div om-flex>
        <label om-layout="vertical">
          Due Date
          <omg-date-picker formControlName="dueAt">
          </omg-date-picker>
        </label>
      </div>
    </div>

    <!-- Health Goal Comment / Note -->
    <div om-layout
         om-layout-fill
         om-layout-gutter>
      <div om-flex
           om-layout="vertical">
        <label om-layout="vertical">
          Notes
          <textarea omgTextarea
                    autosize
                    rows="2"
                    formControlName="comment"
                    om-layout-fill
                    class="block -with-updated"></textarea>
        </label>
        <span *ngIf="editedBy"
              class="caption">
          Last modified by: {{ editedBy }} at {{ updatedAt | omgDate:'withTime2y' }}
        </span>
      </div>
    </div>

    <!-- Screening History -->
    <div om-layout="vertical"
         om-layout-gutter
         om-layout-fill
         *ngIf="form?.get('healthGoalTypeId').value">
      <omg-screening-history [healthGoalId]="form?.get('id').value"
                             [healthGoalType]="goalTypeOptions.selectedGoalType"
                             [healthGoalActionId]="form?.get('healthGoalActionId').value"
                             [screeningHistory]="screeningHistory">
      </omg-screening-history>
    </div>

    <!-- Tips / Decision support -->
    <div *ngIf="form?.get('healthGoalTypeId').value">
      <omg-health-goal-tips [healthGoalType]="goalTypeOptions.selectedGoalType">
      </omg-health-goal-tips>
    </div>
  </div>

  <!-- Action Bar -->
  <div class="action-bar"
       om-layout
       om-layout-gutter
       om-layout-fill>
    <button type="submit"
            omgButton
            variant="primary"
            [disabled]="form?.invalid || form?.pristine"
            (click)="onSave()">
      {{ isNewHealthGoal ? 'Add' : 'Update' }} Health Goal
    </button>
    <button omgButton
            variant="flat"
            (click)="onCancel()">
      Cancel
    </button>
    <button omgButton
            variant="critical-link"
            (click)="onDelete()"
            *ngIf="!isNewHealthGoal">
      Delete
    </button>
  </div>
</form>
