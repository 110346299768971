import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';
import { catchHttpError422 } from '@app/utils';

import { HealthGoalScreeningForm } from '../shared/health-maintenance.type';
import { HealthGoalScreeningResponse } from './health-maintenance-api.type';

import {
  mapHealthGoalScreeningResponse,
  mapHealthGoalScreeningSaveErrorResponse,
  mapHealthGoalScreeningToSaveRequest,
} from './health-maintenance-api-mappers';

const screeningEndpoint = '/v2/admin/health_goal_screenings';

@Injectable()
export class HealthGoalScreeningService {
  constructor(private api: ApiService) {}

  add(patientId: number, screening: HealthGoalScreeningForm) {
    return this.api
      .save<HealthGoalScreeningResponse>(
        patientRoute(patientId, '/health_goal_screenings'),
        mapHealthGoalScreeningToSaveRequest(screening),
      )
      .pipe(
        map(mapHealthGoalScreeningResponse),
        catchHttpError422((error: HttpErrorResponse) =>
          throwError(mapHealthGoalScreeningSaveErrorResponse(error.error)),
        ),
      );
  }

  update(screening: HealthGoalScreeningForm) {
    return this.api
      .update<HealthGoalScreeningResponse>(
        `${screeningEndpoint}/${screening.id}`,
        mapHealthGoalScreeningToSaveRequest(screening),
      )
      .pipe(
        map(mapHealthGoalScreeningResponse),
        catchHttpError422((error: HttpErrorResponse) =>
          throwError(mapHealthGoalScreeningSaveErrorResponse(error.error)),
        ),
      );
  }

  delete(screeningId) {
    return this.api
      .delete(`${screeningEndpoint}/${screeningId}`)
      .pipe(
        catchHttpError422((error: HttpErrorResponse) =>
          throwError(mapHealthGoalScreeningSaveErrorResponse(error.error)),
        ),
      );
  }
}
