// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
// if (environment.production) {
//   enableProdMode();
// }
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.error(err));
// ----------------------------------------------------------------------------
// Hybrid downgradeModule hybrid mode
//   Upgrading performance optimization for hybrid app
//   https://angular.io/guide/upgrade-performance
//
// Once migration is complete this can be removed and replaced with the code
// commented out above.
// ----------------------------------------------------------------------------
import { enableProdMode } from '@angular/core';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { downgradeComponent, downgradeModule } from '@angular/upgrade/static';
import { upgradeRouteResolver, upgradeStates, } from '@app/upgrade/upgraded/upgraded-routing';
import { angularJsModuleName } from './app/upgrade/shared/constants';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
// The Angular boostrap function
var bootstrapAngular = function (extraProviders) {
    return __NgCli_bootstrap_2.platformBrowser(extraProviders).bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
};
var ɵ0 = bootstrapAngular;
// The AngularJS app module
var hybridAppModuleName = 'onelife-ui-hybrid';
var angularJsAppModule = angular.module(angularJsModuleName);
var downgradedAppModule = downgradeModule(bootstrapAngular);
// Create a hybrid app module
var hybridAppModule = angular.module(hybridAppModuleName, [
    angularJsAppModule.name,
    downgradedAppModule,
]);
// Downgrade the app component
hybridAppModule.directive('omgRoot', downgradeComponent({ component: AppComponent, propagateDigest: false }));
// Handles transitions between app.xxx - states and upgrade.xxx - states
angular
    .module(hybridAppModuleName)
    .run(upgradeRouteResolver)
    .config(upgradeStates);
// Boostrap the hybrid app module
angular.bootstrap(document.body, [hybridAppModule.name]);
export { ɵ0 };
