import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FocusService {
  private _subject = new Subject<string>();
  private _focusChanges = this._subject.asObservable();

  constructor() {}

  get focusChanges() {
    return this._focusChanges;
  }

  setFocus(key: string) {
    /* Workaround to ensure *ngIf has rendered the element and is able to utilize
     * the omgFocusOn directive properly.
     */
    setTimeout(() => this._subject.next(key));
  }
}
