import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { formatDate } from '@app/utils';

import { MeasurementTypeResponseItem } from '../shared/measurement-types-response.type';
import { MeasurementTypesService } from '../shared/measurement-types.service';
import { VitalsDataService } from '../shared/vitals-data.service';
import {
  VitalsDataItem,
  VitalsDataMeasurement,
} from '../shared/vitals-data.type';

interface MeasurementType {
  abbreviation: string;
  vitalMeasurements: {
    metadata: string;
    vital: VitalsDataMeasurement;
  }[];
}

interface VitalsData {
  hasData: boolean;
  measurementTypes: MeasurementType[];
  headers: string[];
}

@Component({
  selector: 'omg-vitals-data',
  templateUrl: './vitals-data.component.html',
  styleUrls: ['./vitals-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VitalsDataComponent implements OnInit {
  vitalsData: Observable<VitalsData>;

  constructor(
    private vitalsDataService: VitalsDataService,
    private measurementTypesService: MeasurementTypesService,
    private patient: PatientSelectors,
  ) {}

  ngOnInit() {
    this.vitalsData = this.patient.patientId.pipe(
      filter(Boolean),
      mergeMap(patientId =>
        combineLatest(
          this.vitalsDataService.query(patientId),
          this.measurementTypesService.getAll(),
        ),
      ),
      map(
        ([vitalsData, measurementTypes]: [
          VitalsDataItem[],
          MeasurementTypeResponseItem[]
        ]) => this.mapToMeasurementTypes(measurementTypes, vitalsData),
      ),
    );
  }

  /* istanbul ignore next */
  mapToMeasurementTypes(
    measurementTypes: MeasurementTypeResponseItem[],
    vitalsData: VitalsDataItem[],
  ) {
    const rows = measurementTypes.map(
      (measurementType: MeasurementTypeResponseItem) => ({
        abbreviation: measurementType.abbreviation,
        vitalMeasurements: vitalsData.map((vital: VitalsDataItem) => ({
          metadata: this.vitalMetadata(vital),
          vital: vital.measurements.find(
            measurement =>
              measurement.measurementType.id === measurementType.id,
          ),
        })),
      }),
    );
    const headers = vitalsData.map(vital => vital.collectedAt);

    return {
      hasData: vitalsData.length > 0,
      measurementTypes: rows,
      headers,
    };
  }

  vitalMetadata(vital) {
    let metadata = '';
    const collectedAt = formatDate(vital.collectedAt, 'hh:mm a');
    if (vital.internalUser) {
      metadata += `recorded by ${vital.internalUser} at ${collectedAt}`;
    } else if (vital.withings) {
      metadata += `via Withings device at ${collectedAt}`;
    }
    if (vital.comment) {
      metadata += `\n\nComment: "${vital.comment}"`;
    }
    return metadata;
  }

  trackByIndex(index, item) {
    return index;
  }
}
