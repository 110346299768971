// NOTE: These are global config objects that get injected
// - During build time via Webpack's DefinePlugin
// - During test via Jest global test setup
const envConfig = ENV_CONFIG;
const codeVersion = CODE_VERSION;

// Extract settings from global env config
const appSettings = envConfig.app;

const defaultPollingFrequency = 10000;
const versionChecker = appSettings.versionChecker || {};
const versionCheckerEnabled = !!versionChecker.targetUrl;
const rollbarConfig = appSettings.rollbar || {};

// Strongly typed application enivronment settings
export const environmentConfig = {
  codeVersion: <string>codeVersion,
  versionChecker: {
    enabled: <boolean>versionCheckerEnabled,
    targetUrl: <string>versionChecker.targetUrl,
    pollingFrequency: <number>(
      (versionChecker.pollingFequency || defaultPollingFrequency)
    ),
  },
  awsBucket: <string>appSettings.awsBucket,
  mixPanel: {
    token: <string>appSettings.mixpanelToken,
    application: 'onelife-ui',
  },
  api: { baseUrl: <string>appSettings.api.baseUrl },
  search: {
    host: <string>appSettings.search.host,
    indexes: {
      allergies: <string>appSettings.search.indexes.allergies,
      filing_templates: <string>appSettings.search.indexes.filing_templates,
      lab_order_types: <string>appSettings.search.indexes.lab_order_types,
      health_goal_types: <string>appSettings.search.indexes.health_goal_types,
      medication_routes: <string>appSettings.search.indexes.medication_routes,
      message_templates: <string>appSettings.search.indexes.message_templates,
      note_templates: <string>appSettings.search.indexes.note_templates,
      problem_codes: <string>appSettings.search.indexes.problem_codes,
      problem_types: <string>appSettings.search.indexes.problem_types,
      task_assignees: <string>appSettings.search.indexes.task_assignees,
      vaccines: <string>appSettings.search.indexes.vaccines,
    },
  },
  adminApp: { host: <string>appSettings.adminApp.host },
  legacyUi: {
    host: <string>appSettings.legacyUi.host,
  },
  measurementsUi: {
    host: <string>appSettings.measurementsUi.host || '',
    version: <string>appSettings.measurementsUi.version || '',
  },
  oauth2: {
    providerUrl: <string>appSettings.oauth2.providerUrl,
    clientId: <string>appSettings.oauth2.clientId,
  },
  googleMaps: <string>appSettings.googleMaps,
  pusher: {
    pusher_key: <string>appSettings.pusher.pusher_key,
    pusher_encrypted: <boolean>appSettings.pusher.pusher_encrypted,
  },
  rollbar: {
    enabled: rollbarConfig.enabled || false,
    accessToken: rollbarConfig.accessToken,
    serverAccessToken: rollbarConfig.serverAccessToken,
    captureUncaught: rollbarConfig.captureUncaught || true,
    autoInstrument: rollbarConfig.autoInstrument || true,
    captureUnhandledRejections:
      rollbarConfig.captureUnhandledRejections || true,
    publicPath: rollbarConfig.publicPath,
    payload: {
      environment: rollbarConfig.payload && rollbarConfig.payload.environment,
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          ...(CODE_VERSION ? { code_version: CODE_VERSION } : {}),
        },
      },
    },
  },
};
