<form name="form"
      om-layout="vertical"
      om-layout-align="start start"
      om-layout-gutter
      [ngClass]="{'sub-form': problem && problem?.active}"
      [formGroup]="form">
  <div class="padding-normal"
       om-layout="vertical"
       om-layout-fill
       *ngIf="form.get('isCustom').value">
    <div class="label"
         om-layout
         om-layout-align="space-between">
      Custom Problem
      <a (click)="toggleCustomProblem()">
        Standard Problem</a>
    </div>
    <input omgInputText
           type="text"
           formControlName="customProblemDescription"
           (change)="onCustomDescriptionChange()" />
    <div class="alert"
         *ngIf="form.get('customProblemDescription').hasError('required') && attemptedSubmit">Cannot
      be Blank</div>

    <label om-layout-fill>
      Problem Code
      <omg-auto-complete placeholder="Search by ICD-10 or description..."
                         trackByKey="code"
                         bindLabel="label"
                         bindValue="code"
                         formControlName="problemCode"
                         [searchFn]="problemCodeSearchFn"
                         [items]="(problemCodes | async)"
                         [hideDropdownArrow]="true"
                         [hideClearAll]="true"
                         (search)="searchProblemCodes($event)"
                         (change)="onProblemCodeChange($event)"></omg-auto-complete>
    </label>
    <div class="alert"
         *ngIf="form.get('problemCode').hasError('required') && attemptedSubmit">Cannot
      be blank</div>
  </div>

  <div class="padding-normal"
       om-layout="vertical"
       om-layout-fill
       om-layout-gutter-mini
       *ngIf="!form.get('isCustom').value">
    <div class="label"
         om-layout
         om-layout-align="space-between">
      Problem
      <a (click)="toggleCustomProblem()"
         angulartics2On="click"
         angularticsAction="Custom Problem Clicked"
         [angularticsProperties]="{workflow: 'Charting', component: 'Problems', subComponent: 'Custom Problem Button', subWorkflowId: addProblemSessionId}">
        Custom Problem
      </a>
    </div>
    <omg-auto-complete placeholder="Find problem..."
                       data-qaid="problem-input-field"
                       trackByKey="id"
                       bindLabel="clinicalDescription"
                       bindValue="id"
                       formControlName="problemId"
                       #problemField
                       (opened)="clearProblemId()"
                       (blur)="restoreProblemId()"
                       omgStopEvent="click"
                       [omgFocusOn]="'problemFocus-' + (form.get('id').value || 'create')"
                       [items]="items$ | async"
                       hideDropdownArrow="true"
                       hideClearAll="true"
                       [searchFn]="searchFn"
                       (search)="searchProblems($event)"
                       (change)="onProblemChange($event)"
                       (focusOnRequest)="problemField.focus()"
                       resetItemsOnClear="true">
      <ng-template let-optionData="optionData">
        {{ optionData['label'] }}
      </ng-template>

    </omg-auto-complete>
    <div class="alert"
         *ngIf="form.get('problemId').hasError('required') && attemptedSubmit">Cannot be blank</div>

    <div om-layout="vertical"
         om-layout-fill
         om-layout-gutter-mini
         *ngIf="form.get('locationId').enabled && (locations | async)?.length > 0">
      <div class="label">Location</div>
      <omg-auto-complete placeholder="Select location"
                         om-layout-fill
                         omgStopEvent="click"
                         formControlName="locationId"
                         bindLabel="name"
                         bindValue="id"
                         trackByKey="id"
                         [items]="locations | async"
                         [hideDropdownArrow]="true"
                         [hideClearAll]="true"
                         (change)="trackProblemFieldEvent('Problem Location Field')"></omg-auto-complete>
      <div class="alert"
           *ngIf="form.get('locationId').hasError('required') && attemptedSubmit">Cannot be blank</div>
    </div>
  </div>


  <div om-layout
       om-layout-fill
       om-layout-gutter
       class="-immediate-errors padding-normal-horizontal">
    <div om-flex="50">
      <label om-layout="vertical">
        Date of Onset
        <input type="text"
               omgInputText
               formControlName="onset"
               placeholder="ex: 5/1902"
               data-qaid="date-of-onset"
               (change)="trackProblemFieldEvent('Date of Onset Field')" />
      </label>
      <div class="alert"
           *ngIf="form.get('onset').hasError('isNumeric')">Must only contain numbers and slashes</div>
      <div class="alert"
           *ngIf="form.get('onset').hasError('maxlength')">Should be less than 10 characters</div>
    </div>

    <div om-flex>
      <label om-layout="vertical">
        Date Of Resolution
        <input omgInputText
               type="text"
               name="resolution"
               formControlName="resolution"
               omgStopEvent="click"
               placeholder="ex: 5/1902"
               data-qaid="date-of-resolution"
               (change)="trackProblemFieldEvent('Date of Resolution Field')" />
      </label>
      <div class="alert"
           *ngIf="form.get('resolution').hasError('isNumeric')">Must only contain numbers and slashes</div>
      <div class="alert"
           *ngIf="form.get('resolution').hasError('maxlength')">Should be less than 10 characters</div>
    </div>
  </div>

  <label om-layout-fill
         class="padding-normal-horizontal">
    Brief Comment
    <input omgInputText
           type="text"
           maxlength="32"
           om-layout-fill
           omgStopEvent="click"
           formControlName="briefComment"
           data-qaid="brief-problem-comments"
           (change)="trackProblemFieldEvent('Brief Comment Field')" />
  </label>

  <label om-layout-fill
         class="padding-normal-horizontal">Problem Summary
    <textarea omgTextarea
              autosize
              rows="2"
              om-layout-fill
              omgStopEvent="click"
              placeholder="Historical info about this problem"
              formControlName="summary"
              (change)="trackProblemFieldEvent('Problem Summary Field')"></textarea>
  </label>

  <label om-layout-fill
         class="padding-normal-horizontal">
    Today's Assessment & Plan
    <textarea omgTextarea
              autosize
              rows="2"
              om-layout-fill
              omgStopEvent="click"
              formControlName="assessmentAndPlan"
              data-qaid="problem-assessment-plan"
              (change)="trackProblemFieldEvent('Today\'s Assessment & Plan Field')"></textarea>
  </label>

  <div *ngIf="hasInternalOrders$ | async"
       class="padding-normal-horizontal perform-procedure-link">
    <button omgButton
            type="button"
            variant="link"
            (click)="createNewProcedureOrder()">
      Perform Procedure
    </button>
  </div>

  <!-- Decision Support (Tips) -->
  <omg-collapse class="padding-normal"
                *ngIf="decisionSupport"
                omgCollapse
                #decisionSupportCollapse="omgCollapse">
    <button omgButton
            variant="link"
            angulartics2On="click"
            angularticsAction="Problem Decision Support Expanded"
            *ngIf="!decisionSupportCollapse.expanded"
            [angularticsProperties]="{problemType: clinicalDescription}"
            (click)="decisionSupportCollapse.expand()">
      <i class="fa fa-chevron-down"></i>
      Show Tips for {{ clinicalDescription }}
    </button>
    <button omgButton
            variant="link"
            angulartics2On="click"
            angularticsAction="Problem Decision Support Collapsed"
            *ngIf="decisionSupportCollapse.expanded"
            [angularticsProperties]="{problemType: clinicalDescription}"
            (click)="decisionSupportCollapse.collapse()">
      <i class="fa fa-chevron-up"></i>
      Hide Tips for {{ clinicalDescription }}
    </button>

    <div markdown
         *ngIf="decisionSupportCollapse.expanded"
         [data]="decisionSupport"></div>
  </omg-collapse>

  <div *ngIf="!decisionSupport"
       class="padding-normal-horizontal">
    <a href="https://docs.google.com/forms/d/1tI7htYg_ncVf5Cp_RLCtx-ZEyMT5XsAk8xOHE64KS-0/edit?ts=5cfe9b7e"
       target="_blank">
      Request a Tip for this problem
    </a>
  </div>

  <div class="padding-normal-horizontal"
       om-layout-fill
       om-layout="vertical">
    <div class="om-toggle-switch"
         *ngIf="(!form.get('isCustom').value && form.get('problemId').value) || form.get('customProblemDescription').value">
      <label [for]="'problem' + form.get('id').value + '-medical-history-toggle'">Include In Medical History</label>
      <input [id]="'problem' + form.get('id').value + '-medical-history-toggle'"
             type="checkbox"
             formControlName="includedInMedicalHistory"
             #medicalHistoryCheckbox />
      <div class="toggle"
           (click)="medicalHistoryCheckbox.click()"></div>
    </div>

    <div *ngIf="autoCheckInCreationEnabled && notificationText"
         class="padding-normal-vertical">
      <div class="auto-create-toggle">
        <div class="auto-create-message">
          <input [id]="'problem' + form.get('id').value + '-basic-follow-up-toggle'"
                 type="checkbox"
                 formControlName="basicFollowUpOrder" />
          Create a follow-up order
        </div>
        <div class="emphasized auto-create-description">{{ notificationText }}</div>
      </div>
    </div>

    <omg-problem-history *ngIf="showHistory"
                         [history]="history | async"
                         [editedBy]="problem?.editedBy"
                         [updatedAt]="problem?.updatedAt"
                         [historyStartDate]="problem?.historyStartDate"
                         [aAndP]="problem?.aAndP"></omg-problem-history>
  </div>

  <div class="action-bar"
       om-layout
       om-layout-gutter
       om-layout-fill>
    <button type="submit"
            omgButton
            variant="primary"
            [disabled]="form.pristine"
            (click)="saveProblem()"
            data-qaid="save-problem-button">Save</button>
    <button type="button"
            omgButton
            variant="flat"
            (click)="closeForm()"
            data-qaid="cancel-save-problem-hist-card">Cancel</button>
    <div omgTooltip
         [tooltipText]="problem?.actions?.delete?.reason"
         [showTooltip]="!problem?.actions?.delete?.allowed"
         tooltipPosition="bottom">
      <button omgButton
              variant="critical-link"
              (click)="onDelete()"
              data-qaid="delete-problem-button"
              [disabled]="!problem?.actions?.delete?.allowed">Delete Problem</button>
    </div>
  </div>
</form>
