var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultTo, isEqual } from 'lodash/fp';
import { of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap, } from 'rxjs/operators';
export var FormModelStateValues = [
    'pristine',
    'savePending',
    'saving',
    'saveError',
    'saved',
];
/**
 * FormModel
 *
 * The model wraps a FormGroup and provides an abstration layer to support
 * complex workflows such as autosave, cancel, etc.
 *
 * NOTE: This is implemented as a simple finite state machine.
 *
 */
var FormModel = /** @class */ (function () {
    function FormModel(form, options) {
        if (options === void 0) { options = {}; }
        this.autosave = true;
        this.autosaveDelay = 300;
        this.saveInvalid = false;
        this.resetOnSave = false;
        this._ignoreValueChange = false;
        this._stateSubject = new ReplaySubject(1);
        this._stateChanges = this._stateSubject.asObservable();
        this.configure(__assign({}, options, { form: form }));
        this.markAsPristine();
    }
    FormModel.prototype.dispose = function () {
        this.unsubscribeFromFormChanges();
    };
    FormModel.prototype.configure = function (options) {
        if (options === void 0) { options = {}; }
        this.unsubscribeFromFormChanges();
        this._form = defaultTo(this._form, options.form);
        this.autosave = defaultTo(this.autosave, options.autosave);
        this.autosaveDelay = defaultTo(this.autosaveDelay, options.autosaveDelay);
        this.mapSaveError = defaultTo(this.mapSaveError, options.mapSaveError);
        this.saveInvalid = defaultTo(this.saveInvalid, options.saveInvalid);
        this.saveFunction = defaultTo(this.saveFunction, options.saveFunction);
        this.resetOnSave = defaultTo(this.resetOnSave, options.resetOnSave);
        if (!this._form) {
            throw Error('FormGroup is required');
        }
        this.subscribeToFormChanges();
    };
    Object.defineProperty(FormModel.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    FormModel.prototype.get = function (path) {
        return this.form.get(path);
    };
    Object.defineProperty(FormModel.prototype, "value", {
        get: function () {
            return this.form.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormModel.prototype, "state", {
        get: function () {
            return this._currentState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormModel.prototype, "stateChanges", {
        get: function () {
            return this._stateChanges;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormModel.prototype, "saveError", {
        get: function () {
            return this._lastSaveError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormModel.prototype, "saving", {
        get: function () {
            return (this._currentState === 'saving' || this._currentState === 'savePending');
        },
        enumerable: true,
        configurable: true
    });
    FormModel.prototype.setValue = function (value) {
        var _this = this;
        this.runOperation(function () { return _this._form.setValue(value); });
    };
    FormModel.prototype.patchValue = function (value) {
        var _this = this;
        this.runOperation(function () { return _this._form.patchValue(value); });
    };
    FormModel.prototype.markAsPristine = function () {
        var _this = this;
        this.runOperation(function () {
            _this.setState('pristine');
        });
    };
    FormModel.prototype.reset = function () {
        var _this = this;
        this.runOperation(function () {
            _this._form.reset();
            _this.setState('pristine');
        });
    };
    FormModel.prototype.cancel = function () {
        var _this = this;
        this.runOperation(function () {
            _this._form.setValue(_this._initialValue);
            _this.setState('pristine');
        });
    };
    FormModel.prototype.save = function (subscribe) {
        if (subscribe === void 0) { subscribe = true; }
        if (!subscribe) {
            return this.saveFormValue(this.form.value);
        }
        // We assume that the save function is a cold observable
        this.saveFormValue(this.form.value).subscribe();
    };
    FormModel.prototype.setState = function (state, value, error) {
        if (value === void 0) { value = null; }
        if (error === void 0) { error = null; }
        value = value || this.form.value;
        if (state === 'pristine') {
            this._initialValue = value;
            this._lastSaveError = null;
            this._form.markAsPristine();
        }
        else if (state === 'saving') {
            this._lastSaveValue = value;
            this._lastSaveError = null;
        }
        else if (state === 'saved') {
            this._lastSaveValue = value;
            this._lastSaveError = null;
        }
        else if (state === 'saveError') {
            this._lastSaveValue = value;
            this._lastSaveError = error;
        }
        if (this._currentState !== state) {
            this._currentState = state;
            this._stateSubject.next({ state: state, value: value, error: error });
        }
    };
    FormModel.prototype.canSave = function (value) {
        var defaultSavePredicate = function () { return true; };
        var savePredicate = this.savePredicate || defaultSavePredicate;
        return (savePredicate(value) &&
            this.autosave &&
            (this.saveInvalid ? true : this._form.valid) &&
            this._currentState !== 'saving' &&
            !isEqual(value, this._lastSaveValue));
    };
    FormModel.prototype.saveFormValue = function (value) {
        var _this = this;
        if (!this.saveFunction) {
            throw Error('Unable to save without saveFunction');
        }
        this.setState('saving', value);
        var defaultMapError = function (e) { return e; };
        var mapError = this.mapSaveError || defaultMapError;
        return this.saveFunction(value).pipe(catchError(function (error) {
            _this.setState('saveError', value, mapError(error));
            return of(error);
        }), tap(function (result) {
            if (_this._currentState !== 'saveError') {
                _this.setState('saved', result);
                if (_this.resetOnSave) {
                    _this.reset();
                }
            }
        }));
    };
    FormModel.prototype.subscribeToFormChanges = function () {
        var _this = this;
        this._subscription = this.form.valueChanges
            .pipe(filter(function () { return !_this._ignoreValueChange; }), tap(function (value) { return _this.setState('savePending', value); }), debounceTime(this.autosaveDelay), filter(function (value) { return _this.canSave(value); }), switchMap(function (value) { return _this.saveFormValue(value); }))
            .subscribe();
    };
    FormModel.prototype.unsubscribeFromFormChanges = function () {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    };
    FormModel.prototype.runOperation = function (operation, allowSave) {
        if (allowSave === void 0) { allowSave = false; }
        if (allowSave) {
            operation();
        }
        else {
            this._ignoreValueChange = this._ignoreValueChange || true;
            operation();
            this._ignoreValueChange = !this._ignoreValueChange || false;
        }
    };
    return FormModel;
}());
export { FormModel };
