/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./unauthorized.component";
var styles_UnauthorizedComponent = [];
var RenderType_UnauthorizedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "om-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Authorization Required"]))], null, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "omg-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i0.ɵdid(1, 49152, null, 0, i1.UnauthorizedComponent, [], null, null)], null, null); }
var UnauthorizedComponentNgFactory = i0.ɵccf("omg-unauthorized", i1.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
