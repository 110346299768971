import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SummariesStoreModule } from '@app/features/summaries/store/summaries-store.module';
import { TodoStoreModule } from '@app/modules/todo/store/todo-store.module';

import { AngularHightlightToggleComponent } from './components/angular-highlight-toggle.component';
import { EmptyComponent } from './components/empty.component';
import { UpgradeRouteLinkComponent } from './components/upgrade-route-link.component';

import { LegacyDataResolver } from './routing/legacy-data-resolver';
import { LegacyPatientResolver } from './routing/legacy-patient-resolver';
import { LegacyProfileResolver } from './routing/legacy-profile-resolver';

import { upgradeBridgeServices } from './services/upgrade-bridge-services';
import { upgradedProviders } from './upgraded/upgraded-providers';

// These register the required ngrx stores and dependencies are registered
// to ensure section linking works on the AngularJS side
const preloadedModule = [TodoStoreModule, SummariesStoreModule];

const components = [
  EmptyComponent,
  AngularHightlightToggleComponent,
  UpgradeRouteLinkComponent,
];

@NgModule({
  imports: [CommonModule, FormsModule, ...preloadedModule, RouterModule],
  declarations: [...components],
  exports: [...components],
  providers: [
    ...upgradedProviders,
    ...upgradeBridgeServices,
    LegacyDataResolver,
    LegacyProfileResolver,
    LegacyPatientResolver,
  ],
  entryComponents: [...components],
})
export class AppUpgradeModule {}
