import { OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { PatientActions, PatientSelectors } from '@app/core';
import { CollapseDirective } from '@app/shared/components/collapse';
import { waitFor } from '@app/utils';
import { buildNewHealthGoalForm } from '../../shared/health-maintenance-utils';
import { HealthMaintenanceService } from '../../shared/health-maintenance.service';
import { newEntityId, } from '../../shared/health-maintenance.type';
import { LoadHealthGoalTypes } from '../../store/health-goal-type.actions';
import { selectAllHealthGoalTypesSorted } from '../../store/health-goal-type.selectors';
import { AddHealthGoal, InitNewHealthGoal, LoadHealthGoals, } from '../../store/health-goal.actions';
import { selectAllHealthGoals, selectHealthGoalError, selectHealthGoalWithMetadata, selectIndicatedHealthGoals, selectNotIndicatedHealthGoals, } from '../../store/health-goal.selectors';
var HealthMaintenanceComponent = /** @class */ (function () {
    function HealthMaintenanceComponent(store, patientSelectors, patientActions, service) {
        this.store = store;
        this.patientSelectors = patientSelectors;
        this.patientActions = patientActions;
        this.service = service;
        this.unsubscribe = new Subject();
        this.trackByFn = function (index, value) { return (value && value.id) || index; };
    }
    HealthMaintenanceComponent.prototype.ngOnInit = function () {
        this.loadHealthGoalTypes();
        this.loadHealthGoals();
        this.loadHealthMaintenanceNote();
        this.setupSelectors();
    };
    HealthMaintenanceComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    Object.defineProperty(HealthMaintenanceComponent.prototype, "focusOnNewFormKey", {
        get: function () {
            return "healthGoalFocus-" + newEntityId;
        },
        enumerable: true,
        configurable: true
    });
    HealthMaintenanceComponent.prototype.onAddToNote = function () {
        this.service.addToNote();
    };
    HealthMaintenanceComponent.prototype.onNewHealthGoalFormExpanded = function (expanded) {
        if (expanded) {
            this.resetNewHealthGoalForm();
        }
    };
    HealthMaintenanceComponent.prototype.onSaveNewHealthGoal = function (value) {
        this.saveNewHealthGoal(value);
    };
    HealthMaintenanceComponent.prototype.onSaveNote = function (event) {
        this.saveHealthMaintenanceNote(event.value, event.complete);
    };
    HealthMaintenanceComponent.prototype.loadHealthGoalTypes = function () {
        this.store.dispatch(new LoadHealthGoalTypes());
        this.healthGoalTypes$ = this.store.pipe(select(selectAllHealthGoalTypesSorted));
    };
    HealthMaintenanceComponent.prototype.loadHealthGoals = function () {
        var _this = this;
        this.patientSelectors.patient
            .pipe(filter(function (p) { return !!p; }), tap(function (patient) {
            _this.patientId = patient.id;
            _this.store.dispatch(new LoadHealthGoals(patient.id));
        }), take(1), takeUntil(this.unsubscribe))
            .subscribe();
    };
    HealthMaintenanceComponent.prototype.loadHealthMaintenanceNote = function () {
        var _this = this;
        this.patientSelectors.healthMaintenanceNote
            .pipe(filter(Boolean), map(function (note) { return ({ content: note.content }); }), tap(function (value) { return (_this.healthMaintenanceNote = value); }), take(1))
            .subscribe();
    };
    HealthMaintenanceComponent.prototype.setupSelectors = function () {
        this.healthGoals$ = this.store.pipe(select(selectAllHealthGoals));
        this.allowAddToNote$ = this.service.allowAddToNote();
        this.indicatedHealthGoals$ = this.store.pipe(select(selectIndicatedHealthGoals));
        this.notIndicatedHealthGoals$ = this.store.pipe(select(selectNotIndicatedHealthGoals));
        this.newHealthGoalErrors$ = this.store.pipe(select(selectHealthGoalError, { id: newEntityId }));
    };
    HealthMaintenanceComponent.prototype.resetNewHealthGoalForm = function () {
        this.store.dispatch(new InitNewHealthGoal());
        this.newHealthGoalFormValue = buildNewHealthGoalForm();
    };
    HealthMaintenanceComponent.prototype.saveNewHealthGoal = function (value) {
        var _this = this;
        this.store.dispatch(new AddHealthGoal({ patientId: this.patientId, healthGoal: value }));
        // Wait to collapse the form
        waitFor(this.store.pipe(select(selectHealthGoalWithMetadata, { id: value.id })), function (status) { return !status.pending && !status.error; }).subscribe(function () {
            _this.resetNewHealthGoalForm();
            _this.newHealthGoalCollapse.collapse();
        });
    };
    HealthMaintenanceComponent.prototype.saveHealthMaintenanceNote = function (value, complete) {
        this.patientActions.updatePatient(this.patientId, {
            healthMaintenanceNoteAttributes: value,
        });
        this.patientSelectors.state
            .pipe(filter(function (state) { return !state.loading; }), tap(function (state) { return complete(state.entity, state.error); }), take(1))
            .subscribe();
    };
    return HealthMaintenanceComponent;
}());
export { HealthMaintenanceComponent };
