import { StateSelector } from '@app/core/store/shared/state';

import { LabOrder } from '@app/modules/orders/shared/lab-order.type';
import {
  isLabOrderDue,
  isProcedureOrderDue,
  isVaccineOrderDue,
  OrderTypes,
} from '../shared/order-utils';
import {
  ConsultOrder,
  Order,
  ProcedureOrder,
  VaccineOrderListItem,
} from '../shared/order.type';

export class OrdersSelector extends StateSelector<Order> {
  get activeOrders() {
    return this.filter(order => !this.isInactiveOrder(order));
  }

  get inactiveOrders() {
    return this.filter(order => this.isInactiveOrder(order));
  }

  get dueOrders() {
    return this.filter(order => this.isOrderDue(order));
  }

  isOrderDue(order: Order): boolean {
    switch (order.type) {
      case OrderTypes.ConsultOrder:
      case OrderTypes.ProcedureOrder:
        return isProcedureOrderDue(<ProcedureOrder>order);
      case OrderTypes.VaccineOrder:
        return isVaccineOrderDue(<VaccineOrderListItem>order);
      case OrderTypes.LabOrder:
        return isLabOrderDue(<LabOrder>order);
      case OrderTypes.BasicFollowUpOrder:
      default:
        return false;
    }
  }

  isInactiveOrder(order: Order): boolean {
    switch (order.type) {
      case OrderTypes.ConsultOrder:
        const inactive_states = [
          'completed',
          'redacted',
          'declined',
          'patient_completed',
        ];
        return inactive_states.includes(order.status);
      case OrderTypes.ProcedureOrder:
        return (
          !order.reviewable &&
          (order.status === 'completed' ||
            order.status === 'redacted' ||
            order.status === 'reviewed')
        );
      case OrderTypes.VaccineOrder:
        return !(<VaccineOrderListItem>order).isActive;
      case OrderTypes.LabOrder:
        return (
          order.status === 'cancelled' ||
          order.status === 'collected' ||
          order.status === 'declined'
        );
      default:
        return false;
    }
  }
}
