import { NgModule } from '@angular/core';

import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { SharedModule } from '@app/shared';

import { HealthBackgroundFormComponent } from './components/health-background-form/health-background-form.component';
import { HealthBackgroundComponent } from './components/health-background/health-background.component';
import { HealthBackgroundService } from './shared';

@NgModule({
  imports: [SharedModule],
  declarations: [HealthBackgroundComponent, HealthBackgroundFormComponent],
  providers: [HealthBackgroundService, SummariesActions, SummariesSelectors],
  exports: [HealthBackgroundComponent],
})
export class HealthBackgroundModule {}
