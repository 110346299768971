import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MarkdownModule } from 'ngx-markdown';

import { SharedModule } from '@app/shared';

import { OrdersStoreModule } from '../../features/orders-list/store/orders.store.module';
import { ProblemFormComponent } from './components/problem-form/problem-form.component';
import { ProblemHistoryComponent } from './components/problem-history/problem-history.component';
import { ProblemsComponent } from './components/problems.component';
import { ProblemsApiService } from './shared/problems-api.service';
import { ProblemActions } from './store/problems.actions';
import { ProblemsEffects } from './store/problems.effects';
import { problemsReducer, problemsStatePath } from './store/problems.reducer';

@NgModule({
  imports: [
    SharedModule,
    MarkdownModule.forChild(),
    StoreModule.forFeature(problemsStatePath, problemsReducer),
    EffectsModule.forFeature([ProblemsEffects]),
    OrdersStoreModule,
  ],
  declarations: [
    ProblemsComponent,
    ProblemFormComponent,
    ProblemHistoryComponent,
  ],
  providers: [ProblemsApiService, ProblemActions],
  exports: [ProblemsComponent],
})
export class ProblemsModule {}
