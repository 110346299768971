import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { Patient, PatientActions, PatientSelectors } from '@app/core';
import { CollapseDirective } from '@app/shared/components/collapse';
import { ProxyWithFunction, waitFor } from '@app/utils';

import { buildNewHealthGoalForm } from '../../shared/health-maintenance-utils';
import { HealthMaintenanceService } from '../../shared/health-maintenance.service';
import {
  HealthGoal,
  HealthGoalErrors,
  HealthGoalForm,
  HealthGoalSummary,
  HealthGoalType,
  HealthMaintenanceNoteForm,
  newEntityId,
} from '../../shared/health-maintenance.type';

import { LoadHealthGoalTypes } from '../../store/health-goal-type.actions';
import { selectAllHealthGoalTypesSorted } from '../../store/health-goal-type.selectors';
import {
  AddHealthGoal,
  InitNewHealthGoal,
  LoadHealthGoals,
} from '../../store/health-goal.actions';
import {
  selectAllHealthGoals,
  selectHealthGoalError,
  selectHealthGoalWithMetadata,
  selectIndicatedHealthGoals,
  selectNotIndicatedHealthGoals,
} from '../../store/health-goal.selectors';
import { HealthMainteanceState } from '../../store/health-maintenance.reducer';

@Component({
  selector: 'omg-health-maintenance',
  templateUrl: './health-maintenance.component.html',
  styleUrls: ['./health-maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthMaintenanceComponent implements OnInit, OnDestroy {
  healthGoalTypes$: Observable<HealthGoalType[]>;
  healthGoals$: Observable<HealthGoal[]>;
  indicatedHealthGoals$: Observable<HealthGoalSummary[]>;
  notIndicatedHealthGoals$: Observable<HealthGoalSummary[]>;
  newHealthGoalFormValue: HealthGoalForm;
  newHealthGoalErrors$: Observable<HealthGoalErrors>;
  healthMaintenanceNote: HealthMaintenanceNoteForm;
  allowAddToNote$: Observable<boolean>;

  @ViewChild('newHealthGoalCollapse')
  private newHealthGoalCollapse: CollapseDirective;

  private patientId: number;
  private unsubscribe = new Subject();

  constructor(
    private store: Store<HealthMainteanceState>,
    private patientSelectors: PatientSelectors,
    private patientActions: PatientActions,
    private service: HealthMaintenanceService,
  ) {}

  ngOnInit() {
    this.loadHealthGoalTypes();
    this.loadHealthGoals();
    this.loadHealthMaintenanceNote();
    this.setupSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get focusOnNewFormKey() {
    return `healthGoalFocus-${newEntityId}`;
  }

  onAddToNote() {
    this.service.addToNote();
  }

  onNewHealthGoalFormExpanded(expanded: boolean) {
    if (expanded) {
      this.resetNewHealthGoalForm();
    }
  }

  onSaveNewHealthGoal(value: HealthGoalForm) {
    this.saveNewHealthGoal(value);
  }

  onSaveNote(event: { value: HealthMaintenanceNoteForm; complete: any }) {
    this.saveHealthMaintenanceNote(event.value, event.complete);
  }

  trackByFn = (index, value) => (value && value.id) || index;

  private loadHealthGoalTypes() {
    this.store.dispatch(new LoadHealthGoalTypes());
    this.healthGoalTypes$ = this.store.pipe(
      select(selectAllHealthGoalTypesSorted),
    );
  }

  private loadHealthGoals() {
    this.patientSelectors.patient
      .pipe(
        filter(p => !!p),
        tap(patient => {
          this.patientId = patient.id;
          this.store.dispatch(new LoadHealthGoals(patient.id));
        }),
        take(1),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private loadHealthMaintenanceNote() {
    this.patientSelectors.healthMaintenanceNote
      .pipe(
        filter(Boolean),
        map(note => ({ content: note.content })),
        tap(value => (this.healthMaintenanceNote = value)),
        take(1),
      )
      .subscribe();
  }

  private setupSelectors() {
    this.healthGoals$ = this.store.pipe(select(selectAllHealthGoals));
    this.allowAddToNote$ = this.service.allowAddToNote();

    this.indicatedHealthGoals$ = this.store.pipe(
      select(selectIndicatedHealthGoals),
    );

    this.notIndicatedHealthGoals$ = this.store.pipe(
      select(selectNotIndicatedHealthGoals),
    );

    this.newHealthGoalErrors$ = this.store.pipe(
      select(selectHealthGoalError, { id: newEntityId }),
    );
  }

  private resetNewHealthGoalForm() {
    this.store.dispatch(new InitNewHealthGoal());
    this.newHealthGoalFormValue = buildNewHealthGoalForm();
  }

  private saveNewHealthGoal(value: HealthGoalForm) {
    this.store.dispatch(
      new AddHealthGoal({ patientId: this.patientId, healthGoal: value }),
    );

    // Wait to collapse the form
    waitFor(
      this.store.pipe(select(selectHealthGoalWithMetadata, { id: value.id })),
      status => !status.pending && !status.error,
    ).subscribe(() => {
      this.resetNewHealthGoalForm();
      this.newHealthGoalCollapse.collapse();
    });
  }

  private saveHealthMaintenanceNote(
    value: HealthMaintenanceNoteForm,
    complete: ProxyWithFunction<Patient, any>,
  ) {
    this.patientActions.updatePatient(this.patientId, {
      healthMaintenanceNoteAttributes: value,
    });

    this.patientSelectors.state
      .pipe(
        filter(state => !state.loading),
        tap(state => complete(state.entity, state.error)),
        take(1),
      )
      .subscribe();
  }
}
