import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';

import {
  mapHealthBackgroundResponseToEntity,
  mapToUpdateHealthBackgroundPayload,
} from './health-background-utils';
import {
  HealthBackground,
  HealthBackgroundNote,
  HealthBackgroundNoteType,
  HealthBackgroundResponse,
} from './health-background.type';

const healthBackgroundRoute = (patientId: number) =>
  patientRoute(patientId, '/health_background');

@Injectable()
export class HealthBackgroundService {
  constructor(
    private api: ApiService,
    private summariesActions: SummariesActions,
  ) {}

  get(patientId: number) {
    return this.api
      .get<HealthBackgroundResponse>(healthBackgroundRoute(patientId))
      .pipe(map(mapHealthBackgroundResponseToEntity));
  }

  update(
    patientId: number,
    note: HealthBackgroundNote,
    noteType: HealthBackgroundNoteType,
  ) {
    return this.api
      .update<HealthBackgroundResponse>(
        healthBackgroundRoute(patientId),
        mapToUpdateHealthBackgroundPayload(noteType, note.notes),
      )
      .pipe(map(mapHealthBackgroundResponseToEntity));
  }

  linkSection(patientId: number, entity: HealthBackground) {
    this.summariesActions.linkHealthBackground({
      patientId,
      healthBackground: { ...entity },
    });
  }
}
