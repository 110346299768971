var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from '@app/core';
import { ProblemsApiService } from '../shared/problems-api.service';
import { DeleteProblemError, DeleteProblemSuccess, GetProblemHistoryError, GetProblemHistorySuccess, GetProblemsError, GetProblemsSuccess, ProblemActionTypes, ReactivateProblemError, ReactivateProblemSuccess, ResolveProblemError, ResolveProblemSuccess, SaveProblemError, SaveProblemSuccess, UpdateProblemError, UpdateProblemSuccess, } from './problems.actions';
var ProblemsEffects = /** @class */ (function () {
    function ProblemsEffects(action$, problemsApi, errorHandler) {
        var _this = this;
        this.action$ = action$;
        this.problemsApi = problemsApi;
        this.errorHandler = errorHandler;
        this.getProblems$ = this.action$.pipe(ofType(ProblemActionTypes.GET_PROBLEMS), switchMap(function (action) {
            return _this.problemsApi
                .query(action.payload.patientId, action.payload.params)
                .pipe(map(function (problems) { return new GetProblemsSuccess(problems); }), catchError(function (error) {
                return of(new GetProblemsError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.saveProblem$ = this.action$.pipe(ofType(ProblemActionTypes.SAVE_PROBLEM), switchMap(function (action) {
            return _this.problemsApi
                .save(action.payload.patientId, action.payload.problem)
                .pipe(map(function (problem) { return new SaveProblemSuccess(problem); }), catchError(function (error) {
                return of(new SaveProblemError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
        this.updateProblem$ = this.action$.pipe(ofType(ProblemActionTypes.UPDATE_PROBLEM), switchMap(function (action) {
            return _this.problemsApi
                .update(action.payload.patientId, action.payload.problem)
                .pipe(map(function (problem) { return new UpdateProblemSuccess(problem); }), catchError(function (error) {
                return of(new UpdateProblemError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.problem.id,
                }));
            }));
        }));
        this.resolveProblem$ = this.action$.pipe(ofType(ProblemActionTypes.RESOLVE_PROBLEM), switchMap(function (action) {
            return _this.problemsApi
                .resolve(action.payload.patientId, action.payload.problemId)
                .pipe(map(function (problem) { return new ResolveProblemSuccess(problem); }), catchError(function (error) {
                return of(new ResolveProblemError(_this.errorHandler.handleErrorSafe(error), { id: action.payload.problemId }));
            }));
        }));
        this.reactivateProblem$ = this.action$.pipe(ofType(ProblemActionTypes.REACTIVATE_PROBLEM), switchMap(function (action) {
            return _this.problemsApi
                .reactivate(action.payload.patientId, action.payload.problemId)
                .pipe(map(function (problem) { return new ReactivateProblemSuccess(problem); }), catchError(function (error) {
                return of(new ReactivateProblemError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.problemId,
                }));
            }));
        }));
        this.deleteProblem$ = this.action$.pipe(ofType(ProblemActionTypes.DELETE_PROBLEM), switchMap(function (action) {
            return _this.problemsApi
                .delete(action.payload.patientId, action.payload.problemId)
                .pipe(map(function (problem) { return new DeleteProblemSuccess(problem); }), catchError(function (error) {
                return of(new DeleteProblemError(_this.errorHandler.handleErrorSafe(error), {
                    id: action.payload.problemId,
                }));
            }));
        }));
        this.getProblemHistory$ = this.action$.pipe(ofType(ProblemActionTypes.GET_PROBLEM_HISTORY), mergeMap(function (action) {
            return _this.problemsApi
                .queryProblemHistories(action.payload.patientId, action.payload.problemId)
                .pipe(map(function (history) { return new GetProblemHistorySuccess(history); }), catchError(function (error) {
                return of(new GetProblemHistoryError(_this.errorHandler.handleErrorSafe(error)));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "getProblems$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "saveProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "updateProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "resolveProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "reactivateProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "deleteProblem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ProblemsEffects.prototype, "getProblemHistory$", void 0);
    return ProblemsEffects;
}());
export { ProblemsEffects };
