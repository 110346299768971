var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ElementRef, OnInit, } from '@angular/core';
import { PopoverComponent } from './popover.component';
import { PopoverService } from './popover.service';
// tslint:disable no-input-rename
var PopoverTriggerDirective = /** @class */ (function () {
    function PopoverTriggerDirective(elementRef, popoverService) {
        this.elementRef = elementRef;
        this.popoverService = popoverService;
        this.triggerOn = 'click';
    }
    Object.defineProperty(PopoverTriggerDirective.prototype, "isOpen", {
        get: function () {
            return this.popoverRef && this.popoverRef.isOpen;
        },
        enumerable: true,
        configurable: true
    });
    PopoverTriggerDirective.prototype.ngOnInit = function () { };
    PopoverTriggerDirective.prototype.toggle = function () {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open();
        }
    };
    PopoverTriggerDirective.prototype.open = function () {
        if (this.isOpen || !this.popover.enabled) {
            return;
        }
        this.popoverRef = this.popoverService.open(__assign({}, this.popover, { triggerOn: this.triggerOn, origin: this.elementRef.nativeElement }));
        this.initPopover();
    };
    PopoverTriggerDirective.prototype.close = function () {
        if (this.isOpen) {
            this.popoverRef.close();
        }
    };
    PopoverTriggerDirective.prototype.initPopover = function () {
        if (this.popover instanceof PopoverComponent) {
            this.popover.popoverRef = this.popoverRef;
        }
    };
    PopoverTriggerDirective.prototype.onClick = function (event) {
        if (this.triggerOn === 'click') {
            this.toggle();
        }
    };
    PopoverTriggerDirective.prototype.onMouseEnter = function (event) {
        if (this.triggerOn === 'hover') {
            this.open();
        }
    };
    PopoverTriggerDirective.prototype.onMouseLeave = function (event) {
        if (this.triggerOn === 'hover') {
            this.close();
        }
    };
    return PopoverTriggerDirective;
}());
export { PopoverTriggerDirective };
