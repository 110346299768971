import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { PatientNavComponent } from './components/patient-nav/patient-nav.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PatientNavComponent],
  exports: [PatientNavComponent],
  providers: [],
})
export class PatientNavModule {}
