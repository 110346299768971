var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CollapseDirective } from '@app/shared/components';
import { waitFor } from '@app/utils';
import { mapHealthGoalToFormValue } from '../../shared/health-maintenance-utils';
import { DeleteHealthGoal, UpdateHealthGoal, } from '../../store/health-goal.actions';
import { selectHealthGoalError, selectHealthGoalSummaryWithMetadata, } from '../../store/health-goal.selectors';
var HealthGoalItemComponent = /** @class */ (function () {
    function HealthGoalItemComponent(store) {
        this.store = store;
        this.formLoaded = false;
    }
    Object.defineProperty(HealthGoalItemComponent.prototype, "healthGoal", {
        get: function () {
            return this._healthGoal;
        },
        set: function (value) {
            if (this._healthGoal !== value) {
                this._healthGoal = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    HealthGoalItemComponent.prototype.ngOnInit = function () { };
    HealthGoalItemComponent.prototype.onExpanded = function (expanded) {
        if (expanded) {
            this.loadForm();
        }
    };
    HealthGoalItemComponent.prototype.onCancel = function () {
        this.collapseRef.collapse();
    };
    HealthGoalItemComponent.prototype.onSave = function (value) {
        this.saveHealthGoal(value);
    };
    HealthGoalItemComponent.prototype.onDelete = function (value) {
        this.deleteHealthGoal(value);
    };
    HealthGoalItemComponent.prototype.onIndicatedChange = function () {
        this.loadForm(); // ensure form is loaded
        var formValue = this.healthGoalFormValue;
        this.store.dispatch(new UpdateHealthGoal(__assign({}, formValue, { indicated: !formValue.indicated })));
    };
    HealthGoalItemComponent.prototype.loadForm = function () {
        if (!this.formLoaded) {
            this.setFormValue(this.healthGoal);
            this.setupSelectors();
            this.formLoaded = true;
        }
    };
    HealthGoalItemComponent.prototype.setFormValue = function (value) {
        this.healthGoalFormValue = mapHealthGoalToFormValue(value);
    };
    HealthGoalItemComponent.prototype.setupSelectors = function () {
        this.healthGoalErrors$ = this.store.pipe(select(selectHealthGoalError, { id: this.healthGoal.id }));
    };
    HealthGoalItemComponent.prototype.saveHealthGoal = function (value) {
        var _this = this;
        this.store.dispatch(new UpdateHealthGoal(value));
        waitFor(this.store.pipe(select(selectHealthGoalSummaryWithMetadata, {
            id: value.id,
        })), function (status) { return !status.pending && !status.error; }).subscribe(function (data) {
            _this.healthGoal = data.entity;
            _this.setFormValue(data.entity);
            _this.collapseRef.collapse();
        });
    };
    HealthGoalItemComponent.prototype.deleteHealthGoal = function (value) {
        var confirmation = window.confirm('Are you sure you want to delete this health goal?');
        if (confirmation) {
            this.store.dispatch(new DeleteHealthGoal(value.id));
        }
    };
    return HealthGoalItemComponent;
}());
export { HealthGoalItemComponent };
